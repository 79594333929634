<template>
  <RfSimpleCard title="User" v-if="app">
    <div class="segment-create-section" v-if="showLtv">
      <div class="segment-create-slider">
        <div class="segment-create-title">
          <h4>Lifetime Value</h4>
          <div>0-10 represents the normalized scale of actual lifetime value.</div>
        </div>
        <RfRangeSlider
          v-model="segment.ltv_range"
          style="margin-left: 0px"
          :max="10"
          :min="0"
          :step="1"
          :isNormalized="true"
        />
      </div>
    </div>
    <div class="segment-create-section" v-if="showCss">
      <div class="segment-create-slider">
        <div class="segment-create-title">
          <h4>Customer Satisfaction Score</h4>
          <div>0-10 represents the normalized scale of actual customer satisfaction score.</div>
        </div>
        <RfRangeSlider
          v-model="segment.css_range"
          :max="10"
          :min="0"
          :step="1"
          :isNormalized="true"
          style="margin-left: 0px"
        />
      </div>
    </div>
    <div class="segment-create-section" v-if="showFraudScore">
      <div class="segment-create-slider">
        <div class="segment-create-title">
          <h4>
            Fraud Score
            <v-icon>memory</v-icon>
          </h4>
          <div>
            System generated via machine learning. Specify a range of fraud scores to be included in
            this segment. O is low and 10 is high.
          </div>
        </div>
        <RfRangeSlider
          v-model="segment.fraud_score_range"
          style="margin-left: 0px"
          :max="10"
          :min="0"
          :step="1"
          :isNormalized="true"
        />
      </div>
    </div>
    <div class="segment-create-section" v-if="showFraudScore">
      <div class="segment-create-slider">
        <div class="segment-create-title">
          <h4>
            Churn Score
            <v-icon>memory</v-icon>
          </h4>
          <div>
            System generated via machine learning. Specify a range of fraud scores to be included in
            this segment. O is low and 10 is high.
          </div>
        </div>
        <RfRangeSlider
          v-model="segment.churn_score_range"
          style="margin-left: 0px"
          :max="10"
          :min="0"
          :step="1"
          :isNormalized="true"
        />
      </div>
    </div>
    <!-- Disabling this field for now, somewhat confusing on how a customer might use it RR -->
    <!-- <md-card-content class="card-content md-layout">
      <div class="md-layout-item md-size-90">
        <h4>Session Duration</h4>
        <span class="md-caption">Specify a range of session durations between 0 hrs to 10 hrs.</span>
        <RfRangeSlider
          v-model="segment.session_duration_range"
          style="margin: 20px 0px 0px 4px;"
          :max="10"
          :min="0"
          :step="1"
          :isNormalized="true"
        />
      </div>
    </md-card-content>-->
  </RfSimpleCard>
</template>

<script>
import "vue-slider-component/theme/default.css";
import ObjectUtils from "@/utils/ObjectUtils";
import RfRangeSlider from "@/components/RfRangeSlider.vue";
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import { useLegacyTmpStore } from "@/pinia/legacyTmpStore";
import { useAppsStore } from "@/pinia/appsStore";
import { useCustomFields } from "@/utils/composables/useCustomFields";

export default {
  name: "RfUser",
  components: { RfSimpleCard, RfRangeSlider },
  props: ["modelSegment"],
  setup: () => ({
    legacyTmpStore: useLegacyTmpStore(),
    appsStore: useAppsStore(),
    customFields: useCustomFields({ fieldType: "system" }),
  }),
  data() {
    return {
      errorSessionRange: false,
      segment: this.initModel(this.modelSegment),
    };
  },
  watch: {
    modelSegment(to) {
      this.segment = this.initModel(to);
    },
    mlTrait(to) {
      if (to === "fraud_score") {
        this.segment.fraud_score_range = [0, 0];
      } else if (to === "ltv") {
        this.segment.ltv_range = [0, 10];
      } else if (to === "cac") {
        this.segment.cac_range = [0, 10];
      } else if (to === "css") {
        this.segment.css_range = [0, 10];
      } else if (to === "usage") {
        this.segment.usage_range = [0, 10];
      }
    },
  },
  methods: {
    initModel(modelSegment) {
      const data = {
        user_ids: [],
        ip_addresses: [],
        fraud_score_range: [0, 0],
        ltv_range: [0, 10],
        usage_range: [0, 10],
        css_range: [0, 10],
        session_duration_range: [0, 10],
        churn_score_range: [0, 10],
      };
      if (modelSegment && modelSegment.filter.user) {
        ObjectUtils.mergeObjectByKeys(data, modelSegment.filter.user, Object.keys(data));
      }

      [
        "ltv_range",
        "usage_range",
        "fraud_score_range",
        "css_range",
        "session_duration_range",
        "churn_score_range",
      ].forEach(item => {
        if (data[item].length === 0) {
          data[item] = [0, 10];
        }
      });
      return data;
    },
    fillModelSegment(modelSegment) {
      [
        "ltv_range",
        "usage_range",
        "fraud_score_range",
        "css_range",
        "session_duration_range",
        "churn_score_range",
      ].forEach(item => {
        if (this.segment[item][0] > this.segment[item][1]) {
          [this.segment[item][0], this.segment[item][1]] = [
            this.segment[item][1],
            this.segment[item][0],
          ];
        }

        if (this.segment[item][0] === 0 && this.segment[item][1] === 10) {
          this.segment[item] = [];
        }
      });
      ObjectUtils.mergeObjectByKeys(
        modelSegment.filter.user,
        this.segment,
        Object.keys(this.segment),
      );
    },
    isDisabledByApp(fieldName) {
      if (!this.app) return false;
      const matched = this.customFields.appSystemFields.value.filter(
        item => item.label === fieldName,
      );
      if (matched.length > 0) {
        return matched[0].is_disabled;
      }
      return false;
    },
  },
  computed: {
    app() {
      return this.appsStore.app;
    },
    mlTrait() {
      return this.legacyTmpStore.mlTrait;
    },
    showUsage() {
      return !this.isDisabledByApp("usage");
    },
    showLtv() {
      return !this.isDisabledByApp("ltv");
    },
    showCss() {
      return !this.isDisabledByApp("css");
    },
    showFraudScore() {
      if (this.app) {
        return this.app.flags.fraud_check;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.segment-create-section {
  margin-bottom: 2rem;
}
.segment-create-title {
  padding: 0 0 1rem 1rem;
}
</style>
