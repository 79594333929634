<template>
  <div>
    <v-select
      outlined
      dense
      hide-details
      :items="parentItems"
      item-text="name"
      item-value="value"
      v-model="parentModel"
      :disabled="disabled"
      @change="updateParent"
      placeholder="Select configuration"
      class="segment-builder--subtypes _subtypes"
      height="32px"
      background-color="#ffffff"
      style="min-width: 180px"
      :menu-props="{ maxHeight: 410, offsetY: true }"
    />
    <div class="segment-builder--item next-selection" v-if="parentModel">
      <v-icon>chevron_right</v-icon>
      <v-select
        multiple
        outlined
        dense
        hide-details
        :items="childItems"
        item-text="name"
        :disabled="disabled"
        item-value="value"
        v-model="childModel"
        @change="updateChild"
        chips
        placeholder="All"
        background-color="#fff"
      />
    </div>
  </div>
</template>

<script>
import { useAppsStore } from "@/pinia/appsStore";
import {
  Browsers,
  DeviceOSes,
  DeviceTypes,
  Devices,
  getSegmentTypeDevices,
} from "@/utils/constants/DevicesConstants";

export default {
  name: "RfDevice",
  props: ["filter", "disabled"],
  setup: () => ({
    devices: Devices,
    deviceType: Object.values(DeviceTypes),
    deviceOs: Object.values(DeviceOSes),
    browsers: Object.values(Browsers),
    appsStore: useAppsStore(),
  }),
  data: () => ({ parentModel: null, childModel: null }),
  computed: {
    app() {
      return this.appsStore.app;
    },
    parentItems() {
      return Object.values(getSegmentTypeDevices(this.app?.flags?.custom_devices));
    },
    customDevices() {
      return this.app.custom_devices.map(el => ({ name: el.name, value: el.label }));
    },
    parent() {
      return this.parentItems.find(parent => parent.value === this.parentModel);
    },
    child() {
      return this.parent.child;
    },
    childItems() {
      // maps to deviceType, deviceOs, browsers, devices
      return this[this.child];
    },
    device() {
      return this.filter.device;
    },
    deviceKey() {
      return Object.keys(this.device)[0];
    },
    values() {
      if (!this.parent) return [];

      return this.device[this.parent.value];
    },
  },
  methods: {
    updateParent() {
      this.childModel = [];
      this.updateFilter();
    },
    updateChild() {
      this.updateFilter();
    },
    updateFilter() {
      // {"device":{"os_family":["Mac OS X","iOS","Linux"]}}
      const filter = { device: { [this.parentModel]: this.childModel } };

      // tied to v-bind:model.sync in RfSegmentBuilder
      // props down events up
      this.$emit("update:model", filter);
    },
    prepareFilter() {
      // filter props comes like that
      // {"device":{"os_family":["Mac OS X","iOS","Linux"]}}
      // first dropdown map to values - type, os, target..
      this.parentModel = this.deviceKey;
      this.childModel = this.values;
    },
  },
  mounted() {
    this.prepareFilter();
  },
};
</script>
