import { useExperimentsStore } from "@/pinia/experimentsStore";
import { saveToCsvFile } from "./CsvUtils";

export default {
  computed: {
    experimentsStore: () => useExperimentsStore(),
  },
  methods: {
    exportExperimentsToCsv(variations) {
      const headers = [
        "Variation",
        "Users",
        ...(this.experimentsStore.customGoalInteraction !== "seen" ? ["Impressions"] : []),
        this.interactionsTitle,
        ...(this.shouldShowCTR ? [this.ctrTitle] : []),
        ...(this.hasCustomGoal ? ["Goal Completion"] : []),
        "Conversion Rate",
        ...(this.accept2Enabled && !this.hasCustomGoal
          ? [
              "Clicks (additional)",
              "CTR (additional)",
              `${this.customGoalName} Conversion Rate (additional)`,
            ]
          : []),
      ];
      const values = [];
      variations.forEach(variation => {
        values.push([
          variation.variantName || variation.name,
          `"${this.formatUsers(variation)}"`,
          ...(this.experimentsStore.customGoalInteraction !== "seen"
            ? [`"${this.formatImpressions(variation)}"`]
            : []),
          `"${this.formatGoals(variation)}"`,
          ...(this.shouldShowCTR ? [`"${this.formatCTR(variation)}"`] : []),
          ...(this.hasCustomGoal ? [`"${this.formatCustomGoals(variation)}"`] : []),
          `"${this.formatConversion(variation)}"`,
          ...(this.accept2Enabled && !this.hasCustomGoal
            ? [
                `"${this.formatGoals2(variation)}"`,
                `"${this.formatCTR2(variation)}"`,
                `"${this.formatConversion2(variation)}"`,
              ]
            : []),
        ]);
      });

      values.push([
        "Total",
        `"${this.totalUsersText || this.totalUsersData || ""}"`,
        ...(this.experimentsStore.customGoalInteraction !== "seen"
          ? [`"${this.totalImpressionsText || this.totalImpressionsData || ""}"`]
          : []),
        `"${this.totalGoalsText || this.totalGoalsData || ""}"`,
        ...(this.shouldShowCTR ? [this.displayCTR == null ? "" : `"${this.displayCTR}"`] : []),
        ...(this.hasCustomGoal
          ? [`"${this.totalCustomGoalsText || this.totalCustomGoalsData || ""}"`]
          : []),
        "",
        "",
        ...(this.accept2Enabled && !this.hasCustomGoal
          ? [
              `"${this.totalGoals2Text || this.totalGoals2Data || ""}"`,
              this.totalCTR2 == null ? "" : `"${this.totalCTR2}"`,
              "",
            ]
          : []),
      ]);

      saveToCsvFile([headers, ...values], "experiment_export");
    },
  },
};
