<template>
  <div class="schedule-add-date-time-wrapper">
    <div class="schedule-blurbs-button">
      <div class="schedule-blurbs">
        If dayparts are not specified, the {{ itemType }} is always active.
      </div>
      <div style="text-align: right">
        <v-btn depressed color="accent" @click="openModal()">
          <v-icon left>add</v-icon>
          <span>Add</span>
        </v-btn>
      </div>
    </div>
    <v-simple-table class="rf-settings-table-data">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Day of the week</th>
            <th class="text-left">Start Time</th>
            <th class="text-left">End time</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-if="schedule.length > 0">
          <tr v-for="(item, index) in schedule" :key="index">
            <td>{{ weekDays[item[0]] }}</td>
            <td>{{ hhmmIntegerTohhmm(item[1]) }}</td>
            <td>{{ hhmmIntegerTohhmm(item[2]) }}</td>
            <td align="right">
              <v-btn depressed small @click="deleteTimeRange(index)">
                <v-icon size="18">clear</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td align="center" colspan="4">No date and time has been set up.</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog v-model="addDateTimeDialog" max-width="550">
      <v-card flat>
        <v-card-title>Add new schedule</v-card-title>
        <v-card-text class="add-schedule-form-wrapper">
          <div class="add-schedule-date-week">
            <v-select
              label="Day of the week"
              v-model="weekDay"
              name="weekDay"
              id="weekDay"
              :items="weekDays"
              outlined
              hide-details
            />
          </div>
          <div class="schedule-add-time">
            <rf-time-picker ref="startTime" label="Start Time" :error="errorTimeRange" />
            <rf-time-picker ref="endTime" label="End Time" :error="errorTimeRange" />
          </div>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed outlined class="cancel-btn" width="100px" @click="closeModal()"
            >Cancel</v-btn
          >
          <v-btn large depressed color="success" width="160px" @click="appendDayTimeRange"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import StringUtils from "@/utils/StringUtils";
import { useToastsStore } from "@/pinia/toastsStore";
import RfTimePicker from "./RfTimePicker.vue";

export default {
  name: "RfSchedulePicker",
  setup: () => ({ toastsStore: useToastsStore() }),
  components: { RfTimePicker },
  props: ["scheduleRanges", "itemType"],
  data() {
    return {
      weekDay: "Sunday",
      schedule: [...this.scheduleRanges],
      errorTimeRange: false,
      addDateTimeDialog: false,
    };
  },
  watch: {
    scheduleRanges(to) {
      this.schedule = [...to];
    },
  },
  computed: {
    weekDays() {
      return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    },
  },
  methods: {
    openModal() {
      this.addDateTimeDialog = true;
    },
    closeModal() {
      this.addDateTimeDialog = false;
    },
    appendDayTimeRange() {
      const startTime = this.$refs.startTime.time;
      const endTime = this.$refs.endTime.time;
      if (startTime >= endTime && !(startTime == 47 && endTime == 0)) {
        this.errorTimeRange = true;
        this.toastsStore.create({ type: "error", body: "Start time must be less than end time" });
      } else {
        this.errorTimeRange = false;
        this.schedule = [
          ...this.schedule,
          [
            this.weekDays.indexOf(this.weekDay),
            StringUtils.hhmmBy30MinIntervalIndex2(startTime),
            StringUtils.hhmmBy30MinIntervalIndex2(endTime),
          ],
        ];
        this.$refs.startTime.time = 0;
        this.$refs.endTime.time = 0;
        this.closeModal();
      }
    },
    deleteTimeRange(index) {
      this.schedule = this.schedule.filter((_, pos) => pos != index);
    },
    hhmmIntegerTohhmm(hhmm) {
      return StringUtils.hhmm2IntervalIndex(hhmm);
    },
  },
};
</script>

<style scoped>
.md-table-row:first-child td {
  border: 0;
}
</style>
