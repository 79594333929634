<template>
  <div>
    <RfPromoDetailsInfoRow title="Settings">
      <template v-slot:promodetail>
        <div>
          {{ renderSettings }}
        </div>
        <div class="pd-info-edit">
          <RfButton
            icon-size="16"
            icon="edit"
            color="accent"
            x-small
            :disabled="editDisabled"
            @click="showSettingsDetailDialog"
          />
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <v-dialog
      v-model="showSettingsInfoDialog"
      width="500"
      content-class="show-promo-details-actions"
      persistent
    >
      <v-card flat>
        <v-card-title class="headline">Settings</v-card-title>
        <v-card-text>
          <v-checkbox
            v-model="bullets"
            label="Slider bullets"
            :value="false"
            hide-details
            style="margin-bottom: 1.2rem"
          ></v-checkbox>
          <v-text-field
            dense
            outlined
            persistent-hint
            autofocus
            placeholder="0"
            min="0"
            v-model.trim="autoplay"
            ref="autoplay"
            label="Autoplay"
            type="number"
            hint="Specify a number in seconds. Use 0 to disable"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed class="cancel-btn" outlined width="100px" @click="closeDialog"
            >Close</v-btn
          >
          <v-btn large @click="submitConfiguration" width="200px" color="primary" depressed>
            <v-icon small left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import { usePromptStore } from "@/pinia/promptStore";

export default {
  name: "RfSliderSettings",
  setup: () => ({ toastsStore: useToastsStore(), promptStore: usePromptStore() }),
  props: ["path", "editDisabled"],
  components: { RfPromoDetailsInfoRow, RfButton },
  data() {
    return {
      showSettingsInfoDialog: false,
      autoplay: this.path.settings.slider_auto_loop,
      bullets: this.path.settings.slider_bullets,
    };
  },
  computed: {
    renderSettings() {
      let msg = "Autoplay disabled";
      const seconds = this.$options.filters.pluralize("second", this.autoplay);
      if (parseInt(this.autoplay) > 0) msg = `Autoplay ${this.autoplay} ${seconds}`;

      msg += `, bullets ${this.bullets || false ? "on" : "off"}`;

      return msg;
    },
  },
  methods: {
    closeDialog() {
      this.showSettingsInfoDialog = false;
    },
    showSettingsDetailDialog() {
      this.showSettingsInfoDialog = true;
    },
    submitConfiguration() {
      try {
        const model = cloneDeep(this.path);
        model.settings.slider_auto_loop = this.autoplay;
        model.settings.slider_bullets = this.bullets;

        this.promptStore.updatePathGroup({ appId: this.$route.params.aid, model });

        this.showSettingsInfoDialog = false;
        this.toastsStore.create({ type: "success", body: "Prompt updated successfully" });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
  },
  watch: {
    path(to) {
      this.autoplay = to.settings.slider_auto_loop;
      this.bullets = to.settings.slider_bullets;
    },
  },
};
</script>
