<template>
  <div class="modal-overlay-style banner-rf-promo h-full">
    <div
      class="rfmodal-content rf-bannerpromo-wrapper rf-modal-content-wrapper"
      :style="bannerWrapperStyle"
    >
      <div class="rfmodal-wrapper inner-modal" ref="rfdim" :style="bannerBackgroundImage">
        <RfRetentionBanner :actions="actions" :isVideo="false" />
      </div>
      <div :style="containerBackgroundImage" class="rfmodal-backgroundimage"></div>
    </div>
  </div>
</template>

<script>
import RfRetentionBanner from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionBanner.vue";
import PromoPreviewMixin from "@/utils/PromoPreviewMixin";
import { HorizontalPositions } from "@/utils/constants/PositionConstants";
import { getPosition } from "@/utils/prompts/promptHelpers";
import { baseUrlReplacer } from "@/utils/BaseUrlReplacer";
import { useAppsStore } from "@/pinia/appsStore";

export default {
  name: "RfRetentionBannerContent",
  props: {
    actions: Object,
    deviceType: String,
    model: Object,
    zoomStyle: Object,
    zoomValue: Number,
    hideButtons: { type: Boolean, default: false },
  },
  mixins: [PromoPreviewMixin],
  components: { RfRetentionBanner },
  setup: () => ({ appsStore: useAppsStore() }),
  computed: {
    bannerBackgroundImage() {
      return {
        "background-color": this.actions.rf_settings_bg_image_color,
        "background-size": this.actions.rf_settings_tile_is_responsive,
        "background-image": this.actions.rf_settings_bg_image
          ? `url(${baseUrlReplacer(this.appsStore.app, this.actions.rf_settings_bg_image)})`
          : "none",
      };
    },
    containerBackgroundImage() {
      const styles = {
        "background-color": this.actions.rf_settings_bg_image_color,
      };
      if (this.actions.rf_settings_bg_image) {
        styles["background-image"] =
          `url(${baseUrlReplacer(this.appsStore.app, this.actions.rf_settings_bg_image)})`;
      } else {
        styles["background-image"] = "none";
      }
      return styles;
    },
    bannerWrapperStyle() {
      return {
        width: this.actions.rf_widget_width,
        height: this.actions.rf_widget_height,
        ...this.zoomStyle,
        ...this.bannerPositionStyle,
      };
    },
    bannerPositionStyle() {
      const bottomStyle = {
        "top": "inherit",
        "bottom": "inherit",
        "left": "inherit",
        "right": "inherit",
        "animation-name": "animatetop",
      };
      const position = getPosition(this.actions);
      bottomStyle[position.y] = this.actions.rf_banner_position_offset_y;
      bottomStyle["animation-name"] = `animate${position.y}`;

      if (position.x === HorizontalPositions.center.value) return bottomStyle;

      bottomStyle[position.x] = this.actions.rf_banner_position_offset_x;
      return bottomStyle;
    },
    overlayBackgroundColor() {
      return { background: this.actions.rf_settings_overlay_background };
    },
  },
};
</script>
