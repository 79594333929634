<template>
  <div style="padding: 20px 30px 10px">
    <v-dialog v-model="addItemDialog" max-width="740">
      <v-card flat>
        <v-card-title>{{ modalTitle }}</v-card-title>
        <v-card-text>
          <div class="segment-promo-interaction-setting">
            <div class="spis-user-text">User</div>
            <v-select
              label="Condition"
              v-model="editingItem.positive_match"
              :items="matchTypeItems"
              outlined
              dense
              hide-details
              class="segment-interaction-condition"
            />
            <v-select
              v-model="editingItem.interaction_types"
              label="Event"
              multiple
              outlined
              dense
              hide-details
              class="segment-interaction-chips-drpdwn"
              :items="interactionTypeItems"
            />
            <v-select
              label="Prompt"
              class="segment-interaction-promotion"
              outlined
              dense
              v-model="editingItem.promo_slug"
              :items="promotionItems"
              hide-details
            />
          </div>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed class="cancel-btn" outlined width="100px" @click="closeModal()"
            >Cancel</v-btn
          >
          <v-btn
            large
            depressed
            color="accent"
            width="100px"
            @click="submitItem()"
            :disabled="!canSubmit"
            >Add</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div style="text-align: right">
      <v-btn depressed color="success" @click="addNewItem()">
        <v-icon left>add</v-icon>
        <span>Add</span>
      </v-btn>
    </div>
    <div class="all-triggers-options">
      <v-simple-table :key="computeKey">
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 10%" />
              <th style="width: 15%">Condition</th>
              <th style="width: 30%">Event</th>
              <th style="width: 30%">Prompt</th>
              <th style="width: 15%" />
            </tr>
          </thead>
          <tbody v-if="filter.values.length > 0">
            <tr v-for="(item, index) in filter.values" :key="index">
              <td>{{ index > 0 ? "AND" : "User" }}</td>
              <td>{{ item.positive_match ? "has" : "has not" }}</td>
              <td>{{ interactionTypeDisplay(item) }}</td>
              <td v-if="promoName(item.promo_slug)">{{ promoName(item.promo_slug) }}</td>
              <td v-else>
                <v-icon>warning</v-icon>Prompt not found. This Segment will not match any users
                until this item is deleted.
              </td>
              <td align="right">
                <v-icon size="18" style="margin: 0 5px" @click="editItem(item, index)">edit</v-icon>
                <v-icon size="18" style="margin: 0 5px" @click="deleteItem(index)">clear</v-icon>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="5" align="center">
                <span class="nothing-to-show-segment-promo">Nothing to show</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash-es";
import { InteractionTypeItems, MatchTypeItemsAlter } from "@/utils/constants/SegmentsConstants";
import { computed } from "vue";
import { useAllPromptsStore } from "@/pinia/allPromptsStore";
import { useCustomFields } from "@/utils/composables/useCustomFields";

export default {
  name: "RfPromoInteractions",
  props: ["app", "model"],
  setup: props => {
    const allPromptsStore = useAllPromptsStore();
    return {
      allPromptsStore,
      customFields: useCustomFields({ app: props.app }),
      matchTypeItems: Object.values(MatchTypeItemsAlter),
      interactionTypeItems: Object.values(InteractionTypeItems),
      prompts: computed(() => allPromptsStore.prompts),
    };
  },
  data() {
    return {
      defaultFilter: { values: [{}] },
      filter: { values: [] },
      addItemDialog: false,
      blankItem: { promo_slug: "", positive_match: true, interaction_types: [] },
      editingItem: {},
      editingIndex: -1,
      computeKey: 0,
    };
  },
  mounted() {
    const filter = this.model.custom_filter[this.customField.label];
    if (filter) this.filter = cloneDeep(filter);
    if (!this.prompts.length) this.allPromptsStore.getPrompts(this.$route.params.aid);
  },
  methods: {
    fillModel(model) {
      if (this.customField && this.customField.label) {
        model.custom_filter[this.customField.label] = this.filter;
      } else {
        throw new Error("Invalid custom field");
      }
    },
    openModal() {
      this.addItemDialog = true;
    },
    closeModal() {
      this.addItemDialog = false;
      this.editingItem = {};
      this.editingIndex = -1;
      this.computeKey++;
    },
    submitItem() {
      if (this.editingIndex >= 0) {
        this.filter.values[this.editingIndex] = cloneDeep(this.editingItem);
      } else {
        this.filter.values.push(cloneDeep(this.editingItem));
      }
      this.closeModal();
    },
    promoName(slug) {
      const promo = this.prompts.find(({ id }) => id === slug);
      return promo ? promo.name : null;
    },
    deleteItem(index) {
      if (index !== -1) this.filter.values.splice(index, 1);
    },
    editItem(item, index) {
      this.editingItem = cloneDeep(item);
      this.editingIndex = index;
      this.openModal();
    },
    addNewItem() {
      this.editingItem = cloneDeep(this.blankItem);
      this.openModal();
    },
    interactionTypeDisplay(item) {
      const interactions = item.interaction_types.map(interaction => {
        const displayItem = this.interactionTypeItems.find(option => option.value === interaction);
        return (displayItem && displayItem.text) || interaction;
      });
      return interactions.join(" OR ");
    },
  },
  computed: {
    promotionItems() {
      return [...this.prompts]
        .sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase()
            ? -1
            : a.name.toLowerCase() > b.name.toLowerCase()
              ? 1
              : 0,
        )
        .map(promo => ({ text: promo.name, value: promo.id }));
    },
    canSubmit() {
      return (
        this.editingItem.promo_slug &&
        this.editingItem.positive_match !== null &&
        this.editingItem.interaction_types.length &&
        !this.filter.values.find(item => JSON.stringify(item) === JSON.stringify(this.editingItem))
      );
    },
    modalTitle() {
      return this.editingIndex >= 0 ? "Edit Item" : "Add New Item";
    },
    customField() {
      return this.customFields.appCustomFields.value.find(
        field => field.data_type === "promo_interaction",
      );
    },
  },
};
</script>
