<template>
  <RfSimpleCard>
    <div class="px-7 pt-5">
      <v-card flat class="rf-custom-web-card-wrapper">
        <PrismEditor v-model="styleValues" language="js"></PrismEditor>
      </v-card>
    </div>
  </RfSimpleCard>
</template>

<script>
import "prismjs";
import "prismjs/themes/prism.css";
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import PrismEditor from "vue-prism-editor";
import { useAppsStore } from "@/pinia/appsStore";

export default {
  name: "RfCssStyles",
  components: { RfSimpleCard, PrismEditor },
  data: () => ({ success: false, newStyles: null }),
  setup: () => ({ appsStore: useAppsStore() }),
  computed: {
    styleValues: {
      get() {
        if (this.newStyles) {
          return this.newStyles;
        }
        if (this.appsStore.app) {
          return JSON.stringify(this.appsStore.app.styles, null, "\t");
        }
        return null;
      },
      set(styles) {
        this.newStyles = styles;
      },
    },
  },
  methods: {
    fillModel(model) {
      model.styles = JSON.parse(this.newStyles);
      this.newStyles = null;
    },
  },
};
</script>
