import { ref } from "vue";
import { defineStore } from "pinia";
import ApiCustomFields from "@/apis/ApiCustomFields";
import { catchable } from "./piniaUtils";

export const useCustomFieldsStore = defineStore("custom-fields", () => {
  const segmentCustomFields = ref([]);
  const segmentSystemFields = ref([]);
  const fieldTypes = { custom: segmentCustomFields, system: segmentSystemFields };

  const mutateUpdateAppCustomField = ({ customField, fieldType }) => {
    const fieldTypeVariable = fieldTypes[fieldType];
    if (!fieldTypeVariable || [null, undefined].includes(fieldTypeVariable.value)) return;
    const fieldIndex = fieldTypeVariable.value.findIndex(item => item.id === customField.id);
    if (fieldIndex > -1) {
      const copyArray = [...fieldTypeVariable.value];
      copyArray[fieldIndex] = customField;
      fieldTypeVariable.value = copyArray;
    } else {
      fieldTypeVariable.value = [...fieldTypeVariable.value, customField];
    }
  };

  const getAppCustomFields = ({ appId, fieldType }) =>
    catchable({
      t: async () => {
        const fieldTypeVariable = fieldTypes[fieldType];
        if (!fieldTypeVariable) return;
        fieldTypeVariable.value = await ApiCustomFields.getCustomFields(appId, fieldType);
      },
      loadable: true,
    });

  const deleteAppCustomField = ({ appId, fieldId, fieldType }) =>
    catchable({
      t: async () => {
        const fieldTypeVariable = fieldTypes[fieldType];
        if (!fieldTypeVariable || [null, undefined].includes(fieldTypeVariable.value)) return;
        await ApiCustomFields.deleteCustomField(appId, fieldId);
        fieldTypeVariable.value = fieldTypeVariable.value.filter(item => item.id !== fieldId);
      },
      loadable: true,
      throwable: true,
    });

  const updateAppCustomField = ({ appId, fieldId, model, fieldType }) =>
    catchable({
      t: async () => {
        mutateUpdateAppCustomField({
          customField: await ApiCustomFields.updateCustomField(appId, fieldId, model),
          fieldType,
        });
      },
      loadable: true,
    });

  const createAppCustomField = ({ appId, model, fieldType }) =>
    catchable({
      t: async () => {
        mutateUpdateAppCustomField({
          customField: await ApiCustomFields.createCustomField(appId, model),
          fieldType,
        });
      },
      loadable: true,
    });

  return {
    segmentCustomFields,
    segmentSystemFields,
    getAppCustomFields,
    deleteAppCustomField,
    updateAppCustomField,
    createAppCustomField,
  };
});
