import { defineStore } from "pinia";
import { ref } from "vue";
import ApiPaths from "@/apis/ApiPaths";
import ApiPathGroups from "@/apis/ApiPathGroups";
import { usePromptStore } from "./promptStore";
import { catchable } from "./piniaUtils";
import { useToastsStore } from "./toastsStore";

export const useAllPromptsStore = defineStore("all-prompts", () => {
  const promptStore = usePromptStore();
  const toastsStore = useToastsStore();
  const prompts = ref([]);
  const promptGroups = ref([]);

  const mutateRetentions = retentionsIn => {
    prompts.value = retentionsIn;
    if (promptStore.prompt) {
      const newItem = prompts.value.find(({ id }) => id === promptStore.prompt.id);
      if (newItem) promptStore.prompt = newItem;
    }
  };

  const setPriorityRetentions = ({ appId, pathGroups }) =>
    catchable({
      t: async () => mutateRetentions(await ApiPaths.setPriorityRetentions(appId, pathGroups)),
      c: error => toastsStore.create({ type: "error", body: `${error}. Please try again.` }),
      loadable: true,
    });

  const setOrderRetentions = ({ appId, paths }) =>
    catchable({
      t: async () => {
        mutateRetentions(await ApiPaths.setOrderRetentions(appId, paths));
        if (promptStore.promptGroup)
          promptStore.promptGroup = { ...promptStore.promptGroup, paths };
      },
      loadable: true,
      throwable: true,
    });

  const getPrompts = appId =>
    catchable({
      t: async () => mutateRetentions(await ApiPaths.getRetentions(appId)),
      loadable: true,
    });

  const getPromptGroups = appId =>
    catchable({
      t: async () => (promptGroups.value = (await ApiPathGroups.getPathGroups(appId)) || []),
      loadable: true,
    });

  const cloneRfLitePaths = ({ appId, path_ids }) =>
    catchable({
      t: async () => {
        const paths = await ApiPaths.cloneRfLitePaths(appId, { path_ids });
        getPrompts(appId);
        getPromptGroups(appId);
        return paths;
      },
      loadable: true,
    });

  return {
    prompts,
    promptGroups,
    getPrompts,
    setPriorityRetentions,
    setOrderRetentions,
    cloneRfLitePaths,
    getPromptGroups,
  };
});
