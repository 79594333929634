<template>
  <div class="sgt-builder-usage-opt">
    <v-select
      outlined
      dense
      hide-details
      :items="getUsageFilterData(customFields.appCustomFields.value)"
      item-text="name"
      :disabled="disabled"
      item-value="label"
      v-model="parentModel"
      @change="updateParent"
      placeholder="Select configuration"
      class="segment-builder--subtypes _subtypes"
      height="32px"
      background-color="#ffffff"
      style="min-width: 180px"
      :menu-props="{ maxHeight: 340, offsetY: true, minWidth: 240, maxWidth: 400 }"
    />
    <div class="segment-builder--item next-selection" v-if="parentModel">
      <v-icon>chevron_right</v-icon>
      <v-select
        outlined
        dense
        hide-details
        :items="rangeTypeItems"
        item-text="text"
        :disabled="disabled"
        item-value="value"
        v-model="rangeTypeModel"
        @change="updateRangeType"
        class="segment-builder--subtypes _subtypes usage-drpdwn"
        height="32px"
        background-color="#ffffff"
        :menu-props="{ maxHeight: 340, offsetY: true, minWidth: 240, maxWidth: 400 }"
      />
    </div>
    <div class="less-than-eq-div" v-if="showFilterOption('value')">
      <v-icon>chevron_right</v-icon>
      <v-text-field
        outlined
        dense
        v-model="lessEqGtModel"
        @input="updateLessEqGt"
        placeholder="e.g.: 12"
        background-color="#ffffff"
        :disabled="disabled"
        hide-details
        class="usage-txtfield"
      />
    </div>
    <div class="usage-range-opt-div" v-if="showFilterOption('from_to')">
      <v-icon>chevron_right</v-icon>
      <v-text-field
        hide-details
        outlined
        dense
        v-model="fromModel"
        @input="updateFromTo"
        label="From"
        background-color="#ffffff"
        :disabled="disabled"
        class="usage-txtfield"
        height="32px"
      />
      <span>-</span>
      <v-text-field
        hide-details
        outlined
        dense
        v-model="toModel"
        @input="updateFromTo"
        label="To"
        :disabled="disabled"
        background-color="#ffffff"
        class="usage-txtfield"
        height="32px"
      />
    </div>
    <div class="over-the-last-div my-auto" v-if="showFilterOption('over_the_last')">
      <template v-if="'concurrent_logins' !== parentModel">
        <v-icon>chevron_right</v-icon>
        <div class="over-the-last-txt">Over the last</div>
        <v-select
          hide-details
          :items="rawUsageTimePeriods"
          v-model="timePeriodModel"
          @change="updateTimePeriod"
          dense
          outlined
          background-color="#ffffff"
          :disabled="disabled"
          placeholder="number of days"
          class="usage-drpdwn"
          height="32px"
          :menu-props="{ offsetY: true }"
        />
      </template>
    </div>
    <div class="usage-trending-opt-div" v-if="showFilterOption('trending')">
      <v-icon>chevron_right</v-icon>
      <div>
        <div class="segment-create-slider">
          <div class="slider-label">Range:</div>
          <RfRangeSlider
            :max="10"
            :min="0"
            :step="1"
            :disabled="disabled"
            v-model="trendingRangeSliderModel"
            v-on:input="updateTrending"
            :isNormalized="true"
            style="margin-left: 0px"
          />
        </div>
        <div class="range-selector-opt">
          <v-select
            :items="trendDirection"
            label="Trend Direction"
            v-model="trendingDirectionModel"
            @change="updateTrending"
            hint="Choose trend direction for users"
            persistent-hint
            outlined
            :disabled="disabled"
            dense
            background-color="#ffffff"
            class="usg-drpdwn"
            height="32px"
          ></v-select>
          <v-select
            :items="timeDuration"
            label="Time period"
            hint="Time to compare users against"
            v-model="trendingDurationModel"
            @change="updateTrending"
            persistent-hint
            :disabled="disabled"
            outlined
            dense
            background-color="#ffffff"
            class="usg-drpdwn"
            height="32px"
          ></v-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfRangeSlider from "@/components/RfRangeSlider.vue";
import { max, without, isEmpty } from "lodash-es";
import { getUsageFilterData } from "@/utils/segmentsHelpers";
import { RangeTypes, RawUsageTimePeriods } from "@/utils/constants/SegmentsConstants";
import { useCustomFields } from "@/utils/composables/useCustomFields";

export default {
  name: "RfUsage",
  props: ["filter", "disabled"],
  components: { RfRangeSlider },
  setup: () => ({ getUsageFilterData, customFields: useCustomFields() }),
  data() {
    return {
      parentModel: null,
      rangeTypeModel: "any",
      timePeriodModel: null,
      lessEqGtModel: null,
      fromModel: null,
      toModel: null,
      trendingRangeSliderModel: [],
      tredingDirectionModel: null,
      trendingDurationModel: null,
      rangeTypes: Object.values(RangeTypes),
      rawUsageTimePeriods: Object.values(RawUsageTimePeriods),
      trendDirection: [
        { value: "trending_up", text: "Trending Up" },
        { value: "trending_down", text: "Trending Down" },
        { value: "any", text: "Any Direction" },
      ],
      timeDuration: [
        { value: "monthly", text: "Month over month" },
        { value: "weekly", text: "Week over week" },
        { value: "daily", text: "Day over day" },
      ],
      filterOptions: {
        over_the_last: [
          "at_least_once",
          "equal_to",
          "less_than_or_equal_to",
          "greater_than_or_equal_to",
          "range",
        ],
        value: ["equal_to", "less_than_or_equal_to", "greater_than_or_equal_to"],
        from_to: ["range"],
        trending: ["trending"],
      },
      rangeTypeValues: {
        any: [],
        none: ["0", "0"],
        at_least_once: ["1", "INF"],
      },
    };
  },
  computed: {
    rangeTypeItems() {
      const filterList = [];
      if (["minutes", "visits"].includes(this.parentModel)) {
        filterList.push("none");
      }
      if (this.parentModel === "conc_visits") {
        filterList.push("trending");
      }

      return this.rangeTypes.filter(type => !filterList.includes(type.value));
    },
    usageKey() {
      return Object.keys(this.filter.usage)[0];
    },
    usageData() {
      if (!this.parentModel) return {};

      return this.filter.usage[this.parentModel] || {};
    },
  },
  methods: {
    updateParent() {
      this.rangeTypeModel = this.rangeTypeItems[0].value;
      this.updateRangeType();
    },
    updateRangeType() {
      this.reset();

      const values = this.rangeTypeValues[this.rangeTypeModel];
      this.updateFilter(values);
    },
    updateFilter(values) {
      // keep original if none passed
      if (isEmpty(values)) values = this.usageData.values || [];

      const filter = {
        usage: {
          [this.parentModel]: {
            options: {
              trend: this.trendingDirectionModel,
              frequency: this.trendingDurationModel,
              range_type: this.rangeTypeModel,
              time_period_days: this.timePeriodModel,
            },
            values,
          },
        },
      };
      this.$emit("update:model", filter);
    },
    updateLessEqGt() {
      const values = {
        equal_to: [this.lessEqGtModel, this.lessEqGtModel],
        less_than_or_equal_to: ["0", this.lessEqGtModel],
        greater_than_or_equal_to: [this.lessEqGtModel, "INF"],
      }[this.rangeTypeModel];

      this.updateFilter(values);
    },
    updateTimePeriod() {
      const values = this.rangeTypeValues[this.rangeTypeModel];
      this.updateFilter(values);
    },
    updateFromTo() {
      const values = [this.fromModel, this.toModel];
      this.updateFilter(values);
    },
    updateTrending() {
      const values = this.trendingRangeSliderModel;
      this.updateFilter(values);
    },
    prepareFilter() {
      this.parentModel = this.usageKey;

      let { options, values } = this.usageData;

      if (!options) options = {};
      if (!values) values = [];

      this.trendingDirectionModel = options.trend;
      this.trendingDurationModel = options.frequency;
      this.rangeTypeModel = options.range_type;
      this.timePeriodModel = options.time_period_days;

      this.lessEqGtModel = max(without(values, "INF"));
      [this.fromModel, this.toModel] = values;
      this.trendingRangeSliderModel = values;
    },
    showFilterOption(type) {
      return this.filterOptions[type].includes(this.rangeTypeModel);
    },
    reset() {
      this.timePeriodModel = null;
      this.lessEqGtModel = null;
      this.fromModel = null;
      this.toModel = null;
      this.trendingRangeSliderModel = [0, 10];
      this.tredingDirectionModel = null;
      this.trendingDurationModel = null;
    },
  },
  mounted() {
    this.prepareFilter();
  },
};
</script>
