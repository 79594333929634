<template>
  <v-card data-cy="create-prompt-modal">
    <v-card-title class="headline">Create a new prompt</v-card-title>
    <v-dialog scrollable v-model="standardPromoTypeDialog" width="960" persistent>
      <v-card flat>
        <v-card-text class="action-error-table-modal"> </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            depressed
            class="cancel-btn"
            outlined
            width="100px"
            @click="closeStandardPromoDialog"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-text class="crPr-row-container">
      <RfDeviceTypeSelection
        v-on:setDeviceType="setDeviceType"
        v-on:setCustomDeviceIds="setCustomDeviceIds"
        :webOnly="webOnly"
      />
      <div class="crPr-row-3">
        <h3>{{ promoTypeTitle }}:</h3>
        <div class="crPr-row-description">{{ promoTypeDescription }}</div>
        <div class="crPr-row-2-ct" :class="`singles-for-${deviceType}`">
          <div
            class="crPr-single-wrap"
            :class="`crPr-card-${item.value}`"
            :key="item.value"
            v-for="item in promotionTypes"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card :elevation="hover ? 4 : 0">
                <div class="crPr-type-img">
                  <img
                    :class="{ 'max-h-full !max-w-unset': item.value === 'scheduled_push' }"
                    alt=""
                    :src="item.image"
                  />
                </div>
                <div class="crPr-type-text">
                  {{ item.name }}
                </div>
                <div v-if="true" class="crPr-type-btn">
                  <RfButton
                    small
                    button-text="Select"
                    :data-cy="`create-prompt-modal--select-${item.value}`"
                    color="primary"
                    width="100px"
                    @click="createNewRetention(item.value)"
                  />
                </div>
                <div v-else-if="zoneIsAvailable(item.value)" class="crPr-type-btn">
                  <div v-if="promotionSelected(item.value)" class="loading-compt">
                    <div class="p-load-animation">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                  <RfButton
                    v-else
                    small
                    button-text="Select"
                    :data-cy="`create-prompt-modal--select-${item.value}`"
                    :disabled="deviceType === 'custom_defined' && !customDeviceIds.length"
                    @click="createNewRetention(item.value)"
                    color="primary"
                    width="100px"
                  />
                </div>
                <div v-else class="crPr-type-btn not-avail-btns">
                  No available zones<br />
                  <router-link :to="getSettingLink">Create zone</router-link>
                </div>
              </v-card>
            </v-hover>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="modal-card-actions-div">
      <v-btn depressed class="cancel-btn" outlined @click="close" style="width: 100px">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import RfDeviceTypeSelection from "@/components/RfPathCreate/RfDeviceTypeSelection.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { isStandalone } from "@/utils/prompts/promptHelpers";
import { useAppsStore } from "@/pinia/appsStore";
import { useSequencesStore } from "@/pinia/sequencesStore";
import { usePipelinesStore } from "@/pinia/pipelinesStore";
import { computed } from "vue";

const nonOverlayTypes = ["text", "horizontal", "vertical", "tile", "email", "invisible"];

export default {
  name: "RfCreateRetentionModal",
  components: { RfDeviceTypeSelection, RfButton },
  props: ["path_groups", "webOnly"],
  setup: () => {
    const pipelinesStore = usePipelinesStore();
    const sequencesStore = useSequencesStore();
    return {
      appsStore: useAppsStore(),
      currPipeline: computed(() => pipelinesStore.currPipeline),
      currSequence: computed(() => sequencesStore.currSequence),
    };
  },
  data() {
    return {
      standardPromoTypeDialog: false,
      deviceType: "web",
      deviceTypeData: {},
      selectedPromoType: null,
      customDeviceIds: [],
    };
  },
  computed: {
    sequenceId() {
      return this.$route.query.sequence_id;
    },
    pipelineId() {
      return this.$route.query.pipeline_id;
    },
    pipelineStageId() {
      return this.$route.query.pipeline_stage_id;
    },
    overlayOnly() {
      return this.$route.query.overlay_only === "true";
    },
    promoTypeTitle() {
      return this.deviceTypeData.title || "Prompt type";
    },
    promoTypeDescription() {
      return this.deviceTypeData.description;
    },
    deviceTypeFilter() {
      return item => item.device_type === this.deviceType;
    },
    filteredPathGroups() {
      return this.path_groups.filter(this.deviceTypeFilter);
    },
    getSettingLink() {
      return { path: `/apps/${this.$route.params.aid}/settings/zones` };
    },
    promotionTypes() {
      const types = [
        {
          value: "horizontal",
          name: "Horizontal",
          image: "https://assets.redfastlabs.com/images/pulse/prompts/custom/horizontal.png",
        },
        {
          value: "vertical",
          name: "Vertical",
          image: "https://assets.redfastlabs.com/images/pulse/prompts/custom/vertical.png",
        },
        {
          value: "tile",
          name: "Tile",
          image: "https://assets.redfastlabs.com/images/pulse/prompts/custom/tile.png",
        },
        {
          value: "text",
          name: "Text Only",
          image: "https://assets.redfastlabs.com/images/pulse/prompts/custom/text.png",
        },
        {
          value: "widget",
          name: "Notification",
          image: "https://assets.redfastlabs.com/images/pulse/prompts/custom/widget.png",
        },
        {
          value: "interstitial",
          name: "Interstitial",
          image: "https://assets.redfastlabs.com/images/pulse/prompts/custom/interstitial.png",
        },
        {
          value: "retention_modal",
          name: "Pop up",
          image: "https://assets.redfastlabs.com/images/pulse/prompts/custom/popup-1.png",
        },
        {
          value: "video",
          name: "Video",
          image: "https://assets.redfastlabs.com/images/pulse/prompts/custom/video.png",
        },
        {
          value: "invisible",
          name: "Invisible",
          image: "https://assets.redfastlabs.com/images/pulse/prompts/custom/invisible.png",
        },
      ];
      if (this.deviceType === "web" || this.appsStore.app?.flags?.banner_for_non_web_devices) {
        types.push({
          value: "bottom_banner",
          name: "Bottom Banner",
          image: "https://assets.redfastlabs.com/images/pulse/prompts/custom/bottom_banner.png",
        });
      }

      if (this.deviceType === "custom_defined" && this.appsStore.app?.flags?.scheduled_push) {
        types.push({
          value: "scheduled_push",
          name: "Push",
          image: require("../../assets/images/push_preview.svg"),
        });
      }
      if (this.appsStore.app?.flags?.email_path_enabled) {
        types.push({
          value: "email",
          name: "Email",
          image: "https://assets.redfastlabs.com/images/pulse/prompts/custom/email.png",
        });
      }
      if (this.deviceType === "web") {
        types.push({
          value: "slider",
          name: "Slider",
          image: "https://assets.redfastlabs.com/images/pulse/promotions/opt-slider.png",
        });
      }
      if (this.overlayOnly) {
        return types.filter(type => nonOverlayTypes.indexOf(type.value) < 0);
      }
      return types;
    },
  },
  methods: {
    close() {
      this.$emit("closePromoDeviceTypeDialog");
    },
    promotionSelected(value) {
      return value === this.selectedPromoType;
    },
    setDeviceType(type, data) {
      this.deviceType = type;
      this.deviceTypeData = data;
    },
    setCustomDeviceIds(customDeviceIds) {
      this.customDeviceIds = customDeviceIds;
    },
    createNewRetention(type) {
      if (type === "slider")
        return this.$router.push({
          path: `/apps/${this.$route.params.aid}/settings/zones`,
          hash: "modal",
        });
      if (this.zoneIsAvailable(type)) {
        this.selectedPromoType = type;
        this.$emit("showPromoTypeDialog", this.deviceType, type, this.customDeviceIds);
      }
    },
    closeStandardPromoDialog() {
      this.standardPromoTypeDialog = false;
    },
    zoneIsAvailable(itemType) {
      if (isStandalone({ path_type: itemType })) return true;

      return !!this.filteredPathGroups.find(pathGroup => pathGroup.path_type === itemType);
    },
    unsetSelectedPromoType() {
      this.selectedPromoType = null;
    },
  },
  mounted() {
    const appId = this.$route.params.aid;

    if (this.sequenceId) {
      if (!this.currSequence || this.currSequence.id !== this.sequenceId) {
        this.sequencesStore.getSequence({ appId, sequenceId: this.sequenceId });
      }
    }
    if (this.pipelineId && this.pipelineStageId) {
      if (!this.currPipeline || this.currPipeline.id !== this.pipelineId) {
        this.pipelinesStore.getPipeline({ appId, pipelineId: this.pipelineId });
      }
    }
  },
};
</script>

<style>
.crPr-card-widget {
  display: none;
}
.singles-for-web .crPr-card-widget {
  display: block;
}
</style>
