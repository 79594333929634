<template>
  <div>
    <RfPassword @submit="onSetPassword" />
    <v-dialog persistent v-model="accountSuccessDialog" width="600" overlay-opacity="0.85">
      <v-card flat>
        <v-card-title class="headline">Success!</v-card-title>
        <v-card-text class="create-new-promo-dialog">
          <span class="success-cre-msg">{{ message }}</span>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn x-large depressed color="success" width="170px" to="/login"> Continue </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RfPassword from "@/components/RfPassword.vue";
import { useUserStore } from "@/pinia/userStore";

export default {
  components: { RfPassword },
  data: () => ({ accountSuccessDialog: false }),
  setup: () => ({ userStore: useUserStore() }),
  computed: {
    isPasswordReset() {
      return this.$route.path.match(/password$/);
    },
    isInvitation() {
      return this.$route.path.match(/invitation$/);
    },
    message() {
      if (this.isPasswordReset) return "Your password has been successfully updated.";
      if (this.isInvitation) return "Your account has been successfully created.";
      return "";
    },
  },
  methods: {
    async onSetPassword({ password, password_confirmation }) {
      const response = await this.getResponse({ password, password_confirmation });
      if (response) this.accountSuccessDialog = true;
    },
    getResponse({ password, password_confirmation }) {
      if (this.isPasswordReset)
        return this.userStore.resetPasswordUser({
          reset_password_token: this.$route.query.reset_password_token,
          password,
          password_confirmation,
        });

      if (this.isInvitation)
        return this.userStore.confirmInvitedUser({
          invitation_token: this.$route.query.invitation_token,
          password,
          password_confirmation,
        });

      return false;
    },
  },
};
</script>

<style scoped>
.success-cre-msg {
  font-size: 18px;
  padding: 10px 0 20px;
  display: block;
}
</style>
