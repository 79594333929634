import { ref } from "vue";

const DEFAULT_TIME = 1000 * 60 * 5;
export const useCache = (
  { storeVariable = ref([]), time = DEFAULT_TIME } = {
    storeVariable: ref([]),
    time: DEFAULT_TIME,
  },
) => {
  const lastGetValue = ref(null);
  const lastGetTimestamp = ref(null);

  const cachable = async (f = async () => {}, valueToCompare, forceUpdate = false) => {
    if (
      !forceUpdate &&
      (Array.isArray(storeVariable.value) ? storeVariable.value?.length : storeVariable.value)
    ) {
      if (![null, undefined].includes(valueToCompare) && lastGetValue.value === valueToCompare)
        return () => {};
      if (lastGetTimestamp.value && Date.now() - lastGetTimestamp.value < time) return () => {};
    }
    await f();
    lastGetValue.value = valueToCompare || null;
    lastGetTimestamp.value = +Date.now();
  };

  return { lastGetValue, lastGetTimestamp, cachable };
};
