import Vue from "vue";
import VueGoogleCharts from "vue-google-charts";
import VeeValidate from "vee-validate";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import "./assets/scss/redfast-stylesheet.scss";
import { createPinia, PiniaVuePlugin } from "pinia";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";
import duration from "dayjs/plugin/duration";
import isToday from "dayjs/plugin/isToday";
import dayjs from "dayjs";
import vuetify from "./plugins/vuetify";
import router from "./router";
import App from "./App.vue";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isToday);

Vue.config.productionTip = false;
Vue.use(VueGoogleCharts);
Vue.use(VeeValidate);
Vue.use(PiniaVuePlugin);
if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://ad6c538a212940d3803350d9e81e7ebf@sentry.io/1452606",
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}

if (window.location.pathname === "/") {
  const authToken = new URLSearchParams(window.location.search).get("auth_token");
  if (authToken?.length) localStorage.setItem("id_token", authToken);
}

new Vue({
  router,
  vuetify,
  render: h => h(App),
  pinia: createPinia(),
}).$mount("#app");
