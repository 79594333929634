<template>
  <div>
    <div class="custom-css-text-title">
      <h5>Any styling specified here will override the default styling.</h5>
      <div>
        Refer to the
        <a target="_blank" href="https://help.redfast.com/docs/styling-fine-tuning-css-selectors"
          >documentation</a
        >
        for additional information.
      </div>
    </div>
    <PrismEditor
      class="custom-css-textarea"
      language="css"
      :emitEvents="false"
      v-model.trim="cssValues"
    ></PrismEditor>
  </div>
</template>

<script>
import PrismEditor from "vue-prism-editor";

export default {
  name: "RfPromoCustomCss",
  props: ["actions"],
  components: { PrismEditor },
  computed: {
    cssValues: {
      get() {
        if (this.actions.rf_settings_custom_css && this.actions.rf_settings_custom_css.length > 0) {
          return this.actions.rf_settings_custom_css;
        }
        return "/* your css here */";
      },
      set(value) {
        this.actions.rf_settings_custom_css = value;
      },
    },
  },
};
</script>
