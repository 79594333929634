import ConduitApiActions from "@/apis/ConduitApiActions";
import { useAppsStore } from "@/pinia/appsStore";
import { useLegacyLastErrorStore } from "@/pinia/legacyLastErrorStore";
import { usePromptStore } from "@/pinia/promptStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  data: () => ({ realtimeParams: null }),
  methods: {
    liveUrl(userId, domain, extra = {}) {
      const userStore = useUserStore();
      const app = this.currApp || useAppsStore().app;
      if (!domain) domain = app.domain;
      domain = domain.replace(/^(https?:|)\/\//, "");

      const params = {
        endUserId: userId,
        appId: app.id,
        idToken: localStorage.getItem("id_token"),
        perms: {
          canChangeApp: userStore.isUserSuperAdmin,
          isSuperAdmin: userStore.isUserSuperAdmin,
        },
      };
      if (app.flags.ai_tool && (userStore.canUpdateCompany || userStore.isAppAdmin)) {
        params.ai_tool = true;
      }

      // live v2 params
      const url = new URL(`${window.location.protocol}//${domain}`);

      if (url.search.length) {
        domain = url.host;
        if (url.pathname.length) {
          domain = `${domain}${url.pathname}`;
        }

        const urlParams = new URLSearchParams(url.search);
        extra = { ...extra, ...Object.fromEntries(urlParams) };
      }

      const allParams = { ...params, ...extra };

      this.realtimeParams = `?rf_impersonate=${encodeURIComponent(
        btoa(JSON.stringify(allParams)),
      )}`;

      return `${window.location.protocol}//${domain}/${this.realtimeParams}`;
    },
    openLiveUrl(userId, domain, extra = {}) {
      const url = this.liveUrl(userId, domain, extra);
      window.open(url, "_blank");
    },
    async promoPreviewParams({ actionGroupId, sequenceId }) {
      try {
        const promptStore = usePromptStore();
        await ConduitApiActions.getOverrideActions({ actionGroupId, sequenceId });
        const isMobile = this.viewTypeTab === "mobilewebViewTab";
        const rf_settings_custom_css_scoped = "";

        const language =
          promptStore.currLanguage === "default" ? "" : `&language=${promptStore.currLanguage}`;
        const variable = sequenceId
          ? `rf_sequence_id=${sequenceId}`
          : actionGroupId
            ? `rf_override_id=${actionGroupId}`
            : null;
        if (!variable) throw Error("sequence_id or action_group_id is required");
        let params = `rf_is_preview=true&${variable}${language}`;

        if (isMobile) {
          params += `&rf_is_mobile=${isMobile}&rf_payload_override=${encodeURIComponent(
            JSON.stringify({
              paths: [
                {
                  actions: {
                    rf_settings_custom_css_scoped,
                  },
                },
              ],
            }),
          )}`;
        }

        return params;
      } catch (error) {
        useLegacyLastErrorStore().sendError(error);
        return "";
      }
    },
    async viewPreview(domain, { actionGroupId, sequenceId }) {
      const params = await this.promoPreviewParams({ actionGroupId, sequenceId });
      const visitUrl = `//${domain}?${params}`;

      window.open(visitUrl, "_blank");
    },
  },
};
