<template>
  <tr>
    <td>
      <div class="flex h-full w-full items-start">
        <div>{{ trigger.name }}</div>
      </div>
    </td>
    <td>
      <div class="flex h-full w-full items-start">
        <div>{{ upperCaseEachWord(displayTriggerType) }}</div>
      </div>
    </td>
    <td>
      <div class="flex h-full w-full items-start">
        <ul class="list-deleted-trigger">
          <li v-for="prompt in triggerPrompts.slice(0, showPromptEntries)" :key="prompt.id">
            <router-link :to="getPromptLink(prompt.id, $route.params.aid)">{{
              prompt.name
            }}</router-link>
          </li>
          <template v-if="triggerPrompts.length > 3">
            <template v-if="showPromptEntries < triggerPrompts.length">
              <li>...</li>
              <button class="text-blue-1" @click="showPromptEntries = triggerPrompts.length">
                Show more
              </button>
            </template>
            <template v-else-if="showPromptEntries === triggerPrompts.length">
              <button class="text-blue-1" @click="showPromptEntries = 3">Show less</button>
            </template>
          </template>
        </ul>
      </div>
    </td>
    <td>
      <div class="flex h-full w-full items-start">
        <div class="text-body max-w-44">
          <div>
            Page/Screen:
            <div class="text-black-2">
              {{ displayPage(trigger) }}
            </div>
          </div>
          <div>
            Click on:
            <div v-if="trigger.click_id" class="settings-triggers-tbl-ea trig-clickid text-black-2">
              {{ trigger.click_id }}
            </div>
            <div v-else class="setting-empty-val">N/A</div>
          </div>
          <div>
            Advanced:
            <div v-if="displayCustomTrigger" class="settings-triggers-tbl-ea text-black-2">
              {{ displayCustomTrigger }}
            </div>
            <div v-else class="setting-empty-val">N/A</div>
          </div>
          <div>
            Regex:
            <div class="settings-triggers-tbl-ea text-black-2">
              <div>{{ regexDisplay(trigger) }}</div>
            </div>
          </div>
        </div>
      </div>
    </td>
    <template v-if="isPushEnabled">
      <td class="tbl-col-tr">{{ trigger.event_name }}</td>
      <td class="tbl-col-tr">
        {{ objectToString(trigger.event_attributes) }}
      </td>
    </template>
    <td class="triggers_edit_column" align="right">
      <div class="flex h-full w-full items-start">
        <div class="settings-triggers-tbl-btn">
          <RfButton
            :icon="!!userStore.isDisabledWithMessage ? 'mdi-information-variant' : 'edit'"
            color="accent"
            @click="$emit('edit')"
          />
          <RfButton
            icon="clear"
            color="error"
            :disabled="userStore.isDisabledWithMessage"
            @click="$emit('remove')"
          />
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import StringUtils from "@/utils/StringUtils";
import { getPromptLink } from "@/utils/getLink";
import {
  displayCustomInterval,
  displayCustomTrigger,
  displayPage,
  regexDisplay,
} from "@/utils/triggerHelpers";
import ObjectUtils from "@/utils/ObjectUtils";
import { useUserStore } from "@/pinia/userStore";
import RfButton from "../buttons/RfButton.vue";

export default {
  props: {
    trigger: Object,
    customTriggers: Array,
    retentions: Array,
    isPushEnabled: { type: Boolean, default: false },
  },
  components: { RfButton },
  setup: () => ({
    regexDisplay,
    displayPage,
    getPromptLink,
    upperCaseEachWord: StringUtils.upperCaseEachWord,
    objectToString: ObjectUtils.objectToString,
    userStore: useUserStore(),
  }),
  data: () => ({ showPromptEntries: 3 }),
  computed: {
    displayCustomInterval() {
      return displayCustomInterval(this.trigger, "device");
    },
    displayClickCount() {
      return this.trigger.trigger_type !== "device" ? this.trigger.click_count_threshold : null;
    },
    displayTriggerType() {
      return this.trigger.trigger_type === "easy_button" ? "live" : this.trigger.trigger_type;
    },
    displayCustomTrigger() {
      return displayCustomTrigger(this.trigger, this.customTriggers, "device");
    },
    triggerPrompts() {
      return this.retentions.filter(ret => !!ret.triggers.find(({ id }) => id === this.trigger.id));
    },
  },
};
</script>
