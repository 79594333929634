import { defineStore } from "pinia";
import { computed, ref } from "vue";
import ApiOrgs from "@/apis/ApiOrgs";
import { useUserStore } from "./userStore";
import { catchable } from "./piniaUtils";
import { useAppsStore } from "./appsStore";

export const useOrgsStore = defineStore("orgs", () => {
  const userStore = useUserStore();
  const appsStore = useAppsStore();
  const orgs = ref([]);

  const currOrg = computed(() => {
    const userSettings = userStore.currUser?.settings?.[appsStore.app?.id];
    if (userSettings) {
      const org = orgs.value.find(item => item.key === userSettings.org_key);
      if (org) return org;
    }
    return { key: null, id: null, name: null };
  });

  const currAppUserOrg = computed(() => {
    if (appsStore.app?.id) {
      if (userStore.currUser?.apps?.length) {
        const app = userStore.currUser.apps.find(({ id }) => id === appsStore.app.id);
        if (app) {
          const org = orgs.value.find(({ id }) => id === app.org_id);
          if (org) return org;
        }
      }
    }
    return { key: null, id: null, name: null };
  });

  const editableOrgs = computed(() => {
    if (userStore.isUserSuperAdmin || userStore.canUpdateCompany)
      return orgs.value.map(org => org.id);
    if (currAppUserOrg.value?.id) return [currAppUserOrg.value.id];
    return [];
  });

  const createOrg = ({ appId, name }) =>
    catchable({
      t: async () => (orgs.value = [...orgs.value, await ApiOrgs.createOrg(appId, { name })]),
    });

  const updateOrg = ({ appId, org }) =>
    catchable({
      t: async () => {
        const updatedOrg = await ApiOrgs.updateOrg(appId, org);
        orgs.value = orgs.value.map(item => (item.id === updatedOrg.id ? updatedOrg : item));
      },
    });

  const getOrgs = appId =>
    catchable({ t: async () => (orgs.value = await ApiOrgs.getOrgs(appId)) });

  const deleteOrg = ({ appId, orgId, newOrgId }) =>
    catchable({
      t: async () => {
        await ApiOrgs.deleteOrg(appId, orgId, newOrgId);
        orgs.value = orgs.value.filter(item => item.id !== orgId);
      },
    });

  return { orgs, editableOrgs, currOrg, currAppUserOrg, createOrg, updateOrg, getOrgs, deleteOrg };
});
