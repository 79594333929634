<template>
  <v-dialog
    persistent
    v-model="showCreateModal"
    width="640"
    content-class="create-placement-dialog"
    overlay-opacity="0.85"
  >
    <v-card flat>
      <v-card-title class="headline">Create new placement zone</v-card-title>
      <v-card-text>
        <RfZoneId :model="createModel" ref="zoneDetails" :key="resetKey" showFullModal />
      </v-card-text>
      <v-card-actions class="modal-card-actions-div">
        <v-btn large depressed class="cancel-btn" outlined width="100px" @click="closeDialog"
          >Close</v-btn
        >
        <v-btn
          large
          width="260px"
          color="success"
          :disabled="!isZoneIsValid"
          depressed
          @click="createNewPathGroup"
        >
          <v-icon small left>save</v-icon>Create new placement
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RfZoneId from "@/components/RfCommonCards/RfZoneId.vue";
import {
  DEFAULT_PROMO_DIMENSIONS,
  getDefaultPromoRatio,
  defaultPromoType,
} from "@/utils/PromoDimUtils";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import { ref } from "vue";
import { useLegacyTmpStore } from "@/pinia/legacyTmpStore";
import { usePromptStore } from "@/pinia/promptStore";

export default {
  name: "RfCreateNewPlacement",
  props: ["showCreateModal"],
  setup: () => ({
    toastsStore: useToastsStore(),
    zoneDetails: ref(),
    promptStore: usePromptStore(),
    legacyTmpStore: useLegacyTmpStore(),
  }),
  components: { RfZoneId },
  data() {
    return {
      createModel: this.getDefaultModel(),
      resetKey: 0,
    };
  },
  computed: {
    isZoneIsValid() {
      return this.zoneDetails?.isValid;
    },
  },
  methods: {
    closeDialog() {
      this.resetKey++;
      this.$emit("closeDialog");
    },
    async createNewPathGroup() {
      try {
        this.$emit("closeDialog");

        const pathType = this.$refs.zoneDetails.placementType;
        const { deviceType } = this.$refs.zoneDetails;
        await this.legacyTmpStore.getPathGroupModel({
          appId: this.$route.params.aid,
          pathType,
          deviceType,
        });
        const model = cloneDeep(this.legacyTmpStore.pathGroup);
        this.$refs.zoneDetails.fillModel(model);
        await this.promptStore.createPathGroup({ appId: this.$route.params.aid, model });
        this.toastsStore.create({ type: "success", body: "Zone created successfully" });
        this.createModel = this.getDefaultModel();
        this.resetKey++;
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    getDefaultModel() {
      return {
        name: null,
        description: null,
        zone: null,
        path_type: "horizontal",
        device_type: "web",
        displayType: "stack",
        settings: {
          ...DEFAULT_PROMO_DIMENSIONS[defaultPromoType][getDefaultPromoRatio(defaultPromoType)],
        },
        zone_type: "live",
        paths: [],
      };
    },
  },
};
</script>
