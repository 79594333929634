<template>
  <div style="padding: 2rem">
    <v-text-field
      outlined
      persistent-hint
      autofocus
      placeholder="Redfast Co."
      label="App Name"
      hint="Specify the name of your app or website."
      v-model.trim="name"
      name="name"
      :rules="[rules.required]"
      v-validate="'required'"
      style="margin-bottom: 1rem"
    >
    </v-text-field>
    <v-text-field
      outlined
      persistent-hint
      placeholder="www.redfast.com"
      label="App Domain"
      hint="Specify the domain of your customer facing application."
      v-model.trim="domain"
      name="domain"
      :rules="[rules.required]"
      v-validate="'required'"
      style="margin-bottom: 1rem"
    >
    </v-text-field>
    <v-select
      outlined
      persistent-hint
      hint="Timezone where the app business is based."
      name="timezone_offset"
      id="timezone_offset"
      v-model="timezone_offset"
      :items="Object.keys(app.timezones)"
      label="App Timezone"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "RfAppInfo",
  props: ["modelApp"],
  data() {
    return {
      app: this.modelApp,
      name: this.modelApp.name,
      domain: this.modelApp.domain,
      timezone_offset: "Pacific Time (US & Canada)",
      rules: {
        required: value => !!value || "Required field.",
      },
    };
  },
  methods: {
    fillModelSegment(modelApp) {
      if (!this.name) throw new Error("Name field can't be empty");
      if (!this.domain) throw new Error("Domain field can't be empty");
      const updatedProperties = ["name", "domain", "timezone_offset"];
      updatedProperties.forEach(property => (modelApp[property] = this[property]));
    },
  },
};
</script>
