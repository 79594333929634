import { defineStore } from "pinia";
import { ref } from "vue";
import ApiPipelines from "@/apis/ApiPipelines";
import { useCache } from "@/utils/composables/useCache";
import ApiMetrics from "@/apis/ApiMetrics";
import { catchable } from "./piniaUtils";

export const usePipelinesStore = defineStore("pipelines", () => {
  const pipelines = ref([]);
  const currPipeline = ref({}); // this
  const currPipelineMetrics = ref({}); // this
  const { cachable } = useCache({ storeVariable: pipelines });

  const mutateCurrentPipeline = pipeline => {
    currPipeline.value = pipeline;
    pipelines.value = pipelines.value.map(p => (p.id === pipeline.id ? pipeline : p));
  };

  const getPipelines = (appId, forceUpdate = false) =>
    catchable({
      t: () =>
        cachable(
          async () => (pipelines.value = (await ApiPipelines.getPipelines(appId)) || []),
          appId,
          forceUpdate,
        ),
      loadable: true,
    });

  const createPipeline = ({ appId, newPipeline }) =>
    catchable({
      t: async () => {
        const pipeline = await ApiPipelines.createPipeline(appId, newPipeline);
        currPipeline.value = pipeline;
        pipelines.value = [...pipelines.value, pipeline];
      },
      loadable: true,
      throwable: true,
    });

  const updatePipeline = ({ appId, pipelineId, model }) =>
    catchable({
      t: async () =>
        mutateCurrentPipeline(await ApiPipelines.updatePipeline(appId, pipelineId, model)),
      loadable: true,
      throwable: true,
    });

  const deletePipeline = ({ appId, pipelineId }) =>
    catchable({
      t: async () => {
        const deletedPipelineId = await ApiPipelines.deletePipeline(appId, pipelineId);
        pipelines.value = pipelines.value.filter(pipeline => pipeline.id !== deletedPipelineId);
        [currPipeline.value] = pipelines.value;
      },
      loadable: true,
      throwable: true,
    });

  const getPipelineMetrics = ({ appId, pipelineId, params }) =>
    catchable({
      t: async () =>
        (currPipelineMetrics.value = {
          ...(await ApiMetrics.getOnePipeline(appId, pipelineId, params)),
          pipelineId,
        }),
      loadable: true,
    });

  const getPipeline = ({ appId, pipelineId, params }) =>
    catchable({
      t: async () =>
        mutateCurrentPipeline(await ApiPipelines.getPipeline(appId, pipelineId, params)),
      loadable: true,
    });

  const restartPipeline = ({ appId, pipelineId }) =>
    catchable({
      t: () => ApiPipelines.restartPipeline(appId, pipelineId),
      loadable: true,
      throwable: true,
    });

  return {
    pipelines,
    getPipelines,
    createPipeline,
    updatePipeline,
    deletePipeline,
    restartPipeline,
    getPipelineMetrics,
    getPipeline,
    currPipeline,
    currPipelineMetrics,
  };
});
