<template>
  <RfBaseModal
    ref="modalRef"
    title="Clone Prompt"
    width="580"
    secondary-text="Clone"
    :disabledPrimary="
      (isPlacementComputed && cloneIntoCurrApp && !cloneItemZone?.value && 'Zone is required') ||
      (!cloneAppTarget.value && 'App is required')
    "
    @secondary="
      emit('clone', {
        targetAppId: cloneAppTarget.value,
        targetAppName: cloneAppTarget.text,
        pathGroupId: cloneItemZone?.value,
        sequenceId: cloneItemSequence?.value,
        sequenceName: cloneItemSequence?.text,
        defaultCompany:
          !cloneCompanyTarget.value || currUser.company_id === cloneCompanyTarget.value,
      })
    "
    @close="emit('update:item', null)"
  >
    <template #body>
      <div class="flex flex-col gap-4">
        <div>
          This will create a copy of your prompt with the current design and details. Custom Goals,
          Triggers, Actions, and Experiments will also be cloned. You can choose to clone this into
          another Zone or Guide, within this app.
        </div>
        <div v-if="showAppPicker">
          You can also clone the basic design and details into another app.
        </div>
        <RfSelect
          v-if="isSuperAdmin"
          v-model="cloneCompanyTarget"
          :items="companies"
          dense
          outlined
          hide-details
          return-object
          title="Company"
          :rules="[value => !!value || 'Company is required.']"
        />
        <template v-if="showAppPicker">
          <RfSelect
            v-model="cloneAppTarget"
            :items="apps"
            dense
            outlined
            hide-details
            return-object
            title="App"
            :rules="[value => !!value || 'App is required.']"
          />
          <v-alert v-if="!cloneIntoCurrApp" dense outlined type="warning" border="left">
            <template v-if="isPlacementComputed">
              This requires a similar Zone of the same identifier and type in the target app.
            </template>
            <template v-else> This will be placed into a new Zone in the target app. </template>
            Custom Goals, Actions, Triggers, Experiments, Segments, and certain other prompt
            properties will not be cloned.
          </v-alert>
        </template>

        <RfSelect
          v-if="isPlacementComputed && cloneIntoCurrApp"
          v-model="cloneItemZone"
          :items="filteredPathGroupItems"
          :disabled="pathGroups === null"
          dense
          outlined
          hide-details
          return-object
          title="Zone"
        />

        <RfSelect
          v-if="cloneIntoCurrApp"
          v-model="cloneItemSequence"
          :items="filteredSequences"
          :disabled="sequences === null"
          dense
          outlined
          hide-details
          return-object
          title="Guide"
        />
      </div>
    </template>
  </RfBaseModal>
</template>

<script setup>
import ApiApps from "@/apis/ApiApps";
import ApiCompanies from "@/apis/ApiCompanies";
import ApiPathGroups from "@/apis/ApiPathGroups";
import ApiSequences from "@/apis/ApiSequences";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfBaseModal from "@/components/modals/RfBaseModal.vue";
import { catchable } from "@/pinia/piniaUtils";
import { isPlacement } from "@/utils/prompts/promptHelpers";
import { isRunning, isInvalidSequenceTypeForPromptDevice } from "@/utils/guideHelpers";
import { computed, onMounted, ref, watch } from "vue";

const emit = defineEmits(["clone", "update:item"]);
const props = defineProps({
  item: { type: Object, default: null },
  isSuperAdmin: { type: Boolean, default: false },
  isCompanyAdmin: { type: Boolean, default: false },
  currApp: { type: Object, default: () => ({}) },
  currUser: { type: Object, default: () => ({}) },
});

const apps = ref([]);
const companies = ref([]);
const pathGroups = ref(null);
const sequences = ref(null);
const modalRef = ref();
const cloneItemZone = ref();
const cloneItemSequence = ref();
const cloneAppTarget = ref({});
const cloneCompanyTarget = ref({});

const isPlacementComputed = computed(() => isPlacement(props.item.path_type));
const cloneIntoCurrApp = computed(() => props.currApp.id === cloneAppTarget.value.value);
const showAppPicker = computed(
  () =>
    apps.value.length >
    (!cloneCompanyTarget.value.value || cloneCompanyTarget.value.value === props.currUser.company_id
      ? 1
      : 0),
);
const filteredPathGroupItems = computed(() => {
  if (!pathGroups.value) return [{ text: "Loading..." }];
  return pathGroups.value
    .filter(
      ({ path_type, device_type }) =>
        path_type === props.item.path_type && device_type === props.item.device_type,
    )
    .map(({ name, id }) => ({ text: name, value: id }));
});
const filteredSequences = computed(() => {
  if (!sequences.value) return [{ text: "Loading..." }];
  const filtered = sequences.value.filter(
    seq => !isRunning(seq) && !isInvalidSequenceTypeForPromptDevice(seq, props.item),
  );
  return [
    { text: "(none)", value: null },
    ...filtered.map(({ name, id }) => ({ text: name, value: id })),
  ];
});

const getApps = companyId =>
  catchable({
    t: async () => {
      const appsData = await ApiApps.getApps(companyId);
      apps.value = (
        props.isSuperAdmin || props.isCompanyAdmin
          ? appsData
          : appsData.filter(app => {
              const userApp = props.currUser.apps.find(userApp => userApp.id === app.id);
              return userApp && userApp.role !== "read_only";
            })
      ).map(el => ({ text: el.name, value: el.id }));
      if (showAppPicker.value)
        cloneAppTarget.value =
          apps.value.find(el => el.value === props.currApp?.id) || apps.value[0];
      else if (
        (!props.isSuperAdmin && !props.isCompanyAdmin) ||
        (apps.value.length === 1 && apps.value[0].value === props.currApp.id)
      )
        cloneAppTarget.value = { value: props.currApp.id, text: props.currApp.name };
      else cloneAppTarget.value = {};
    },
    loadable: true,
  });
const show = async () => {
  modalRef.value?.show();
  await Promise.all([
    ...(props.isSuperAdmin
      ? [
          catchable({
            t: async () =>
              (companies.value = (await ApiCompanies.getCompanies()).map(el => ({
                text: el.name,
                value: el.id,
              }))),
            loadable: true,
          }),
        ]
      : []),
    getApps(props.currUser.company_id),
  ]);
  if (props.isSuperAdmin)
    cloneCompanyTarget.value = companies.value.find(el => el.value === props.currUser.company_id);
};

const close = () => modalRef.value?.close();

watch(() => cloneCompanyTarget.value?.value, getApps);

onMounted(() => {
  catchable({
    t: async () => (sequences.value = await ApiSequences.getSequences(props.currApp.id)),
    loadable: true,
  }).then(() => {
    cloneItemSequence.value =
      filteredSequences.value.find(({ value }) => value === props.item.sequence_id) ||
      filteredSequences.value[0];
  });
  catchable({
    t: async () => (pathGroups.value = await ApiPathGroups.getPathGroups(props.currApp.id)),
    loadable: true,
  }).then(() => (cloneItemZone.value = filteredPathGroupItems.value[0]));
});

defineExpose({ show, close });
</script>
