<template>
  <div style="width: 100%; height: 100%; position: relative">
    <div style="display: flex">
      <v-tabs
        v-model="viewTabModal"
        slider-color="666666"
        dark
        centered
        icons-and-text
        height="50"
        background-color="#000"
      >
        <v-tab href="#desktopViewTab" @click="$emit('clickTab', 'desktopViewTab')">Desktop</v-tab>
        <v-tab href="#mobilewebViewTab" @click="$emit('clickTab', 'mobilewebViewTab')"
          >Mobile Web</v-tab
        >
      </v-tabs>
      <RfLanguagePreview :model="model" :actions="actions" />
    </div>
    <v-tabs-items v-model="viewTabModal">
      <v-tab-item
        :transition="false"
        :reverse-transition="false"
        value="desktopViewTab"
        class="desktop-web-base-bg"
      >
        <RfRetentionVideoContent
          :actions="previewActions"
          :model="model"
          ref="rfmodal"
          :zoomStyle="zoomStyle"
          v-if="retentionTypeVideo"
        />
        <RfRetentionModal
          :model="model"
          :actions="previewActions"
          deviceType="desktop"
          ref="web_desktop"
          :zoomStyle="zoomStyle"
          v-else
        />
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" value="mobilewebViewTab">
        <RfRetentionModal
          :model="model"
          :actions="previewActions"
          deviceType="mobileWeb"
          ref="web_mobile"
        />
      </v-tab-item>
    </v-tabs-items>
    <div class="preview-control-buttons" v-if="viewTabModal === 'desktopViewTab'">
      <v-btn small color="#f3f3f3" @click="zoomIn">+</v-btn>
      <v-btn class="reset-zoom-btn" small color="#f3f3f3" @click="resetZoom">{{
        `${zoomValue * 100}%`
      }}</v-btn>
      <v-btn small color="#f3f3f3" @click="zoomOut">-</v-btn>
    </div>
    <component :is="'style'" v-if="actions.rf_settings_custom_css">{{
      actions.rf_settings_custom_css
    }}</component>
  </div>
</template>

<script>
import RfRetentionModal from "@/components/RfPathCreate/RfRetentionActions/RfRetentionModal.vue";
import RfRetentionVideoContent from "@/components/RfPathCreate/RfRetentionActions/RfRetentionVideoContent.vue";
import ZoomPreviewMixin from "@/utils/ZoomPreviewMixin";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import RfLanguagePreview from "@/components/RfPathCreate/RfRetentionDevices/RfLanguagePreview.vue";
import CompositeImageSaver from "@/utils/CompositeImageSaver";

export default {
  name: "RfRetentionDeviceWeb",
  props: ["actions", "viewTypeTab", "model", "retentionTypeVideo", "actionGroupId"],
  components: { RfRetentionModal, RfRetentionVideoContent, RfLanguagePreview },
  mixins: [ZoomPreviewMixin, LanguagePreviewMixin],
  data() {
    return { showErrors: true, viewTabModal: this.viewTypeTab };
  },
  methods: {
    async fillModel(model) {
      if (!model.device_type) {
        model.device_type = this.model.device_type;
      }
      await CompositeImageSaver.fill(model, this.$refs);
    },
  },
};
</script>
