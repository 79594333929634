<template>
  <RfSimpleCard style="min-height: 400px" class="mb-7 !p-7">
    <div>
      <div class="settings-usage-tracking-desc">
        <div>
          <h4>Zone Manager</h4>
          <p class="title-blurb">
            Create and manage placement zones. Only zones with no items may be deleted.
          </p>
        </div>
        <RfButton
          icon="add"
          button-text="New Zone"
          color="success"
          :disabled="userStore.isDisabledWithMessage"
          @click="showAddDialog"
        />
      </div>
      <v-simple-table class="rf-settings-table-data" style="margin-bottom: 40px">
        <thead>
          <th class="zonetablecol-1">Name & Description</th>
          <th class="zonetablecol-2">Organization</th>
          <th class="zonetablecol-3">Dimensions</th>
          <th class="zonetablecol-4">Identifier</th>
          <th class="zonetablecol-5">Type</th>
          <th class="zonetablecol-6">Device</th>
          <th class="zonetablecol-7">Items</th>
          <th class="zonetablecol-8"></th>
        </thead>
        <tbody v-if="filteredPathGroups.length > 0">
          <tr v-for="(pathGroup, index) in filteredPathGroups" :key="index">
            <td>
              <div class="pl-info-det">
                <h4>
                  <div v-if="isCurrOrg(pathGroup)">
                    <router-link :to="getItemUrl(pathGroup)">
                      {{ pathGroup.name }}
                    </router-link>
                  </div>
                  <div v-else>{{ pathGroup.paths.length }}</div>
                </h4>
                <span v-if="pathGroup.description">{{ pathGroup.description }}</span>
              </div>
            </td>
            <td>{{ pathGroup.org_name }}</td>
            <td>{{ pathGroup.settings.iab }}</td>
            <td>
              <div class="pathgroup-zone-id-ctr" v-if="pathGroup.zone">
                <span class="my-auto">
                  {{ renderIdentifier(pathGroup) }}
                </span>
                <v-btn
                  @click="copyToClipboard(renderIdentifier(pathGroup))"
                  class="copy-paste-button"
                  small
                  icon
                >
                  <v-icon size="16" class="copy-paste-icon">mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </td>
            <td>{{ showPromoType(pathGroup) }}; {{ capitalize(pathGroup.zone_type) }}</td>
            <td>{{ promoDeviceType(pathGroup) }}</td>
            <td>
              <div v-if="isCurrOrg(pathGroup)">
                <router-link :to="getItemUrl(pathGroup)">
                  {{ pathGroup.paths.length }}
                </router-link>
              </div>
              <div v-else>{{ pathGroup.paths.length }}</div>
            </td>
            <td>
              <div class="settings-button-wrapper">
                <RfButton
                  :icon="!!userStore.isDisabledWithMessage ? 'mdi-information-variant' : 'edit'"
                  color="accent"
                  :disabled="!isCurrOrg(pathGroup)"
                  @click="openEditDialog(pathGroup)"
                />
                <RfButton
                  icon="clear"
                  color="error"
                  :disabled="
                    userStore.isDisabledWithMessage ||
                    !isCurrOrg(pathGroup) ||
                    disabledState(pathGroup)
                  "
                  @click="openDeleteDialog(pathGroup)"
                />
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7">
              <div class="settings-no-data-message">Nothing to show</div>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-dialog v-model="showDeleteConfirmationDialog" persistent width="500">
        <v-card>
          <v-card-title class="headline">Are you sure you want to delete?</v-card-title>
          <v-card-text>
            Deleting this zone removes all information about this zone. This cannot be undone.
          </v-card-text>
          <v-card-actions class="modal-card-actions-div">
            <v-btn depressed class="cancel-btn px-7" outlined @click="closeDeleteDialog">
              Cancel
            </v-btn>
            <v-btn depressed color="error" @click="submitDelete" class="px-10">Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showEditDialog" persistent width="640">
        <v-card>
          <v-card-title class="headline">Edit zone</v-card-title>
          <v-card-text class="placement-zone-editform" v-if="pathGroupItem">
            <RfZoneId
              :model="pathGroupItem"
              ref="placementZone"
              :key="pathGroupItem.id"
              showFullModal
            />
          </v-card-text>
          <v-card-actions class="modal-card-actions-div">
            <v-btn large depressed class="cancel-btn px-7" outlined @click="closeEditDialog">
              {{ !userStore.isDisabledWithMessage ? "Cancel" : "Close" }}
            </v-btn>
            <RfButton
              button-text="Submit"
              color="accent"
              class="px-10"
              large
              :disabled="userStore.isDisabledWithMessage || !isZoneIsValid"
              @click="submitEdit"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editConfirmDialog" persistent width="500">
        <v-card>
          <v-card-title class="headline">Are you sure?</v-card-title>
          <v-card-text>
            Changing from a live zone to a code zone is a one-way change. Changing from code to live
            is not allowed.
          </v-card-text>
          <v-card-actions class="modal-card-actions-div">
            <v-btn depressed class="cancel-btn px-7" outlined @click="closeEditConfirmDialog">
              Cancel
            </v-btn>
            <v-btn depressed color="error" class="px-10" @click="submitConfiguration">
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <RfCreateNewPlacement v-on:closeDialog="closeDialog" :showCreateModal="showCreateModal" />
    </div>
  </RfSimpleCard>
</template>

<script>
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import RfZoneId from "@/components/RfCommonCards/RfZoneId.vue";
import RfCreateNewPlacement from "@/components/RfPlacements/RfCreateNewPlacement.vue";
import copyToClipboard from "@/utils/CopyToClipboard";
import PromoTypeMixin from "@/utils/PromoTypeMixin";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import StringUtils from "@/utils/StringUtils";
import { useToastsStore } from "@/pinia/toastsStore";
import { ref } from "vue";
import { isStandalone } from "@/utils/prompts/promptHelpers";
import { usePromptStore } from "@/pinia/promptStore";
import { useUserStore } from "@/pinia/userStore";
import { useAllPromptsStore } from "@/pinia/allPromptsStore";
import { useOrgsStore } from "@/pinia/orgsStore";

export default {
  name: "RfPlacementZones",
  components: { RfSimpleCard, RfCreateNewPlacement, RfZoneId, RfButton },
  mixins: [PromoTypeMixin],
  setup: () => ({
    placementZone: ref(),
    capitalize: StringUtils.capitalize,
    toastsStore: useToastsStore(),
    orgsStore: useOrgsStore(),
    allPromptsStore: useAllPromptsStore(),
    promptStore: usePromptStore(),
    userStore: useUserStore(),
  }),
  data() {
    return {
      showDeleteConfirmationDialog: false,
      showCreateModal: false,
      showEditDialog: false,
      pathGroupItem: null,
      zone: null,
      editConfirmDialog: false,
      copyToClipboard,
    };
  },
  computed: {
    isZoneIsValid() {
      return this.placementZone?.isValid;
    },
    filteredPathGroups() {
      const groups = this.allPromptsStore.promptGroups.filter(pg => !isStandalone(pg));
      return groups.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    },
  },
  methods: {
    submitEdit() {
      if (this.originalItem.zone_type === "live" && this.$refs.placementZone.zoneType === "code") {
        this.openEditConfirmDialog();
      } else {
        this.submitConfiguration();
      }
    },
    openEditConfirmDialog() {
      this.editConfirmDialog = true;
    },
    closeEditConfirmDialog() {
      this.editConfirmDialog = false;
    },
    closeDialog() {
      this.showCreateModal = false;
    },
    showAddDialog() {
      this.showCreateModal = true;
    },
    isCurrOrg(item) {
      return this.orgsStore.editableOrgs.indexOf(item.org_id) > -1;
    },
    disabledState(item) {
      return item.paths.length > 0;
    },
    renderIdentifier(item) {
      if (item.device_type === "web") {
        return `<div data-rf-zone="${item.zone}"></div>`;
      }
      return item.zone;
    },
    promoDeviceType(item) {
      return this.getPromoDeviceTypeWording(item.device_type);
    },
    showPromoType(item) {
      return this.getPromoTypeWording(item.path_type);
    },
    getItemUrl(item) {
      return { path: `/apps/${this.$route.params.aid}/placements/${item.id}` };
    },
    openDeleteDialog(item) {
      this.showDeleteConfirmationDialog = true;
      this.pathGroupItem = item;
    },
    closeDeleteDialog() {
      this.showDeleteConfirmationDialog = false;
    },
    closeEditDialog() {
      this.showEditDialog = false;
      this.pathGroupItem = null;
    },
    openEditDialog(item) {
      this.originalItem = cloneDeep(item);
      this.showEditDialog = true;
      this.pathGroupItem = item;
    },
    submitDelete() {
      this.promptStore
        .deletePathGroup({
          appId: this.$route.params.aid,
          pathGroupId: this.pathGroupItem.id,
        })
        .then(() => {
          this.showDeleteConfirmationDialog = false;
          this.toastsStore.create({ type: "success", body: "Zone deleted successfully" });
        })
        .catch(() => null);
    },
    async submitConfiguration() {
      try {
        const newPath = cloneDeep(this.pathGroupItem);
        if (this.$refs.placementZone) {
          this.$refs.placementZone.fillModel(newPath);
        }
        if (!newPath.name) throw new Error("Name field can't be empty");
        if (!newPath.zone) throw new Error("Identifier field can't be empty");
        this.closeEditDialog();
        this.originalItem = null;
        this.closeEditConfirmDialog();
        await this.promptStore.updatePathGroup({ appId: this.$route.params.aid, model: newPath });
        this.toastsStore.create({ type: "success", body: "Zone updated successfully" });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
  },
  mounted() {
    this.allPromptsStore.getPromptGroups(this.$route.params.aid);

    if (this.$route.hash !== "#modal") return;
    this.$router.push({ hash: "" });
    this.showAddDialog();
  },
};
</script>
<style scoped>
.zonetablecol-1 {
  width: 31%;
}
.zonetablecol-2 {
  width: 10%;
}
.zonetablecol-3 {
  width: 31%;
}
.zonetablecol-4 {
  width: 10%;
}
.zonetablecol-5 {
  width: 17%;
}
.zonetablecol-6 {
  width: 10%;
}
.zonetablecol-7 {
  width: 10%;
}
.zonetablecol-8 {
  width: 10%;
}
</style>
