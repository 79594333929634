<template>
  <div class="rf-setting-pipelines rf-setting-card">
    <div class="rf-category-header">
      <span class="rf-category-title">Enable Pipelines</span>
    </div>
    <div class="d-flex flex-column mt-2">
      <v-checkbox
        v-for="pipeline in pipelines"
        :key="pipeline.id"
        v-model="enabledPipelines[pipeline.id]"
        :value="true"
        :label="pipeline.name"
        @change="submitUpdate(pipeline.id)"
        hide-details
      />
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash-es";
import { usePipelinesStore } from "@/pinia/pipelinesStore";

export default {
  name: "RfHiddenPipelines",
  props: ["app", "pipelines"],
  setup: () => ({ pipelinesStore: usePipelinesStore() }),
  data() {
    return {
      enabledPipelines: this.initPipelines(),
    };
  },
  methods: {
    initPipelines() {
      const flags = {};
      this.pipelines.forEach(pipeline => {
        flags[pipeline.id] = !pipeline.is_hidden;
      });
      return flags;
    },
    submitUpdate(pipelineId) {
      const pipeline = cloneDeep(this.pipelines.find(p => p.id === pipelineId));

      pipeline.is_hidden = !this.enabledPipelines[pipelineId];

      this.pipelinesStore
        .updatePipeline({
          appId: this.app.id,
          pipelineId,
          model: pipeline,
        })
        .then(() => null)
        .catch(() => null);
    },
  },
};
</script>

<style scoped>
.rf-setting-pipelines {
  padding-bottom: 20px;
}
</style>
