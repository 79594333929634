<template>
  <div>
    <RfCreatePromoModal
      :promoCreateDialog="promoCreateDialog"
      :promoDeviceType="newPromoDeviceType"
      :promoDisplayType="newPromoDisplayType"
      :promoCustomDeviceIds="newPromoCustomDeviceIds"
      :sequenceId="sequenceId"
      v-on:closeCreateNewPromoDialog="closeCreateNewPromoDialog"
      v-on:closePromoDeviceTypeDialog="closePromoDeviceTypeDialog"
      :pipelineStageId="pipelineStageId"
      :selectedSegments="selectedSegments"
      :pipelineId="pipelineId"
      :pipelineStageName="pipelineStageName"
      :sequenceOrderModal="sequenceOrder"
    />
    <v-dialog scrollable v-model="promoDeviceTypeDialog" width="1048" persistent>
      <RfCreateRetentionModal
        v-on:closePromoDeviceTypeDialog="closePromoDeviceTypeDialog"
        v-on:showPromoTypeDialog="showPromoTypeDialog"
        :path_groups="pathGroups"
        :overlayOnlyModal="overlayOnlyModal"
        :webOnly="webOnly"
        ref="types"
      />
    </v-dialog>
  </div>
</template>

<script>
import RfCreateRetentionModal from "@/views/retentions/RfCreateRetentionModal.vue";
import RfCreatePromoModal from "@/components/RfPromotions/RfCreatePromoModal.vue";
import { useLegacyTmpStore } from "@/pinia/legacyTmpStore";

export default {
  name: "RfPromotionCreator",
  components: { RfCreateRetentionModal, RfCreatePromoModal },
  props: [
    "pathGroups",
    "promoDeviceTypeDialog",
    "sequenceId",
    "pipelineStageId",
    "selectedSegments",
    "pipelineId",
    "pipelineStageName",
    "sequenceOrder",
    "overlayOnlyModal",
    "webOnly",
  ],
  setup: () => ({ legacyTmpStore: useLegacyTmpStore() }),
  data() {
    return {
      promoCreateDialog: false,
      newPromoDeviceType: null,
      newPromoDisplayType: null,
      newPromoCustomDeviceIds: null,
    };
  },
  methods: {
    closeCreateNewPromoDialog() {
      this.promoCreateDialog = false;
    },
    async showPromoTypeDialog(device, type, customDeviceIds) {
      this.newPromoDeviceType = device;
      this.newPromoDisplayType = type;
      this.newPromoCustomDeviceIds = customDeviceIds;
      const payload = {
        appId: this.$route.params.aid,
        pathType: type,
        deviceType: device,
        sequenceId: this.sequenceId,
      };
      Promise.all([
        this.legacyTmpStore.getRetentionModel({ ...payload, customDeviceIds }),
        this.legacyTmpStore.getPathGroupModel(payload),
      ])
        .then(() => {
          this.$refs.types.unsetSelectedPromoType();
          this.promoCreateDialog = true;
        })
        .catch(() => null);
    },
    closePromoDeviceTypeDialog() {
      this.$emit("closePromoDeviceTypeDialog");
    },
  },
};
</script>
