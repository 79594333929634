<template>
  <div class="promo-design-group">
    <v-textarea
      hide-details
      outlined
      dense
      label="Terms of Service text"
      v-model.trim="currentActions.rf_settings_privacy_policy_text"
      :disabled="currentTranslations.hasOwnProperty('rf_settings_privacy_policy_text')"
      rows="3"
    ></v-textarea>
    <RfRetentionInsertLink
      v-model="currentActions.rf_settings_privacy_policy_text"
      title="Terms of Service text"
      textPlaceholder="Terms of Service"
      urlPlaceholder="http://company.com/terms"
      :deviceType="model.device_type"
      :insertColor="currentActions.rf_settings_fill_color"
    />
  </div>
</template>
<script>
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import RfRetentionInsertLink from "./components/RfRetentionInsertLink.vue";

export default {
  name: "RfRetentionActionsLinks",
  props: ["actions", "model"],
  mixins: [LanguagePreviewMixin],
  components: { RfRetentionInsertLink },
  data() {
    return {
      showInsertLinkDialog: false,
      linkURLToInsert: null,
      linkTextToInsert: null,
    };
  },
  computed: {
    currentActions() {
      return this.currentTranslations.hasOwnProperty("rf_settings_privacy_policy_text")
        ? this.previewActions
        : this.actions;
    },
  },
};
</script>
