<template>
  <RfBaseCard>
    <Transition mode="out-in">
      <RfBaseSkeleton v-if="props.loading" height="20" dynamic width="100" />
      <span v-else class="rf-stats-card--title text-body">{{ title }} </span>
    </Transition>
    <Transition mode="out-in">
      <RfBaseSkeleton v-if="props.loading" height="32" dynamic />
      <span v-else class="rf-stats-card--value text-heading-0">{{ value }}</span>
    </Transition>

    <Transition
      v-if="props.timePeriod === METRICS_PERIODS.last_seven_to_fourteen_days.value"
      mode="out-in"
    >
      <RfBaseSkeleton v-if="props.loading" height="24" dynamic width="80" />
      <span
        v-else
        class="rf-stats-card--grow text-action-buttons relative inline-flex items-center gap-2 !text-black-2"
      >
        <span
          ref="tooltipAnchorEl"
          class="rf-stats-card--grow-icon grid h-6 w-6 flex-shrink-0 place-content-center rounded-full bg-input-background"
          :class="growData.class"
        >
          <component v-if="growData.icon" :is="growData.icon" />
          <span v-else aria-hidden="true" class="text-grey-4">—</span>
        </span>
        <span> {{ `${growData.sign}${Math.abs(grow)}%` }} </span>
        <button
          class="absolute h-full w-full"
          @focus="() => tooltipEl.show()"
          @mouseenter="() => tooltipEl.show()"
          @blur="() => tooltipEl.hide()"
          @mouseleave="() => tooltipEl.hide()"
        ></button>
        <RfTooltip ref="tooltipEl" class="mr-0 w-fit" :anchor="tooltipAnchorEl">
          <template #tooltip> compared to the {{ timePeriodName }} </template>
        </RfTooltip>
      </span>
    </Transition>

    <span v-if="icon" class="absolute right-5 top-5">
      <Transition mode="out-in">
        <span key="icon" v-if="props.loading">
          <RfBaseSkeleton height="40" width="40" radius="6" />
        </span>
        <span
          key="icon-load"
          v-else
          class="rf-stats-card--icon inline-flex rounded-md bg-input-background [&>*]:m-auto [&>*]:!h-5 [&>*]:!w-5 [&>*]:!fill-grey-2"
        >
          <component :is="icon" />
        </span>
      </Transition>
    </span>
  </RfBaseCard>
</template>

<script setup>
import { computed, ref } from "vue";
import RfArrowIcon from "@/components/icons/RfArrowIcon.vue";
import RfTooltip from "@/components/tooltip/RfTooltip.vue";
import { METRICS_PERIODS } from "@/utils/constants/MetricsConstants";
import MetricsUtils from "@/utils/MetricsUtils";
import RfBaseCard from "./RfBaseCard.vue";
import RfBaseSkeleton from "../skeletons/RfBaseSkeleton.vue";

const tooltipAnchorEl = ref();
const tooltipEl = ref();

const props = defineProps({
  title: { type: String, default: "" },
  value: { type: [String, Number], default: 0 },
  icon: { type: Object, default: null },
  grow: { type: [String, Number], default: 0 },
  timePeriod: { type: String, default: "" },
  loading: { type: Boolean, default: false },
});

const timePeriodName = computed(
  () =>
    props.timePeriod &&
    MetricsUtils.All_Periods.find(el => el.value === props.timePeriod).name.toLowerCase(),
);

const growData = computed(() => {
  if (+props.grow > 0)
    return {
      class: "rf-stats-card--grow-plus bg-green-4 [&>*]:!fill-green-1",
      icon: RfArrowIcon,
      sign: "+",
    };
  if (+props.grow < 0)
    return {
      class: "rf-stats-card--grow-minus bg-red-3 [&>*]:!fill-red-2",
      icon: RfArrowIcon,
      sign: "-",
    };
  return { class: "", icon: null, sign: "" };
});
</script>

<style lang="scss" scoped>
.rf-stats-card {
  &--grow {
    &-plus {
      > * {
        transform: translateY(-1px);
      }
    }
    &-minus {
      > * {
        transform: translateY(2px) rotateX(180deg);
      }
    }
  }

  &--icon {
    padding: 10px;
  }
}
</style>
