import { useAppsStore } from "@/pinia/appsStore";
import { usePromptStore } from "@/pinia/promptStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  computed: {
    appsStore: () => useAppsStore(),
    app() {
      return this.appsStore.app;
    },
    userStore() {
      return useUserStore();
    },
    promptStore() {
      return usePromptStore();
    },
    user() {
      return this.userStore.currUser;
    },
    demoFlagOn() {
      if (this.app && this.app.flags) {
        return !!this.app.flags.demo;
      }
      return false;
    },
  },
  methods: {
    updatePathActivity(appId, pathId) {
      if (!this.user) return;
      if (this.userStore.isUserSuperAdmin && !this.demoFlagOn) return;

      const timestamp = parseInt(new Date() / 1000); // epoch time in seconds
      this.promptStore.updateRecentPathActivity({
        appId,
        pathId,
        userId: this.user.id,
        timestamp,
      });
    },
  },
};
