<template>
  <div>
    <v-select
      outlined
      dense
      hide-details
      :disabled="disabled"
      :items="parentItems"
      v-model="parentModel"
      @change="updateParent"
      placeholder="All"
      class="segment-builder--subtypes _subtypes"
      height="32px"
      background-color="#ffffff"
      :menu-props="{ maxHeight: 410, offsetY: true }"
    />
    <div class="segment-builder--item next-selection" v-if="parentModel">
      <v-icon>chevron_right</v-icon>
      <div class="segment-create-section">
        <div class="segment-create-slider">
          <div class="segment-create-title">
            <div>0-10 represents the normalized scale of actual value.</div>
          </div>
          <RfRangeSlider
            v-model="childModel"
            v-on:input="updateFilter"
            :max="10"
            :min="0"
            :step="1"
            :disabled="disabled"
            :isNormalized="true"
            style="margin-left: 0px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfRangeSlider from "@/components/RfRangeSlider.vue";
import { UserFilterItems } from "@/utils/constants/SegmentsConstants";
import { isUserFilterDisabled } from "@/utils/segmentsHelpers";
import { useCustomFields } from "@/utils/composables/useCustomFields";

export default {
  name: "RfUser",
  props: ["filter", "disabled"],
  components: { RfRangeSlider },
  setup: () => ({ customFields: useCustomFields() }),
  data: () => ({ parentModel: null, childModel: [0, 10] }),
  computed: {
    parent() {
      return this.parentItems.find(parent => parent.value === this.parentModel);
    },
    parentItems() {
      return Object.values(UserFilterItems).filter(item =>
        isUserFilterDisabled(item.field, this.customFields.appSystemFields.value),
      );
    },
    user() {
      return this.filter.user;
    },
    userKey() {
      return Object.keys(this.user)[0];
    },
    values() {
      if (!this.parent) return [];

      return this.filter.user[this.parent.value];
    },
  },
  methods: {
    updateParent() {
      this.childModel = [0, 10];
      this.updateFilter();
    },
    updateChild() {
      this.updateFilter();
    },
    updateFilter() {
      const filter = { user: { [this.parentModel]: this.childModel } };

      this.$emit("update:model", filter);
    },
    prepareFilter() {
      this.parentModel = this.userKey;
      this.childModel = this.values;
    },
  },
  mounted() {
    this.prepareFilter();
  },
};
</script>
