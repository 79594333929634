import ApiPaths from "@/apis/ApiPaths";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import ApiPathGroups from "@/apis/ApiPathGroups";
import ApiMetrics from "@/apis/ApiMetrics";
import VuexUtils from "@/utils/VuexUtils";
import { catchable } from "./piniaUtils";
import { useAllPromptsStore } from "./allPromptsStore";
import { useUserStore } from "./userStore";

function getUserFilterSettings(currUser, appId) {
  const userSettings = currUser.settings || {};
  const appSettings = userSettings[appId] || {};
  const filterItems = appSettings.rf_filter_settings || {};
  const filter = filterItems.promotionsDateRange || "last_seven_days";
  const range = filterItems.promotionsCustomDateRange;
  return { range, filter };
}

export const usePromptStore = defineStore("current-prompt", () => {
  const allPromptsStore = useAllPromptsStore();
  const userStore = useUserStore();

  const currLanguage = ref(null);
  const prompt = ref(null);
  const promptGroup = ref(null);
  const activities = ref([]);
  const customMetrics = ref({});
  const promptMetrics = ref(null);
  const promptMetricsId = ref(null);
  const customDevices = computed(() => prompt.value?.custom_devices || []);

  const $reset = () => {
    currLanguage.value = null;
    prompt.value = null;
    activities.value = [];
    promptGroup.value = null;
  };

  const mutateUpdatePrompt = ({ prompt: promptIn, activities: activitiesIn }) => {
    prompt.value = promptIn;
    activities.value = activitiesIn;
    allPromptsStore.prompts = allPromptsStore.prompts.map(pa =>
      pa.id === promptIn.id ? promptIn : pa,
    );
  };

  const mutateUpdatePathGroup = pathGroup => {
    promptGroup.value = pathGroup;
    allPromptsStore.promptGroups = allPromptsStore.promptGroups.map(pg =>
      pg.id === pathGroup.id ? pathGroup : pg,
    );
  };

  const getPrompt = ({ appId, pathId }) =>
    catchable({
      t: async () => {
        const [promptIn, activitiesIn] = await Promise.all([
          ApiPaths.getPath(appId, pathId),
          ApiPaths.getActivities(appId, pathId),
        ]);
        prompt.value = promptIn;
        activities.value = activitiesIn;
        if (prompt.value) {
          promptGroup.value = allPromptsStore.promptGroups.find(
            ({ id }) => id === prompt.value.path_group.id,
          );
        }
        if (allPromptsStore.prompts.length) {
          const hasPath = allPromptsStore.prompts.find(p => p.id === promptIn.id);
          if (!hasPath) allPromptsStore.prompts = [...allPromptsStore.prompts, promptIn];
        }
      },
      loadable: true,
    });

  const updatePrompt = ({ appId, pathId, modelPath }) =>
    catchable({
      t: async () => {
        if (modelPath.custom_devices)
          modelPath.custom_device_ids = modelPath.custom_devices.map(({ id }) => id);
        const path = await ApiPaths.updatePath(appId, pathId, modelPath);
        const activitiesIn = await ApiPaths.getActivities(appId, pathId);
        mutateUpdatePrompt({ prompt: path, activities: activitiesIn });
        if (promptGroup.value?.paths) {
          promptGroup.value.paths = promptGroup.value.paths.map(p => (p.id === path.id ? path : p));
        }
        allPromptsStore.promptGroups = allPromptsStore.promptGroups.map(pg => {
          pg.paths = pg.paths.map(p => (p.id === path.id ? path : p));
          return pg;
        });
      },
      loadable: true,
      throwable: true,
    });

  const getVideoUploadUrl = ({ appId, pathId }) =>
    catchable({ t: () => ApiPaths.getVideoUploadUrl(appId, pathId) });

  const createPathGroup = ({ appId, model }) =>
    catchable({
      t: async () => {
        const { paths } = model;
        delete model.paths;
        const pathGroup = await ApiPathGroups.createPathGroup(appId, model);
        const createdPaths = paths.map(item => {
          const { custom_devices, ...newItem } = item;
          newItem.custom_device_ids = custom_devices.map(el => el.id);
          return ApiPaths.createPath(appId, { ...newItem, path_group_id: pathGroup.id });
        });
        pathGroup.paths = await Promise.all(createdPaths);
        allPromptsStore.promptGroups = [...allPromptsStore.promptGroups, pathGroup];
        allPromptsStore.prompts = [...allPromptsStore.prompts, ...pathGroup.paths];
      },
      loadable: true,
      throwable: true,
    });

  const updatePathGroup = ({ appId, model, isLoading }) =>
    catchable({
      t: async () => {
        const { paths } = model;
        if (paths) {
          const updatedPaths = paths.map(item => {
            if (item.id) return ApiPaths.updatePath(appId, item.id, item);
            return ApiPaths.createPath(appId, { ...item, path_group_id: model.id });
          });
          const updatedPathItems = await Promise.all(updatedPaths);
          if (isLoading) {
            updatedPathItems.forEach(path => {
              const modelPath = paths.find(mp => mp.id === path.id);
              if (!modelPath) mutateUpdatePrompt({ prompt: path });
            });
          }
          delete model.paths;
        }
        const { range, filter } = getUserFilterSettings(userStore.currUser, appId);
        const pathGroup = await ApiPathGroups.updatePathGroup(appId, model, range, filter);

        mutateUpdatePathGroup(pathGroup);

        if (prompt.value) prompt.value = { ...prompt.value, triggers: pathGroup.triggers };
        allPromptsStore.prompts = allPromptsStore.prompts.map(pa =>
          prompt.value && pa.id === prompt.value.id ? prompt.value : pa,
        );
      },
      loadable: true,
      throwable: true,
    });

  const sendTestEmail = ({ appId, pathId, recipient, actionGroupId }) =>
    catchable({
      t: () => ApiPaths.sendTestEmail(appId, pathId, recipient, actionGroupId),
      loadable: true,
    });

  const getPathMetrics = ({ appId, id, params }) =>
    catchable({
      t: async () => {
        const metrics = await ApiMetrics.getOnePath(appId, id, params);
        promptMetricsId.value = id;
        promptMetrics.value = VuexUtils.combineMetrics(promptMetrics.value || {}, metrics);
      },
      loadable: true,
    });

  const resetPathGoals = ({ appId, pathId }) =>
    catchable({
      t: () => ApiPaths.resetGoals(appId, pathId),
      loadable: true,
    });

  const updateRecentPathActivity = ({ appId, pathId, userId, timestamp }) =>
    catchable({
      t: () => ApiPaths.updateRecentActivity(appId, pathId, userId, timestamp),
    });

  const getPathCustomMetrics = ({ appId, id, params }) =>
    catchable({
      t: async () =>
        (customMetrics.value = VuexUtils.combineMetrics(
          customMetrics.value,
          await ApiMetrics.getOnePathCustom(appId, id, params),
        )),
      loadable: true,
    });

  const deletePathGroup = ({ appId, pathGroupId }) =>
    catchable({
      t: async () => {
        const pathGroupIdValue = await ApiPathGroups.deletePathGroup(appId, pathGroupId);
        promptGroup.value = null;
        allPromptsStore.promptGroups = allPromptsStore.promptGroups.filter(
          pg => pg.id !== pathGroupIdValue,
        );
        allPromptsStore.prompts = allPromptsStore.prompts.filter(
          p => p.path_group_id !== pathGroupIdValue,
        );
      },
      loadable: true,
      throwable: true,
    });

  const getPathGroup = ({ appId, pathGroupId }) =>
    catchable({
      t: async () => {
        const settings = getUserFilterSettings(userStore.currUser, appId);
        const pathGroup = await ApiPathGroups.getPathGroup(
          appId,
          pathGroupId,
          settings.range,
          settings.filter,
        );
        if (promptGroup.value?.id === pathGroup.id) {
          const paths = VuexUtils.combineModelListMetrics(promptGroup.value.paths, pathGroup.paths);
          pathGroup.paths = paths;
        }
        promptGroup.value = pathGroup;
      },
      loadable: true,
    });

  const deletePath = ({ appId, pathId }) =>
    catchable({
      t: async () => {
        const promptId = await ApiPaths.deletePath(appId, pathId);
        prompt.value = null;
        allPromptsStore.prompts = allPromptsStore.prompts.filter(p => p.id !== promptId);
      },
      loadable: true,
      throwable: true,
    });

  const clonePath = ({ appId, pathId, pathGroupId, sequenceId, targetAppId, defaultCompany }) =>
    catchable({
      t: async () => {
        let path;
        if (targetAppId && appId !== targetAppId) {
          path = await ApiPaths.clonePathToApp(appId, pathId, targetAppId, defaultCompany);
        } else {
          path = await ApiPaths.clonePath(appId, pathId, pathGroupId, sequenceId);
          if (pathGroupId) {
            allPromptsStore.promptGroups = allPromptsStore.promptGroups.map(pg => {
              if (pg.id === pathGroupId) {
                pg.paths.push(path);
              }
              return pg;
            });
          }
          allPromptsStore.prompts = [...allPromptsStore.prompts, path];

          getPrompt({ appId, pathId: path.id });
          if (!pathGroupId) allPromptsStore.getPromptGroups(appId); // have to refresh path groups when cloning standalone item
        }
        return path;
      },
      loadable: true,
      throwable: true,
    });

  const deleteConnectorError = ({ appId, pathId, connectorError }) =>
    catchable({
      t: () => ApiPaths.deleteConnectorError(appId, pathId, connectorError),
      loadable: true,
    });

  const retryAndDeleteConnectorError = ({ appId, pathId, connectorError }) =>
    catchable({
      t: () => ApiPaths.retryAndDeleteConnectorError(appId, pathId, connectorError),
      loadable: true,
    });

  return {
    prompt,
    promptGroup,
    currLanguage,
    customDevices,
    activities,
    getPrompt,
    $reset,
    updatePrompt,
    mutateUpdatePrompt,
    getVideoUploadUrl,
    createPathGroup,
    updatePathGroup,
    sendTestEmail,
    customMetrics,
    getPathCustomMetrics,
    getPathMetrics,
    promptMetrics,
    promptMetricsId,
    resetPathGoals,
    updateRecentPathActivity,
    deletePathGroup,
    deletePath,
    clonePath,
    getPathGroup,
    deleteConnectorError,
    retryAndDeleteConnectorError,
  };
});
