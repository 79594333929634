import { defineStore } from "pinia";
import { ref } from "vue";
import ApiSegments from "@/apis/ApiSegments";
import ApiApps from "@/apis/ApiApps";
import { useLegacyTmpStore } from "./legacyTmpStore";
import { catchable } from "./piniaUtils";

export const useAllSegmentsStore = defineStore("all-segments", () => {
  const legacyTmpStore = useLegacyTmpStore();
  const segments = ref([]);

  const getSegments = ({ appId, params }) =>
    catchable({
      t: async () => {
        if (params?.metric_periods) {
          const [segmentsValue] = await Promise.all([
            ApiSegments.getSegments(appId, params),
            ApiApps.getCustomMetricsStatus(appId, { data_type: "segments", ...params }),
          ]);

          segments.value = segmentsValue;
          legacyTmpStore.getSegmentModel(appId);
        } else {
          segments.value = await ApiSegments.getSegments(appId, params);
        }
      },
      loadable: true,
    });

  return {
    segments,
    getSegments,
  };
});
