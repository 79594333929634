import { defineStore } from "pinia";
import { computed, ref } from "vue";
import ApiJobs from "@/apis/ApiJobs";
import { useToastsStore } from "./toastsStore";
import { catchable } from "./piniaUtils";

export const useJobsStore = defineStore("jobs", () => {
  const toastsStore = useToastsStore();
  const jobs = ref([]);

  const activeJobs = computed(() =>
    jobs.value.filter(job => ["pending", "processing"].includes(job.status)),
  );

  const getJobs = appId =>
    catchable({ t: async () => (jobs.value = await ApiJobs.getJobs(appId)) });

  const createJob = ({ appId, metrics_period, path_ids }) =>
    catchable({
      t: async () => {
        await ApiJobs.createJob(appId, { ...metrics_period, path_ids });
        getJobs(appId);
        toastsStore.create({
          type: "success",
          body: "Export in progress, please visit Settings > Download Data to access. May take up to 15 minutes.",
          timeout: 5000,
          bodyTransform: null,
        });
      },
      c: error =>
        toastsStore.create({
          type: "error",
          body: `${error.message}. Visit Settings > Downloads to access.`,
          timeout: 5000,
          bodyTransform: null,
        }),
      loadable: true,
    });

  return { jobs, activeJobs, getJobs, createJob };
});
