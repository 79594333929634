import { defineStore } from "pinia";
import { ref } from "vue";
import ApiCompanies from "@/apis/ApiCompanies";
import { catchable } from "./piniaUtils";

export const useCompaniesStore = defineStore("companies", () => {
  const companies = ref([]);

  const createCompany = ({ companyName, awsMarketplaceCustomerId, awsMarketplaceEnabled }) =>
    catchable({
      t: async () => {
        const company = await ApiCompanies.createCompany(
          companyName,
          awsMarketplaceCustomerId,
          awsMarketplaceEnabled,
        );
        companies.value = [...companies.value, company];
      },
    });

  const getCompanies = loadable =>
    catchable({
      t: async () => (companies.value = await ApiCompanies.getCompanies()),
      loadable,
    });

  return {
    companies,
    getCompanies,
    createCompany,
  };
});
