import dayjs from "dayjs";
import StatusesConstants from "../constants/PromptStatusesConstants";
import TimezonesConstants from "../constants/TimezonesConstants";
import isPromptLimited from "./isPromptLimited";

export default (prompt, timezone) => {
  if (isPromptLimited(prompt)) return StatusesConstants.limited;
  if (prompt.is_expired) return StatusesConstants.ended;

  const tz = TimezonesConstants[timezone] || "UTC";
  const date = dayjs().tz(tz).utc(true);
  const endDate = dayjs(prompt.end_date).utc();
  if (endDate.isBefore(date)) return StatusesConstants.ended; // JL 1/6/25: some evidence that server might not return `is_expired` correctly sometimes
  const startDate = dayjs(prompt.start_date).utc();
  const startsInFuture = startDate.isAfter(date);
  if (prompt.is_enabled && startsInFuture) return StatusesConstants.ready;
  if (prompt.is_enabled && !startsInFuture) return StatusesConstants.running;
  if (!prompt.is_enabled && startsInFuture) return StatusesConstants.pending;
  if (!prompt.is_enabled && !startsInFuture) return StatusesConstants.paused;

  return StatusesConstants.ended;
};
