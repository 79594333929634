<template>
  <div class="all-segments-list-leftcol" data-cy="setting-list">
    <div class="subnav-row-one">
      <div class="leftnav-cat-title">
        <h1>Settings</h1>
      </div>
      <button class="subnav-menu-button" @click="$emit('showHideSubnav')">
        <v-icon color="#000">{{ subnavOpenStatus ? "menu_open" : "menu" }}</v-icon>
      </button>
    </div>
    <div class="segment-subnav-content rf-settings-subnav-menu">
      <router-link :to="{ path: `/apps/${appsStore.app.id}/settings/system` }"
        >Application</router-link
      >
      <router-link :to="{ path: `/apps/${appsStore.app.id}/settings/user_id` }"
        >User ID Matching</router-link
      >
      <router-link :to="{ path: `/apps/${appsStore.app.id}/settings/usage_tracking` }"
        >Usage Tracking</router-link
      >
      <router-link :to="{ path: `/apps/${appsStore.app.id}/settings/segment_traits` }"
        >User Traits</router-link
      >
      <router-link :to="{ path: `/apps/${appsStore.app.id}/settings/tags` }">Tags</router-link>
      <router-link :to="{ path: `/apps/${appsStore.app.id}/settings/triggers` }"
        >Triggers</router-link
      >
      <router-link :to="{ path: `/apps/${appsStore.app.id}/settings/data_sources` }"
        >Data Sources</router-link
      >
      <router-link :to="{ path: `/apps/${appsStore.app.id}/settings/connectors` }"
        >Integrations</router-link
      >
      <router-link
        data-cy="setting-list--zones"
        :to="{ path: `/apps/${appsStore.app.id}/settings/zones` }"
        >Zones</router-link
      >
      <router-link
        v-if="appsStore.app.flags.custom_devices"
        data-cy="setting-list--custom-devices"
        :to="{ path: `/apps/${appsStore.app.id}/settings/custom_devices` }"
        >Custom Devices</router-link
      >
      <router-link
        v-if="appsStore.app.feature_set.indexOf('pipelines') > -1 && userStore.isAdmin"
        :to="{ path: `/apps/${appsStore.app.id}/settings/pipelines` }"
        >Pipelines</router-link
      >
      <router-link :to="{ path: `/apps/${appsStore.app.id}/settings/users` }">Users</router-link>
      <router-link :to="{ path: `/apps/${appsStore.app.id}/settings/app_messages` }"
        >App Messages</router-link
      >
      <router-link
        v-if="userStore.isAdmin"
        :to="{ path: `/apps/${appsStore.app.id}/settings/custom_web_snippet` }"
        >Custom JS Snippet</router-link
      >
      <router-link
        v-if="userStore.isUserSuperAdmin"
        :to="{ path: `/apps/${appsStore.app.id}/settings/css_styles` }"
        >CSS Styles</router-link
      >
      <router-link :to="{ path: `/apps/${appsStore.app.id}/settings/download_csv` }"
        >Download Data</router-link
      >
      <router-link
        v-if="userStore.isUserSuperAdmin"
        data-cy="setting-list--admin"
        :to="{ path: `/apps/${appsStore.app.id}/settings/admin` }"
        >Admin</router-link
      >
      <router-link
        v-if="userStore.isSales || userStore.isUserSuperAdmin"
        :to="{ path: `/apps/${appsStore.app.id}/settings/sales_admin` }"
        >Sales Admin</router-link
      >
      <router-link
        v-if="userStore.isUserSuperAdmin && this.isAwsMarketplaceCompany()"
        :to="{ path: `/apps/${appsStore.app.id}/settings/aws_marketplace` }"
        >AWS Marketplace</router-link
      >
    </div>
  </div>
</template>

<script>
import { useAppsStore } from "@/pinia/appsStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  name: "RfSettingList",
  props: { subnavOpenStatus: { type: Boolean, default: true } },
  setup: () => ({ userStore: useUserStore(), appsStore: useAppsStore() }),
  methods: {
    isAwsMarketplaceCompany() {
      return this.appsStore.app.aws_marketplace_enabled === true;
    },
  },
};
</script>
