<template>
  <div
    class="promo-design-group"
    v-if="['web', 'custom_defined', 'ios', 'android_os'].includes(model.device_type)"
  >
    <div class="rf-rc-close-buttons-optq">
      <v-checkbox
        v-if="model.path_type === 'horizontal'"
        v-model="actions.rf_settings_disable_click"
        label="Passive"
        value="true"
        class="promotion-closeBtn-checkbox"
      />
    </div>
    <div class="rf-rc-close-buttons-opt">
      <v-checkbox
        v-if="['web', 'custom_defined'].includes(model.device_type) && clickOutsideSupported"
        v-model="actions.rf_settings_click_outside_close_enabled"
        label="Click outside to close"
        value="true"
        class="promotion-closeBtn-checkbox"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "RfRetentionBehavior",
  props: ["actions", "model", "clickOutsideSupported"],
};
</script>
