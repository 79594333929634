import { defineStore } from "pinia";
import { ref } from "vue";

export const useLegacyLastErrorStore = defineStore("legacy-error", () => {
  const lastError = ref(null);
  const loading = ref(0);

  const sendError = error => {
    lastError.value = error;
  };

  const setLoading = loadingIn => {
    if (loadingIn) {
      loading.value += 1;
      lastError.value = null;
    } else {
      loading.value -= 1;
    }
  };

  return { lastError, loading, sendError, setLoading };
});
