<template>
  <div>
    <v-btn style="margin-right: 10px" text depressed @click="openTestEmailDialog">
      <v-icon left>open_in_new</v-icon>Send Test Email
    </v-btn>
    <v-dialog v-model="testEmailDialog" width="500">
      <v-card flat>
        <v-card-title class="headline">Enter Email Address</v-card-title>
        <v-card-text>
          <v-text-field v-model.trim="testEmail" dense outlined label="Recipient Email Address" />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            large
            depressed
            class="cancel-btn"
            outlined
            width="100px"
            @click="closeTestEmailDialog"
            >Cancel</v-btn
          >
          <v-btn large depressed color="accent" @click="submitTestEmail" style="width: 150px"
            >Send</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useLegacyLastErrorStore } from "@/pinia/legacyLastErrorStore";
import { usePromptStore } from "@/pinia/promptStore";
import { useToastsStore } from "@/pinia/toastsStore";
import { computed } from "vue";

export default {
  name: "RfTestEmailSender",
  setup: () => {
    const legacyLastErrorStore = useLegacyLastErrorStore();
    return {
      toastsStore: useToastsStore(),
      promptStore: usePromptStore(),
      lastError: computed(() => legacyLastErrorStore.lastError),
    };
  },
  props: ["path", "actionGroupId"],
  data: () => ({ testEmail: null, testEmailDialog: false, sending: false }),
  methods: {
    openTestEmailDialog() {
      this.testEmailDialog = true;
    },
    closeTestEmailDialog() {
      this.testEmailDialog = false;
    },
    submitTestEmail() {
      this.closeTestEmailDialog();
      const params = {
        appId: this.$route.params.aid,
        pathId: this.path.id,
        recipient: this.testEmail,
      };
      if (this.actionGroupId) params.actionGroupId = this.actionGroupId;
      this.sending = true;
      this.promptStore.sendTestEmail(params).then(() => {
        if (!this.lastError) {
          this.toastsStore.create({ type: "success", body: "Email sent. Please check your inbox" });
        }
      });

      this.sending = false;
    },
  },
  watch: {
    lastError(to) {
      if (to && this.sending) {
        this.sending = false;
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
};
</script>
