<template>
  <div class="promo-language-container">
    <v-btn
      depressed
      color="accent"
      class="upload-image-btn"
      @click="triggerUploadCSV"
      :disabled="imageUploaded"
    >
      <v-icon size="14" left>fas fa-upload</v-icon>Upload CSV
    </v-btn>
    <input type="file" style="display: none" ref="csvInput" accept=".csv" @change="uploadCSV" />
    <div v-if="fileName">
      <span>Current File: </span> <span>{{ fileName }}</span>
    </div>

    <div class="upload-bgimg-wrapper" v-if="translationsLink && model.path_type !== 'text'">
      <v-btn
        depressed
        color="accent"
        class="upload-image-btn"
        @click="triggerUploadBgImageFromInput"
        :disabled="!!csvUploaded"
      >
        <v-icon size="14" left>fas fa-upload</v-icon> {{ uploadLabel }}
      </v-btn>
      <input
        type="file"
        style="display: none"
        ref="bgImageInput"
        accept="image/*"
        @change="uploadBgImageFromInput"
      />
      <RfImageFileInfo :image="currentImage" :filename="currentImageFileName" />
    </div>

    <v-btn
      depressed
      v-if="translationsLink"
      color="accent"
      class="upload-image-btn"
      target="_blank"
      :href="translationsLink"
    >
      <v-icon size="14" left>fas fa-download</v-icon>Download Current Translations
    </v-btn>
    <p style="margin: 0">
      Refer to Multi Language Support within the
      <a :href="appsStore.docsUrl" target="_blank">documentation</a> for additional information and
      sample CSVs.
    </p>
  </div>
</template>

<script>
import { useAppsStore } from "@/pinia/appsStore";
import { useExperimentsStore } from "@/pinia/experimentsStore";
import { useLegacyLastErrorStore } from "@/pinia/legacyLastErrorStore";
import { usePromptStore } from "@/pinia/promptStore";
import RfImageFileInfo from "@/components/RfPathCreate/RfRetentionActions/components/RfImageFileInfo.vue";

export default {
  name: "RfPromoLanguages",
  components: { RfImageFileInfo },
  props: ["model", "actionGroup", "viewTypeTab", "actions"],
  setup: () => ({
    appsStore: useAppsStore(),
    promptStore: usePromptStore(),
    legacyLastErrorStore: useLegacyLastErrorStore(),
  }),
  data() {
    return {
      fileName: this.actions.translations_filename,
      record: this.actionGroup || this.model,
      imageUploaded: false,
      csvUploaded: false,
      uploadedImageFileName: null,
    };
  },
  methods: {
    triggerUploadCSV() {
      this.$refs.csvInput.click();
    },
    uploadCSV() {
      this.legacyLastErrorStore.setLoading(true);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          this.record.translations = reader.result;
          this.actions.translations_filename = this.fileName;
          this.legacyLastErrorStore.setLoading(false);
        },
        false,
      );
      const inputRef = this.$refs.csvInput;
      if (inputRef && inputRef.files[0]) {
        reader.readAsDataURL(inputRef.files[0]);
        this.csvUploaded = true;
        this.fileName = inputRef.files[0].name;
        this.actions.translations_filename = this.fileName;
      } else {
        this.legacyLastErrorStore.setLoading(false);
      }
    },
    triggerUploadBgImageFromInput() {
      this.$refs.bgImageInput.click();
    },
    uploadBgImageFromInput() {
      this.legacyLastErrorStore.setLoading(true);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          const key = this.imageKey;
          const languages = (this.record.translations && this.record.translations.images) || {};
          const currImages = languages[this.promptStore.currLanguage] || {};
          currImages[key] = reader.result;
          if (this.uploadedImageFileName) {
            currImages[`${key}_filename`] = this.uploadedImageFileName;
          }
          languages[this.promptStore.currLanguage] = currImages;

          this.record.translations = { images: languages };

          const previewLanguage =
            this.record.parsed_translations[this.promptStore.currLanguage] || {};
          previewLanguage[key] = reader.result;
          if (this.uploadedImageFileName) {
            previewLanguage[`${key}_filename`] = this.uploadedImageFileName;
          }
          this.record.parsed_translations[this.promptStore.currLanguage] = previewLanguage;
          // some issues with Vue reactivity, so force re-render
          this.$emit("forceParentRender");
          this.legacyLastErrorStore.setLoading(false);
          this.imageUploaded = true;
        },
        false,
      );
      const inputRef = this.$refs.bgImageInput;

      if (inputRef && inputRef.files[0]) {
        reader.readAsDataURL(inputRef.files[0]);
        this.uploadedImageFileName = inputRef.files[0].name;
      } else {
        this.legacyLastErrorStore.setLoading(false);
      }
      this.$refs.bgImageInput.value = "";
    },
  },
  computed: {
    hasTranslations() {
      const translations = this.record.parsed_translations;
      if (!translations) return false;

      return Object.keys(translations).length;
    },
    translationsLink() {
      return this.record.translations_csv;
    },
    uploadLabel() {
      const type = this.viewTypeTab === "mobilewebViewTab" ? "mobile" : "desktop";

      return `Upload ${type} background (${this.promptStore.currLanguage})`;
    },
    currExperiment() {
      return useExperimentsStore().currExperiment;
    },
    imageKey() {
      return this.viewTypeTab === "mobilewebViewTab"
        ? "rf_settings_mobile_bg_image"
        : "rf_settings_bg_image";
    },
    currentImage() {
      this.imageUploaded;
      return this.currentLanguageSettings[this.imageKey];
    },
    currentImageFileName() {
      if (this.uploadedImageFileName) return this.uploadedImageFileName;
      return this.currentLanguageSettings[`${this.imageKey}_filename`];
    },
    currentLanguageSettings() {
      const languages = this.record.parsed_translations || {};
      if (!languages) return null;
      return languages[this.currLanguage] || {};
    },
  },
  watch: {
    "promptStore.prompt": function () {
      if (this.actionGroup) return;
      this.imageUploaded = false;
      this.csvUploaded = false;
      this.record = this.model;
      this.fileName = this.actions.translations_filename;
      this.uploadedImageFileName = null;
    },
    "currExperiment": function () {
      if (!this.actionGroup) return;
      this.imageUploaded = false;
      this.csvUploaded = false;
      this.record = this.actionGroup;
      this.fileName = this.actions.translations_filename;
      this.uploadedImageFileName = null;
    },
  },
};
</script>
