<template>
  <div class="flex flex-col gap-3 overflow-hidden rounded bg-white-1 p-5">
    <div class="flex items-center justify-between">
      <div class="flex flex-col gap-1">
        <Transition mode="out-in">
          <RfBaseSkeleton v-if="skeleton" height="25" width="189" />
          <h2 v-else class="text-heading-1 !text-black-1">Custom Goal</h2>
        </Transition>
        <Transition mode="out-in">
          <RfBaseSkeleton v-if="skeleton" class="mt-2" height="20" width="271" />
          <template v-else>
            <div v-if="!customGoals.length" class="text-action-buttons">
              No custom goals for this prompt yet
            </div>
          </template>
        </Transition>
      </div>
      <Transition mode="out-in">
        <RfBaseSkeleton v-if="skeleton" height="40" width="185" />
        <RfButtonNew
          v-else
          :disabled="props.disabled"
          :text="customGoals.length ? `Edit custom goal` : `+ Add custom goal`"
          @click="emit('add')"
        />
      </Transition>
    </div>
    <Transition mode="out-in">
      <template v-if="!skeleton">
        <RfTable v-if="customGoals.length" class="h-full w-full" :tableData="{ totalCount: 1 }">
          <template #thead>
            <tr class="text-body !text-black-2">
              <td class="px-4 py-2">Name</td>
              <td class="px-4 py-2">Attribution duration</td>
              <td class="px-4 py-2">Label</td>
              <td class="px-4 py-2">Type</td>
              <td class="px-4 py-2">Description</td>
              <td class="px-4 py-2 text-right">Data</td>
              <td class="w-0 px-4 py-2"></td>
            </tr>
          </template>
          <template v-if="customGoals" #tbody>
            <template v-for="customGoal in customGoals">
              <tr
                v-if="customGoal"
                :key="customGoal.label"
                class="h-full border-t border-solid border-strokes"
              >
                <td class="text-heading-2 px-4 py-1 !text-black-2">
                  {{ customGoal.name }}
                </td>
                <td class="text-body px-4 py-1 !text-black-2">
                  {{ duration }}
                </td>
                <td class="text-body px-4 py-1 !text-black-2">
                  {{ customGoal.label }}
                </td>
                <td class="text-body px-4 py-1 !text-black-2">
                  {{ customGoal.data_type }}
                </td>
                <td class="text-body px-4 py-1 !text-black-2">
                  {{ customGoal.description }}
                </td>
                <td class="text-body px-4 py-1 text-right !text-black-2">
                  {{ toLocaleNumberString(data) }}
                </td>
                <td class="px-4 py-1">
                  <div class="flex items-center justify-end gap-3">
                    <RfButtonNew
                      :disabled="props.disabled"
                      type="none"
                      size="none"
                      class="h-6 w-6"
                      @click="emit('add')"
                    >
                      <RfEditIcon class="!h-6 !w-6" />
                    </RfButtonNew>
                    <RfButtonNew
                      :disabled="props.disabled"
                      type="none"
                      size="none"
                      class="h-6 w-6"
                      @click="emit('delete')"
                    >
                      <RfDeleteIcon class="!h-6 !w-6" />
                    </RfButtonNew>
                  </div>
                </td>
              </tr>
            </template>
          </template>
        </RfTable>
      </template>
    </Transition>
  </div>
</template>

<script setup>
import RfButtonNew from "@/components/buttons/RfButtonNew.vue";
import RfDeleteIcon from "@/components/icons/RfDeleteIcon.vue";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import RfEditIcon from "@/components/icons/RfEditIcon.vue";
import RfBaseSkeleton from "@/components/skeletons/RfBaseSkeleton.vue";
import RfTable from "../RfTable/RfTable.vue";
import { useCustomFields } from "@/utils/composables/useCustomFields";
import { computed } from "vue";

const emit = defineEmits(["delete", "add"]);

const props = defineProps({
  disabled: { type: [Boolean, String], default: false },
  customFilter: { type: Object, default: () => ({}) },
  duration: { type: [String, Number, null], default: "" },
  data: { type: [Number, String], default: 0 },
  skeleton: { type: Boolean, default: false },
});

const appCustomFields = useCustomFields();

const customGoals = computed(() => {
  return Object.entries(props.customFilter || {}).map(([key]) =>
    appCustomFields.appCustomFields.value.find(({ label }) => label === key),
  );
});
</script>
