<template>
  <div v-if="viewTypeTab === 'desktopViewTab'">
    <v-expansion-panels v-model="getPromoPanel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>Message</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsTitleMessage :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Size & Position</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="banner-config-size">
            <v-text-field
              outlined
              dense
              hide-details
              type="number"
              label="Width"
              :value="widgetWidth[0]"
              placeholder="e.g.: 400"
              @input="v => (widgetWidth = [v, 0])"
            >
              <template #append>
                <v-select
                  :value="widgetWidth[1]"
                  class="m-0 -mt-2 w-14 p-0"
                  hide-details
                  :items="measures"
                  @input="v => (widgetWidth = [v, 1])"
                >
                </v-select>
              </template>
            </v-text-field>
            <v-text-field
              outlined
              dense
              hide-details
              type="number"
              label="Height"
              :value="widgetHeight[0]"
              placeholder="e.g.: 250"
              @input="v => (widgetHeight = [v, 0])"
            >
              <template #append>
                <v-select
                  dense
                  :value="widgetHeight[1]"
                  class="m-0 -mt-2 w-14 p-0"
                  hide-details
                  :items="measures"
                  @input="v => (widgetHeight = [v, 1])"
                >
                </v-select>
              </template>
            </v-text-field>
          </div>
          <div class="banner-config-size">
            <v-text-field
              outlined
              dense
              hide-details
              type="number"
              label="Message Width"
              :value="widgetMessageWdth[0]"
              placeholder="e.g.: 250"
              @input="v => (widgetMessageWdth = [v, 0])"
            >
              <template #append>
                <v-select
                  dense
                  :value="widgetMessageWdth[1]"
                  class="m-0 -mt-2 w-14 p-0"
                  hide-details
                  :items="measures"
                  @input="v => (widgetMessageWdth = [v, 1])"
                >
                </v-select>
              </template>
            </v-text-field>
            <v-select
              outlined
              hide-details
              dense
              label="Banner Display"
              v-model="actions.rf_banner_display"
              :items="bannerDisplayOptions"
            />
          </div>
          <div class="banner-config-size">
            <v-select
              outlined
              hide-details
              dense
              label="Widget Horizontal Position"
              v-model="horizontalPosition"
              :items="Object.values(HorizontalPositions)"
            />
            <v-select
              outlined
              hide-details
              dense
              label="Widget Vertical Position"
              v-model="verticalPosition"
              :items="Object.values(VerticalPositions)"
            />
          </div>
          <div class="banner-config-size">
            <v-text-field
              outlined
              dense
              hide-details
              type="number"
              :label="offsetXLabel"
              :value="xPosOffset[0]"
              :disabled="offsetXDisabled"
              placeholder="e.g.: 250"
              @input="v => (xPosOffset = [v, 0])"
            >
              <template #append>
                <v-select
                  dense
                  :value="xPosOffset[1]"
                  :disabled="offsetXDisabled"
                  class="m-0 -mt-2 w-14 p-0"
                  hide-details
                  :items="measures"
                  @input="v => (xPosOffset = [v, 1])"
                >
                </v-select>
              </template>
            </v-text-field>
            <v-text-field
              outlined
              dense
              hide-details
              type="number"
              :label="offsetYLabel"
              :value="yPosOffset[0]"
              placeholder="e.g.: 250"
              @input="v => (yPosOffset = [v, 0])"
            >
              <template #append>
                <v-select
                  dense
                  :value="yPosOffset[1]"
                  class="m-0 -mt-2 w-14 p-0"
                  hide-details
                  :items="measures"
                  @input="v => (yPosOffset = [v, 1])"
                >
                </v-select>
              </template>
            </v-text-field>
          </div>
          <div class="banner-config-size">
            <v-checkbox
              v-model="actions.rf_banner_position_reverse"
              value="true"
              label="Reverse direction"
              hide-details
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>User Interaction</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionButtons :actions="actions" :model="model" :showCloseOptions="true" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type === 'web'">
        <v-expansion-panel-header>Consent Checkbox</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionConsent :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type === 'web'">
        <v-expansion-panel-header>Countdown Timer</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsCountdown :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Close Timer</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsBehavior :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Background</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionImageUpload :actions="actions" :model="model" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="
          [
            DefaultDeviceOptions.ios.value,
            DefaultDeviceOptions.roku_os.value,
            DefaultDeviceOptions.android_os.value,
          ].includes(props.model.device_type)
        "
      >
        <v-expansion-panel-header>In-App Purchase</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsInapp :actions="actions" :app="app" :model="model" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="props.model.device_type === DefaultDeviceOptions.web.value">
        <v-expansion-panel-header>CSS</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="promo-design-group">
            <RfPromoCustomCSS :actions="actions" />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  <div v-else>
    <v-expansion-panels v-model="getPromoPanel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>CONFIGURATION</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsMobileWeb :app="app" :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Size & Position</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="banner-config-size">
            <v-text-field
              outlined
              dense
              hide-details
              label="Width"
              v-model.trim="actions.rf_mobile_width"
              placeholder="e.g.: 300px"
            />
            <v-text-field
              outlined
              dense
              hide-details
              label="Height"
              v-model.trim="actions.rf_mobile_height"
              placeholder="e.g.: 300px"
            />
          </div>
          <v-text-field
            outlined
            dense
            class="mt-4"
            hide-details
            label="Message Width"
            v-model.trim="actions.rf_mobile_message_width"
            placeholder="e.g.: 300px"
          />
          <div class="banner-config-size mt-4">
            <v-select
              outlined
              hide-details
              dense
              label="Widget Vertical Position"
              v-model="mobileVerticalPosition"
              :items="Object.values(VerticalPositions)"
            />
            <v-text-field
              hide-details
              outlined
              dense
              :label="mobileOffsetYLabel"
              v-model.trim="actions.rf_mobile_banner_position_offset_y"
              required
              v-validate="'required'"
            ></v-text-field>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="props.model.device_type === DefaultDeviceOptions.web.value">
        <v-expansion-panel-header>CSS</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfPromoCustomCSS :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script setup>
import RfRetentionActionsTitleMessage from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsTitleMessage.vue";
import RfRetentionActionsBehavior from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsBehavior.vue";
import RfRetentionConsent from "@/components/RfPathCreate/RfRetentionActions/RfRetentionConsent.vue";
import RfRetentionButtons from "@/components/RfPathCreate/RfRetentionActions/RfRetentionButtons.vue";
import RfRetentionActionsMobileWeb from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsMobileWeb.vue";
import RfPromoCustomCSS from "@/components/RfPromotions/RfPromoCustomCSS.vue";
import RfRetentionImageUpload from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionImageUpload.vue";
import { computed, ref, watchEffect } from "vue";
import { HorizontalPositions, VerticalPositions } from "@/utils/constants/PositionConstants";
import { getPosition } from "@/utils/prompts/promptHelpers";
import {
  DEFAULT_MEASURES,
  DEVICES_MEASURES,
  useCSSProperty,
  WEB_MEASURES,
} from "@/utils/composables/useCSSProperty";
import { DefaultDeviceOptions } from "@/utils/constants/DevicesConstants";
import RfRetentionActionsInapp from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsInapp.vue";
import RfRetentionActionsCountdown from "./RfRetentionActionsCountdown.vue";

const props = defineProps(["actions", "model", "app", "viewTypeTab"]);
const emit = defineEmits(["updateBannerPosition", "updateMobileBannerPosition"]);

const bannerDisplayOptions = [
  { value: "horizontal", text: "Horizontal" },
  { value: "vertical", text: "Vertical" },
];
const { variable: widgetWidth } = useCSSProperty(() => props.actions, "rf_widget_width");
const { variable: widgetHeight } = useCSSProperty(() => props.actions, "rf_widget_height");
const { variable: widgetMessageWdth } = useCSSProperty(
  () => props.actions,
  "rf_settings_text_container_max_width",
);
const { variable: xPosOffset } = useCSSProperty(() => props.actions, "rf_banner_position_offset_x");
const { variable: yPosOffset } = useCSSProperty(() => props.actions, "rf_banner_position_offset_y");

const measures = computed(() => {
  if (props.model.device_type === DefaultDeviceOptions.web.value) return WEB_MEASURES;
  if (
    [
      DefaultDeviceOptions.android_os.value,
      DefaultDeviceOptions.ios.value,
      DefaultDeviceOptions.tv_os.value,
    ].includes(props.model.device_type)
  )
    return DEVICES_MEASURES;
  return DEFAULT_MEASURES;
});
const widgetPosition = computed(() => getPosition(props.actions));
const horizontalPosition = ref(widgetPosition.value.x);
const verticalPosition = ref(widgetPosition.value.y);
const mobileWidgetPosition = computed(() => getPosition(props.actions, true));
const mobileVerticalPosition = ref(mobileWidgetPosition.value.y);
const getPromoPanel = ref([0]);

const offsetXLabel = computed(_ => `${HorizontalPositions[widgetPosition.value.x].text} Padding`);
const offsetYLabel = computed(_ => `${VerticalPositions[widgetPosition.value.y].text} Padding`);
const mobileOffsetYLabel = computed(
  () => `${VerticalPositions[mobileWidgetPosition.value.y].text} Padding`,
);
const offsetXDisabled = computed(() =>
  props.actions.rf_widget_position.includes(HorizontalPositions.center.value),
);

watchEffect(() =>
  emit("updateBannerPosition", `${verticalPosition.value}_${horizontalPosition.value}`),
);

watchEffect(() =>
  emit(
    "updateMobileBannerPosition",
    `${mobileVerticalPosition.value}_${HorizontalPositions.center.value}`,
  ),
);
</script>

<style lang="scss">
.banner-config-size {
  display: flex;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
  > div {
    width: 50%;
    padding-right: 10px !important;
    &:last-child {
      padding-right: 0 !important;
    }
  }
}
</style>
