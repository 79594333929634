<template>
  <div class="promo-design-group">
    <div class="video-play-option">
      <div class="upload-video-wrapper">
        <div v-if="isVideoUrl">
          <v-text-field
            outlined
            dense
            label="Video Url"
            v-model="videoUrl"
            name="video-url"
            hide-details
          ></v-text-field>
        </div>
        <div v-else>
          <v-btn depressed color="accent" class="upload-image-btn" @click="triggerUploadVideoInput">
            <v-icon size="24" left>videocam</v-icon>Upload Video
          </v-btn>
          <input
            type="file"
            style="display: none"
            ref="uploadVideoInput"
            name="upload_video"
            accept="video/*"
            @change="uploadVideoInput"
          />
        </div>
        <div
          v-if="actions.rf_settings_video_filename && actions.rf_settings_video_src"
          class="mb-3"
        >
          <span>Current File: </span> <span>{{ actions.rf_settings_video_filename }}</span>
        </div>
        <div style="padding-top: 12px">
          <v-checkbox
            v-model="actions.rf_settings_video_is_url"
            label="Input video url"
            class="background-img-checkbox"
            value="true"
          />
        </div>
        <a
          :href="actions.rf_settings_video_src"
          target="_blank"
          v-if="actions.rf_settings_video_src"
          >Open Video in New Tab
        </a>
        <div style="padding-top: 10px">
          <v-btn depressed color="accent" class="upload-image-btn" @click="triggerUploadCoverImage">
            <v-icon left>panorama</v-icon>Upload video cover
          </v-btn>
          <input
            type="file"
            style="display: none"
            ref="bgImageInput"
            accept="image/*"
            @change="uploadCoverImage"
          />
        </div>
        <RfImageFileInfo
          :image="actions.rf_settings_bg_image"
          :filename="actions.rf_settings_bg_image_filename"
        />
        <div style="padding-left: 0" class="upload-img-helper">1920 x 1080px recommended</div>
      </div>
      <div class="div-flex-layout">
        <v-checkbox
          v-model="actions.rf_settings_video_muted"
          label="Mute video"
          class="background-img-checkbox"
          value="true"
          hide-details
          @change="onMutedChange"
        />
        <v-checkbox
          v-model="actions.rf_settings_video_loop"
          label="Loop video"
          class="background-img-checkbox"
          value="true"
          hide-details
        />
      </div>
      <div class="div-flex-layout">
        <v-checkbox
          v-model="actions.rf_settings_video_autoplayed"
          label="Autoplay video"
          class="background-img-checkbox"
          value="true"
          hide-details
          :disabled="autoplayDisabled"
        />
      </div>
      <div class="upload-img-helper !pl-0">
        Note: unmuted video cannot be autoplayed due to browser restrictions
      </div>
    </div>
  </div>
</template>

<script>
import { useLegacyLastErrorStore } from "@/pinia/legacyLastErrorStore";
import { usePromptStore } from "@/pinia/promptStore";
import { useToastsStore } from "@/pinia/toastsStore";
import RfImageFileInfo from "@/components/RfPathCreate/RfRetentionActions/components/RfImageFileInfo.vue";

export default {
  name: "RfRetentionVideoUpload",
  props: ["actions", "model"],
  components: { RfImageFileInfo },
  setup: () => ({
    toastsStore: useToastsStore(),
    promptStore: usePromptStore(),
    legacyLastErrorStore: useLegacyLastErrorStore(),
  }),
  data() {
    return { autoplayDisabled: !this.actions.rf_settings_video_muted };
  },
  computed: {
    videoUrl: {
      get() {
        return this.actions.rf_settings_video_src;
      },
      set(value) {
        this.actions.rf_settings_video_src = value;
        const mediaType = value.split(".").pop();
        this.actions.rf_settings_video_media_type = mediaType;
      },
    },
    isVideoUrl() {
      return ["true", true].indexOf(this.actions.rf_settings_video_is_url) > -1;
    },
  },
  methods: {
    triggerUploadVideoInput() {
      this.$refs.uploadVideoInput.click();
    },
    async uploadVideoInput() {
      this.legacyLastErrorStore.setLoading(true);

      const videoAttrs = await this.promptStore.getVideoUploadUrl({
        appId: this.$route.params.aid,
        pathId: this.$route.params.pid,
      });

      const uploadVideoUrl = videoAttrs.rf_settings_video_src_upload_url;
      const videoUrl = videoAttrs.rf_settings_video_src;
      const file = this.$refs.uploadVideoInput.files[0];
      if (file.size > 5242880) {
        this.legacyLastErrorStore.setLoading(false);
        this.toastsStore.create({
          type: "error",
          body: "The 5 MB file size limit has been exceeded. Please upload a smaller file or specify a video URL.",
        });
        return;
      }
      const fileType = file.type;
      const filename = file.name;
      fetch(uploadVideoUrl, {
        method: "PUT",
        headers: {
          "Content-Type": fileType,
        },
        body: file,
      })
        .then(() => {
          this.legacyLastErrorStore.setLoading(false);
          this.actions.rf_settings_video_src = videoUrl;
          const mediaType = fileType.split("/").pop();
          this.actions.rf_settings_video_media_type = mediaType;
          this.actions.rf_settings_video_filename = filename;
          this.toastsStore.create({ type: "success", body: "Video was uploaded successfully." });
        })
        .catch(() => {
          this.legacyLastErrorStore.setLoading(false);
          this.toastsStore.create({
            type: "error",
            body: "Video upload failed. Please try again.",
          });
        });
    },
    triggerUploadCoverImage() {
      this.$refs.bgImageInput.click();
    },
    uploadCoverImage() {
      this.legacyLastErrorStore.setLoading(true);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          this.actions.rf_settings_bg_image = reader.result;
          this.actions.rf_settings_video_poster = reader.result;

          this.$emit("forceRender");
          this.legacyLastErrorStore.setLoading(false);
        },
        false,
      );
      const inputRef = this.$refs.bgImageInput;
      const filepath = inputRef.files[0];
      if (filepath) {
        this.actions.rf_settings_bg_image_filename = filepath.name;
        reader.readAsDataURL(filepath);
      } else {
        this.legacyLastErrorStore.setLoading(false);
      }
    },
    onMutedChange(newValue) {
      this.autoplayDisabled = !newValue;
      if (!newValue) {
        this.actions.rf_settings_video_autoplayed = null;
      }
    },
  },
  watch: {
    actions() {
      this.$emit("forceRender");
    },
  },
};
</script>
<style scoped>
.upload-img-helper {
  text-align: center;
  padding: 10px 0 0 0;
}
.div-flex-layout {
  padding-top: 15px;
}
</style>
