<template>
  <div>
    <v-select
      outlined
      dense
      hide-details
      :items="parentItems"
      v-model="parentModel"
      :disabled="disabled"
      placeholder="Select "
      class="segment-builder--subtypes _subtypes"
      height="32px"
      background-color="#ffffff"
      style="min-width: 180px"
      :menu-props="{ maxHeight: 410, offsetY: true }"
      @change="updateParent"
    />
    <template v-if="parentModel === UserBucketItems.range.value">
      <div class="segment-builder--item next-selection" v-if="parentModel">
        <v-icon>chevron_right</v-icon>
        <v-select
          outlined
          dense
          hide-details
          height="32px"
          v-model="rangeType"
          :disabled="disabled"
          :items="rangeTypes"
          placeholder="Match Type"
          background-color="#ffffff"
          @change="updateFilter(), resetChild()"
        />
      </div>
      <div class="segment-builder--item next-selection" v-if="rangeType !== null">
        <v-icon>chevron_right</v-icon>
        <RfCustomRangeSelector
          v-if="RangeTypesBase.range.value === rangeType"
          :value="childModel"
          :disabled="disabled"
          @input="updateChild"
          minPlaceholder="0"
          maxPlaceholder="9999"
        />
        <v-text-field
          v-else
          hide-details
          :value="childModel?.[childModelIndex]"
          outlined
          dense
          :disabled="disabled"
          placeholder="9999"
          background-color="#ffffff"
          @input="updateChild"
        />
      </div>
    </template>

    <template v-else-if="parentModel === UserBucketItems.values.value">
      <div class="segment-builder--item next-selection">
        <v-icon>chevron_right</v-icon>
        <v-select
          outlined
          dense
          hide-details
          height="32px"
          v-model="negativeMatch"
          :disabled="disabled"
          :items="matchTypeItems"
          placeholder="Match Type"
          background-color="#ffffff"
          @change="updateFilter"
        />
      </div>
      <div class="segment-builder--item next-selection">
        <v-icon>chevron_right</v-icon>
        <div class="sgc-chips-wrap">
          <div class="sgc-chips-ctr">
            <v-chip
              class="autocmp-chip"
              small
              v-for="item in userIds"
              :key="item"
              :disabled="disabled"
              @click:close="removeOption(item)"
              close
            >
              {{ item }}
            </v-chip>
          </div>
          <v-autocomplete
            outlined
            dense
            hide-no-data
            hide-details
            height="32px"
            autofocus
            :items="[tmpSearch]"
            background-color="#ffffff"
            :disabled="disabled"
            :values="''"
            :search-input.sync="tmpSearch"
            placeholder="User Ids"
            @keydown.enter="addOption(tmpSearch)"
            @input="addOption"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import {
  MatchTypeItems,
  UserBucketItems,
  RangeTypesBase,
} from "@/utils/constants/SegmentsConstants";
import { computed, onMounted, ref } from "vue";
import RfCustomRangeSelector from "../RfCustomRangeSelector.vue";

const emit = defineEmits(["update:model"]);
const props = defineProps(["filter", "disabled"]);
const parentModel = ref(null);
const rangeType = ref(null);
const childModel = ref([]);
const rangeTypes = Object.values(RangeTypesBase);
const parentItems = Object.values(UserBucketItems);
const userIds = ref([]);
const tmpSearch = ref("");
const matchTypeItems = Object.values(MatchTypeItems);
const negativeMatch = ref(null);
const childModelIndex = computed(
  () => +(rangeType.value === RangeTypesBase.less_than_or_equal_to.value),
);

const prepareFilter = () => {
  if (props.filter.user_bucket.user_bucket) {
    parentModel.value = UserBucketItems.range.value;
    childModel.value = props.filter.user_bucket.user_bucket[UserBucketItems.range.value];
    rangeType.value = props.filter.user_bucket.user_bucket.range_type;
  }
  if (props.filter.user_bucket.user_id) {
    parentModel.value = UserBucketItems.values.value;
    userIds.value = props.filter.user_bucket.user_id[UserBucketItems.values.value];
    negativeMatch.value = props.filter.user_bucket.user_id.negative_match;
  }
};

const getFilter = () => {
  if (parentModel.value === UserBucketItems.range.value)
    return {
      user_bucket: {
        user_bucket: {
          [UserBucketItems.range.value]: childModel.value,
          range_type: rangeType.value,
        },
      },
    };
  if (parentModel.value === UserBucketItems.values.value)
    return {
      user_bucket: {
        user_id: {
          [UserBucketItems.values.value]: userIds.value,
          negative_match: negativeMatch.value,
        },
      },
    };
  return {};
};

const updateFilter = () => {
  emit("update:model", getFilter());
};

const updateParent = () => {
  childModel.value = [];
  updateFilter();
};

const updateChild = v => {
  if (parentModel.value === UserBucketItems.range.value) {
    if (rangeType.value === RangeTypesBase.range.value) childModel.value = v;
    else if (rangeType.value === RangeTypesBase.equal_to.value) childModel.value = [v, v];
    else childModel.value.splice(childModelIndex.value, 1, v);
  }
  updateFilter();
};

const resetChild = () => {
  if (rangeType.value === RangeTypesBase.greater_than_or_equal_to.value)
    childModel.value = [null, "INF"];
  else if (rangeType.value === RangeTypesBase.less_than_or_equal_to.value)
    childModel.value = [0, null];
  else [null, null];
};

const addOption = option => {
  const tmp = [...new Set([...userIds.value, option])];
  userIds.value = tmp;
  tmpSearch.value = "";
  updateFilter();
};

const removeOption = item => {
  const index = userIds.value.findIndex(el => el === item);
  if (index === -1) return;
  userIds.value.splice(index, 1);
  updateFilter();
};

onMounted(prepareFilter);
</script>
