<template>
  <div class="rfmodal-input-wrapper">
    <label class="rfmodal-input-label" :style="consentStyle">
      <input type="checkbox" :checked="value" @change="$emit('input', $event.target.checked)" />
      <span v-html="actions.consent_text" />
    </label>
  </div>
</template>

<script>
export default {
  name: "RfPromoConsentPreview",
  props: ["actions", "value"],
  emits: ["input"],
  computed: {
    consentStyle() {
      return {
        "font-size": this.actions.consent_font_size,
        "color": this.actions.consent_font_color,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.rfmodal-input-wrapper {
  padding: 0 0 15px;
}
.rfmodal-input-label {
  cursor: pointer;
}
input[type="checkbox"] {
  margin-right: 8px;
  vertical-align: middle;
}
span {
  vertical-align: middle;
}
</style>
