import { defineStore } from "pinia";
import { computed, ref } from "vue";
import ApiApps from "@/apis/ApiApps";
import ApiSegments from "@/apis/ApiSegments";
import MetricsUtils from "@/utils/MetricsUtils";
import ApiMetrics from "@/apis/ApiMetrics";
import { useUserStore } from "./userStore";
import { catchable } from "./piniaUtils";
import { useLegacyTmpStore } from "./legacyTmpStore";
import { useToastsStore } from "./toastsStore";

export const useAppsStore = defineStore("apps", () => {
  const legacyTmpStore = useLegacyTmpStore();
  const userStore = useUserStore();
  const toastsStore = useToastsStore();
  const apps = ref([]);
  const app = ref(null);
  const activities = ref([]);
  const segmentMetrics = ref({});
  const sequenceMetrics = ref({});
  const promptMetrics = ref({});
  const promptsExperimentMetrics = ref({});
  const dashboardRetentionMetrics = ref({});
  const currAppAwsCredentials = ref({});
  const currAppConnectors = ref({});

  const docsUrl = computed(() => app.value?.docs);

  const mutateUpdateApp = appIn => {
    const index = apps.value.findIndex(item => item.id === appIn.id);
    app.value = appIn;
    if (index > -1) {
      const appsTemp = [...apps.value];
      appsTemp[index] = appIn;
      apps.value = appsTemp;
    }
  };

  const mutateSegmentMetrics = ({ value, extended }) =>
    extended
      ? (segmentMetrics.value = { ...segmentMetrics.value, ...value })
      : (segmentMetrics.value = value);

  const mutateSequenceMetrics = ({ value, extended }) =>
    extended
      ? (sequenceMetrics.value = { ...sequenceMetrics.value, ...value })
      : (sequenceMetrics.value = value);

  const mutateRetentionMetrics = ({ value, extended }) =>
    extended
      ? (promptMetrics.value = { ...promptMetrics.value, ...value })
      : (promptMetrics.value = value);

  const mutateRetentionExperimentMetrics = ({ value, extended }) =>
    extended
      ? (promptsExperimentMetrics.value = { ...promptsExperimentMetrics.value, ...value })
      : (promptsExperimentMetrics.value = value);

  const selectApp = appId =>
    catchable({
      t: async () => {
        const [appIn, activitiesIn] = await Promise.all([
          ApiApps.getApp(appId),
          ApiApps.getActivities(appId),
        ]);
        mutateUpdateApp(appIn);
        activities.value = activitiesIn;
      },
      loadable: true,
    });

  const getApps = currAppId =>
    catchable({
      t: async () => {
        const appsIn = await ApiApps.getApps();
        apps.value = appsIn;
        if (appsIn && appsIn.length > 0) {
          const appId = currAppId || appsIn[0].id;
          await selectApp(appId);
          legacyTmpStore.getSegmentModel(appId);
          if (userStore.isUserSuperAdmin) legacyTmpStore.getAppModel();
        }
      },
      loadable: true,
    });

  const createApp = modelApp =>
    catchable({
      t: async () => {
        const appIn = await ApiApps.createApp(modelApp);
        apps.value = [...apps.value, appIn];
        app.value = appIn;
        activities.value = await ApiApps.getActivities(app.value.id);
        userStore.getUser();
      },
      loadable: true,
    });

  const updateApp = ({ appObj, includeProviders }) =>
    catchable({
      t: async () => {
        if (!includeProviders) {
          delete appObj.providers;
        }
        mutateUpdateApp(await ApiApps.updateApp(appObj));
        userStore.getUser();
      },
      loadable: true,
    });

  const deleteApp = appId =>
    catchable({
      t: async () => {
        await ApiApps.deleteApp(appId);
        apps.value = apps.value.filter(a => a.id !== appId);
        getApps();
        userStore.getUser();
      },
      loadable: true,
    });

  const getAppDashboard = ({ appId, elasticMetrics, params }) =>
    catchable({
      t: async () => {
        if (!appId) return;
        const timePeriod = params.metric_periods[0].period;
        const segmentParams =
          timePeriod === "custom"
            ? params
            : {
                metric_periods: [
                  { period: timePeriod },
                  { period: MetricsUtils.Comparisons[timePeriod] },
                ],
              };
        const promptsParams = {
          ...params,
          path_type:
            "retention_modal,video,horizontal,vertical,tile,text,widget,interstitial,email,bottom_banner",
          ...(elasticMetrics && { limit: 5, metric_periods: [params.metric_periods[0]] }),
        };
        const promptsWithoutCompare = {
          ...promptsParams,
          metric_periods: [params.metric_periods[0]],
          ...(elasticMetrics && { limit: 5 }),
        };
        const [
          dashboardRetentionMetricsData,
          retentionMetrics,
          promptsExperimentMetricsIn,
          segmentMetricsIn,
          sequenceMetricsIn,
        ] = await Promise.all([
          ApiMetrics.getAppDashboard(appId, params),
          ApiMetrics.getRetentions(appId, promptsParams),
          ApiMetrics.getRetentionExperiments(appId, promptsWithoutCompare),
          ApiSegments.getSegment(appId, "all_users", segmentParams),
          ApiMetrics.getSequences(appId, promptsWithoutCompare),
        ]);
        const extended = params?.metric_periods;
        mutateSegmentMetrics({ value: segmentMetricsIn.data.users, extended });
        mutateSequenceMetrics({ value: sequenceMetricsIn, extended });
        dashboardRetentionMetrics.value = dashboardRetentionMetricsData["raw-impressions-goals"];
        mutateRetentionMetrics({ value: retentionMetrics, extended });
        mutateRetentionExperimentMetrics({ value: promptsExperimentMetricsIn, extended });
      },
      c: error => toastsStore.create({ type: "error", body: error.message }),
      loadable: true,
    });

  const getAppAwsCredentials = currAppId =>
    catchable({
      t: async () => (currAppAwsCredentials.value = await ApiApps.getAwsCredentials(currAppId)),
      loadable: true,
    });

  const updateAwsMarketplace = ({ appId, clicks }) =>
    catchable({
      t: () => ApiApps.updateAwsMarketplace(appId, clicks),
      loadable: true,
    });

  const generateApiKey = appId =>
    catchable({
      t: async () => mutateUpdateApp(await ApiApps.generateApiKey(appId)),
      loadable: true,
    });

  const checkSdkActive = appId =>
    catchable({
      t: async () => {
        const sdkActive = await ApiApps.sdkActive(appId);
        app.value && (app.value.flags.sdk_active = sdkActive.success);
      },
      loadable: true,
    });

  const verifyPinpointEmail = ({ appId, email }) =>
    catchable({
      t: async () => {
        await ApiApps.verifyPinpointEmail(appId, email);
        app.value && (app.value.pinpoint_sender_email = email);
      },
      loadable: true,
    });

  const getAppConnectors = appId =>
    catchable({
      t: async () => (currAppConnectors.value = await ApiApps.connectors(appId)),
      loadable: true,
    });

  return {
    apps,
    app,
    docsUrl,
    activities,
    currAppAwsCredentials,
    getAppAwsCredentials,
    updateAwsMarketplace,
    selectApp,
    getApps,
    createApp,
    updateApp,
    deleteApp,
    generateApiKey,
    checkSdkActive,
    verifyPinpointEmail,
    getAppDashboard,
    segmentMetrics,
    sequenceMetrics,
    promptMetrics,
    promptsExperimentMetrics,
    dashboardRetentionMetrics,
    currAppConnectors,
    getAppConnectors,
  };
});
