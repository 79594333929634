<template>
  <div style="width: 100%">
    <div class="webActions-options">
      <v-card-text>
        <div class="edit-redirect-url" v-if="isEditing && isRedirect">
          <span>Redirect URL</span>
          <v-text-field
            outlined
            dense
            v-model.trim="actionCopies[integrationValue.actionName]"
            :placeholder="redirectPlaceholder"
            background-color="#fff"
            hide-details
          />
          <RfInsertUserTrait
            :actionName="integrationValue.actionName"
            :app="appsStore.app"
            title="redirect"
            :actions="actionCopies"
            :noSpace="true"
          />
        </div>
        <v-radio-group
          v-else
          hide-details
          class="webActions-radio-group"
          v-model="selectedWebsiteAction"
          @change="selectedWAChanged"
          style="margin-top: 0px; padding-top: 0"
        >
          <div
            :class="radioClassesForAction(action)"
            v-for="(action, index) in activeClientActions"
            :key="index"
          >
            <v-radio
              :disabled="isRedirectAction(action.value) && surveyOptionSelected !== 'any'"
              :label="action.text"
              :value="action.value"
            ></v-radio>
            <v-select
              v-if="shouldRenderPrompts(action.value)"
              outlined
              hide-details
              dense
              :items="overlayPrompts"
              v-model="selectedPrompts[action.value]"
              placeholder="Prompt Name"
              background-color="#fff"
              style="width: 50%"
            />
            <v-text-field
              v-if="isRedirectAction(action.value)"
              :disabled="!isRedirectAction(selectedWebsiteAction)"
              outlined
              dense
              v-model.trim="actionCopies[selectedWebsiteAction]"
              :placeholder="redirectPlaceholder"
              background-color="#fff"
              hide-details
            />
            <RfInsertUserTrait
              v-if="isRedirectAction(action.value)"
              :actionName="selectedWebsiteAction"
              :app="appsStore.app"
              title="redirect"
              :actions="actionCopies"
              :noSpace="true"
            />
          </div>
        </v-radio-group>
      </v-card-text>
    </div>
    <div class="promo-add-integration-buttons" style="padding-top: 1rem; text-align: right">
      <v-btn
        large
        depressed
        style="margin-right: 1rem"
        outlined
        @click="close"
        class="promo-add-integration cancel-btn"
        >Cancel</v-btn
      >
      <v-btn
        large
        depressed
        color="success"
        style="width: 200px"
        @click="submitAdd"
        class="promo-add-integration"
      >
        <v-icon left>add</v-icon>
        {{ buttonCTA }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import RfInsertUserTrait from "@/components/RfPathCreate/RfRetentionActions/RfInsertUserTrait.vue";
import SurveyMixin from "@/utils/SurveyMixin";
import { cloneDeep } from "lodash-es";
import { isEmail } from "@/utils/prompts/promptHelpers";
import { isRedirectAction } from "@/utils/prompts/promptActionHelpers";
import { REDIRECTS_ACTION_OBJ } from "@/utils/constants/PromptActionsConstants";
import { useAppsStore } from "@/pinia/appsStore";
import { useActionsStore } from "@/pinia/actionsStore";
import { useAllPromptsStore } from "@/pinia/allPromptsStore";

export default {
  name: "RfRetentionConnectorWebsiteActions",
  components: { RfInsertUserTrait },
  props: {
    model: Object,
    isEditing: Boolean,
    interactionType: String,
    surveyOptionSelected: String,
    actions: Object,
    editIntegrationValue: Object,
    editIntegration: Object,
    clientActions: Array,
    actionGroupClientActions: Array,
    newBehaviour: { type: Boolean, default: false },
  },
  mixins: [SurveyMixin],
  setup: () => ({
    isRedirectAction,
    appsStore: useAppsStore(),
    actionsStore: useActionsStore(),
    allPromptsStore: useAllPromptsStore(),
  }),
  data() {
    const selectedPrompts = {};
    if (this.editIntegrationValue.value) {
      const action = this.actionGroupClientActions.find(
        item =>
          item.connector_action && item.connector_action.id === this.editIntegrationValue.value,
      );
      if (action) {
        selectedPrompts[this.editIntegrationValue.value] = action.args && action.args.prompt_id;
      }
    }
    return {
      selectedWebsiteAction: this.editIntegrationValue.value || null,
      selectedIntegration: this.editIntegration,
      integrationValue: this.editIntegrationValue,
      actionCopies: cloneDeep(this.actions),
      selectedPrompts,
    };
  },
  methods: {
    selectedWAChanged() {
      this.$emit("selectedWAChanged", this.selectedWebsiteAction);
    },
    close() {
      this.actionCopies = cloneDeep(this.actions);
      this.$emit("closeConnectorDialog");
    },
    radioClassesForAction(action) {
      const isRedirect = this.isRedirectAction(action.value);
      return {
        "webActions-radio-item": true,
        "redirect-radio-item": isRedirect,
      };
    },
    submitAdd() {
      if (this.isRedirect) {
        Object.keys(this.actionCopies).forEach(key => {
          this.actions[key] = this.actionCopies[key];
        });
      } else {
        const newInteractionType = this.interactionType;
        const alreadyExists = this.actionGroupClientActions.find(
          ({ interaction_type, args }) =>
            interaction_type === newInteractionType &&
            ((this.surveyOptionSelected === "any" && !args?.survey_option_selected) ||
              this.surveyOptionSelected === args?.survey_option_selected),
        );

        if (alreadyExists) {
          if (this.newBehaviour) {
            const shallowCopy = { ...alreadyExists, args: {} };
            shallowCopy.connector_action = { id: this.selectedWebsiteAction };
            if (this.selectedPrompts[this.selectedWebsiteAction]) {
              shallowCopy.args.prompt_id = this.selectedPrompts[this.selectedWebsiteAction];
            }
            if (this.surveySelected && this.surveyOptionSelected !== "any") {
              shallowCopy.args.survey_option_selected = this.surveyOptionSelected;
            }
            return (
              this.$emit("setActionGroupClientActions", shallowCopy) &&
              this.$emit("closeConnectorDialog")
            );
          }
          alreadyExists.connector_action = { id: this.selectedWebsiteAction };
          alreadyExists.args = {};
          if (this.selectedPrompts[this.selectedWebsiteAction]) {
            alreadyExists.args.prompt_id = this.selectedPrompts[this.selectedWebsiteAction];
          } else if (alreadyExists.args) {
            delete alreadyExists.args.prompt_id;
          }
          if (this.surveySelected && this.surveyOptionSelected !== "any") {
            alreadyExists.args.survey_option_selected = this.surveyOptionSelected;
          } else if (alreadyExists.args) {
            delete alreadyExists.args.survey_option_selected;
          }
        } else {
          const newAction = {
            connector_action: {
              id: this.selectedWebsiteAction,
            },
            connectable_type: "ClientAction",
            interaction_type: newInteractionType,
          };
          if (this.selectedPrompts[this.selectedWebsiteAction]) {
            newAction.args = { prompt_id: this.selectedPrompts[this.selectedWebsiteAction] };
          }
          if (this.surveySelected && this.surveyOptionSelected !== "any") {
            newAction.args = {
              ...newAction.args,
              survey_option_selected: this.surveyOptionSelected,
            };
          }

          if (this.newBehaviour) {
            return (
              this.$emit("setActionGroupClientActions", newAction) &&
              this.$emit("closeConnectorDialog")
            );
          }
          this.actionGroupClientActions.push(newAction);
        }
      }
      this.$emit("setActionGroupClientActions", this.actionGroupClientActions);

      this.$emit("closeConnectorDialog");
    },
    shouldRenderPrompts(id) {
      if (this.selectedWebsiteAction !== id) return false;
      const action = this.clientActionObjects.find(item => item.id === id);
      if (!action) return false;

      return action.options && action.options.overlay_id_dropdown;
    },
  },
  computed: {
    appClientActions() {
      return this.actionsStore.clientActions || [];
    },
    overlayPrompts() {
      const pathTypes = ["retention_modal", "video", "widget", "interstitial"];
      return this.allPromptsStore.prompts
        .filter(item => {
          return (
            pathTypes.indexOf(item.path_type) >= 0 &&
            item.sequence_id === this.model.sequence_id &&
            item.id !== this.model.id
          );
        })
        .sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        })
        .map(item => {
          return {
            text: item.name,
            value: item.id,
          };
        });
    },
    clientActionObjects() {
      return this.appClientActions.filter(action => action.action_type === "website_action");
    },
    isRedirect() {
      return (
        this.isRedirectAction(this.integrationValue.actionName) ||
        this.isRedirectAction(this.selectedWebsiteAction)
      );
    },
    activeClientActions() {
      if (this.isEditing || this.interactionType === "holdout") {
        if (!this.isRedirect) {
          return this.clientActions;
        }
        return [{ text: "Redirect", value: this.integrationValue.actionName }];
      }
      return [
        { text: "Redirect", value: REDIRECTS_ACTION_OBJ[`${this.interactionType}_redirect`] },
        ...this.clientActions,
      ];
    },
    buttonCTA() {
      return this.isEditing ? "Update Action" : "Add Action";
    },
    submitEnabled() {
      return !!this.selectedWebsiteAction;
    },
    redirectPlaceholder() {
      return isEmail(this.model)
        ? "e.g.: https://www.redfast.com/company/homepage"
        : "e.g.: /company/homepage";
    },
  },
  async mounted() {
    if (!this.allPromptsStore.prompts.length)
      await this.allPromptsStore.getPrompts(this.appsStore.app.id);
    if (!this.appClientActions.length) this.actionsStore.getClientActions(this.appsStore.app.id);
  },
};
</script>
