<template>
  <div class="modal-overlay-style promo-video-outer-wrapper" :style="overlayBackgroundColor">
    <div
      class="rfmodal-content outer-modal video-promo-wrapper rf-device-web rf-modal-content-wrapper center-modal"
      :style="videoWinWidth"
    >
      <div class="rfmodal-wrapper inner-modal" ref="rfmodal">
        <RfRetentionMessage :actions="actions" isVideo />
      </div>

      <div :style="containerBackgroundImage" class="rfmodal-backgroundimage">
        <video
          crossorigin="anonymous"
          ref="promoVideoPlayer"
          :autoplay="actions.rf_settings_video_autoplayed"
          :loop="actions.rf_settings_video_loop"
          :muted="actions.rf_settings_video_muted"
          :key="backgroundVideoKey"
          @ended="endedPlaying"
          :poster="actions.rf_settings_video_poster"
          v-if="showVideo"
        >
          <source
            :src="backgroundVideo"
            :type="backgroundVideoType"
            v-if="actions.rf_settings_video_media_type !== 'm3u8'"
          />
          Your browser does not support the HTML5 Video element.
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import RfRetentionMessage from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionMessage.vue";
import { bottomLeft, bottomRight, topLeft, topRight } from "@/utils/constants/PromptStyleConstants";
import Hls from "hls.js";
import { baseUrlReplacer } from "@/utils/BaseUrlReplacer";
import { useAppsStore } from "@/pinia/appsStore";

export default {
  name: "RfRetentionVideoContent",
  props: ["actions", "deviceType", "zoomStyle"],
  components: { RfRetentionMessage },
  setup: () => ({ appsStore: useAppsStore() }),
  data() {
    return {
      showVideo: true,
      showPoster: false,
      hls: new Hls(),
    };
  },
  computed: {
    videoWinWidth() {
      return {
        width: this.actions.rf_settings_video_width,
        height: this.actions.rf_settings_video_height,
        ...this.zoomStyle,
      };
    },
    backgroundVideoKey() {
      if (
        this.actions.rf_settings_video_media_type.length === 0 ||
        this.actions.rf_settings_video_media_type === "m3u8"
      ) {
        return "staticKey";
      }
      return this.backgroundVideo;
    },
    containerBackgroundImage() {
      const styles = {
        "background-color": this.actions.rf_settings_bg_image_color,
      };
      if (this.actions.rf_settings_bg_image) {
        styles["background-image"] =
          `url(${baseUrlReplacer(this.appsStore.app, this.actions.rf_settings_bg_image)})`;
      } else {
        styles["background-image"] = "none";
      }
      return styles;
    },
    backgroundVideo() {
      return this.actions.rf_settings_video_src;
    },
    backgroundVideoType() {
      return `video/${this.actions.rf_settings_video_media_type}`;
    },
    modalWrapperStyle() {
      return {
        "width": this.actions.rf_settings_window_max_width,
        ...(this.actions.rf_settings_bg_image && {
          "background-color": this.actions.rf_settings_bg_image_color,
        }),
        ...Object.fromEntries(
          [topRight, topLeft, bottomRight, bottomLeft].map(({ value }) => [
            `border-${value}-radius`,
            this.actions[`rf_retention_border-${value}-radius`],
          ]),
        ),
        "border-width": this.actions["rf_retention_border-width"],
        "border-style": this.actions["rf_retention_border-style"],
        "border-color": this.actions["rf_retention_border-color"],
        "box-sizing": this.actions["rf_retention_box-sizing"],
        "box-shadow": this.actions["rf_retention_box-shadow"],
      };
    },
    overlayBackgroundColor() {
      return {
        background: this.actions.rf_settings_overlay_background || "#252323B3",
      };
    },
  },
  methods: {
    endedPlaying() {
      this.$refs.promoVideoPlayer.classList.add("fadeOutVideo");
      this.$refs.promoVideoPoster.classList.add("fadeInPoster");
      this.showPoster = true;
    },
    attachHlsVideo(url) {
      this.$nextTick(() => {
        const mediaType = url.split(".").pop();
        if (mediaType === "m3u8") {
          const video = this.$refs.promoVideoPlayer;
          if (Hls.isSupported()) {
            this.hls.detachMedia();
            this.hls = new Hls();
            this.hls.attachMedia(video);
            this.hls.on(Hls.Events.MEDIA_ATTACHED, () => {
              this.hls.loadSource(url);
            });
          } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
            video.src = url;
            video.addEventListener("loadedmetadata", () => {
              video.play();
            });
          }
        }
      });
    },
  },
  watch: {
    "actions.rf_settings_video_src": function (to, from) {
      if (to && to.length && to !== from) {
        this.attachHlsVideo(to);
      }
    },
  },

  mounted() {
    this.attachHlsVideo(this.actions.rf_settings_video_src);
  },
};
</script>
