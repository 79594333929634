import { defineStore } from "pinia";
import { computed, ref } from "vue";
import ApiUsers from "@/apis/ApiUsers";
import { useAppsStore } from "./appsStore";
import { catchable } from "./piniaUtils";

export const READONLY = "read_only";
export const ADMIN = "admin";
export const SUPER_ADMIN = "super_admin";
export const MEMBER = "member";

export const APP_ROLES = { [MEMBER]: "Member", [ADMIN]: "Admin", [READONLY]: "Read only" };

export const useUserStore = defineStore("user", () => {
  const appsStore = useAppsStore();
  const currUser = ref({ apps: [], role: READONLY });

  const isSales = computed(() => currUser.value?.departments?.includes("sales"));
  const currAppUserRole = computed(() =>
    currUser.value.apps.find(({ id }) => id === appsStore.app.id),
  );

  const isAppAdmin = computed(() => currAppUserRole.value?.role === ADMIN);
  const isUserSuperAdmin = computed(() => currUser.value.role === SUPER_ADMIN);
  const isUserAdmin = computed(() => currUser.value.role === ADMIN);
  const canUpdateCompany = computed(() => [SUPER_ADMIN, ADMIN].includes(currUser.value.role));
  const isAdmin = computed(() => isUserSuperAdmin.value || isUserAdmin.value || isAppAdmin.value);
  const isReadOnly = computed(() => {
    if (!currUser.value?.apps || !appsStore.app.id) return false;
    return currAppUserRole.value?.role === READONLY;
  });

  const isDisabledWithMessage = computed(() => isReadOnly.value && "Insufficient Access");
  const isDisabledExtendedWithMessage = computed(() => !isAdmin.value && "Insufficient Access");

  const isImpersonating = computed(() => currUser.value?.impersonator);

  const mutateUpdateUserAuth = user => {
    currUser.value = user || (user && user.length) ? user : { id_token: "" };
    localStorage.setItem("id_token", currUser.value.id_token);
    if (currUser.value.impersonator) {
      localStorage.setItem("impersonator", currUser.value.impersonator);
    } else {
      localStorage.removeItem("impersonator");
    }
  };

  const updateUserSettings = user =>
    catchable({ t: async () => (currUser.value = await ApiUsers.updateUserSettings({ user })) });
  const updateCurrUser = user =>
    catchable({ t: async () => (currUser.value = await ApiUsers.updateUser({ user })) });
  const register = params => catchable({ t: () => ApiUsers.register(params) });
  const login = async ({ email, password }) =>
    mutateUpdateUserAuth(await ApiUsers.loginUser({ email, password }));

  const getUser = () =>
    catchable({
      t: async () => {
        const userIn = await ApiUsers.getUser();
        if (userIn) currUser.value = userIn;
      },
    });
  const resetPasswordUser = ({ reset_password_token, password, password_confirmation }) =>
    catchable({
      t: () =>
        ApiUsers.resetPasswordUser({ reset_password_token, password, password_confirmation }),
    });
  const impersonateUser = userId =>
    catchable({
      t: async () => {
        mutateUpdateUserAuth(await ApiUsers.impersonate({ userId }));
        window.location = "/";
      },
    });

  const canModifyUser = userRole =>
    isUserSuperAdmin.value || isUserAdmin.value || (isAppAdmin.value && userRole === MEMBER);

  const logout = ({ redirect = "/login" } = {}) =>
    catchable({
      t: async () => {
        await ApiUsers.logoutUser();
        localStorage.removeItem("id_token");
        localStorage.removeItem("impersonator");
        redirect && (location.href = redirect);
      },
    });

  const confirmInvitedUser = ({ invitation_token, password, password_confirmation }) =>
    catchable({
      t: async () =>
        await ApiUsers.confirmInvitedUser({ invitation_token, password, password_confirmation }),
    });

  return {
    currUser,
    isImpersonating,
    register,
    login,
    getUser,
    resetPasswordUser,
    impersonateUser,
    canUpdateCompany,
    currAppUserRole,
    currUser,
    isAdmin,
    isAppAdmin,
    isDisabledExtendedWithMessage,
    isDisabledWithMessage,
    isReadOnly,
    isSales,
    isUserAdmin,
    isUserSuperAdmin,
    updateCurrUser,
    canModifyUser,
    logout,
    confirmInvitedUser,
    updateUserSettings,
  };
});
