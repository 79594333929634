<template>
  <div>
    <div class="goal-configuration-wrapper">
      <div class="rf-section-title">
        <span v-if="showHelpText"
          >Add a pipeline segment to this prompt to enable this feature.</span
        >
      </div>
    </div>
    <div style="max-width: 600px">
      <v-radio-group hide-details v-model="selectedStage" style="margin-top: 0">
        <v-radio
          v-for="(stage, index) in stageItems"
          :key="index"
          :value="stage.value"
          :label="stage.text"
        />
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import { usePipelinesStore } from "@/pinia/pipelinesStore";

export default {
  name: "RfStageSelection",
  props: ["pipelineStageId", "segments", "showHelpText", "pipelineId"],
  setup: () => ({ pipelinesStore: usePipelinesStore() }),
  data() {
    return { selectedStage: this.pipelineStageId };
  },
  computed: {
    pipeline() {
      return this.pipelinesStore.pipelines.find(p => p.id === this.pipelineId);
    },
    availableStages() {
      let stages = [];
      if (this.pipeline && this.segmentStage) {
        const { segmentStage } = this;
        stages = [{ name: "None", id: segmentStage.id }];
        if (segmentStage) {
          const stage_index = this.pipeline.pipeline_stages.indexOf(segmentStage);
          stages = stages.concat(this.pipeline.pipeline_stages.filter(s => s.order > stage_index));
        }
      }
      return stages;
    },
    stageItems() {
      const stages = this.availableStages.map(stage => {
        return {
          text: stage.name,
          value: stage.id,
        };
      });
      return stages;
    },
    segmentStage() {
      if (this.pipeline) {
        const segment_pipeline_stage_ids = this.segments.map(segment => segment.pipeline_stage_id);
        const stage = this.pipeline.pipeline_stages.find(
          ps => segment_pipeline_stage_ids.indexOf(ps.id) >= 0,
        );
        return stage;
      }
    },
  },
  methods: {
    fillModel(model) {
      if (!this.selectedStage) {
        throw new Error("Stage transition is required");
      }
      model.pipeline_stage_id = this.selectedStage;
    },
  },
  mounted() {
    this.pipelinesStore.getPipelines(this.$route.params.aid);
  },
};
</script>
