import { defineStore } from "pinia";
import ApiSegments from "@/apis/ApiSegments";
import ApiMetrics from "@/apis/ApiMetrics";
import VuexUtils from "@/utils/VuexUtils";
import { ref } from "vue";
import { useLegacyTmpStore } from "./legacyTmpStore";
import { useToastsStore } from "./toastsStore";
import { catchable } from "./piniaUtils";

export const useSegmentStore = defineStore("segment", () => {
  const legacyTmpStore = useLegacyTmpStore();
  const toastsStore = useToastsStore();
  const segment = ref(null);
  const activities = ref([]);
  const metrics = ref({});

  const get = ({ appId, segId }) =>
    catchable({
      t: async () => {
        const [segmentIn, activitiesIn] = await Promise.all([
          ApiSegments.getSegment(appId, segId),
          ApiSegments.getActivities(appId, segId),
        ]);
        segment.value = segmentIn;
        activities.value = activitiesIn;
        legacyTmpStore.setMlEnabled(segmentIn.is_ml_enabled);
        legacyTmpStore.setMlTrait(segmentIn.ml_target_trait);
      },
      loadable: true,
    });

  const create = ({ appId, modelSegment }) =>
    catchable({
      t: async () => {
        const segmentIn = await ApiSegments.createSegment(appId, modelSegment);
        const [activitiesIn, metricsIn] = await Promise.all([
          ApiSegments.getActivities(appId, segmentIn.id),
          ApiMetrics.getOneSegment(appId, segmentIn.id),
        ]);
        segment.value = segmentIn;
        activities.value = activitiesIn;
        if (metricsIn) metrics.value = metricsIn;
        legacyTmpStore.setMlEnabled(segmentIn.is_ml_enabled);
        legacyTmpStore.setMlTrait(segmentIn.ml_target_trait);
        return segmentIn;
      },
      c: e => toastsStore.create({ type: "error", body: e.message }),
      loadable: true,
      throwable: true,
    });

  const update = ({ appId, segId, modelSegment }) =>
    catchable({
      t: async () => {
        const segmentIn = await ApiSegments.updateSegment(appId, segId, modelSegment);
        const activitiesIn = await ApiSegments.getActivities(appId, segId);
        segment.value = segmentIn;
        activities.value = activitiesIn;
        legacyTmpStore.setMlEnabled(segmentIn.is_ml_enabled);
        legacyTmpStore.setMlTrait(segmentIn.ml_target_trait);
        toastsStore.create({ type: "success", body: "Segment updated successfully" });
      },
      c: e => toastsStore.create({ type: "error", body: e.message }),
      loadable: true,
      throwable: true,
    });

  const remove = ({ appId, segId }) =>
    catchable({
      t: async () => {
        await ApiSegments.deleteSegment(appId, segId);
        segment.value = null;
      },
      loadable: true,
    });

  const getMetrics = ({ appId, id, params }) =>
    catchable({
      t: async () => {
        const metricsIn = await ApiMetrics.getOneSegment(appId, id, params);
        metrics.value = VuexUtils.combineMetrics(metrics.value, metricsIn);
      },
      loadable: true,
    });
  return { segment, activities, metrics, get, create, update, remove, getMetrics };
});
