import { useAppsStore } from "@/pinia/appsStore";
import { DeviceTypeWording } from "./constants/DevicesConstants";
import { PromptTypes } from "./constants/PromoTypesConstants";

export default {
  methods: {
    getPromoDeviceTypeWording(type) {
      return DeviceTypeWording[type]?.text;
    },
    getPromoTypeWording(type) {
      return PromptTypes[type]?.label;
    },
    typeWithDevice(item) {
      const type = PromptTypes[item.path_type].label;
      let deviceType = DeviceTypeWording[item.device_type].text;
      if (useAppsStore().app?.flags?.custom_devices && deviceType === "Custom") {
        deviceType = item.custom_devices.map(el => el.name).join(", ");
      }

      return `${deviceType}: ${type}`;
    },
  },
};
