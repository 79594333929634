import { computed, ref, watch } from "vue";

export const useConsentPreview = props => {
  const consentChecked = ref(false);

  const needsConsent = computed(() => props.actions.consent_enabled === "true");
  const consentNotNeededOrGiven = computed(() => !needsConsent.value || consentChecked.value);

  watch(
    () => props.actions.consent_prechecked,
    prechecked => (consentChecked.value = prechecked === "true"),
    { immediate: true },
  );

  return {
    consentChecked,
    needsConsent,
    consentNotNeededOrGiven,
  };
};
