<template>
  <div class="rf-detail-content-wrapper company-settings-wrapper" style="margin-top: 1rem">
    <v-card>
      <v-tabs v-model="settingsTab" centered background-color="#082638" height="70" dark>
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1" v-if="userStore.isUserSuperAdmin">
          <v-icon left>fas fa-building</v-icon>Custom Metrics
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="settingsTab">
        <v-tab-item value="tab-1">
          <RfCustomMetrics />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script setup>
import RfCustomMetrics from "@/components/RfSettings/RfCustomMetrics.vue";
import { useUserStore } from "@/pinia/userStore";
import { ref } from "vue";

const userStore = useUserStore();
const settingsTab = ref(null);
</script>
