<template>
  <div class="promo-design-group flex flex-col gap-3">
    <v-checkbox
      v-model="actions.consent_enabled"
      true-value="true"
      false-value="false"
      label="Enable consent checkbox"
      hide-details
    />
    <v-textarea
      v-model.trim="actions.consent_text"
      hide-details
      outlined
      dense
      label="Consent Checkbox Label"
      rows="3"
    ></v-textarea>
    <RfRetentionInsertLink
      v-model="actions.consent_text"
      title="Consent Checkbox label"
      textPlaceholder="Legal terms"
      urlPlaceholder="http://company.com/terms"
      deviceType="web"
      :insertColor="actions.consent_font_color"
    />
    <div class="promo-fonts-size-input">
      <v-text-field
        outlined
        dense
        label="Font Size"
        v-model.trim="actions.consent_font_size"
        v-validate="'required'"
        name="consent-font-size"
        required
        hide-details
      ></v-text-field>
      <v-text-field
        outlined
        dense
        label="Font Color"
        v-model.trim="actions.consent_font_color"
        required
        v-validate="'required'"
        name="consent-font-color"
        hide-details
      ></v-text-field>
    </div>
    <v-checkbox
      v-model="actions.consent_prechecked"
      true-value="true"
      false-value="false"
      label="Consent checkbox ticked by default"
      hide-details
    />
  </div>
</template>

<script>
import RfRetentionInsertLink from "./components/RfRetentionInsertLink.vue";

export default {
  name: "RfRetentionConsent",
  props: ["actions"],
  components: { RfRetentionInsertLink },
};
</script>
