<template>
  <div class="wrapper">
    <top-navbar @switchApp="switchApp" />
    <div v-if="$route.name !== 'Settings'" data-rf-zone="top-notification-bar" class="mt-12"></div>
    <LeftMainNav
      v-if="currApp"
      :apps="appsStore.apps"
      :currApp="currApp"
      :docsUrl="appsStore.docsUrl"
      v-on:clickLive="clickLive"
      @switchApp="switchApp"
    />
    <div class="main-panel">
      <div :class="currClasses">
        <!-- your content here -->
        <router-view v-if="currApp"></router-view>
        <div
          v-else-if="!legacyLastErrorStore.loading"
          class="absolute inset-0 left-16 z-0 flex h-full"
        >
          <div class="align-center m-auto inline-flex flex-col gap-4 pt-10 text-center">
            <span class="text-heading-1 uppercase">Insufficient Access</span>
            <span class="text-heading-2 !font-normal">
              Please contact your admin user to authorize access
            </span>
          </div>
        </div>
      </div>
    </div>
    <rf-modal-loading :showDialog="legacyLastErrorStore.loading > 0" />
    <RfToastContainer :toasts="toastsStore.toasts" @close="toastsStore.remove" />
    <RfDomainChooserWrapper
      :app="currApp"
      :visible="livePreviewShow"
      v-on:open="openLivePreview"
      v-on:close="closeLivePreview"
    />
  </div>
</template>

<script>
import TopNavbar from "@/views/dashboard/Layout/TopNavbar.vue";
import LeftMainNav from "@/views/dashboard/Layout/LeftMainNav.vue";
import LiveMixin from "@/utils/LiveMixin";
import RfDomainChooserWrapper from "@/components/RfCommonCards/RfDomainChooserWrapper.vue";
import copyToClipboard from "@/utils/CopyToClipboard";
import RfToastContainer from "@/blocks/RfToastContainer.vue";
import { useToastsStore } from "@/pinia/toastsStore";
import { useAppsStore } from "@/pinia/appsStore";
import { useLegacyLastErrorStore } from "@/pinia/legacyLastErrorStore";
import RfModalLoading from "./RfModalLoading.vue";

export default {
  name: "RfDashboard",
  setup: () => ({
    toastsStore: useToastsStore(),
    appsStore: useAppsStore(),
    legacyLastErrorStore: useLegacyLastErrorStore(),
    // TODO: replace with *import.env.prod* on vue 3 migration
    showNotificationBar: [
      "pulserf1.redfast.com",
      "pulse.redfast.com",
      "pulsepp.redfast.com",
    ].includes(window.location.host),
  }),
  components: {
    TopNavbar,
    LeftMainNav,
    RfModalLoading,
    RfDomainChooserWrapper,
    RfToastContainer,
  },
  mixins: [LiveMixin],
  data: () => ({ livePreviewShow: false }),
  computed: {
    currApp() {
      return this.appsStore.app;
    },
    currClasses() {
      return {
        "content": !this.$route.meta.hideContent,
        "prompt-flag-enabled": true,
        "prompt-tab-view-disabled": true,
      };
    },
  },
  methods: {
    switchApp(app) {
      if (!app?.id) return;
      const pathFull = `/apps/${app.id}/dashboard`;

      if (app.id === this.currApp?.id) return this.$router.push(pathFull);
      window.location.replace(pathFull);
    },
    clickLive() {
      this.livePreviewShow = true;
    },
    openLivePreview(domain) {
      this.openLiveUrl(null, domain);
      this.closeLivePreview();
      this.toastsStore.create({
        type: "info",
        body: "Copy Real Time params to clipboard",
        action: { title: "Copy", handler: () => copyToClipboard(this.realtimeParams) },
        timeout: 30000,
      });
    },
    closeLivePreview() {
      this.livePreviewShow = false;
    },
    truncate(value) {
      if (value.length > 25) {
        value = `${value.substring(0, 22)}...`;
      }
      return value;
    },
  },
};
</script>
