<template>
  <router-link :to="getItemUrl(item)">
    <v-hover v-slot:default="{ hover }">
      <v-card :elevation="hover ? 2 : 1" height="auto">
        <div class="prompt-thumb-wrapper">
          <RfPromotionThumbnail :item="item" :key="item.id" />
        </div>
        <div class="prompt-detail-wrapper min-w-px">
          <span class="d-flex min-w-px pr-4">
            <span class="d-flex flex-column w-full min-w-px">
              <h1 class="max-w-full truncate">
                {{ item.name }}
              </h1>
              <div>{{ item.description }}</div>
            </span>
            <v-tooltip v-if="showPin" bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  class="ml-4"
                  icon
                  small
                  :color="isPinned ? 'primary' : undefined"
                  @click.stop.prevent="$emit('pin', item.id)"
                >
                  <v-icon size="22"> push_pin </v-icon>
                </v-btn>
              </template>
              <span>{{ isPinned ? "Unpin" : "Pin" }} item</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  class="ml-2"
                  icon
                  small
                  @click.stop.prevent="exportSinglePromptToCsv(item, currChartSrc)"
                >
                  <v-icon size="22"> download </v-icon>
                </v-btn>
              </template>
              <span>Export to CSV</span>
            </v-tooltip>
          </span>

          <div v-if="item.sequence_id && !hideSequence">
            Guide:
            <router-link :to="sequenceLink(item)">{{ item.sequence_name }}</router-link>
          </div>
          <div v-if="item.pipeline_id">
            Pipeline:
            <router-link :to="pipelineLink(item)">{{ item.pipeline_name }}</router-link>
          </div>
          <div class="promo-list-view-details">
            <RfPromoDetailsRow title="Type">
              <template v-slot:promoinfo>
                {{ typeWithDevice(item) }}
              </template>
            </RfPromoDetailsRow>
            <RfPromoDetailsRow title="Segments" v-if="!hideSegment">
              <template v-slot:promoinfo>
                <div class="list-promo-detail-text" v-if="item.sequence_id">
                  Inherited from:
                  <router-link :to="sequenceLink(item)">{{ item.sequence_name }}</router-link>
                </div>
                <div class="list-promo-detail-text" v-else>
                  <template v-if="item.segments?.length">
                    <router-link
                      v-for="segment in item.segments"
                      :key="segment.name"
                      class="list-promo-detail--segments-link"
                      :to="segmentLink(segment)"
                    >
                      {{ segment.name }}
                    </router-link>
                  </template>
                  <span v-else>-</span>
                </div>
              </template>
            </RfPromoDetailsRow>
            <RfPromoDetailsRow title="Schedule" v-if="!hideSchedule">
              <template v-slot:promoinfo>{{ scheduleDisplay(item) }}</template>
            </RfPromoDetailsRow>
            <RfPromoDetailsRow title="Status" v-if="!hideStatus">
              <template v-slot:promoinfo>
                <RfPromoPublishStatus :item="item" />
              </template>
            </RfPromoDetailsRow>
          </div>
        </div>
        <div class="prompt-stats-wrapper">
          <RfPlacementsStats
            :model="item"
            :metricsKey="currChartSrc"
            :autoHeight="true"
            :preprocessedGoals="preprocessedGoals"
            class="rf-metrics-arrow-responsive"
          />
        </div>
      </v-card>
    </v-hover>
  </router-link>
</template>

<script>
import PromoTypeMixin from "@/utils/PromoTypeMixin";
import RfPlacementsStats from "@/components/RfPlacements/RfPlacementsStats.vue";
import RfPromotionThumbnail from "@/components/RfPromotions/RfPromotionThumbnail.vue";
import RfPromoDetailsRow from "@/components/RfPromotions/RfPromoDetailsRow.vue";
import RfPromoPublishStatus from "@/components/RfPromotions/RfPromoPublishStatus.vue";
import { exportSinglePromptToCsv } from "@/utils/CsvUtils";
import { dateDisplay } from "@/utils/DateDisplayUtils";

export default {
  name: "RfPromotionsListViewItem",
  props: {
    item: Object,
    currChartSrc: String,
    preprocessedGoals: { type: Boolean, default: false },
    hideSegment: { type: Boolean, default: false },
    hideSchedule: { type: Boolean, default: false },
    hideSequence: { type: Boolean, default: false },
    hideStatus: { type: Boolean, default: false },
    fromSequence: { type: Boolean, default: false },
    isPinned: { type: Boolean, default: false },
    showPin: { type: Boolean, default: false },
  },
  mixins: [PromoTypeMixin],
  components: { RfPlacementsStats, RfPromotionThumbnail, RfPromoDetailsRow, RfPromoPublishStatus },
  setup: () => ({ exportSinglePromptToCsv }),
  methods: {
    sequenceLink(item) {
      return { path: `/apps/${this.$route.params.aid}/experiences/${item.sequence_id}` };
    },
    pipelineLink(item) {
      return { path: `/apps/${this.$route.params.aid}/pipelines/${item.pipeline_id}` };
    },
    segmentLink(item) {
      return { path: `/apps/${this.$route.params.aid}/segments/${item.id}` };
    },
    scheduleDisplay(item) {
      return `${dateDisplay(item.start_date)} to ${dateDisplay(item.end_date)}`;
    },
    getItemUrl(item) {
      if (this.fromSequence && item.sequence_id) {
        return {
          path: `/apps/${this.$route.params.aid}/experiences/${item.sequence_id}/promotions/${item.id}`,
        };
      }
      return { path: `/apps/${this.$route.params.aid}/retentions/${item.id}` };
    },
  },
};
</script>
