// used by the parent component of the filters component, the parent renders the promos
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import { useSequencesStore } from "@/pinia/sequencesStore";
import { useAppsStore } from "@/pinia/appsStore";
import { useAllPromptsStore } from "@/pinia/allPromptsStore";

export default {
  data() {
    return {
      deviceFilter: [],
      filterPlacementsSegment: [],
      placementPathType: [],
      promoPublishStatus: [],
      groupFilter: null,
      expirationStatus: [],
    };
  },
  mixins: [UserSettingsMixin],
  computed: {
    appsStore: () => useAppsStore(),
    currApp() {
      return this.appsStore.app;
    },
    sequencesStore() {
      return useSequencesStore();
    },
    sequences() {
      return this.sequencesStore.sequences;
    },
    allPromptsStore() {
      return useAllPromptsStore();
    },
  },
  methods: {
    setDeviceTypes(types) {
      this.deviceFilter = types;
    },
    setPathTypes(types) {
      this.placementPathType = types;
    },
    setSegmentIds(ids) {
      this.filterPlacementsSegment = ids;
    },
    setStatuses(statuses) {
      this.promoPublishStatus = statuses;
    },
    groupFilterUpdate(filter) {
      this.groupFilter = filter;
    },
    setExpirationStatus(expirationStatus) {
      this.expirationStatus = expirationStatus;
    },
    segmentMatch(item) {
      return item.segments.findIndex(c => this.filterPlacementsSegment.indexOf(c.id) >= 0) >= 0;
    },
    expirationStatusMatch(endDate) {
      if (!endDate) return false;

      const daysFromToday = date => Math.floor((new Date() - new Date(date)) / (24 * 3600 * 1000));

      const expirationComparators = {
        expires_soon: date => daysFromToday(date) >= -6 && daysFromToday(date) <= 0,
        expired_recently: date => daysFromToday(date) > 0 && daysFromToday(date) <= 7,
      };

      return this.expirationStatus.reduce(
        (prev, curr) => prev || expirationComparators[curr](endDate),
        false,
      );
    },
  },
  mounted() {
    const appId = this.$route.params.aid;
    if (!this.sequences || !this.sequences.length) this.sequencesStore.getSequences(appId);
    if (!this.allPromptsStore.promptGroups?.length) this.allPromptsStore.getPromptGroups(appId);
    this.deviceFilter = this.getUserSetting("placementsDeviceFilter") || [];
    this.filterPlacementsSegment = this.getUserSetting("placementsSegmentFilter") || [];
    this.placementPathType = this.getUserSetting("placementsPathTypeFilter") || [];
    this.promoPublishStatus = this.getUserSetting("placementsStatusFilter") || [
      "Running",
      "Pending",
      "Paused",
    ]; // running and pending
    this.groupFilter = this.getUserSetting("placementsGroupFilter") || "ungrouped";
    this.expirationStatus = this.getUserSetting("placementsExpirationStatus") || [];
  },
};
