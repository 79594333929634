<template>
  <div class="w-full">
    <div class="flex">
      <v-tabs
        v-if="appsStore.app?.flags?.legacy_apple_editor && model.path_type !== 'interstitial'"
        v-model="viewTabModal"
        slider-color="666666"
        dark
        centered
        icons-and-text
        height="50"
        background-color="#000"
      >
        <v-tab href="#desktopViewTab" @click="$emit('clickTab', 'desktopViewTab')">iPad</v-tab>
        <v-tab href="#mobilewebViewTab" @click="$emit('clickTab', 'mobilewebViewTab')"
          >iPhone</v-tab
        >
      </v-tabs>
      <RfLanguagePreview :model="model" :actions="actions" />
    </div>
    <v-tabs-items v-model="viewTabModal">
      <v-tab-item :transition="false" :reverse-transition="false" value="desktopViewTab">
        <RfRetentionModal
          :model="model"
          :actions="previewActions"
          :deviceType="mobileViewType"
          :zoomStyle="zoomStyle"
          :isMobile="
            !appsStore.app?.flags?.legacy_apple_editor || model.path_type === 'interstitial'
          "
          :isNewDeviceEditor="!appsStore.app?.flags?.legacy_apple_editor"
          ref="ios_ipad"
        />
        <div class="ipadToggleView">
          <button @click="mobileView('ipadLandscape')">
            <v-icon color="#ccc">stay_current_landscape</v-icon>
          </button>
          <button @click="mobileView('ipadPortrait')">
            <v-icon color="#ccc">stay_current_portrait</v-icon>
          </button>
        </div>
      </v-tab-item>
      <v-tab-item :transition="false" :reverse-transition="false" value="mobilewebViewTab">
        <RfRetentionModal
          :model="model"
          isMobile
          :actions="previewActions"
          deviceType="mobileWeb"
          ref="ios_iphone"
        />
      </v-tab-item>
    </v-tabs-items>
    <div class="preview-control-buttons" v-if="viewTabModal === 'desktopViewTab'">
      <v-btn small color="#f3f3f3" @click="zoomIn">+</v-btn>
      <v-btn class="reset-zoom-btn" small color="#f3f3f3" @click="resetZoom">{{
        `${zoomValue * 100}%`
      }}</v-btn>
      <v-btn small color="#f3f3f3" @click="zoomOut">-</v-btn>
    </div>
    <component :is="'style'" v-if="actions.rf_settings_custom_css">{{
      actions.rf_settings_custom_css
    }}</component>
  </div>
</template>

<script>
import RfRetentionModal from "@/components/RfPathCreate/RfRetentionActions/RfRetentionModal.vue";
import ZoomPreviewMixin from "@/utils/ZoomPreviewMixin";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import CompositeImageSaver from "@/utils/CompositeImageSaver";
import RfLanguagePreview from "@/components/RfPathCreate/RfRetentionDevices/RfLanguagePreview.vue";
import { useAppsStore } from "@/pinia/appsStore";

export default {
  name: "RfRetentionDevicIos",
  props: ["actions", "viewTypeTab", "model"],
  components: { RfRetentionModal, RfLanguagePreview },
  mixins: [ZoomPreviewMixin, LanguagePreviewMixin],
  setup: () => ({ appsStore: useAppsStore() }),
  data() {
    return { viewTabModal: this.viewTypeTab, mobileViewType: "ipadLandscape" };
  },
  methods: {
    mobileView(viewMode) {
      this.mobileViewType = viewMode;
    },
    async fillModel(model) {
      if (!model.device_type) {
        model.device_type = this.model.device_type;
      }
      await CompositeImageSaver.fill(model, this.$refs, this.appsStore.app);
    },
  },
};
</script>
