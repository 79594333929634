import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiExperiments {
  static getExperimentModel(pathId) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/experiments/new`);
  }

  static createExperiment(pathId, experiment) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/experiments`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ experiment }),
    });
  }

  static getExperiments(pathId) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/experiments`);
  }

  static setExperiment(pathId, expId, experiment) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/experiments/${expId}`, {
      method: "PUT",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ experiment }),
    });
  }

  static startExperiment(pathId, expId) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/experiments/${expId}/start`, {
      method: "POST",
    });
  }

  static stopExperimentWithWinner(pathId, expId, variant) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/experiments/${expId}/stop`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ variation: { id: variant.id } }),
    });
  }

  static stopExperiment(pathId, expId) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/experiments/${expId}/stop`, {
      method: "POST",
    });
  }

  static getExperiment(pathId, expId) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/experiments/${expId}`);
  }

  static getExperimentForceUpdate(pathId, expId) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/experiments/${expId}?force_update=true`);
  }

  static cloneExperiment(pathId, experimentId) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/experiments/${experimentId}/clone`, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  static deleteExperiment(pathId, experimentId) {
    return authenticatedFetch(`${ApiBaseUrl}paths/${pathId}/experiments/${experimentId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
      },
    });
  }
}
