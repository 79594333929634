import { render, staticRenderFns } from "./RfInsertUserTrait.vue?vue&type=template&id=2b4e4d2a"
import script from "./RfInsertUserTrait.vue?vue&type=script&lang=js"
export * from "./RfInsertUserTrait.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports