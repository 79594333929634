<template>
  <div>
    <div class="crPr-row-2">
      <h3>Device type:</h3>
      <div class="crPr-device-selection">
        <div
          v-for="item in deviceTypeOptions"
          @click="setDeviceType(item.value)"
          :data-cy="`device-type-${item.value}`"
          :key="item.name"
        >
          <div :class="currDeviceType === item.value ? 'cp_d_inner selected' : 'cp_d_inner'">
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="crPr-row-2"
      style="padding-top: 20px"
      v-if="customDevicesFlag && currDeviceType === 'custom_defined'"
    >
      <h3>Custom Devices:</h3>
      <template v-if="devicesAreLoaded">
        <v-chip-group
          class="crPr-device-selection"
          v-model="deviceIdxs"
          column
          mandatory
          multiple
          v-if="customDevicesOptions.length"
        >
          <v-chip
            v-for="item in customDevicesOptions"
            class="cp_d_inner"
            active-class="selected"
            :data-cy="`custom-device-${item.label}`"
            :key="item.value"
            filter
          >
            {{ item.text }}
          </v-chip>
        </v-chip-group>
        <span class="error--text" v-else>
          No custom devices found. In order to continue you need to
          <router-link :to="`/apps/${$route.params.aid}/settings/custom_devices`">
            create one.
          </router-link>
        </span>
      </template>
      <span v-else>Loading...</span>
    </div>
  </div>
</template>

<script>
import { useAppsStore } from "@/pinia/appsStore";
import { useCustomDevicesStore } from "@/pinia/customDevicesStore";

const deviceTypes = [
  {
    value: "web",
    name: "Desktop & Mobile Web",
    description:
      "Works on Desktop and mobile browsers. Supports Safari, Chrome, Firefox, Edge, and Internet Explorer.",
    title: "Custom Prompt for Desktop and Mobile Web",
  },
  {
    value: "ios",
    name: "iPhone & iPad",
    description:
      "Works on Apple iOS iPhone and iPad native apps. Requires the addition of the Redfast iOS SDK to your app.",
    title: "Prompt type for iPhone and iPad",
  },
  {
    value: "tv_os",
    name: "Apple TV",
    description:
      "Works on Apple TV second generation or later running tvOS. Requires the addition of Redfast tvOS SDK to your app.",
    title: "Prompt type for Apple TV",
  },
  {
    value: "android_os",
    name: "Android & Fire TV",
    description:
      "Works on Android phone, tablet, TV devices and Fire TV. Requires the addition of the Redfast Android SDK to your app.",
    title: "Prompt type for Android Tablet, TV, and Fire TV",
  },
  {
    value: "roku_os",
    name: "Roku",
    description:
      "Works on Roku devices including Roku TV. Requires the addition of Redfast Roku SDK to your app.",
    title: "Prompt type for Roku",
  },
  {
    value: "custom_defined",
    name: "Custom",
    description: "Used for any other purposes other than default var",
    title: "Prompt type for custom devices",
  },
];

export default {
  name: "RfDeviceTypeSelection",
  props: ["webOnly"],
  setup: () => ({
    customDevicesStore: useCustomDevicesStore(),
    appsStore: useAppsStore(),
  }),
  data() {
    return {
      currDeviceType: "web",
      deviceIdxs: [],
      devicesAreLoaded: false,
    };
  },
  methods: {
    setDeviceType(type) {
      this.currDeviceType = type;
      this.$emit("setDeviceType", type, this.deviceTypeData);
    },
  },
  computed: {
    customDevicesFlag() {
      return this.appsStore.app?.flags?.custom_devices;
    },
    customDevicesOptions() {
      return this.customDevicesStore.customDevices.map(el => ({
        text: el.name,
        value: el.id,
        label: el.label,
      }));
    },
    deviceTypeOptions() {
      if (this.webOnly) return deviceTypes.filter(el => el.value === "web");
      if (this.customDevicesFlag) return deviceTypes;
      return deviceTypes.filter(el => el.value !== "custom_defined");
    },
    deviceTypeData() {
      return deviceTypes.find(el => el.value === this.currDeviceType);
    },
    customDeviceIds() {
      if (this.currDeviceType !== "custom_defined") return [];
      return this.deviceIdxs.map(idx => this.customDevicesOptions[idx].value);
    },
  },
  watch: {
    customDeviceIds(to) {
      this.$emit("setCustomDeviceIds", to);
    },
  },
  mounted() {
    this.devicesAreLoaded = false;
    this.customDevicesStore.getCustomDevices(this.$route.params.aid).finally(() => {
      this.devicesAreLoaded = true;
    });
  },
};
</script>
