<template>
  <div>
    <div class="upload-bgimg-wrapper">
      <v-btn
        depressed
        color="accent"
        class="upload-image-btn"
        @click="triggerUploadBgImageFromInput('bgImageMobileWebInput')"
      >
        <v-icon size="14" left>fas fa-upload</v-icon>Upload Company Logo
      </v-btn>
      <RfImageFileInfo
        :image="actions.rf_settings_mobile_bg_image"
        :filename="actions.rf_settings_mobile_bg_image_filename"
      />
      <input
        type="file"
        style="display: none"
        ref="bgImageMobileWebInput"
        accept="image/*"
        @change="uploadBgImageFromInput('mobileWeb')"
      />
      <div class="upload-img-helper">width of 300px recommended</div>
    </div>
    <div style="padding: 15px 0 0; display: flex">
      <v-text-field
        outlined
        dense
        label="Background color"
        v-model.trim="actions.rf_settings_bg_image_color"
        hide-details
      />
      <v-text-field
        outlined
        dense
        label="Logo Width"
        v-model.trim="actions.rf_mobile_width"
        hide-details
        style="margin-left: 10px"
      />
    </div>
  </div>
</template>
<script>
import UploadImageMixin from "@/utils/UploadImageMixin";
import RfImageFileInfo from "@/components/RfPathCreate/RfRetentionActions/components/RfImageFileInfo.vue";

export default {
  name: "RfRetentionActionsCompanyLogo",
  mixins: [UploadImageMixin],
  components: { RfImageFileInfo },
  props: ["actions", "model", "app"],
  data() {},
  computed: {
    isInlineOrText() {
      return ["tile", "horizontal", "vertical", "text"].indexOf(this.model.path_type) > -1;
    },
  },
  methods: {
    triggerUploadBgImageFromInput(displayType) {
      if (displayType === "bgImageInput") {
        this.$refs.bgImageInput.click();
      } else {
        this.$refs.bgImageMobileWebInput.click();
      }
    },
  },
};
</script>
