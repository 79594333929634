import { defineStore } from "pinia";
import { ref } from "vue";
import ApiSegments from "@/apis/ApiSegments";
import { exceptionWrapper, useTableData } from "./piniaUtils";

export const useSegmentsStore = defineStore("segments", () => {
  const segments = ref([]);
  const loading = ref(false);
  const error = ref(false);
  const { table, resetPage } = useTableData();

  const getSegments = (appId, params) =>
    exceptionWrapper(
      async () => {
        segments.value = await ApiSegments.getSegments(appId, params);
        table.page = 0;
        table.totalCount = 0;
        table.totalPages = 1;
      },
      { loading, error, toast: true },
    );

  const getSegmentsPaged = (appId, params, next = false) =>
    exceptionWrapper(
      async () => {
        if (!next) segments.value = [];
        const { segments: fetchedSegments, pagination } = await ApiSegments.getSegments(appId, {
          ...params,
          serialize: "short",
          page: table.page + 1,
          per_page: table.perPage,
        });

        if (next) {
          const segmentsFiltered = fetchedSegments.filter(
            el => !segments.value.find(({ id }) => id === el.id),
          );
          segments.value = [...segments.value, ...segmentsFiltered];
        } else {
          segments.value = fetchedSegments;
        }

        table.page = pagination.page;
        table.totalCount = pagination.total_count;
        table.totalPages = pagination.total_pages;
      },
      { loading, error, toast: true },
    );

  const bulkDelete = (appId, segmentsIn = []) =>
    exceptionWrapper(
      () =>
        ApiSegments.bulkDelete(
          appId,
          segmentsIn.map(({ id }) => ({ id })),
        ),
      { toast: true, throw: true },
    );

  const updateLocalSegment = segment => {
    if (!segments.value.length) return;
    const segmentsIndex = segments.value.findIndex(({ id }) => id === segment.id);
    const segmentsCopy = [...segments.value];
    if (segmentsIndex === -1) return;
    segmentsCopy[segmentsIndex] = { ...segments.value[segmentsIndex], ...segment };
    segments.value = segmentsCopy;
  };

  const bulkUpdate = (appId, segmentsIn = []) =>
    exceptionWrapper(
      () => {
        ApiSegments.bulkUpdate(appId, segmentsIn);
        segmentsIn.forEach(updateLocalSegment);
      },
      { toast: true, throw: true },
    );

  return {
    segments,
    loading,
    error,
    getSegments,
    getSegmentsPaged,
    table,
    resetPage,
    bulkDelete,
    bulkUpdate,
  };
});
