<template>
  <div class="d-flex flex-column">
    <RfDomainChooserWrapper
      :app="app"
      :visible="livePreviewShow"
      @open="openLivePreview"
      @close="closeLivePreview"
    />
    <RfSetupUsageTrackingModal
      v-if="showDialog"
      :showDialog="showDialog"
      :custom_field="custom_field"
      :isUpdating="isUpdating"
      :disabled="userStore.isDisabledWithMessage"
      @onSubmit="onSubmit"
      @resetForm="resetForm"
    />
    <v-dialog v-model="showImportListDialog" max-width="500">
      <v-card tag="form" @submit.prevent="onSubmitEventDataSource">
        <v-card-title class="headline">Add External Tracker</v-card-title>
        <v-card-text>
          <v-autocomplete
            outlined
            dense
            label="Your imported data source"
            :items="fieldsByExternalTypes"
            item-text="label"
            return-object
            v-model="externalFieldsList"
            placeholder="Start typing to select"
          >
            <template v-slot:item="data">
              <v-list-item-avatar>
                <img :src="logos[data.item.connector_type]" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            depressed
            class="cancel-btn"
            outlined
            @click="showImportListDialog = false"
            style="width: 100px"
            >Close</v-btn
          >
          <v-btn depressed color="accent" type="submit">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTrackingCodeDialog" max-width="700">
      <v-card>
        <v-card-title class="headline">Custom tracking code</v-card-title>
        <v-card-text>
          <RfSetupUsageTrackingCode
            :app="app"
            :custom_field="custom_field_code"
            :is_disabled="false"
          />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            depressed
            class="cancel-btn"
            outlined
            @click="showTrackingCodeDialog = false"
            style="width: 100px"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RfSimpleCard class="mb-5">
      <div class="px-7 py-5">
        <p>
          Redfast provides automated tracking of visits and minutes and allows you to customize
          additional tracking of various features within your app/site. Using a tag manager, add the
          following script to your app. If you're on Google Tag Manager go
          <b><a href="https://tagmanager.google.com/gallery/#/?filter=redfast">here</a></b> for a
          one-click install. Please check our
          <a target="_blank" href="https://help.redfast.com/docs/add-the-redfast-tag"
            >documentation</a
          >
          for more details.
        </p>
        <p v-if="currApp.flags.sdk_active">
          Tracking is <b>Active <span class="promo-status-light green-color"></span></b>
        </p>
        <p v-else>
          Tracking <b>Not detected <span class="promo-status-light"></span></b>
        </p>
        <RfTextInput v-model="code" solo class="sdk-code" outlined flat dense readonly hide-details>
          <template #append>
            <span class="d-inline-flex align-center">
              <button @click="copyToClipboard(code, 'Tag script copied to clipboard.')">
                <v-icon size="22" left>mdi-content-copy</v-icon>
              </button>
            </span>
          </template>
        </RfTextInput>
      </div>
    </RfSimpleCard>
    <RfSimpleCard class="rf-retention-form">
      <div class="px-7 py-5">
        <div class="rf-settings-section-title">
          <h4>Customize Tracking</h4>
        </div>
        <div>
          Provide details for the item you wish to track. Refer to the
          <a target="_blank" href="https://help.redfast.com/docs/usage-tracking-1">documentation</a>
          for additional information.
        </div>

        <div class="settings-usage-tracking-desc settings-usage-tracking-align">
          <div class="d-flex align-center" style="gap: 10px">
            <RfButton
              button-text="Add External Tracker"
              icon="add"
              color="success"
              :disabled="userStore.isDisabledWithMessage || !fieldsByExternalTypes.length"
              @click.stop="showImportListDialog = true"
            />

            <RfButton
              button-text="Add New Tracker"
              icon="add"
              color="success"
              :disabled="userStore.isDisabledWithMessage"
              @click.stop="showDialog = true"
            />

            <RfButton
              button-text="Add New Live Tracker"
              icon="bolt"
              color="success"
              :disabled="userStore.isDisabledWithMessage"
              @click.stop="clickLive"
            />
          </div>
        </div>
        <v-simple-table class="rf-settings-table-data all-rf-trackings">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Label</th>
                <th class="text-left">Description</th>
                <th class="text-left">Type</th>
                <th class="text-left">Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-if="usageTrackingFields.length">
              <tr v-for="(custom, index) in usageTrackingFields" :key="index">
                <td class="rf-settings-table-col-one">
                  <div class="rf-name-wrapper">
                    {{ custom.name }}
                    <img
                      v-if="custom.connector_type !== 'redfast'"
                      :src="logos[custom.connector_type]"
                      width="80"
                    />
                  </div>
                </td>
                <td class="rf-settings-table-col-one">
                  {{ truncate(custom.label || "", 20) }}
                </td>
                <td class="rf-settings-table-col-two">
                  {{ truncate(custom.description || "", 30) }}
                </td>
                <td class="rf-settings-table-col-three">
                  {{ custom.data_type }}
                  <v-icon
                    @click="showTrackingCode(custom, index)"
                    v-if="custom.data_type === 'custom' && custom.id"
                    right
                    size="18"
                    >code</v-icon
                  >
                </td>
                <td class="rf-settings-table-col-four">
                  <RfTooltip
                    ref="tooltipEl"
                    :anchor="getAnchorEl(index)"
                    class="rf-row--configuration-tooltip"
                  >
                    <template #tooltip>
                      {{ displayValues(custom) }}
                    </template>
                  </RfTooltip>
                  <div
                    :style="{ cursor: displayValues(custom).length > 30 ? 'pointer' : 'default' }"
                    class="pathgroup-zone-id-ctr"
                    :ref="'tooltipAnchorEl_' + index"
                    @focus="handleFocus(displayValues(custom), index, tooltipEl)"
                    @mouseenter="handleFocus(displayValues(custom), index, tooltipEl)"
                    @blur="() => tooltipEl[index]?.hide && tooltipEl[index].hide()"
                    @mouseleave="() => tooltipEl[index]?.hide && tooltipEl[index].hide()"
                  >
                    {{
                      !!custom && typeof displayValues(custom) === "string"
                        ? truncate(displayValues(custom), 30)
                        : ""
                    }}
                  </div>
                </td>
                <td>
                  <div class="settings-button-wrapper">
                    <template
                      v-if="!['visits', 'minutes', 'concurrent_logins'].includes(custom.label)"
                    >
                      <RfButton
                        color="accent"
                        :icon="
                          !!userStore.isDisabledWithMessage ? 'mdi-information-variant' : 'edit'
                        "
                        :disabled="!custom.id"
                        @click="onEdit(custom, index)"
                      ></RfButton>
                      <RfButton
                        color="error"
                        icon="clear"
                        :disabled="userStore.isDisabledWithMessage"
                        @click="onDelete(custom, fields.values, index)"
                      ></RfButton>
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">
                  <div class="settings-no-data-message">Nothing to show.</div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </RfSimpleCard>
  </div>
</template>

<script>
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import RfSetupUsageTrackingModal from "@/components/RfSettings/RfSetupUsageTrackingModal.vue";
import RfSetupUsageTrackingCode from "@/components/RfSettings/RfSetupUsageTrackingCode.vue";
import RfDomainChooserWrapper from "@/components/RfCommonCards/RfDomainChooserWrapper.vue";
import RfTooltip from "@/components/tooltip/RfTooltip.vue";
import LiveMixin from "@/utils/LiveMixin";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import copyToClipboard from "@/utils/CopyToClipboard";
import RfButton from "@/components/buttons/RfButton.vue";
import StringUtils from "@/utils/StringUtils";
import { useToastsStore } from "@/pinia/toastsStore";
import { ref } from "vue";
import { useAppsStore } from "@/pinia/appsStore";
import { useUserStore } from "@/pinia/userStore";
import { useCustomFieldsStore } from "@/pinia/customFieldsStore";

export default {
  name: "RfSetupUsageTracking",
  props: { app: Object },
  components: {
    RfSimpleCard,
    RfSetupUsageTrackingModal,
    RfSetupUsageTrackingCode,
    RfDomainChooserWrapper,
    RfTextInput,
    RfButton,
    RfTooltip,
  },
  mixins: [LiveMixin],
  setup: () => {
    const tooltipEl = ref();

    return {
      truncate: StringUtils.truncate,
      toastsStore: useToastsStore(),
      appsStore: useAppsStore(),
      userStore: useUserStore(),
      customFieldsStore: useCustomFieldsStore(),
      tooltipEl,
    };
  },
  data() {
    return {
      custom_field: {
        id: null,
        label: "",
        description: "",
        data_type: "",
        display_type: "",
        is_normalized: true,
        name: "",
        is_usage: true,
        values: [],
        options: {
          use_regex: false,
          custom_trigger_seconds: 2,
        },
      },
      custom_field_code: {},
      isUpdating: false,
      showDialog: false,
      showImportListDialog: false,
      showTrackingCodeDialog: false,
      externalFieldsList: null,
      code: `<script src="${this.app.js_sdk}" async><\/script>`,
      currApp: this.app,
      livePreviewShow: false,
      fieldType: "custom",
      logos: {
        segment: require("@/assets/images/logos/segment.svg"),
      },
      copyToClipboard,
    };
  },
  methods: {
    handleFocus(value, index, tooltipEl) {
      if (value.length > 25) {
        tooltipEl[index]?.show && tooltipEl[index].show();
      }
    },
    getAnchorEl(index) {
      return document.querySelector(`[ref='tooltipAnchorEl_${index}']`);
    },
    clickLive() {
      this.livePreviewShow = true;
    },
    openLivePreview(domain) {
      this.openLiveUrl(null, domain, { focus: "trackers" });
      this.closeLivePreview();
    },
    closeLivePreview() {
      this.livePreviewShow = false;
    },
    filterUsage(fields) {
      return fields.filter(item => item.is_usage);
    },
    resetForm() {
      this.isUpdating = false;
      this.custom_field.id = null;
      this.custom_field.label = "";
      this.custom_field.description = "";
      this.custom_field.data_type = "";
      this.custom_field.display_type = "";
      this.custom_field.is_normalized = true;
      this.custom_field.is_usage = true;
      this.custom_field.id = null;
      this.custom_field.name = "";
      this.custom_field.values = [];
      this.custom_field.options = {
        use_regex: false,
        custom_trigger_seconds: 2,
      };
      this.externalFieldsList = null;
      this.showDialog = false;
    },
    showTrackingCode(custom) {
      this.showTrackingCodeDialog = true;
      this.custom_field_code = custom;
    },
    async onSubmit(args) {
      args && (this.custom_field.options.custom_trigger_args = args);
      if (this.custom_field.id) {
        await this.customFieldsStore.updateAppCustomField({
          appId: this.currApp.id,
          fieldId: this.custom_field.id,
          model: this.custom_field,
          fieldType: this.fieldType,
        });
        this.resetForm();
        this.showDialog = false;
      } else {
        const index = this.custom_fields.findIndex(item => {
          return item.label === this.custom_field.label;
        });
        if (this.hasAllData && index < 0) {
          this.customFieldsStore.createAppCustomField({
            model: { connector_type: "redfast", ...this.custom_field },
            appId: this.currApp.id,
            fieldType: this.fieldType,
          });
          this.resetForm();
          this.showDialog = false;
        } else {
          this.toastsStore.create({ type: "error", body: "Duplicate names or missing data" });
        }
      }
    },
    async onSubmitEventDataSource() {
      if (!this.externalFieldsList) return [];
      this.custom_field.label = this.externalFieldsList.label;
      this.custom_field.values = this.externalFieldsList.values;
      this.custom_field.description = this.externalFieldsList.description;
      this.custom_field.data_type = this.externalFieldsList.data_type;
      this.custom_field.is_normalized = true;
      this.custom_field.is_usage = true;
      const id = this.custom_fields.find(item => {
        return (
          ["segment", "ga", "mixpanel"].includes(item.connector_type) &&
          item.label === this.custom_field.label
        );
      })?.id;
      if (id) {
        await this.customFieldsStore.updateAppCustomField({
          appId: this.currApp.id,
          fieldId: id,
          model: { ...this.externalFieldsList, is_usage: true },
          fieldType: this.fieldType,
        });
      }
      this.resetForm();
      this.showImportListDialog = false;
    },
    async onDelete(custom) {
      if (["segment", "ga", "mixpanel"].includes(custom.connector_type)) {
        await this.customFieldsStore.updateAppCustomField({
          appId: this.currApp.id,
          fieldId: custom.id,
          model: { ...custom, is_usage: false },
          fieldType: this.fieldType,
        });
      } else {
        this.customFieldsStore.deleteAppCustomField({
          appId: this.currApp.id,
          fieldId: custom.id,
          fieldType: this.fieldType,
        });
      }
      this.showDeleteDialog = false;
    },
    onEdit(custom) {
      this.showDialog = true;
      this.isUpdating = true;
      custom.values = custom.values || [];
      this.custom_field = { ...custom };
    },
    displayValues(customField) {
      if (customField.data_type === "page") {
        const settings = (customField.values || [])[0];
        if (!settings) return "";

        let display = settings.url_path || "";
        if (settings.query_params) {
          if (display) {
            display += "; ";
          }
          display += settings.query_params;
        }
        if (settings.url_hash) {
          if (display) {
            display += "; ";
          }
          display += settings.url_hash;
        }
        return display;
      }
      return customField.values && customField.values.length ? customField.values[0] : "";
    },
  },
  computed: {
    custom_fields() {
      return this.customFieldsStore.segmentCustomFields || [];
    },
    hasAllData() {
      return this.custom_field.label.length && this.custom_field.data_type.length;
    },
    fields_by_connector_type() {
      const values = this.custom_fields.reduce((total, item) => {
        const type = item.connector_type;
        if (!total[type]) total[type] = [];
        total[type].push(item);
        return total;
      }, {});
      return ["redfast", "segment", "aws_s3", "redfast_system", "ga", "mixpanel"].map(item => {
        return {
          label: item,
          values: values[item] || [],
        };
      });
    },
    fields_by_visible_connector_type() {
      return this.fields_by_connector_type.filter(
        item => ["segment", "redfast", "ga", "mixpanel"].indexOf(item.label) > -1,
      );
    },
    usageTrackingFields() {
      const fields = [];

      this.fields_by_visible_connector_type.forEach(item => {
        fields.push(...this.filterUsage(item.values));
      });

      return fields;
    },
    fieldsByExternalTypes() {
      const values = this.custom_fields.reduce((total, item) => {
        const type = item.connector_type;
        if (!total[type]) total[type] = [];
        if (!item.is_usage) total[type].push(item);
        return total;
      }, {});
      const segment = values.segment || [];
      const ga = values.ga || [];
      const mixpanel = values.mixpanel || [];
      return [...segment, ...ga, ...mixpanel];
    },
  },
  async mounted() {
    const appId = this.$route.params.aid;

    if (!this.currApp.flags.sdk_active) this.appsStore.checkSdkActive(appId);
    if (!this.custom_fields.length) {
      await this.customFieldsStore.getAppCustomFields({ appId, fieldType: this.fieldType });
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep.rf-row--configuration-tooltip .rf-tooltip {
  max-width: 350px;
}

.rf-row--configuration {
  @apply flex w-full flex-col items-end;
  @apply ml-0 #{!important};
  &-tooltip {
    @apply mt-2 w-fit;
  }
}

.rf-name-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 5px;
}

.rf-name-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 5px;
}
</style>
