<template>
  <button
    ref="tooltipAnchorEl"
    v-bind="$attrs"
    class="flex items-center !text-black-2"
    :data-cy-value="`filter--${filtered ? `${filtered}` : 'inactive'}`"
    :disabled="disabled"
    @click.prevent="
      !disabled && $emit('click', nextFilterType) && nextFilterType
        ? nextTick(() => tooltipEl?.show?.())
        : tooltipEl?.hide?.()
    "
    @focus="() => !disabled && tooltipEl?.show?.()"
    @mouseenter="() => !disabled && tooltipEl?.show?.()"
    @blur="() => !disabled && tooltipEl?.hide?.()"
    @mouseleave="() => !disabled && tooltipEl?.hide?.()"
  >
    <slot></slot>
    <Transition>
      <RfArrowUpIcon
        v-if="filtered"
        class="rf-filter-icon box-content !h-5 !w-5 flex-shrink-0 pl-1"
        :class="{ 'rf-filter-icon--rotate': filtered === 'desc' }"
      />
    </Transition>
    <RfTooltip v-if="clicks" ref="tooltipEl" :anchor="tooltipAnchorEl">
      <template #tooltip>
        <div>Click {{ clicks.number }} more {{ clicks.text }} to clear</div>
      </template>
    </RfTooltip>
  </button>
</template>

<script setup>
import RfTooltip from "@/components/tooltip/RfTooltip.vue";
import RfArrowUpIcon from "@/components/icons/RfArrowUpIcon.vue";
import { computed, nextTick, ref } from "vue";

const filterTypes = [null, "asc", "desc"];

const tooltipEl = ref();
const tooltipAnchorEl = ref();
const props = defineProps({
  filtered: { type: [String, null], default: null },
  disabled: { type: [Boolean, String], default: false },
});

const clicks = computed(() => {
  if (props.filtered === "asc") return { number: 2, text: "times" };
  if (props.filtered === "desc") return { number: 1, text: "time" };
  return null;
});

const nextFilterType = computed(() => {
  const index = filterTypes.findIndex(el => el === props.filtered);
  return filterTypes[index + 1] || filterTypes[0];
});
</script>

<style lang="scss" scoped>
.rf-filter-icon {
  transform: rotate(0deg);

  &--rotate {
    animation: spin 300ms ease-in-out forwards;
  }
}

@keyframes spin {
  100% {
    transform: rotate(-180deg);
  }
}
</style>
