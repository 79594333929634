var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[(!_vm.hideCloseButton && _vm.closeButtonEnabled)?_c('span',{staticClass:"rfmodal-close inline-flex flex-row-reverse"},[_c('v-icon',{style:({ color: _vm.fillColor }),attrs:{"id":"rfmodal-close-icon"}},[_vm._v("close")]),(_vm.isNewDeviceEditor)?_c('RfPromptTimeout',{staticClass:"my-auto mr-2",attrs:{"actions":_vm.actions,"newVariant":""}}):_vm._e()],1):_vm._e(),(_vm.isVideo)?[_c('div',{staticClass:"video-volume-control"},[_c('span',{staticClass:"video-volume-up"},[(_vm.actions.rf_settings_video_muted)?_c('v-icon',{style:({ color: _vm.fillColor }),attrs:{"size":"24"}},[_vm._v("volume_off")]):_c('v-icon',{staticClass:"video-volume-up",style:({ color: _vm.fillColor }),attrs:{"size":"24"}},[_vm._v("volume_up")])],1)]),_c('span',{staticClass:"rfmodal-play"},[(!_vm.actions.rf_settings_video_autoplayed)?_c('v-icon',{style:({ color: _vm.fillColor }),attrs:{"size":"24"}},[_vm._v(" fa-play ")]):_vm._e()],1)]:_vm._e(),_c('div',{staticClass:"rfmodal-inner-wrapper"},[_c('div',{class:_vm.backgroundContentAlignmentClass},[_c('div',{staticClass:"rfmodal-text-container",class:_vm.messageAlignClass,style:({ width: _vm.actions.rf_settings_text_container_max_width })},[_c('div',[_c('div',[_c('div',{staticClass:"rfmodal-header"},[_c('h1',{style:(_vm.titleStyle),domProps:{"innerHTML":_vm._s(
                  _vm.actions[
                    _vm.isNewDeviceEditor && _vm.isMobile ? 'rf_mobile_title' : 'rf_retention_title'
                  ]
                )}})]),_c('div',{staticClass:"rfmodal-body"},[_c('RfBodyCountdown',{attrs:{"actions":_vm.actions}}),_c('div',{staticClass:"rfmodal-message",style:(_vm.messageStyle),domProps:{"innerHTML":_vm._s(_vm.isNewDeviceEditor && _vm.isMobile ? _vm.mobileMessage : _vm.message)}})],1)])]),(_vm.hasSurvey)?_c('RfPromoSurveyPreview',{attrs:{"actions":_vm.actions}}):_c('RfPromoInputsPreview',{attrs:{"actions":_vm.actions}}),(_vm.consent.needsConsent.value)?_c('RfPromoConsentPreview',{attrs:{"actions":_vm.actions},model:{value:(_vm.consent.consentChecked.value),callback:function ($$v) {_vm.$set(_vm.consent.consentChecked, "value", $$v)},expression:"consent.consentChecked.value"}}):_vm._e(),_c('div',{staticClass:"rfmodal-footer",class:{
            'disable-bg':
              !_vm.actions.rf_retention_button1_text &&
              (!_vm.actions.rf_settings_confirm_button_2_enabled ||
                !_vm.actions.rf_retention_button2_text) &&
              (!_vm.actions.rf_settings_cancel_button_enabled || !_vm.actions.rf_retention_button3_text),
          }},[_c('RfRetentionMessageButton',{attrs:{"actions":_vm.actions,"isNewDeviceEditor":_vm.isNewDeviceEditor,"acceptButtonEnabled":_vm.consent.consentNotNeededOrGiven.value}})],1),_c('RfRetentionLinksPreview',{attrs:{"actions":_vm.actions}})],1),(!_vm.isVideo)?_c('div',{staticClass:"rfmodal-colm-spacer",style:(_vm.columnspacerWidth)}):_vm._e()])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }