<template>
  <v-dialog :value="value" width="800" scrollable @input="v => (v ? show() : close())">
    <v-card flat class="rf-custom-web-card-wrapper">
      <v-card-title class="headline">Payload</v-card-title>
      <v-card-text class="code-preview">
        <PrismEditor :code="codeValue" language="js" readonly></PrismEditor>
      </v-card-text>
      <v-card-actions class="modal-card-actions-div">
        <v-btn depressed class="cancel-btn" outlined width="100px" @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PrismEditor from "vue-prism-editor";
import ApiPaths from "@/apis/ApiPaths";
import { useToastsStore } from "@/pinia/toastsStore";
import { useAppsStore } from "@/pinia/appsStore";
import { usePromptStore } from "@/pinia/promptStore";

export default {
  name: "RfDeviceApiPayloadModal",
  components: { PrismEditor },
  setup: () => ({
    toastsStore: useToastsStore(),
    promptStore: usePromptStore(),
    appsStore: useAppsStore(),
  }),
  data: () => ({ value: false, code: null, loading: false, language: null }),
  computed: {
    codeValue() {
      if (this.loading) return "// Loading...";
      if (!this.code) return "// No data";
      return this.code;
    },
  },
  methods: {
    show(language) {
      this.value = true;
      this.language = language;
      this.getPayload();
    },
    close() {
      this.value = false;
      this.language = null;
      this.code = null;
    },
    async getPayload() {
      try {
        this.loading = true;
        const data = await ApiPaths.livePaths(
          this.appsStore.app.id,
          [this.promptStore.prompt.id],
          this.language,
        );
        this.code = `${JSON.stringify(data[0], null, 2)}`;
      } catch (error) {
        this.code = null;
        this.toastsStore.create({ type: "error", body: "Couldn't fetch data" });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.code-preview {
  display: flex;

  .prism-editor-wrapper {
    width: 100%;

    .prism-editor__code {
      max-height: 100%;
    }
  }
}
</style>
