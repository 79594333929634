import { defineStore } from "pinia";
import ApiApps from "@/apis/ApiApps";
import ConduitApiUsers from "@/apis/ConduitApiUsers";
import { ref } from "vue";
import { catchable } from "./piniaUtils";

export const useEndUserStore = defineStore("end-user", () => {
  const actions = ref({});
  const results = ref([]);

  const search = ({ appId, query }) =>
    catchable({
      t: async () => (results.value = await ApiApps.searchEndUsers(appId, query)),
      loadable: true,
    });

  const remove = ({ appId, endUserId }) =>
    catchable({
      t: () => ApiApps.deleteEndUser(appId, endUserId),
      loadable: true,
    });

  const create = ({ appId, traits }) =>
    catchable({
      t: () => ApiApps.createEndUser(appId, traits),
      loadable: true,
    });

  const getActions = ({ appId, userId, params }) =>
    catchable({
      t: async () => {
        let actionsIn = await ConduitApiUsers.ping(appId, userId, params);
        let count = 0;
        while (actionsIn.paths && !actionsIn.paths.length && count < 3) {
          actionsIn = await ConduitApiUsers.ping(appId, userId, params);
          await new Promise(resolve => setTimeout(resolve, 3000));
          count++;
        }
        actions.value = actionsIn;
      },
      loadable: true,
    });

  return { actions, results, search, remove, create, getActions };
});
