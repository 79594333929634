const appBase = appId => (appId ? `/apps/${appId}` : "");

const linkBuilder =
  (itemType = "") =>
  (itemId, appId) => {
    if (!itemType) return new Error("Link type is empty");
    return `${appBase(appId)}/${itemType}${itemId ? `/${itemId}` : ""}`;
  };

const promotionBuilder = (base, promptId) => `${base}${linkBuilder("promotions")(promptId)}`;

export const getSegmentLink = linkBuilder("segments");
export const getPromptLink = linkBuilder("retentions");
export const getPromptsLink = linkBuilder("prompts");
export const getGuideLink = linkBuilder("experiences");
export const getPipelineLink = linkBuilder("pipelines");
export const getZoneLink = linkBuilder("placements");
export const getAppLink = appBase;

export const getExperimentLink = (experimentId, promptId, appId) =>
  `${getPromptLink(promptId, appId)}${linkBuilder("experiments")(experimentId)}`;

export const getPlacementPromptLink = (promptId, zoneId, appId) =>
  promotionBuilder(getZoneLink(zoneId, appId), promptId);
export const getGuidePromptLink = (promptId, guideId, appId) =>
  promotionBuilder(getGuideLink(guideId, appId), promptId);
export const getPipelinePromptLink = (promptId, pipelineId, appId) =>
  promotionBuilder(getPipelineLink(pipelineId, appId), promptId);
