<template>
  <div>
    <div class="all-promo-info-name">
      <h3>
        {{ item.name }}
        <RfButton
          icon="edit"
          color="accent"
          icon-size="16"
          class="all-promo-info-name--edit ml-1"
          x-small
          :tooltip="{ bottom: true }"
          :disabled="userStore.isDisabledWithMessage || locked"
          @click="showSegmentNameDialog"
        />
      </h3>
      <span>{{ item.description }}</span>
    </div>
    <v-dialog
      v-if="!userStore.isReadOnly || !locked"
      v-model="segmentNameDialog"
      width="580"
      content-class="show-promo-details-actions"
      persistent
    >
      <v-card flat>
        <v-card-title class="headline">Segment's Name and Description</v-card-title>
        <v-card-text>
          <RfInfo :model="item" ref="systemInfo" />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed class="cancel-btn" outlined width="100px" @click="closeDialog"
            >Close</v-btn
          >
          <v-btn
            large
            @click="submitConfiguration"
            width="200px"
            color="primary"
            depressed
            :disabled="locked"
          >
            <v-icon small left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RfInfo from "@/components/RfCommonCards/RfInfo.vue";
import { cloneDeep } from "lodash-es";
import RfButton from "@/components/buttons/RfButton.vue";
import { useSegmentStore } from "@/pinia/segmentStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  name: "RfSegmentName",
  components: { RfInfo, RfButton },
  props: ["item", "locked"],
  setup: () => ({ segmentStore: useSegmentStore(), userStore: useUserStore() }),
  data: () => ({ segmentNameDialog: false }),
  methods: {
    showSegmentNameDialog() {
      this.segmentNameDialog = true;
    },
    closeDialog() {
      this.segmentNameDialog = false;
    },
    async submitConfiguration() {
      const newSegment = cloneDeep(this.item);
      if (this.$refs.systemInfo) {
        this.$refs.systemInfo.fillModel(newSegment);
      }
      await this.segmentStore
        .update({
          appId: this.$route.params.aid,
          segId: this.$route.params.sid,
          modelSegment: newSegment,
        })
        .then(() => (this.segmentNameDialog = false))
        .catch(() => null);
    },
  },
};
</script>
