<template>
  <RfSimpleCard title="Lite Admin">
    <div class="mt-5 flex flex-row gap-5 px-7">
      <RfSelect
        v-model="rfLiteCopiablePaths"
        title="Copy Prompts"
        class="flex-1"
        dense
        outlined
        hide-details
        multiple
        :items="rfLiteCopyFromDataSelect"
      />
      <v-btn class="mt-5" @click="rfLiteInitCopy">Submit</v-btn>
    </div>
  </RfSimpleCard>
</template>

<script>
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import RfSelect from "@/components/inputs/RfSelect.vue";
import { useToastsStore } from "@/pinia/toastsStore";
import { useAllPromptsStore } from "@/pinia/allPromptsStore";

export default {
  name: "RfAdminLite",
  setup: () => ({ toastsStore: useToastsStore(), allPromptsStore: useAllPromptsStore() }),
  components: { RfSimpleCard, RfSelect },
  data() {
    return {
      rfLiteCopiablePaths: [],
      rfLiteCopyFromData: {
        "Save - Cancellation": {
          popup: "04920a5c-b05c-4049-b4fd-2462b31f7ae7",
          interstitial: "6074de6e-8dba-446a-8112-7164b3a5cbce",
        },
        "Save - Payment Failure": {
          notification: "61bac322-9210-4162-82cc-39a36b015a84",
          popup: "732768c5-ef38-4611-8e3f-e9c5896c8145",
          interstitial: "eb4affe6-d4fb-430f-9d02-263d4240b700",
        },
        "Save - Anxious Users": {
          notification: "097b1ee8-e504-4c54-a01c-8ffb8723b365",
          text: "8df72d61-54e6-4951-b36a-c837e144df93",
          popup: "e6fa9560-7e1f-4272-bce4-1a6f23df2a69",
        },
        "Engage - Resume": {
          notification: "95ad1015-366e-4ac3-855b-84f23d2596db",
          popup: "181d23a-28bf-43d2-b72f-993b66bf85d9",
        },
        "Engage - Feature Adoption": {
          notification: "57faeba4-3c0a-44d2-b1ee-6467b457c491",
          popup: "a92816e8-20f3-43a4-b15b-605d724f16bf",
        },
        "Upsell - Trial to Monthly": {
          popup: "e3fbd171-dbf6-463c-b481-d72e36a62673",
          text: "bc896c63-cb59-4e99-81d4-bcf2270074a9",
          interstitial: "467f6a5c-8800-48a4-8a26-6f435f0ef92d",
        },
        "Upsell - Trial to Annual ": {
          popup: "dc4a5a14-23f9-449e-b30b-9d9a4bbe31b9",
          text: "aa2b91d3-7175-4e30-8f2e-7e4afb556d4a",
          interstitial: "e19cb670-c35d-471f-b4c8-e7f43024ff47",
        },
        "Upsell - Monthly to Annual": {
          popup: "609a7403-6f55-40ef-94e0-b37b62c6e37c",
          text: "b1f2791b-9e9f-4987-902c-409228c46d09",
          interstitial: "9a3ccebf-c86c-4dea-990c-aec64d804fea",
        },
      },
    };
  },
  computed: {
    rfLiteCopyFromDataSelect() {
      const arr = [];
      Object.entries(this.rfLiteCopyFromData).forEach(([k, v]) => {
        Object.entries(v).forEach(([k2, v2]) => {
          arr.push({ text: `${k} - ${k2}`, value: v2 });
        });
      });
      return arr;
    },
  },
  methods: {
    async rfLiteInitCopy() {
      await this.allPromptsStore.cloneRfLitePaths({
        appId: this.$route.params.aid,
        path_ids: this.rfLiteCopiablePaths,
      });
      this.toastsStore.create({
        type: "success",
        body: "Items have been cloned, browser will refresh in 3 seconds",
      });
    },
  },
};
</script>
