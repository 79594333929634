import { defineStore } from "pinia";
import { ref } from "vue";
import ApiDataSources from "@/apis/ApiDataSources";
import { catchable } from "./piniaUtils";
import { useToastsStore } from "./toastsStore";

export const useDataSourcesStore = defineStore("data-sources", () => {
  const toastsStore = useToastsStore();
  const dataSources = ref([]);

  const mutateUpdateDataSource = dataSource => {
    dataSources.value = dataSources.value.map(item =>
      item && item.id === dataSource.id ? dataSource : item,
    );
  };

  const getDataSources = appId =>
    catchable({
      t: async () => (dataSources.value = await ApiDataSources.getDataSources(appId)),
      loadable: true,
    });

  const createDataSource = ({ appId, model }) =>
    catchable({
      t: async () =>
        (dataSources.value = [
          ...dataSources.value,
          await ApiDataSources.createDataSource(appId, model),
        ]),
      loadable: true,
    });

  const upsertDataSourceCSV = ({ appId, dataSourceId, csv }) =>
    catchable({
      t: async () => {
        mutateUpdateDataSource(await ApiDataSources.upsertFromCSV(appId, dataSourceId, csv));
        toastsStore.create({ type: "success", body: "Successfully uploaded CSV" });
      },
      loadable: true,
    });

  const editDataSource = ({ appId, model }) =>
    catchable({
      t: async () => {
        mutateUpdateDataSource(await ApiDataSources.editDataSource(appId, model));
        toastsStore.create({ type: "success", body: "Successfully updated data source" });
      },
      loadable: true,
    });

  const deleteDataSource = ({ appId, dataSourceId }) =>
    catchable({
      t: async () => {
        await ApiDataSources.deleteDataSource(appId, dataSourceId);
        dataSources.value = dataSources.value.filter(ds => ds && ds.id !== dataSourceId);
      },
      loadable: true,
    });

  return {
    dataSources,
    getDataSources,
    createDataSource,
    upsertDataSourceCSV,
    editDataSource,
    deleteDataSource,
  };
});
