<template>
  <div>
    <RfSimpleCard title="Sales Admin" class="!mb-0 !pb-4">
      <div style="position: relative">
        <v-btn color="success" @click="triggerSampleData">
          <v-icon left>add</v-icon>Generate Sample Data
        </v-btn>
      </div>
    </RfSimpleCard>
  </div>
</template>

<script setup>
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import { useAdminsStore } from "@/pinia/adminStore";

const props = defineProps(["model"]);
const adminsStore = useAdminsStore();

const triggerSampleData = () => {
  adminsStore.createSampleData(props.model.id);
};
</script>
