<template>
  <div class="tab-selector">
    <v-select
      v-if="languages.length > 1"
      :items="languages"
      v-model="previewLanguage"
      dark
      width="400px"
      append-icon=""
      placeholder="Lang"
      persistent-placeholder
      :menu-props="{
        'offset-y': true,
        'bottom': true,
        'content-class': 'tab-selector-content',
        'tile': true,
        'dark': true,
        'origin': 'bottom center',
        'z-index': 3,
      }"
    >
      <template #selection><span>Language</span></template>
      <template #item="{ item }">
        <div class="tab-selector-item">{{ item.text }}</div>
      </template>
    </v-select>
  </div>
</template>

<script>
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";
import { usePromptStore } from "@/pinia/promptStore";

export default {
  name: "RfLanguagePreview",
  props: ["model", "actions"],
  mixins: [LanguagePreviewMixin],
  setup: () => ({ promptStore: usePromptStore() }),
  data() {
    return {
      previewLanguage: null,
    };
  },
  computed: {
    languages() {
      if (!this.isLocalized) return [];
      const translations = this.model.parsed_translations;
      if (!translations) return [];
      const items = [];
      Object.keys(translations).forEach(language => {
        const values = translations[language];
        if (values.default) {
          items.unshift({ text: `${language} (default)`, value: language });
        } else {
          items.push({ text: language, value: language });
        }
      });
      return items;
    },
  },
  watch: {
    "previewLanguage": function (to) {
      if (to) {
        this.promptStore.currLanguage = to.value || to;
      }
    },
    "promptStore.prompt": function (to) {
      if (to.parsed_translations && !this.previewLanguage && this.languages.length) {
        this.previewLanguage = this.languages[0].value;
      }
    },
    "isLocalized": function (to) {
      if (to && !this.previewLanguage && this.languages.length)
        this.previewLanguage = this.languages[0].value;
    },
  },
  mounted() {
    if (this.languages.length) {
      this.previewLanguage = this.promptStore.currLanguage || this.languages[0].value;
    }
  },
};
</script>
