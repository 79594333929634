<template>
  <div class="rf-category-pg-wrapper prompts-list--wrapper">
    <div v-if="hasPathGroups || pathGroup">
      <div class="all-segments-list-rightcol" style="margin-left: 0">
        <div
          class="rf-detail-content-wrapper retention-detail-container placement-detail-pg"
          v-if="pathGroup"
        >
          <div class="promo-detail-pg-title-btns">
            <div class="promo-dtlpg-title-left">
              <RfPromoNameInfo
                :showPromoName="true"
                :showPromoError="false"
                :item="pathGroup"
                :promotionView="false"
                :pathGroupDisplay="true"
              />
            </div>
            <div class="promo-dtlpg-title-right">
              <RfButton
                small
                icon="fas fa-cog"
                color="primary"
                button-text="Settings"
                @click="gotoZoneSetting"
              />
            </div>
          </div>
          <v-card class="promo-hero-div">
            <div class="zone-name-row">
              <RfLimitWarning />
              <div class="zonename-label">
                <span><v-icon left color="#369" size="20">layers</v-icon>Zone</span>
              </div>

              <RfPromoNameInfo
                :showPromoName="false"
                :showPromoError="true"
                :item="pathGroup"
                :promotionView="false"
                :pathGroupDisplay="true"
              />
            </div>
            <div class="promo-hero-col-wrapper">
              <div class="promo-hero-col left-col">
                <RfPromoDetailsInfoRow title="Type">
                  <template v-slot:promodetail>
                    {{ promoDeviceType }} - {{ showPromoType }};
                    {{ capitalize(pathGroup.zone_type) }}
                  </template>
                </RfPromoDetailsInfoRow>
                <RfPromoTriggersInfo
                  :path="pathGroup"
                  :experiments="[]"
                  :key="pathGroup.id"
                  :editDisabled="userStore.isDisabledWithMessage"
                />
                <RfZoneDimension
                  :path="pathGroup"
                  :editDisabled="userStore.isDisabledWithMessage"
                />
              </div>
              <div
                class="promo-hero-col right-col-col-1"
                v-if="pathGroup.path_type !== 'invisible'"
              >
                <RfPromoZone :path="pathGroup" :pathGroupDisplay="true" />
                <RfPathGroupDisplay
                  :path="pathGroup"
                  :pathGroupDisplay="true"
                  :editDisabled="userStore.isDisabledWithMessage"
                />
                <RfSliderSettings
                  v-if="pathGroup.display_type === 'slider'"
                  :path="pathGroup"
                  :editDisabled="userStore.isDisabledWithMessage"
                />
              </div>
            </div>
          </v-card>
          <v-card class="rf-segment-detail-live-content">
            <div class="placement-stats-full-width second-row-plc-detail">
              <div class="chart-filter-date-select">
                <div class="chart-filter-label">Date range:</div>
                <RfMetricsDateRangeSelector
                  parentPage="promotions"
                  v-on:fetchMetrics="fetchMetrics"
                  v-on:fetchMetricsCustom="fetchMetricsCustom"
                />
              </div>
              <RfSinglePromoStats
                :model="pathGroup.paths"
                :metricsKey="currChartSrc"
                :singleDayCustom="singleDayCustom"
              />
            </div>
          </v-card>
          <div class="all-promotions-header">
            <div class="leftCol">
              <h2>Items</h2>
            </div>
            <div class="rightCol">
              <PlacementPromoFilters
                v-on:setSegmentIds="setSegmentIds"
                v-on:setStatuses="setStatuses"
              ></PlacementPromoFilters>
              <div class="placement-buttons">
                <RfButton
                  v-if="pathGroup.paths && pathGroup.paths.length > 1"
                  icon="import_export"
                  class="list-ordering-btn"
                  color="#ddd"
                  button-text="Order"
                  :disabled="userStore.isDisabledWithMessage"
                  @click="orderListDialog = true"
                />
                <RfButton
                  v-if="pathGroup.paths.length"
                  icon="add"
                  color="success"
                  button-text="Add Item"
                  :disabled="userStore.isDisabledWithMessage"
                  @click="showAddPromoModal"
                />
              </div>
            </div>
          </div>
          <div class="no-item-placement-view" v-if="!pathGroup.paths.length">
            <div class="no-msg">
              <span class="no-msg-copy">Placement has no items yet</span>
              <RfButton
                icon="add"
                large
                color="success"
                button-text="Add Item"
                :disabled="userStore.isDisabledWithMessage"
                @click="showAddPromoModal"
              />
            </div>
          </div>
          <!-- this up -->
          <template v-else>
            <div
              class="all-placements-promos promo-list-view-wrapper"
              v-for="(path, index) in pathGroupPaths"
              :key="index"
            >
              <RfPromotionsListViewItem
                :item="pathWithStats(path)"
                :currChartSrc="currChartSrc"
                preprocessedGoals
              />
            </div>
          </template>
          <v-dialog content-class="modal-div" v-model="addPromoModal" persistent width="500px">
            <v-card flat>
              <v-card-title class="headline">Add item</v-card-title>
              <v-card-text class="pl-add-promo-modal">
                <v-text-field
                  dense
                  outlined
                  label="Name"
                  v-model.trim="promotionName"
                  placeholder="e.g., Slide one"
                  hide-details
                />
                <v-text-field
                  dense
                  outlined
                  label="Description"
                  v-model.trim="promotionDescription"
                  placeholder="e.g., Slide description"
                  hide-details
                />
              </v-card-text>
              <v-card-actions class="modal-card-actions-div">
                <v-btn
                  large
                  depressed
                  class="cancel-btn"
                  outlined
                  width="100px"
                  @click="closeAddPromoModal"
                  >Cancel</v-btn
                >
                <RfButton
                  icon="add"
                  button-text="Add item"
                  color="success"
                  large
                  :disabled="
                    (!promotionName && `Name field can't be empty`) ||
                    (disableSubmit && `Submitting`)
                  "
                  style="width: 250px"
                  @click="addPromoToPathGroup"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <RfPlacementsOrder
            :retentions="pathGroup.paths"
            :orderListDialog="orderListDialog"
            v-on:closeOrderListDialog="orderListDialog = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash-es";
import RfPromoNameInfo from "@/components/RfPromotions/RfPromoNameInfo.vue";
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfPromoZone from "@/components/RfPromotions/RfPromoZone.vue";
import RfZoneDimension from "@/components/RfPromotions/RfZoneDimension.vue";
import RfPathGroupDisplay from "@/components/RfPromotions/RfPathGroupDisplay.vue";
import RfMetricsDateRangeSelector from "@/components/RfCommonCards/RfMetricsDateRangeSelector.vue";
import RfPlacementsOrder from "@/views/RfPlacementsOrder.vue";
import RfSinglePromoStats from "@/components/RfPromotions/RfSinglePromoStats.vue";
import RfSliderSettings from "@/views/RfSliderSettings.vue";
import RfPromoTriggersInfo from "@/components/RfPromotions/RfPromoTriggersInfo.vue";
import PromoTypeMixin from "@/utils/PromoTypeMixin";
import PromoStatusMixin from "@/utils/PromoStatusMixin";
import RfLimitWarning from "@/components/RfCommonCards/RfLimitWarning.vue";
import RfPromotionsListViewItem from "@/components/RfPromotions/RfPromotionsListViewItem.vue";
import MetricsMixin from "@/utils/MetricsMixin";
import PromoFilterMixin from "@/utils/PromoFilterMixin";
import PlacementPromoFilters from "@/components/RfPlacements/PlacementPromoFilters.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import StringUtils from "@/utils/StringUtils";
import { tally } from "@/utils/metricsHelpers";
import { useToastsStore } from "@/pinia/toastsStore";
import { useLegacyTmpStore } from "@/pinia/legacyTmpStore";
import { useAllPromptsStore } from "@/pinia/allPromptsStore";
import { usePromptStore } from "@/pinia/promptStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  name: "RfPlacementView",
  components: {
    RfPromoNameInfo,
    RfPromoDetailsInfoRow,
    RfPlacementsOrder,
    RfMetricsDateRangeSelector,
    RfPromoZone,
    RfZoneDimension,
    RfPathGroupDisplay,
    RfSinglePromoStats,
    RfSliderSettings,
    RfPromoTriggersInfo,
    RfLimitWarning,
    RfPromotionsListViewItem,
    PlacementPromoFilters,
    RfButton,
  },
  mixins: [PromoTypeMixin, PromoStatusMixin, MetricsMixin, PromoFilterMixin],
  setup: () => ({
    capitalize: StringUtils.capitalize,
    toastsStore: useToastsStore(),
    allPromptsStore: useAllPromptsStore(),
    promptStore: usePromptStore(),
    userStore: useUserStore(),
    legacyTmpStore: useLegacyTmpStore(),
  }),
  data() {
    return {
      addPromoModal: false,
      currChartSrc: null,
      singleDayCustom: false,
      promotionName: null,
      promotionDescription: null,
      orderListDialog: false,
      createModel: {
        name: null,
        description: null,
        zone: null,
        pathType: null,
        deviceType: null,
      },
      disableSubmit: false,
    };
  },
  computed: {
    pathGroup() {
      return this.promptStore.promptGroup;
    },
    hasPathGroups() {
      return this.allPromptsStore.promptGroups?.length;
    },
    promoDeviceType() {
      return this.getPromoDeviceTypeWording(this.pathGroup.device_type);
    },
    showPromoType() {
      return this.getPromoTypeWording(this.pathGroup.path_type);
    },
    pathGroupPaths() {
      return this.pathGroup.paths.filter(path => this.customPathFilter(path));
    },
    appId() {
      return this.$route.params.aid;
    },
    pathGroupId() {
      return this.$route.params.pid;
    },
  },
  methods: {
    customPathFilter(item) {
      if (this.filterPlacementsSegment.length && !this.segmentMatch(item)) return false;

      const isFilteringByPromptState =
        this.expirationStatus.length !== 0 || this.promoPublishStatus.length !== 0;
      const matchesPromptStatus =
        this.promoPublishStatus.indexOf(this.promoStatus(item).label) > -1;
      const matchesExpirationStatus = this.expirationStatusMatch(item.end_date);

      if (isFilteringByPromptState && !(matchesPromptStatus || matchesExpirationStatus))
        return false;

      return true;
    },
    pathWithStats(p) {
      const path = cloneDeep(p);

      if (Object.keys(path.custom_filter).length > 0) {
        path.data.totalGoals =
          tally(path.data, this.currChartSrc, "custom_goals_accepted") +
          tally(path.data, this.currChartSrc, "custom_goals_accepted2") +
          tally(path.data, this.currChartSrc, "custom_goals_dismissed") +
          tally(path.data, this.currChartSrc, "custom_goals_declined");
      } else {
        path.data.totalGoals = tally(path.data, this.currChartSrc, "goals");
      }
      return path;
    },
    gotoZoneSetting() {
      this.$router.replace({
        path: `/apps/${this.appId}/settings/zones`,
      });
    },
    getItemUrl(item) {
      return { path: `/apps/${this.appId}/retentions/${item.id}` };
    },
    async fetchMetrics(timePeriod, force) {
      this.singleDayCustom = false;
      if (!this.pathGroupId) return;

      if (
        force ||
        this.pathGroupId !== this.pathGroup.id ||
        !this.pathGroup.paths.find(
          retention => retention.data.impressions && retention.data.impressions[timePeriod],
        )
      ) {
        await this.promptStore.getPathGroup({ appId: this.appId, pathGroupId: this.pathGroupId });
      }
      this.currChartSrc = timePeriod;
    },
    async fetchMetricsCustom(timePeriod) {
      this.singleDayCustom = timePeriod[0] === timePeriod[1];

      if (!this.pathGroupId) return;

      await this.promptStore.getPathGroup({ appId: this.appId, pathGroupId: this.pathGroupId });
      this.currChartSrc = "custom";
    },
    async generatePath({
      promotionName,
      promotionDescription,
      appId,
      pathType,
      deviceType,
      iab,
      customDeviceIds = [],
    }) {
      await this.legacyTmpStore.getRetentionModel({
        appId,
        pathType,
        deviceType,
        iab,
        customDeviceIds,
      });
      return {
        ...this.legacyTmpStore.retentionModel,
        name: promotionName,
        description: promotionDescription,
      };
    },
    async addPromoToPathGroup() {
      try {
        this.disableSubmit = true;

        const pathGroup = cloneDeep(this.pathGroup);

        const newPath = await this.generatePath({
          promotionName: this.promotionName,
          promotionDescription: this.promotionDescription,
          appId: this.appId,
          pathType: this.pathGroup.path_type,
          deviceType: this.pathGroup.device_type,
          iab: pathGroup.settings.iab,
        });

        pathGroup.paths = [...pathGroup.paths, newPath];
        this.editedModel = pathGroup;
        this.addPromoModal = false;
        await this.promptStore.updatePathGroup({
          appId: this.appId,
          model: pathGroup,
          isLoading: true,
        });
        this.disableSubmit = false;
        this.$router.push({ path: `/apps/${this.appId}/retentions/${newPath.id}` });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
        this.disableSubmit = false;
      }
    },
    showAddPromoModal() {
      this.disableSubmit = false;
      this.addPromoModal = true;
    },
    closeAddPromoModal() {
      this.addPromoModal = false;
    },
  },
  async mounted() {
    if (!this.pathGroup || this.pathGroup.id !== this.pathGroupId)
      this.promptStore.getPathGroup({ appId: this.appId, pathGroupId: this.pathGroupId });
  },
  watch: {
    $route() {
      this.promptStore.getPathGroup({ appId: this.appId, pathGroupId: this.pathGroupId });
    },
  },
};
</script>
<style lang="scss" scoped>
.zone-name-row {
  .list-promo-row-one {
    padding-bottom: 10px;
  }
}
</style>
