<template>
  <RfSimpleCard>
    <v-text-field
      outlined
      autofocus
      placeholder="Number of clicks"
      label="CPC metering"
      hint="Specify the number of clicks received per month"
      v-model="clicks"
      name="cpc"
      v-validate="'numeric'"
      data-vv-as="field"
      style="margin-top: 1.5rem; max-width: 600px"
    >
    </v-text-field>
  </RfSimpleCard>
</template>

<script>
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import * as Sentry from "@sentry/browser";
import { useToastsStore } from "@/pinia/toastsStore";
import { useAppsStore } from "@/pinia/appsStore";

export default {
  name: "RfAwsMarketplaceSettings",
  components: { RfSimpleCard },
  setup: () => ({ toastsStore: useToastsStore(), appsStore: useAppsStore() }),
  data: () => ({ clicks: null }),
  computed: {
    currApp() {
      return this.appsStore.app;
    },
  },
  methods: {
    async updateSettings() {
      try {
        await this.appsStore.updateAwsMarketplace({ appId: this.currApp.id, clicks: this.clicks });
        this.toastsStore.create({ type: "success", body: "Aws Marketplace updated!" });
      } catch (error) {
        this.toastsStore.create({ type: "error", body: error.message });
        Sentry.captureException(error);
      }
    },
  },
};
</script>
