import ApiTriggers from "@/apis/ApiTriggers";
import { defineStore } from "pinia";
import { ref } from "vue";
import { catchable } from "./piniaUtils";

export const useTriggersStore = defineStore("triggers", () => {
  const triggers = ref([]);
  const pushEventOptions = ref([]);

  const mutateUpdateTrigger = trigger => {
    const triggerIndex = triggers.value.map(t => t.id).indexOf(trigger.id);
    if (triggerIndex >= 0) {
      const tempTriggers = [...triggers.value];
      tempTriggers[triggerIndex] = trigger;
      triggers.value = tempTriggers;
    } else {
      triggers.value = [...triggers.value, trigger];
    }
  };

  const getTriggers = appId =>
    catchable({
      t: async () => (triggers.value = await ApiTriggers.getTriggers(appId)),
      loadable: true,
    });

  const createTrigger = ({ appId, newTrigger }) =>
    catchable({
      t: async () => mutateUpdateTrigger(await ApiTriggers.createTrigger(appId, newTrigger)),
      loadable: true,
    });

  const updateTrigger = ({ appId, triggerId, model }) =>
    catchable({
      t: async () => mutateUpdateTrigger(await ApiTriggers.updateTrigger(appId, triggerId, model)),
      loadable: true,
    });

  const deleteTrigger = ({ appId, triggerId }) =>
    catchable({
      t: async () => {
        const deletedTriggerId = await ApiTriggers.deleteTrigger(appId, triggerId);
        triggers.value = triggers.value.filter(trigger => trigger.id !== deletedTriggerId);
      },

      loadable: true,
      throwable: true,
    });

  const getCustomEvents = appId =>
    catchable({
      t: async () => (pushEventOptions.value = (await ApiTriggers.customEvents(appId)) || []),
      loadable: true,
      throwable: true,
    });

  return {
    triggers,
    getTriggers,
    createTrigger,
    updateTrigger,
    deleteTrigger,
    pushEventOptions,
    getCustomEvents,
  };
});
