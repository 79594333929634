import { defineStore } from "pinia";
import { ref } from "vue";
import ApiApps from "@/apis/ApiApps";
import * as Sentry from "@sentry/browser";
import ApiPathGroups from "@/apis/ApiPathGroups";
import ApiSegments from "@/apis/ApiSegments";
import ApiExperiments from "@/apis/ApiExperiments";
import ApiPaths from "@/apis/ApiPaths";
import { catchable } from "./piniaUtils";
import { useLegacyLastErrorStore } from "./legacyLastErrorStore";

export const useLegacyTmpStore = defineStore("tmp", () => {
  const lastErrorState = useLegacyLastErrorStore();

  const app = ref(null);
  const customConnectorAction = ref(null);
  const experiment = ref(null);
  const pathGroup = ref(null);
  const retentionModel = ref(null);
  const segment = ref(null);
  const uiML = ref({ mlEnabled: false, mlTrait: "" });
  const pipeline = ref(null);
  const pipelineStage = ref(null);

  const setMlEnabled = mlEnabled => (uiML.value.mlEnabled = mlEnabled);
  const setMlTrait = mlTrait => (uiML.value.mlTrait = mlTrait);
  const getAppModel = async () => {
    lastErrorState.setLoading(true);
    try {
      app.value = await ApiApps.getAppModel();
    } catch (error) {
      Sentry.captureException(error);
      lastErrorState.sendError(error);
    }
    lastErrorState.setLoading(false);
  };
  const getPathGroupModel = async ({ appId, pathType, deviceType }) => {
    await catchable({
      t: async () =>
        (pathGroup.value = await ApiPathGroups.getPathGroupModel(
          appId,
          ["modal", "popup"].includes(pathType) ? "retention_modal" : pathType,
          deviceType,
        )),
      loadable: true,
      throwable: true,
    });
  };
  const getSegmentModel = async appId => {
    lastErrorState.setLoading(true);
    try {
      segment.value = await ApiSegments.getSegmentModel(appId);
    } catch (error) {
      Sentry.captureException(error);
      lastErrorState.sendError(error);
    }
    lastErrorState.setLoading(false);
  };
  const getExperimentModel = async pathId => {
    lastErrorState.setLoading(true);
    try {
      experiment.value = await ApiExperiments.getExperimentModel(pathId);
    } catch (error) {
      Sentry.captureException(error);
      lastErrorState.sendError(error);
    }
    lastErrorState.setLoading(false);
  };
  const getRetentionModel = async ({
    appId,
    pathType,
    deviceType,
    iab,
    rfLitePathId,
    customDeviceIds = [],
    sequenceId,
  }) =>
    catchable({
      t: async () =>
        (retentionModel.value = await ApiPaths.getRetentionModel(
          appId,
          ["modal", "popup"].includes(pathType) ? "retention_modal" : pathType,
          deviceType,
          iab,
          rfLitePathId,
          customDeviceIds,
          sequenceId,
        )),
      loadable: true,
      throwable: true,
    });
  const newConnectorAction = async appId => {
    lastErrorState.setLoading(true);
    try {
      customConnectorAction.value = await ApiApps.newConnectorAction(appId);
    } catch (error) {
      Sentry.captureException(error);
      lastErrorState.sendError(error);
    }
    lastErrorState.setLoading(false);
  };
  const getPipelineModel = async appId => {
    lastErrorState.setLoading(true);
    try {
      pipeline.value = await ApiApps.getPipelineModel(appId);
    } catch (error) {
      Sentry.captureException(error);
      lastErrorState.sendError(error);
    }
    lastErrorState.setLoading(false);
  };
  const getPipelineStageModel = async ({ appId, pipelineId }) => {
    lastErrorState.setLoading(true);
    try {
      pipelineStage.value = await ApiApps.getPipelineStageModel(appId, pipelineId);
    } catch (error) {
      Sentry.captureException(error);
      lastErrorState.sendError(error);
    }
    lastErrorState.setLoading(false);
  };

  return {
    pathGroup,
    retentionModel,
    mlEnabled: uiML.value.mlEnabled,
    mlTrait: uiML.value.mlTrait,
    customConnectorAction,
    app,
    pipeline,
    pipelineStage,
    segment,
    experiment,
    getRetentionModel,
    setMlEnabled,
    setMlTrait,
    getAppModel,
    getPathGroupModel,
    getSegmentModel,
    getExperimentModel,
    newConnectorAction,
    getPipelineModel,
    getPipelineStageModel,
  };
});
