<template>
  <div class="rf-detail-content-wrapper" v-if="legacyTmpStore.app" style="max-width: 700px">
    <div class="page-detail-header" style="padding-top: 1rem">
      <h1>Create a new app</h1>
    </div>
    <v-card>
      <RfAppInfo :modelApp="legacyTmpStore.app" ref="appInfo" />
      <v-card-actions class="rf-retention-form-btn-div">
        <v-btn x-large color="primary" style="width: 300px; font-size: 18px" @click="submitCreate"
          >Submit</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { cloneDeep } from "lodash-es";
import * as Sentry from "@sentry/browser";
import RfAppInfo from "@/components/RfAppCreate/RfAppInfo.vue";
import { useToastsStore } from "@/pinia/toastsStore";
import { useLegacyLastErrorStore } from "@/pinia/legacyLastErrorStore";
import { useLegacyTmpStore } from "@/pinia/legacyTmpStore";
import { useAppsStore } from "@/pinia/appsStore";
import { computed } from "vue";

export default {
  name: "RfAppCreate",
  setup: () => {
    const legacyLastErrorStore = useLegacyLastErrorStore();
    return {
      toastsStore: useToastsStore(),
      appsStore: useAppsStore(),
      legacyTmpStore: useLegacyTmpStore(),
      lastError: computed(() => legacyLastErrorStore.lastError),
    };
  },
  components: { RfAppInfo },
  computed: {
    apps() {
      return this.appsStore.apps;
    },
  },
  methods: {
    async submitCreate() {
      try {
        const modelApp = cloneDeep(this.legacyTmpStore.app);
        this.$refs.appInfo.fillModelSegment(modelApp);
        await this.appsStore.createApp(modelApp);
        this.toastsStore.create({ type: "success", body: "App created successfully" });
        const currApp = this.apps.at(-1);
        this.$router.replace({ path: `/apps/${currApp.id}/segments` });
      } catch (error) {
        this.toastsStore.create({ type: "error", body: error.message });
        Sentry.captureException(error);
      }
    },
  },
  watch: {
    lastError(to) {
      if (to) {
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
};
</script>

<style></style>
