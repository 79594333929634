import { defineStore } from "pinia";
import { ref } from "vue";
import ApiClientActions from "@/apis/ApiClientActions";
import ApiConnectorActions from "@/apis/ApiConnectorActions";
import { catchable } from "./piniaUtils";
import { useToastsStore } from "./toastsStore";

export const useActionsStore = defineStore("actions", () => {
  const toastsStore = useToastsStore();
  const clientActions = ref([]);
  const connectorActions = ref([]);

  const mutateUpdateAction = (actionType, action) => {
    const actionIndex = actionType.value.findIndex(ca => ca.id === action.id);
    if (actionIndex >= 0) {
      const tempActions = [...actionType.value];
      tempActions[actionIndex] = action;
      actionType.value = tempActions;
    } else {
      actionType.value = [...actionType.value, action];
    }
  };

  const getClientActions = appId =>
    catchable({
      t: async () => (clientActions.value = await ApiClientActions.getActions(appId)),
      loadable: true,
    });

  const createClientAction = ({ appId, newAction }) =>
    catchable({
      t: async () => {
        mutateUpdateAction(clientActions, await ApiClientActions.createAction(appId, newAction));
        toastsStore.create({ type: "success", body: "Successfully created" });
      },
      loadable: true,
    });

  const updateClientAction = ({ appId, actionId, model }) =>
    catchable({
      t: async () => {
        mutateUpdateAction(
          clientActions,
          await ApiClientActions.updateAction(appId, actionId, model),
        );
        toastsStore.create({ type: "success", body: "Successfully updated" });
      },
      loadable: true,
    });

  const deleteClientAction = ({ appId, actionIdIn }) =>
    catchable({
      t: async () => {
        const actionId = await ApiClientActions.deleteAction(appId, actionIdIn);
        clientActions.value = clientActions.value.filter(action => action.id !== actionId);
      },
      loadable: true,
      throwable: true,
    });

  const getConnectorActions = appId =>
    catchable({
      t: async () => (connectorActions.value = await ApiConnectorActions.getActions(appId)),
      loadable: true,
    });

  const createConnectorAction = ({ appId, newAction }) =>
    catchable({
      t: async () => {
        mutateUpdateAction(
          connectorActions,
          await ApiConnectorActions.createAction(appId, newAction),
        );
        toastsStore.create({ type: "success", body: "Successfully created action" });
      },
      loadable: true,
    });

  const updateConnectorAction = ({ appId, actionId, model }) =>
    catchable({
      t: async () => {
        mutateUpdateAction(
          connectorActions,
          await ApiConnectorActions.updateAction(appId, actionId, model),
        );
        toastsStore.create({ type: "success", body: "Successfully updated action" });
      },
      loadable: true,
    });

  const deleteConnectorAction = ({ appId, actionIdin }) =>
    catchable({
      t: async () => {
        const actionId = await ApiConnectorActions.deleteAction(appId, actionIdin);
        connectorActions.value = connectorActions.value.filter(action => action.id !== actionId);
      },
      loadable: true,
      throwable: true,
    });

  return {
    clientActions,
    getClientActions,
    createClientAction,
    updateClientAction,
    deleteClientAction,
    connectorActions,
    getConnectorActions,
    createConnectorAction,
    updateConnectorAction,
    deleteConnectorAction,
  };
});
