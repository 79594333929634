import { confidenceIndicatorColor } from "@/utils/StatsUtils";
import { getDisplayMetricsText, percentage } from "@/utils/metricsHelpers";
import { useExperimentsStore } from "@/pinia/experimentsStore";
import { isCustomGoalExists } from "./prompts/promptHelpers";

export default {
  computed: {
    experimentsStore: () => useExperimentsStore(),
    interactionText() {
      return {
        seen: "Seen",
        accepted:
          getDisplayMetricsText(this.model, "rf_retention_button1_text") || "Accepted (1st)",
        accepted2:
          getDisplayMetricsText(this.model, "rf_retention_button2_text") || "Accepted (2nd)",
        declined: getDisplayMetricsText(this.model, "rf_retention_button3_text") || "Declined",
        dismissed: "Dismissed",
      };
    },
    customGoalInteractionName() {
      return this.interactionText[this.experimentsStore.customGoalInteraction];
    },
    hasCustomGoal() {
      return isCustomGoalExists(this.model);
    },
    customGoalName() {
      if (this.hasCustomGoal) {
        const name = Object.keys(this.model.custom_filter)[0];
        return `${name}: ${this.customGoalInteractionName}`;
      }
      return "";
    },
    goalsTitle() {
      if (this.hasCustomGoal)
        return (
          this.appCustomFields.find(el => el.label === Object.keys(this.model.custom_filter)[0])
            ?.name || "Custom Goal"
        );
      return "Goals";
    },
    goalsTitleExperiment() {
      return this.hasCustomGoal ? this.customGoalName : "Goals";
    },
    interactionsTitle() {
      if (!this.hasCustomGoal)
        return getDisplayMetricsText(this.model, "rf_retention_button1_text") || "Accepted (1st)";

      return this.customGoalInteractionName;
    },
    shouldShowCTR() {
      return (
        !this.hasCustomGoal ||
        this.experimentsStore.customGoalInteraction === "accepted" ||
        this.experimentsStore.customGoalInteraction === "accepted2"
      );
    },
    ctrTitle() {
      if (this.experimentsStore.customGoalInteraction === "accepted2") return "CTR (additional)";
      return "CTR";
    },
    significanceInteraction() {
      return this.experimentsStore.customGoalInteraction
        ? `(${this.customGoalInteractionName})`
        : "";
    },
    conversionRateValue() {
      return percentage(this.totalCustomGoals, this.totalUsers);
    },
    displayConversionRate() {
      return this.conversionRateValue || this.conversionRateValue === 0
        ? `${this.conversionRateValue.toFixed(2)}%`
        : "N/A";
    },
    conversionRateDefaultValue() {
      return this.totalUsers ? percentage(this.totalGoals, this.totalUsers) : 0;
    },
    conversionRateAdditionalValue() {
      return this.totalUsers ? percentage(this.totalGoals2, this.totalUsers) : 0;
    },
    conversionRateAdditionalCustomGoalValue() {
      return this.totalUsers ? percentage(this.totalCustomGoals2, this.totalUsers) : 0;
    },
    displayConversionRateAdditionalCustomGoal() {
      return this.conversionRateAdditionalCustomGoalValue
        ? `${this.conversionRateAdditionalCustomGoalValue.toFixed(2)}%`
        : "N/A";
    },
    CTRValue() {
      return this.totalImpressions ? percentage(this.totalGoals, this.totalImpressions) : 0;
    },
    displayCTR() {
      return this.CTRValue?.toFixed ? `${this.CTRValue.toFixed(2)}%` : "N/A";
    },
    CTRAdditionalValue() {
      return this.totalImpressions ? percentage(this.totalGoals2, this.totalImpressions) : 0;
    },
  },
  methods: {
    confidenceKey(secondary) {
      const confidence =
        this.holdoutTraffic > 0 ? "confidence_vs_holdout" : "confidence_vs_baseline";
      const key = !this.hasCustomGoal
        ? secondary
          ? "2"
          : ""
        : `_${this.experimentsStore.customGoalInteraction}`;

      return `${confidence}${key}`;
    },
    confidenceIndicator(item, secondary) {
      const key = this.confidenceKey(secondary);
      let confidence;
      if (item.is_control || (item.is_baseline && this.holdoutTraffic <= 0)) confidence = "-";

      if (!confidence) {
        const data = this.totalStatByKey(item, key) || {};
        if (data.confidence) {
          confidence = data.confidence;
        } else {
          confidence = "-";
        }
      }

      return { style: { color: confidenceIndicatorColor(confidence) }, confidence };
    },
  },
  watch: {
    hasCustomGoal(to) {
      if (!to) this.experimentsStore.customGoalInteraction = null;
    },
  },
};
