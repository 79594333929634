import { defineStore } from "pinia";
import { computed, ref } from "vue";
import ApiExperiments from "@/apis/ApiExperiments";
import ApiMetrics from "@/apis/ApiMetrics";
import VuexUtils from "@/utils/VuexUtils";
import { catchable } from "./piniaUtils";
import { useLegacyTmpStore } from "./legacyTmpStore";

export const useExperimentsStore = defineStore("experiments", () => {
  const legacyTmpStore = useLegacyTmpStore();

  const experiments = ref([]);
  const currExperiment = ref(null);
  const currExperimentMetrics = ref({});
  const currExperimentCustomMetrics = ref({});
  const customGoalInteraction = ref("accepted");

  const isCurrExperimentRunning = computed(() => currExperiment.value?.is_active);

  const getExperimentForceUpdate = ({ pathId, expId }) =>
    catchable({
      t: async () => (currExperiment.value = await ApiExperiments.getExperimentForceUpdate(pathId, expId)),
      loadable: true,
    });

  const getExperiment = ({ pathId, expId }) =>
    catchable({
      t: async () => (currExperiment.value = await ApiExperiments.getExperiment(pathId, expId)),
      loadable: true,
    });

  const getExperiments = pathId =>
    catchable({
      t: async () => {
        legacyTmpStore.getExperimentModel(pathId);
        const experimentsValue = await ApiExperiments.getExperiments(pathId);
        experiments.value = experimentsValue;
        currExperiment.value = experimentsValue.find(({ is_completed }) => !is_completed) || null;
        return experiments;
      },
      loadable: true,
    });

  const runExperimentWithVariant = ({ appId, pathId, expId, variation }) =>
    catchable({
      t: async () => {
        await ApiExperiments.stopExperimentWithWinner(pathId, expId, variation);
        currExperiment.value = null;
        [currExperimentMetrics.value] = await Promise.all([
          ApiMetrics.getOnePathActionGroups(appId, pathId),
          getExperiments(pathId),
          getExperiment({ pathId, expId }),
        ]);
      },
      loadable: true,
    });

  const runExperiment = ({ pathId, expId, isStart }) =>
    catchable({
      t: async () => {
        if (isStart) await ApiExperiments.startExperiment(pathId, expId);
        else await ApiExperiments.stopExperiment(pathId, expId);
        if (!isStart) currExperiment.value = null;
        await Promise.all([getExperiments(pathId), getExperiment({ pathId, expId })]);
      },
      loadable: true,
    });

  const getExperimentCustomMetrics = ({ pathId, id, params }) =>
    catchable({
      t: async () =>
        (currExperimentCustomMetrics.value = VuexUtils.combineMetrics(
          currExperimentCustomMetrics.value,
          await ApiMetrics.getExperimentCustomMetrics(pathId, id, params),
        )),
      loadable: true,
    });

  const getExperimentMetrics = ({ appId, pathId, params }) =>
    catchable({
      t: async () =>
        (currExperimentMetrics.value = {
          ...currExperimentMetrics.value,
          ...(await ApiMetrics.getOnePathActionGroups(appId, pathId, params)),
        }),
      loadable: true,
    });

  const createExperiment = ({ pathId, modelExperiment }) =>
    catchable({
      t: async () => {
        const experiment = await ApiExperiments.createExperiment(pathId, modelExperiment);
        currExperiment.value = experiment;
        return experiment;
      },
      loadable: true,
    });

  const cloneExperiment = ({ pathId, experimentId }) =>
    catchable({
      t: () => ApiExperiments.cloneExperiment(pathId, experimentId),
      loadable: true,
    });

  const updateExperiment = ({ pathId, expId, modelExperiment }) =>
    catchable({
      t: async () =>
        (currExperiment.value = await ApiExperiments.setExperiment(pathId, expId, modelExperiment)),
      loadable: true,
    });

  const deleteExperiment = ({ pathId, experimentId }) =>
    catchable({
      t: async () => {
        await ApiExperiments.deleteExperiment(pathId, experimentId);
        currExperiment.value = null;
        experiments.value = experiments.value.filter(exp => exp.id !== experimentId);
      },
      loadable: true,
    });

  return {
    experiments,
    getExperiments,
    getExperimentForceUpdate,
    currExperiment,
    currExperimentMetrics,
    currExperimentCustomMetrics,
    getExperiment,
    deleteExperiment,
    updateExperiment,
    cloneExperiment,
    createExperiment,
    getExperimentMetrics,
    getExperimentCustomMetrics,
    runExperiment,
    runExperimentWithVariant,
    isCurrExperimentRunning,
    customGoalInteraction,
  };
});
