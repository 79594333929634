import { computed } from "vue";

export const WEB_MEASURES = ["px", "%", "em", "rem", "vw", "vh"];
export const BORDER_MEASURES = ["px", "em", "rem", "vw", "vh"];
export const DEFAULT_MEASURES = ["px", "%"];
export const DEVICES_MEASURES = ["px", "pt"];
export const NONE_MEASURE = "n/a";

export const useCSSProperty = (
  actionsFn,
  key,
  { defaultValue = 0, defaultMeasure = DEFAULT_MEASURES[0], canBeEmpty = false } = {
    defaultValue: 0,
    defaultMeasure: DEFAULT_MEASURES[0],
    canBeEmpty: false,
  },
) => {
  const variable = computed({
    get() {
      if (!actionsFn()[key] && canBeEmpty) return [0, NONE_MEASURE];
      const number = actionsFn()[key]?.match?.(/[\d\,\.]+(?=[^0-9])/g)?.[0];
      const postfix = actionsFn()[key]?.match?.(/[\D]+$/g)?.[0];

      return [number || defaultValue, postfix || defaultMeasure];
    },
    set([v, index]) {
      const number = actionsFn()[key]?.match?.(/[\d\,\.]+(?=[^0-9])/g)?.[0];
      const postfix = actionsFn()[key]?.match?.(/[\D]+$/g)?.[0];
      if (index === 1 && v === NONE_MEASURE && canBeEmpty) return (actionsFn()[key] = "");
      if (index === 0 && postfix === NONE_MEASURE && canBeEmpty)
        return `${+v || defaultValue}${defaultMeasure}`;
      if (index) actionsFn()[key] = `${number ?? defaultValue}${v || defaultMeasure}`;
      else actionsFn()[key] = `${+v || defaultValue}${postfix ?? defaultMeasure}`;
      return actionsFn()[key];
    },
  });

  const init = ([value, measure]) => {
    if (!value) value = defaultValue;
    if (!measure) value = defaultMeasure;
    variable.value = [value, 0];
    variable.value = [measure, 1];
  };

  return { variable, init };
};
