<template>
  <div v-if="viewTypeTab === 'desktopViewTab'">
    <v-expansion-panels v-model="getPromoPanel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>Message</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsTitleMessage :model="model" :actions="actions" :app="app" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Size & Position</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="widget-config-size">
            <v-text-field
              outlined
              dense
              hide-details
              type="number"
              label="Width"
              :value="widgetWidth[0]"
              v-validate="'required'"
              placeholder="e.g.: 400"
              @input="v => (widgetWidth = [v, 0])"
            >
              <template #append>
                <v-select
                  dense
                  :value="widgetWidth[1]"
                  class="m-0 -mt-2 w-14 p-0"
                  hide-details
                  :items="measures"
                  @input="v => (widgetWidth = [v, 1])"
                >
                </v-select>
              </template>
            </v-text-field>
            <v-text-field
              outlined
              dense
              hide-details
              type="number"
              label="Height"
              :value="widgetHeight[0]"
              v-validate="'required'"
              placeholder="e.g.: 250"
              @input="v => (widgetHeight = [v, 0])"
            >
              <template #append>
                <v-select
                  dense
                  :value="widgetHeight[1]"
                  class="m-0 -mt-2 w-14 p-0"
                  hide-details
                  :items="measures"
                  @input="v => (widgetHeight = [v, 1])"
                >
                </v-select>
              </template>
            </v-text-field>
          </div>
          <div class="widget-config-size second-row-wdg">
            <v-text-field
              outlined
              dense
              hide-details
              type="number"
              label="Message Width"
              :value="widgetMessageWdth[0]"
              v-validate="'required'"
              placeholder="e.g.: 250"
              @input="v => (widgetMessageWdth = [v, 0])"
            >
              <template #append>
                <v-select
                  dense
                  :value="widgetMessageWdth[1]"
                  class="m-0 -mt-2 w-14 p-0"
                  hide-details
                  :items="measures"
                  @input="v => (widgetMessageWdth = [v, 1])"
                >
                </v-select>
              </template>
            </v-text-field>
            <v-select
              outlined
              hide-details
              dense
              class="h-full"
              label="Widget Position"
              v-model="actions.rf_widget_position"
              :items="widgetPositionOptions"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>User Interaction</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionButtons :actions="actions" :model="model" :showCloseOptions="true" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Form</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionFieldSet :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type === 'web'">
        <v-expansion-panel-header>Consent Checkbox</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionConsent :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Close Timer</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsBehavior :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Background</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionImageUpload :actions="actions" :model="model" />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="model.device_type === DefaultDeviceOptions.web.value">
        <v-expansion-panel-header>CSS</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="promo-design-group">
            <RfPromoCustomCSS :actions="actions" />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  <div v-else>
    <v-expansion-panels v-model="getPromoPanel" multiple focusable>
      <v-expansion-panel>
        <v-expansion-panel-header>CONFIGURATION</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfRetentionActionsMobileWeb :app="app" :model="model" :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>Size</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <div class="widget-config-size" style="margin-bottom: 18px">
            <v-text-field
              outlined
              dense
              hide-details
              label="Width"
              v-model.trim="actions.rf_mobile_width"
              placeholder="e.g.: 300px"
            />
            <v-text-field
              outlined
              dense
              hide-details
              label="Height"
              v-model.trim="actions.rf_mobile_height"
              placeholder="e.g.: 300px"
            />
          </div>
          <v-text-field
            outlined
            dense
            hide-details
            label="Message Width"
            v-model.trim="actions.rf_mobile_message_width"
            placeholder="e.g.: 300px"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="model.device_type === DefaultDeviceOptions.web.value">
        <v-expansion-panel-header>CSS</v-expansion-panel-header>
        <v-expansion-panel-content class="retention-config-segments">
          <RfPromoCustomCSS :actions="actions" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import RfRetentionActionsTitleMessage from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsTitleMessage.vue";
import RfRetentionActionsBehavior from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsBehavior.vue";
import RfRetentionButtons from "@/components/RfPathCreate/RfRetentionActions/RfRetentionButtons.vue";
import RfRetentionActionsMobileWeb from "@/components/RfPathCreate/RfRetentionActions/RfRetentionActionsMobileWeb.vue";
import RfPromoCustomCSS from "@/components/RfPromotions/RfPromoCustomCSS.vue";
import RfRetentionFieldSet from "@/components/RfPathCreate/RfRetentionActions/RfRetentionFieldSet.vue";
import RfRetentionConsent from "@/components/RfPathCreate/RfRetentionActions/RfRetentionConsent.vue";
import RfRetentionImageUpload from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionImageUpload.vue";
import { DEFAULT_MEASURES, useCSSProperty, WEB_MEASURES } from "@/utils/composables/useCSSProperty";
import { DefaultDeviceOptions } from "@/utils/constants/DevicesConstants";
import { computed } from "vue";

export default {
  name: "RfRetentionActionsWidget",
  components: {
    RfRetentionActionsTitleMessage,
    RfRetentionActionsBehavior,
    RfRetentionButtons,
    RfRetentionActionsMobileWeb,
    RfPromoCustomCSS,
    RfRetentionFieldSet,
    RfRetentionConsent,
    RfRetentionImageUpload,
  },
  props: ["actions", "model", "app", "viewTypeTab"],
  setup: props => {
    const { variable: widgetWidth } = useCSSProperty(() => props.actions, "rf_widget_width");
    const { variable: widgetHeight } = useCSSProperty(() => props.actions, "rf_widget_height");
    const { variable: widgetMessageWdth } = useCSSProperty(
      () => props.actions,
      "rf_settings_text_container_max_width",
    );
    const measures = computed(() =>
      props.model.device_type === DefaultDeviceOptions.web.value ? WEB_MEASURES : DEFAULT_MEASURES,
    );
    return {
      widgetWidth,
      widgetHeight,
      widgetMessageWdth,
      measures,
      DefaultDeviceOptions,
      WEB_MEASURES,
      DEFAULT_MEASURES,
    };
  },
  data() {
    return {
      getPromoPanel: [0],
      widgetPositionOptions: [
        { value: "bottom_left", text: "Bottom Left" },
        { value: "bottom_right", text: "Bottom Right" },
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss">
.widget-config-size {
  display: flex;
  &.second-row-wdg {
    padding-top: 15px;
  }
  > div {
    width: 50%;
    &:first-child {
      padding-right: 10px;
    }
  }
}
</style>
