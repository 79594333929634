<template>
  <RfAuth
    :title="getTitle"
    :error="error"
    :success="success"
    @submit="
      displayContainer === 'forgot-password'
        ? onForgotPassword()
        : displayContainer === 'sso'
          ? onSSO()
          : onLogin()
    "
  >
    <template #inputs>
      <v-text-field
        v-if="['forgot-password', 'sso'].includes(displayContainer)"
        outlined
        label="Email"
        prepend-inner-icon="far fa-envelope"
        v-model="email"
        type="email"
      ></v-text-field>
      <template v-if="displayContainer === null">
        <v-text-field
          outlined
          label="Email"
          prepend-inner-icon="far fa-envelope"
          v-model="email"
          type="email"
        ></v-text-field>
        <v-text-field
          outlined
          label="Password"
          prepend-inner-icon="lock_outline"
          :type="showPassword ? 'text' : 'password'"
          v-model="password"
          @click:append="showPassword = !showPassword"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
        ></v-text-field>
      </template>
    </template>

    <template #footer>
      <div class="flex flex-col">
        <v-btn
          v-if="displayContainer === 'forgot-password'"
          class="login-button"
          width="100%"
          depressed
          :loading="loading"
          :disabled="loading"
          x-large
          type="submit"
          color="accent"
        >
          <v-icon left>fas fa-paper-plane</v-icon>Send Reset Email
        </v-btn>
        <v-btn
          v-if="displayContainer === 'sso'"
          class="login-button"
          width="100%"
          depressed
          :loading="loading"
          :disabled="loading"
          x-large
          type="submit"
          color="success"
        >
          <v-icon left>fas fa-sign-in-alt</v-icon>Login
        </v-btn>
        <div
          v-if="['sso', 'forgot-password'].includes(displayContainer)"
          style="padding: 1rem 0 0 0; text-align: center"
        >
          <v-btn text @click="displayContainer = null">Back to Login</v-btn>
        </div>

        <template v-if="displayContainer === null">
          <v-btn
            class="login-button"
            width="100%"
            depressed
            :disabled="loading"
            :loading="loading"
            x-large
            color="success"
            type="submit"
          >
            <v-icon left>fas fa-sign-in-alt</v-icon>Login
          </v-btn>
          <RfButton
            large
            :disabled="loading"
            :loading="loading"
            class="mt-5"
            @click="displayContainer = 'sso'"
          >
            <v-icon left>fas fa-key</v-icon>Login with Your Organization
          </RfButton>
          <div style="padding: 1rem 0 0 0; text-align: center">
            <v-btn
              text
              :disabled="loading"
              :loading="loading"
              @click="displayContainer = 'forgot-password'"
            >
              Forgot Password
            </v-btn>
          </div>
        </template>
      </div>
    </template>
  </RfAuth>
</template>

<script>
import RfButton from "@/components/buttons/RfButton.vue";
import ApiUsers from "@/apis/ApiUsers";
import RfAuth from "@/blocks/RfAuth.vue";
import { useUserStore } from "@/pinia/userStore";

export default {
  components: { RfButton, RfAuth },
  setup: () => ({ userStore: useUserStore() }),
  data() {
    return {
      email: null,
      password: null,
      company: null,
      displayContainer: null,
      displayForgotPassword: false,
      resetSuccess: null,
      success: null,
      error: null,
      showPassword: false,
      loading: false,
    };
  },
  computed: {
    getTitle() {
      if (this.displayContainer === "forgot-password") return "Reset Password";
      if (this.displayContainer === "sso") return "SSO Login";
      return "";
    },
  },
  methods: {
    resetValues() {
      this.success = null;
      this.error = null;
    },
    async onLogin() {
      try {
        this.resetValues();
        this.loading = true;
        await this.userStore.login({ email: this.email, password: this.password });
      } catch ({ message }) {
        this.error = message;
        this.loading = false;
      }
    },
    async onForgotPassword() {
      try {
        if (!this.email) throw new Error("Email must not be empty.");
        this.resetValues();
        this.loading = true;
        await ApiUsers.sendResetLoginUser(this.email);
        this.success = "Reset instructions have been sent to your email.";
      } catch ({ message }) {
        this.error = message;
      } finally {
        this.loading = false;
      }
    },
    async onSSO() {
      try {
        if (!this.email) throw new Error("Email must not be empty.");
        this.loading = true;
        const { company_id } = await ApiUsers.getEmailCompany(encodeURI(this.email));
        const { sso_authorization_url } = await ApiUsers.loginSSO(company_id);
        window.location.href = sso_authorization_url;
      } catch (error) {
        this.error = error.message;
        this.loading = false;
      }
    },
  },
  mounted() {
    const { confirmation } = this.$route.query;
    if (confirmation === "true") {
      this.success = "You have confirmed your email. Please login.";
    } else if (confirmation === "false") {
      this.success = "Your email has already been confirmed. Please login.";
    }
  },
  watch: {
    "displayContainer": function () {
      this.resetValues();
    },
    "userStore.currUser": function (to) {
      if (!to) return;
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get("docs") === "1") {
        if (urlParams.get("redirect")) {
          const docsObj = new URL(to.docs);
          const baseUrl = new URL(`${docsObj.protocol}//${docsObj.hostname}`);
          const path = decodeURIComponent(urlParams.get("redirect"));
          const urlObj = new URL(path, baseUrl);
          urlObj.search = docsObj.search;

          window.location = urlObj.toString();
        } else {
          window.location = to.docs;
        }
      } else {
        window.location = "/";
      }
    },
  },
};
</script>
