<template>
  <div class="rf-retention-create-col1 rf-create-segments-opt">
    <div v-if="pipelineSegment">
      <div v-for="segment in model.segments" :key="segment.id">
        <v-checkbox
          v-model="linkedSegments"
          :label="segment.name"
          :value="segment.id"
          hide-details
        />
      </div>
      <v-checkbox
        v-model="linkedSegments"
        :label="testUserSegment.name"
        :value="testUserSegment.id"
        hide-details
        v-if="testUserSegment.id"
      />
    </div>
    <div v-else>
      <div class="rf-section-title">
        <h4>Who are you targeting</h4>
        <span>Select one or more segments below.</span>
      </div>
      <RfSelectionWithChips
        title="Available Segments"
        :multiple="model.path_type !== PromptTypes.scheduled_push.value"
        :selectors="segmentListWithHeaders"
        v-model="linkedSegments"
      />
    </div>
  </div>
</template>

<script>
import SegmentListMixin from "@/utils/SegmentListMixin";
import { PromptTypes } from "@/utils/constants/PromoTypesConstants";
import RfSelectionWithChips from "../RfSelectionWithChips.vue";

export default {
  name: "RfLinkedSegments",
  props: {
    model: Object,
    pipelineStageId: String,
    pipelineSegment: { type: Boolean, default: false },
  },
  components: { RfSelectionWithChips },
  mixins: [SegmentListMixin],
  setup: () => ({ PromptTypes }),
  data() {
    return {
      linkedSegments: this.pipelineSegment
        ? this.model.segments.map(({ id }) => id)
        : this.model.segments,
    };
  },
  methods: {
    fillModel(model) {
      const linked = [];
      let pipelineButNoPipelineSeg = !!this.pipelineStageId;
      this.linkedSegments.forEach(segment => {
        const id = segment?.id || segment;
        const matched = this.segments.find(s => s.id === id);
        if (matched) {
          linked.push({ id: matched.id, name: matched.name });
          if (this.pipelineStageId && matched.pipeline_stage_id) pipelineButNoPipelineSeg = false;
        }
      });
      model.segments = linked;
      if (!model.segments.length) {
        // corner case for new pipelines, full segments list might not be refreshed
        this.linkedSegments.forEach(segment => {
          const id = segment?.id || segment;
          if (id && id.length) model.segments.push({ id });
        });
      }
      const noSegs = (model.id || this.pipelineSegment) && !model.segments.length;

      if (noSegs || pipelineButNoPipelineSeg) {
        throw new Error("At least one segment required");
      }
    },
  },
};
</script>
