<template>
  <v-app class="shortSidebar">
    <router-view></router-view>
  </v-app>
</template>

<script setup>
import { onBeforeMount, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useAppsStore } from "./pinia/appsStore";
import { useUserStore } from "./pinia/userStore";

const route = useRoute();
const router = useRouter();
const appsStore = useAppsStore();
const userStore = useUserStore();
onBeforeMount(() => userStore.getUser());
onMounted(() => appsStore.getApps(route.params.aid));
watch(
  () => appsStore.app,
  app => {
    if (route.name === "Home" && app) {
      router.replace({ path: `/apps/${app.id}/dashboard` });
    }
  },
);
watch(route, (from, to) => {
  if (from.params.aid && to.params.aid && to.params.aid !== from.params.aid) {
    appsStore.selectApp(to.params.aid);
  }
});
</script>
