<template>
  <div>
    <RfSelect
      hide-details
      dense
      :outlined="outlined"
      v-model="currChartSrc"
      :items="periods"
      item-text="name"
      item-value="value"
      v-if="currChartSrc !== 'custom'"
      :menu-props="{ offsetY: true }"
      :label="label"
    />
    <div class="custom-date-display-wrapper" v-if="currChartSrc === 'custom'">
      <RfCustomMetricsDatePicker
        v-model="date_range"
        :min_date="min_date"
        :max_date="max_date"
        :outlined="outlined"
        :modalDatePicker="modalDatePicker"
        v-on:closeCustomDateRange="closeCustomDateRange"
      />
      <button class="reset-dates-form" @click="closeCustomDateRange">Reset</button>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash-es";
import MetricsUtils from "@/utils/MetricsUtils";
import RfCustomMetricsDatePicker from "@/components/RfCustomMetricsDatePicker.vue";
import UserSettingsMixin from "@/utils/UserSettingsMixin";
import RfSelect from "@/components/inputs/RfSelect.vue";
import dayjs from "dayjs";
import { useAppsStore } from "@/pinia/appsStore";
import { computed } from "vue";

export default {
  name: "RfMetricsDateRangeSelector",
  props: [
    "parentPage",
    "sinceStartDate",
    "isNotOutlined",
    "label",
    "hasOnlyComparable",
    "currChartDefaultValue",
    "customPeriodKeys",
  ],
  components: { RfCustomMetricsDatePicker, RfSelect },
  mixins: [UserSettingsMixin],
  setup: () => {
    const appsStore = useAppsStore();
    return { currApp: computed(() => appsStore.app) };
  },
  data() {
    return {
      currChartSrc: this.currChartDefaultValue || "last_seven_days",
      date_range: null,
      max_date: dayjs(),
      modalDatePicker: false,
      filterLoaded: false,
      previousChartSrc: "last_seven_days",
      standardKey: `${this.parentPage}DateRange`,
      refreshTimer: null,
      refreshInterval: 300,
      outlined: !this.isNotOutlined,
    };
  },
  methods: {
    setFilters() {
      if (!this.filterLoaded && this.currApp) {
        let filter = this.getUserSetting(this.standardKey);
        const filterItem = this.periods.find(item => item.value === filter);
        if (!filterItem) {
          filter = this.currChartDefaultValue || "last_seven_days";
        }
        if (filter) {
          this.currChartSrc = filter;
          if (filter === "custom") {
            // Don't remember custom date settings
            filter = this.currChartDefaultValue || "last_seven_days";
          } else {
            this.fetchMetrics(filter);
          }
        } else {
          // when loading for first time with no filter
          this.fetchMetrics(this.currChartSrc);
        }
        this.filterLoaded = true;
      }
    },
    fetchMetrics: debounce(function debouncedFetchMetrics(dates, force) {
      this.$emit("fetchMetrics", dates, force);
    }, 200),
    fetchMetricsCustom: debounce(function debouncedFetchMetricsCustom(dates) {
      this.$emit("fetchMetricsCustom", dates);
    }, 200),
    closeCustomDateRange() {
      this.currChartSrc = this.previousChartSrc;
    },
    setRefreshTimer() {
      const interval =
        parseInt(this.appFlags && this.appFlags.pulse_charts_refresh_seconds) ||
        this.refreshInterval;
      if (this.refreshTimer) {
        if (interval === this.refreshInterval) {
          return null;
        }
        this.refreshInterval = interval;
        clearInterval(this.refreshTimer);
      }
      this.refreshTimer = setInterval(() => {
        if (this.currChartSrc === "custom") {
          if (this.date_range) {
            this.fetchMetricsCustom(this.date_range);
          }
        } else {
          this.fetchMetrics(this.currChartSrc, true);
        }
      }, interval * 1000);
      return null;
    },
    forceFetch() {
      if (this.currChartSrc === "custom") {
        if (this.date_range) {
          this.$emit("fetchMetricsCustom", this.date_range);
        }
      } else {
        this.$emit("fetchMetrics", this.currChartSrc, true);
      }
    },
  },
  mounted() {
    this.setFilters();
    this.setRefreshTimer();
  },
  updated() {
    this.setFilters();
    this.setRefreshTimer();
  },
  destroyed() {
    clearInterval(this.refreshTimer);
  },
  computed: {
    min_date() {
      return this.parentPage !== "dashboard" && this.currApp?.flags?.one_year_metrics
        ? dayjs().subtract(1, "year")
        : dayjs().subtract(90, "days");
    },
    appFlags() {
      if (this.currApp) {
        return this.currApp.flags;
      }
      return null;
    },
    periods() {
      const items = this.hasOnlyComparable
        ? MetricsUtils.getComparablePeriods()
        : MetricsUtils.getPeriods();
      if (!this.sinceStartDate) {
        const startDateItem = items.find(item => item.value === "since_start_date");
        const index = items.indexOf(startDateItem);
        if (index >= 0) items.splice(index, 1);
      }

      return this.customPeriodKeys
        ? items.filter(el => this.customPeriodKeys.includes(el.value))
        : items;
    },
  },
  watch: {
    appFlags(to) {
      if (to) {
        this.setRefreshTimer();
      }
    },
    async date_range(to) {
      if (this.currChartSrc === "custom" && to) {
        this.fetchMetricsCustom(to);
      }
    },
    async currChartSrc(to, from) {
      if (to !== from && to !== "custom") {
        await this.setUserSetting(this.standardKey, to);
        this.fetchMetrics(to);
      }
      if (to === "custom") {
        this.previousChartSrc = from;
        if (!this.date_range) this.modalDatePicker = true;
      }
    },
    currApp() {
      if (this.filterLoaded) {
        if (this.currChartSrc === "custom") {
          this.fetchMetricsCustom(this.date_range);
        } else {
          this.fetchMetrics(this.currChartSrc);
        }
      } else {
        this.setFilters();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-date-display-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .reset-dates-form {
    font-size: 0.8rem;
    padding: 0 0 0 10px;
    color: #666;
  }
}
</style>
