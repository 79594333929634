import { ApiBaseUrl, authenticatedFetch } from "./api";

export default class ApiUsers {
  static getUsers(companyId) {
    let query = "";
    if (companyId && companyId.length) {
      query = `?company_id=${companyId}`;
    }
    return authenticatedFetch(`${ApiBaseUrl}users${query}`);
  }

  static inviteUser({ user, companyId }) {
    let query = "";
    if (companyId && companyId.length) {
      query = `?company_id=${companyId}`;
    }
    return authenticatedFetch(`${ApiBaseUrl}users/invite${query}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user }),
    });
  }

  static register(params) {
    return fetch(`${ApiBaseUrl}register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    }).then(response => {
      if (response.ok) {
        return response.text();
      }
      return response.text().then(text => {
        throw new Error(text);
      });
    });
  }

  static confirmInvitedUser({ invitation_token, password, password_confirmation }) {
    return fetch(`${ApiBaseUrl}users/invitation`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user: { invitation_token, password, password_confirmation } }),
    }).then(response => {
      if (response.ok) {
        return response.text();
      }
      return response.text().then(text => {
        throw new Error(text);
      });
    });
  }

  static loginUser({ email, password }) {
    return fetch(`${ApiBaseUrl}users/sign_in`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user: { email, password } }),
    }).then(async res => {
      const data = await res.json();
      if (!res.ok) throw new Error(data.error);
      const token = [...res.headers].find(([key]) => key === "authorization")[1];
      if (!token) throw new Error("Token is null");
      return { ...data, id_token: token.split(" ")[1] };
    });
  }

  static sendResetLoginUser(email) {
    return fetch(`${ApiBaseUrl}users/password`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ user: { email } }),
    }).then(async res => {
      const json = await res.json();
      if (res.status == 422) throw new Error(json.status);
      if (!res.ok) throw new Error("Error occured, please retry later");
      else return json;
    });
  }

  static loginSSO(companyId) {
    return fetch(`${ApiBaseUrl}sso/login?company_id=${companyId}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then(async res => {
      const data = await res.json();
      if (!res.ok) throw new Error(data.error);
      else return data;
    });
  }

  static getEmailCompany(email) {
    return fetch(`${ApiBaseUrl}sso/company?email=${email}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }).then(async res => {
      if (!res.ok)
        throw new Error(
          "SSO is not enabled for this domain. Please contact your customer service manager if you would like to enable this feature.",
        );
      else return await res.json();
    });
  }

  static getUser() {
    return authenticatedFetch(`${ApiBaseUrl}users/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static resetPasswordUser({ reset_password_token, password, password_confirmation }) {
    return fetch(`${ApiBaseUrl}users/password`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user: { reset_password_token, password, password_confirmation } }),
    }).then(response => {
      if (response.ok) {
        return response.text();
      }
      return response.text().then(text => {
        throw new Error(text);
      });
    });
  }

  static logoutUser() {
    return authenticatedFetch(`${ApiBaseUrl}users/sign_out`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static updateUser({ user, companyId, updatePermissions }) {
    let queryStr = "";
    if (updatePermissions) {
      const query = ["update_permissions=true"];
      if (companyId && companyId.length) {
        query.push(`company_id=${companyId}`);
      }

      queryStr = `?${query.join("&")}`;
    }

    return authenticatedFetch(`${ApiBaseUrl}users/${user.id}${queryStr}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user }),
    });
  }

  static updateUserSettings({ user }) {
    return authenticatedFetch(`${ApiBaseUrl}users/${user.id}/update_settings`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user: { settings: user.settings } }),
    });
  }

  static impersonate({ userId }) {
    return authenticatedFetch(`${ApiBaseUrl}users/${userId}/impersonate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  static deleteUser({ userId, isAdminingCompany, appId }) {
    const query = [];
    let queryStr = "";
    if (isAdminingCompany) {
      query.push("is_admining_company=true");
    }

    if (appId) {
      query.push(`app_id=${appId}`);
    }

    if (query.length) {
      queryStr = `?${query.join("&")}`;
    }

    return authenticatedFetch(`${ApiBaseUrl}users/${userId}${queryStr}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }).then(() => userId);
  }
}
