import { useLegacyLastErrorStore } from "@/pinia/legacyLastErrorStore";

export default {
  methods: {
    getInputKey(displayType) {
      if (displayType === "desktop") return "bgImageInput";
      if (displayType === "mobileWeb") return "bgImageMobileWebInput";
      if (displayType === "pushAndroid") return "androidImageInput";
      if (displayType === "pushIos") return "iosImageInput";
      return "";
    },
    getActionKey(displayType) {
      if (displayType === "desktop") return "rf_settings_bg_image";
      if (displayType === "mobileWeb") return "rf_settings_mobile_bg_image";
      if (displayType === "pushAndroid") return "rf_settings_bg_image";
      if (displayType === "pushIos") return "rf_pinpoint_ios_image";
      return "";
    },
    uploadBgImageFromInput(displayType) {
      const legacyLastErrorStore = useLegacyLastErrorStore();
      legacyLastErrorStore.setLoading(true);
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          const key = this.getActionKey(displayType);
          if (key) this.actions[key] = reader.result;
          if (key && this[key] !== undefined) this[key] = reader.result;
          // some issues with Vue reactivity, so force re-render
          this.$emit("forceRender");
          legacyLastErrorStore.setLoading(false);
        },
        false,
      );

      let inputRef = null;
      const key = this.getInputKey(displayType);
      if (key) inputRef = this.$refs[key];

      if (inputRef?.files?.[0]) {
        reader.readAsDataURL(inputRef.files[0]);
        const key = this.getActionKey(displayType) + "_filename";
        this.actions[key] = inputRef.files[0].name;
      } else {
        legacyLastErrorStore.setLoading(false);
      }
    },
  },
};
