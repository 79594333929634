<template>
  <div v-if="appsStore.app">
    <v-alert v-if="shouldShowWarning" dense prominent outlined type="warning" color="red"
      >{{ messageStart }} <span><a href="#" @click="showLimitDialog">usage limit</a></span
      >. {{ messageEnd }}</v-alert
    >
    <v-dialog persistent v-model="limitDialog" width="500px">
      <v-card flat>
        <v-card-title class="headline">Current Month Usage</v-card-title>
        <v-card-text>
          <h3>
            <v-icon size="14" :color="impressionsColor">lens</v-icon> Impressions:
            {{ currentImpressions }} of
            {{ impressionsHardLimit }}
            {{ exceededImpressionsMessage }}
          </h3>
          <h3>
            <v-icon size="14" :color="usersColor">lens</v-icon> Users: {{ currentUsers }} of
            {{ usersHardLimit }} {{ exceededUsersMessage }}
          </h3>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <a href="mailto:support@redfast.com"
            ><v-btn large depressed color="accent" style="width: 150px">Upgrade</v-btn></a
          >
          <v-btn
            large
            depressed
            class="cancel-btn"
            outlined
            width="100px"
            @click="closeLimitDialog"
            style="margin-left: 4px"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useAppsStore } from "@/pinia/appsStore";

export default {
  name: "RfLimitWarning",
  setup: () => ({ appsStore: useAppsStore() }),
  data: () => ({ limitDialog: false }),
  computed: {
    shouldShowWarning() {
      return this.appsStore.app.hard_limited || this.appsStore.app.soft_limited;
    },
    messageStart() {
      if (this.appsStore.app.hard_limited) return "You have reached your monthly";
      if (this.appsStore.app.soft_limited) return "You have almost reached your monthly";
      return "";
    },
    messageEnd() {
      return "Once the limit is reached, Redfast is paused until next month. Please reach out to support@redfast.com to raise your monthly limit.";
    },
    impressionsHardLimit() {
      return this.appsStore.app.path_filter.impressions.hard_limit;
    },
    usersHardLimit() {
      return this.appsStore.app.path_filter.users.hard_limit;
    },
    impressionsColor() {
      return this.exceededImpressions ? "red" : "green";
    },
    usersColor() {
      return this.exceededUsers ? "red" : "green";
    },
    currentUsers() {
      return this.exceededUsers ? this.usersHardLimit : this.appsStore.app.current_monthly_users;
    },
    currentImpressions() {
      return this.exceededImpressions
        ? this.impressionsHardLimit
        : this.appsStore.app.current_monthly_impressions;
    },
    exceededImpressions() {
      return this.appsStore.app.current_monthly_impressions >= this.impressionsHardLimit;
    },
    exceededUsers() {
      return this.appsStore.app.current_monthly_users >= this.usersHardLimit;
    },
    exceededImpressionsMessage() {
      if (this.exceededImpressions) return "(limit exceeded)";
      return "";
    },
    exceededUsersMessage() {
      if (this.exceededUsers) return "(limit exceeded)";
      return "";
    },
  },
  methods: {
    showLimitDialog() {
      this.limitDialog = true;
    },
    closeLimitDialog() {
      this.limitDialog = false;
    },
  },
};
</script>
