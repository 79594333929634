export default {
  computed: {
    surveySelected() {
      return this.actions.rf_retention_survey_selected === "true";
    },
    surveyOptions() {
      const options = ["any"];
      const optionsTotal = +(this.actions.rf_retention_survey_options_total || 3);

      if (this.surveySelected) {
        for (let i = 1; i <= optionsTotal; i += 1) {
          options.push(this.actions[`rf_retention_survey_option_${i}_value`]);
        }
      }
      return options;
    },
    optionsTotal() {
      return +(this.actions.rf_retention_survey_options_total || 3);
    },
  },
};
