<template>
  <div :class="{ 'rf-category-pg-wrapper': true, 'prompt-flag-two-enabled': true }">
    <div v-if="pipeline">
      <div class="all-segments-list-rightcol pipeline-detail-pg-ct">
        <div class="pipelines-details-page" v-if="pipeline">
          <div class="mt-2" v-if="currApp.hard_limited || currApp.soft_limited">
            <RfLimitWarning />
          </div>
          <div class="page-detail-header pipe-header-title">
            <div>
              <div class="page-detail-header-title">
                <h1>{{ pipeline.name }}</h1>
              </div>
              <div v-if="pipeline.description" class="page-detail-description">
                {{ pipeline.description }}
              </div>
            </div>
            <div class="page-detail-header-button">
              <div class="header-button-wrapper pipeline-edit-div">
                <v-btn class="dwnload-as-csv-btn mr-0" small text @click="exportToCsv">
                  <v-icon left size="18">download</v-icon>Download</v-btn
                >
                <RfButton
                  v-if="showAddStageButton"
                  icon="add"
                  button-text="Add Stage"
                  color="primary"
                  small
                  :tooltip="{ bottom: true }"
                  :disabled="userStore.isDisabledWithMessage"
                  @click="newStage"
                />
                <RfButton
                  icon="edit"
                  button-text="Edit"
                  color="primary"
                  small
                  :tooltip="{ bottom: true }"
                  :disabled="userStore.isDisabledWithMessage"
                  @click="editPipeline"
                />
                <RfButton
                  :icon="pipeline.is_enabled ? 'pause' : 'play_arrow'"
                  :button-text="pipeline.is_enabled ? 'Disable' : 'Enable'"
                  color="primary"
                  small
                  :tooltip="{ bottom: true }"
                  :disabled="userStore.isDisabledWithMessage"
                  @click="enablePipeline(!pipeline.is_enabled)"
                />
                <RfButton
                  icon="refresh"
                  button-text="Restart"
                  color="primary"
                  small
                  :tooltip="{ bottom: true }"
                  :disabled="userStore.isDisabledWithMessage"
                  @click="showRestartDialog"
                />
                <RfButton
                  v-if="isCustom"
                  icon="clear"
                  button-text="Delete"
                  color="error"
                  small
                  outlined
                  :tooltip="{ bottom: true }"
                  :disabled="userStore.isDisabledWithMessage"
                  @click="openDeletePipelineDialog"
                />
              </div>
            </div>
          </div>
          <div class="pipelines-stages-container">
            <div class="all-pipes-list-header">
              <div class="all-pipes-list-header-col1">
                <h5>Stages</h5>
                <div v-if="stagePromos.length" class="rf-all-pg-time-select">
                  <v-select
                    hide-details
                    dense
                    outlined
                    background-color="#ffffff"
                    height="34"
                    v-model="stageItemsSelected"
                    :items="stageItems"
                    item-text="text"
                    item-value="value"
                    width="100"
                    :menu-props="{ offsetY: true }"
                  ></v-select>
                </div>
              </div>
              <div class="all-pipes-list-header-col2">
                <span>Date range:</span>
                <div class="rf-all-pg-time-select">
                  <RfMetricsDateRangeSelector
                    parentPage="pipelines"
                    ref="date_selector"
                    v-on:fetchMetrics="fetchMetrics"
                    v-on:fetchMetricsCustom="fetchMetricsCustom"
                    :key="pipelineId"
                  />
                </div>
              </div>
            </div>
            <div class="rf-page-with-no-data" v-if="!pipeline.pipeline_stages.length">
              <NothingToShow message="Start a new stage" />
              <v-btn large depressed color="success" @click="newStage">
                <v-icon left>add</v-icon>Add Stage
              </v-btn>
            </div>
            <div class="all-pipes-list-wrapper" :class="stageItemsSelected">
              <div :class="showPipelinesPromotionsClass" class="pipeline-all-stages">
                <div
                  v-if="stagePromos.length && pipelineMetrics.stage_transitions"
                  class="pipeStage-promo-wrapper"
                >
                  <div
                    v-for="(fromStage, row_index) in pipeline.pipeline_stages"
                    :class="promotionThumbsRow(row_index)"
                    :key="fromStage.id"
                  >
                    <div
                      v-for="(toStage, col_index) in pipeline.pipeline_stages"
                      :class="promotionThumbsColumn(col_index)"
                      :key="toStage.id"
                    >
                      <div v-if="fromStage !== toStage">
                        <button
                          class="pipeStage-promo-group"
                          v-if="getTransitionPromos(fromStage.id, toStage.id).length > 1"
                          @click="showMultiplePromos(fromStage.id, toStage.id)"
                        >
                          <div class="pipeStage-promo-group-pr-count">
                            <span>{{ getTransitionPromos(fromStage.id, toStage.id).length }}</span>
                          </div>
                          <div class="pipeStage-promo-thumb">
                            <RfPromotionThumbnail
                              :item="getTransitionPromos(fromStage.id, toStage.id)[0]"
                              :key="getTransitionPromos(fromStage.id, toStage.id)[0].id"
                              imagewiz="pipelines"
                            />
                            <div class="pipeStage-promo-gs-behind"></div>
                            <span class="ps-circle-line"></span>
                          </div>
                        </button>
                        <template v-else>
                          <div
                            v-for="promo in getTransitionPromos(fromStage.id, toStage.id)"
                            class="pipeStage-promo-thumb"
                            :key="promo.id"
                          >
                            <div class="rf-metrics-simple-display">
                              <span class="rf-metrics-sp-name">{{ promo.name }}</span>
                              <RfPlacementsStats
                                v-if="promo"
                                :model="promo"
                                :metricsKey="currChartSrc"
                                :simpleDisplay="true"
                                :preprocessedGoals="true"
                              />
                            </div>
                            <router-link :key="promo.id" :to="getPromoUrl(promo)">
                              <RfPromotionThumbnail
                                :item="promo"
                                :key="promo.id"
                                imagewiz="pipelines"
                              />
                            </router-link>
                            <span class="ps-circle-line"></span>
                          </div>
                        </template>
                        <div
                          class="pp-promo-lines"
                          v-if="getTransitionPromos(fromStage.id, toStage.id).length"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="pipeStage-s-row pl-stg-row2">
                  <div
                    class="pipeline-stage-single"
                    v-for="(stage, stage_index) in pipeline.pipeline_stages"
                    :key="stage.id"
                  >
                    <div :class="pipelineItemClassName(stage_index)">
                      <div class="pipeStage-name-desc">
                        <div class="pipeStage-name">{{ stage.name }}</div>
                        <div class="pipeStage-desc">{{ stage.description }}</div>
                      </div>
                      <div class="pipeStage-segment-divs">
                        <div class="pipeStage-segment-detail">
                          <div class="pps-segment-name">All</div>
                          <div class="pipeStage-segment-stat">
                            {{ toLocaleNumberString(totalStageMembers(stage.id)) }}
                          </div>
                        </div>
                        <div
                          v-for="segment in orderSegments(stage.segments)"
                          :key="segment.id"
                          class="pipeStage-segment-detail"
                        >
                          <div class="pps-segment-name">
                            <router-link :to="segmentLink(segment)" class="ps-segment-name">
                              {{ segment.name }}
                            </router-link>
                            <div class="pipeStage-segment-icon">
                              <v-icon
                                color="#666"
                                size="20"
                                v-if="segment.segment_type === 'pipeline_usage_increasing'"
                                >trending_up</v-icon
                              >
                              <v-icon
                                color="#999"
                                size="20"
                                v-if="segment.segment_type === 'pipeline_usage_decreasing'"
                                >trending_down</v-icon
                              >
                            </div>
                          </div>
                          <div class="pipeStage-segment-stat">
                            <span>{{
                              toLocaleNumberString(segmentUsersCount(stage.id, segment.id))
                            }}</span>
                            <span class="ps-percent-total">{{
                              percentTotal(
                                segmentUsersCount(stage.id, segment.id),
                                totalStageMembers(stage.id),
                              )
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div v-if="!userStore.isReadOnly" class="pipeStage-last-row">
                        <div class="ps-add-promo-div">
                          <v-btn
                            depressed
                            small
                            outlined
                            color="primary"
                            @click="showPromoDeviceTypeDialog(stage_index)"
                          >
                            Add prompt
                          </v-btn>
                        </div>
                        <div class="pipeStage-btns-div">
                          <div class="pipeStage-more-horiz-div">
                            <v-icon>more_horiz</v-icon>
                            <div class="ps-more-menu">
                              <button class="menu-edit-stage" @click="setEditStage(stage_index)">
                                <v-icon left color="primary" size="12">mode_edit</v-icon>Edit
                              </button>
                              <button
                                class="menu-delete-stage"
                                @click="openDeleteStageDialog(stage_index)"
                              >
                                <v-icon left color="#c00" size="12">clear</v-icon>Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="stage-promos-nontransistion">
                      <div
                        v-for="promo in getTransitionPromos(stage.id, stage.id)"
                        class="pipeStage-promo-thumb"
                        :key="promo.id"
                      >
                        <div class="rf-metrics-simple-display">
                          <span class="rf-metrics-sp-name">{{ promo.name }}</span>
                          <RfPlacementsStats
                            v-if="promo"
                            :model="promo"
                            :metricsKey="currChartSrc"
                            :simpleDisplay="true"
                          />
                        </div>
                        <router-link :key="promo.id" :to="getPromoUrl(promo)">
                          <RfPromotionThumbnail
                            :item="promo"
                            :key="promo.id"
                            imagewiz="pipelines"
                          />
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-dialog width="640" scrollable v-model="multiplePromotionsDialog">
            <v-card>
              <v-card-title>Promotions</v-card-title>
              <v-card-text>
                <div class="multiple-promotions-list">
                  <div
                    v-for="promo in multiplePromotionsList"
                    class="multiple-promos-row"
                    :key="promo.id"
                  >
                    <div class="multiple-promos-col1">
                      <router-link :key="promo.id" :to="getPromoUrl(promo)">
                        <RfPromotionThumbnail
                          :item="promo"
                          :key="`al_${promo.id}`"
                          imagewiz="pipelines"
                        />
                      </router-link>
                    </div>
                    <div class="multiple-promos-col2">
                      <router-link :key="promo.id" :to="getPromoUrl(promo)">
                        {{ promo.name }}
                      </router-link>
                      <RfPlacementsStats
                        v-if="promo"
                        :model="promo"
                        :metricsKey="currChartSrc"
                        :simpleDisplay="true"
                        :preprocessedGoals="true"
                      />
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions class="modal-card-actions-div">
                <v-btn
                  depressed
                  class="cancel-btn"
                  outlined
                  width="100px"
                  @click="multiplePromotionsDialog = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog width="1024" scrollable v-model="editStageDialog">
            <v-card>
              <v-card-title>{{ editStageTitle }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text v-if="editStage">
                <div class="edit-stage-name-div">
                  <RfInfo :model="editStage" ref="stage_info" />
                </div>
                <div class="ppl-slct-tabs-wrapper">
                  <button
                    @click.stop="activeTab = 'tab1'"
                    :class="{ active: activeTab === 'tab1' }"
                  >
                    Usage
                  </button>
                  <button
                    @click.stop="activeTab = 'tab2'"
                    :class="{ active: activeTab === 'tab2' }"
                  >
                    Custom
                  </button>
                </div>
                <div :class="tabContentClass(activeTab)" class="ppl-slct-content-wrapper">
                  <div class="pipe-edit-content ct-tab1">
                    <RfUsage
                      :app="currApp"
                      :model="editStage"
                      ref="stage_usage"
                      objectType="pipeline_stage"
                      :pipeline="pipeline"
                    />
                  </div>
                  <div class="pipe-edit-content ct-tab2">
                    <RfCustom
                      :model="editStage"
                      ref="stage_custom"
                      objectType="pipeline_stage"
                      :pipeline="pipeline"
                    />
                  </div>
                </div>
              </v-card-text>
              <v-card-actions class="modal-card-actions-div">
                <v-btn
                  x-large
                  depressed
                  class="cancel-btn"
                  outlined
                  width="100px"
                  @click="closeStageDialog"
                  >Cancel</v-btn
                >
                <v-btn depressed width="200px" x-large color="primary" @click="submitEditStage">
                  <v-icon small left>save</v-icon>Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="deleteDialog" persistent width="500">
            <v-card>
              <v-card-title class="headline">Delete {{ deleteType }}</v-card-title>
              <v-card-text>Are you sure you want to delete?</v-card-text>
              <v-card-actions class="modal-card-actions-div">
                <v-btn
                  large
                  depressed
                  class="cancel-btn"
                  outlined
                  @click="closeDeleteDialog"
                  style="width: 100px"
                  >Cancel</v-btn
                >
                <v-btn large depressed color="error" @click="submitDelete" style="width: 150px"
                  >Delete</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="restartDialog" persistent width="500">
            <v-card>
              <v-card-title class="headline">Are you sure you want to restart?</v-card-title>
              <v-card-text
                >Restarting a pipeline deletes all segment user counts. Prompt stats will not be
                affected. This cannot be undone.</v-card-text
              >
              <v-card-actions class="modal-card-actions-div">
                <v-btn
                  large
                  depressed
                  class="cancel-btn"
                  outlined
                  @click="closeRestartDialog"
                  style="width: 100px"
                  >Cancel</v-btn
                >
                <v-btn large depressed color="error" @click="submitRestart" style="width: 150px"
                  >Restart</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <CreateEditPipeline
            v-model="editPipelineDialog"
            :editModel="editModel"
            :isCustom="isCustom"
            isEdit
            @closeEditPipeline="closeEditPipeline"
          />
          <RfPromotionCreator
            :pathGroups="allPromptsStore.promptGroups"
            :promoDeviceTypeDialog="promoDeviceTypeDialog"
            v-on:closePromoDeviceTypeDialog="closePromoDeviceTypeDialog"
            :pipelineStageId="addPromoStageId"
            :selectedSegments="addPromoSegments"
            :pipelineId="pipeline.id"
            :pipelineStageName="addPromoStageName"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfCustom from "@/components/RfCommonCards/RfCustom.vue";
import RfUsage from "@/components/RfSegmentCreate/RfUsage.vue";
import RfInfo from "@/components/RfCommonCards/RfInfo.vue";
import RfMetricsDateRangeSelector from "@/components/RfCommonCards/RfMetricsDateRangeSelector.vue";
import RfPromotionThumbnail from "@/components/RfPromotions/RfPromotionThumbnail.vue";
import RfPlacementsStats from "@/components/RfPlacements/RfPlacementsStats.vue";
import NothingToShow from "@/views/placements/NothingToShow.vue";
import RfPromotionCreator from "@/components/RfPromotions/RfPromotionCreator.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import CreateEditPipeline from "@/views/pipelines/CreateEditPipeline.vue";
import MetricsMixin from "@/utils/MetricsMixin";
import RfLimitWarning from "@/components/RfCommonCards/RfLimitWarning.vue";
import { cloneDeep } from "lodash-es";
import { tally } from "@/utils/metricsHelpers";
import { useToastsStore } from "@/pinia/toastsStore";
import { toLocaleNumberString } from "@/utils/stringHelpers";
import { useLegacyLastErrorStore } from "@/pinia/legacyLastErrorStore";
import { useLegacyTmpStore } from "@/pinia/legacyTmpStore";
import { usePipelinesStore } from "@/pinia/pipelinesStore";
import { useAppsStore } from "@/pinia/appsStore";
import { useAllPromptsStore } from "@/pinia/allPromptsStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  name: "RfPipelineView",
  setup: () => ({
    toastsStore: useToastsStore(),
    toLocaleNumberString,
    pipelinesStore: usePipelinesStore(),
    appsStore: useAppsStore(),
    allPromptsStore: useAllPromptsStore(),
    userStore: useUserStore(),
    legacyTmpStore: useLegacyTmpStore(),
    legacyLastErrorStore: useLegacyLastErrorStore(),
  }),
  components: {
    RfCustom,
    RfUsage,
    RfInfo,
    RfMetricsDateRangeSelector,
    RfPromotionThumbnail,
    RfPlacementsStats,
    NothingToShow,
    RfPromotionCreator,
    CreateEditPipeline,
    RfLimitWarning,
    RfButton,
  },
  mixins: [MetricsMixin],
  data() {
    return {
      activeTab: "tab1",
      editStage: null,
      editStageDialog: false,
      editStageIndex: null,
      editPipelineDialog: false,
      singleDayCustom: false,
      currChartSrc: null,
      editModel: {},
      deleteDialog: false,
      deleteType: null,
      deleteStageIndex: null,
      stageItemsSelected: "show_all",
      promoDeviceTypeDialog: false,
      addPromoSegments: [],
      addPromoStageId: null,
      addPromoStageName: false,
      multiplePromotionsList: [],
      multiplePromotionsDialog: false,
      restartDialog: false,
    };
  },
  computed: {
    lastError() {
      return this.legacyLastErrorStore.lastError;
    },
    currApp() {
      return this.appsStore.app;
    },
    pipeline() {
      return this.pipelinesStore.currPipeline;
    },
    pipelineMetrics() {
      return this.pipelinesStore.currPipelineMetrics;
    },
    showPipelinesPromotionsClass() {
      if (this.stageItemsSelected === "show_none") {
        return "hide-all-promotions";
      }
      return "show-all-promotions";
    },
    stagePromos() {
      let items = [];
      this.pipeline.pipeline_stages.forEach(stage => (items = items.concat(stage.paths)));
      return cloneDeep(items);
    },
    promoByStageHash() {
      const fromToHash = {};
      this.stagePromos.forEach(item => {
        item.segments.forEach(segment => {
          if (!fromToHash[segment.pipeline_stage_id]) fromToHash[segment.pipeline_stage_id] = {};
          if (!fromToHash[segment.pipeline_stage_id][item.pipeline_stage_id])
            fromToHash[segment.pipeline_stage_id][item.pipeline_stage_id] = [];

          const alreadyExists = fromToHash[segment.pipeline_stage_id][item.pipeline_stage_id].find(
            i => i.id === item.id,
          );
          if (!alreadyExists) {
            fromToHash[segment.pipeline_stage_id][item.pipeline_stage_id].push(item);
          }
        });
      });
      return fromToHash;
    },
    pipelineId() {
      return this.$route.params.pid;
    },
    appId() {
      return this.$route.params.aid;
    },
    stageItems() {
      if (this.pipeline) {
        let items = [
          { text: "All", value: "show_all" },
          { text: "None", value: "show_none" },
        ];
        items = items.concat(
          this.pipeline.pipeline_stages.map(stage => {
            return {
              text: stage.name,
              value: `show_stg_${stage.order}`,
            };
          }),
        );
        items.splice(-1, 1);
        return items;
      }
      return [];
    },
    showAddStageButton() {
      if (this.stageItems.length < 6) {
        return true;
      }
      return false;
    },
    editStageTitle() {
      if (this.editStage && this.editStage.id) {
        return "Edit Pipeline Stage";
      }
      return "New Pipeline Stage";
    },
    isCustom() {
      return this.pipeline.pipeline_type === "custom";
    },
  },
  methods: {
    exportToCsv() {
      this.exportPipelineToCsv(this.pipeline, this.currChartSrc);
    },
    async submitConfiguration() {
      const newPipeline = cloneDeep(this.editModel);
      if (this.$refs.device) this.$refs.device.fillModelSegment(newPipeline);
      if (this.$refs.location) this.$refs.location.fillModel(newPipeline);
      if (this.$refs.custom) this.$refs.custom.fillModel(newPipeline);
      if (this.$refs.info) this.$refs.info.fillModel(newPipeline);
      if (!newPipeline.id) return;

      await this.pipelinesStore.updatePipeline({
        appId: this.appId,
        pipelineId: this.pipeline.id,
        model: newPipeline,
      });
      this.toastsStore.create({ type: "success", body: "Pipeline stages updated successfully" });

      this.closeEditPipeline();
    },
    getPromoUrl(item) {
      return { path: `/apps/${this.appId}/pipelines/${this.pipelineId}/promotions/${item.id}` };
    },
    segmentLink(segment) {
      return { path: `/apps/${this.currApp.id}/segments/${segment.id}` };
    },
    showMultiplePromos(fromID, toID) {
      this.multiplePromotionsList = this.getTransitionPromos(fromID, toID);
      this.multiplePromotionsDialog = true;
    },
    setEditStage(index) {
      this.editStageIndex = index;
      this.editStage = this.pipeline.pipeline_stages[index];
      this.openEditStageDialog();
    },
    openEditStageDialog() {
      this.editStageDialog = true;
    },
    closeStageDialog() {
      this.editStageDialog = false;
      this.editStage = null;
      this.editStageIndex = null;
    },
    submitEditStage() {
      const newStage = cloneDeep(this.editStage);
      try {
        if (this.$refs.stage_usage) {
          this.$refs.stage_usage.fillModel(newStage);
        }
        if (this.$refs.stage_custom) {
          this.$refs.stage_custom.fillModel(newStage);
        }
        if (this.$refs.stage_info) {
          this.$refs.stage_info.fillModel(newStage);
        }
        this.pipeline.pipeline_stages[this.editStageIndex] = newStage;
        this.editModel = this.pipeline;
        this.submitConfiguration();
        this.closeStageDialog();
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    newStage() {
      this.editStage = cloneDeep(this.legacyTmpStore.pipelineStage);
      this.editStageIndex = this.pipeline.pipeline_stages.length;
      this.openEditStageDialog();
    },
    editPipeline() {
      this.editModel = this.pipeline;
      this.editPipelineDialog = true;
    },
    closeEditPipeline() {
      this.editPipelineDialog = false;
    },
    async fetchMetrics(timePeriod, force) {
      this.singleDayCustom = false;
      if (
        force ||
        !this.pipelineMetrics ||
        this.pipelineMetrics.pipelineId !== this.pipeline.id ||
        this.pipelineMetrics.pipelineId !== this.pipelineId ||
        !this.pipelineMetrics.periods ||
        this.pipelineMetrics.periods.indexOf(timePeriod) < 0
      ) {
        const payload = {
          appId: this.appId,
          pipelineId: this.pipelineId || this.pipeline.id,
          params: { metric_periods: [{ period: timePeriod }] },
        };
        await this.pipelinesStore.getPipelineMetrics(payload);
        this.pipelinesStore.getPipeline(payload);
        this.currChartSrc = timePeriod;
      }
    },
    async fetchMetricsCustom(timePeriod) {
      this.singleDayCustom = timePeriod[0] === timePeriod[1];
      const payload = {
        appId: this.appId,
        pipelineId: this.pipelineId || this.pipeline.id,
        params: { metric_periods: [{ period: "custom", date_range: timePeriod }] },
      };
      await this.pipelinesStore.getPipelineMetrics(payload);
      this.pipelinesStore.getPipeline(payload);
      this.currChartSrc = "custom";
    },
    async submitDelete() {
      try {
        if (this.deleteType === "stage" && this.deleteStageIndex >= 0) {
          this.pipeline.pipeline_stages.splice(this.deleteStageIndex, 1);
          this.editModel = this.pipeline;
          await this.submitConfiguration();
        } else {
          await this.pipelinesStore.deletePipeline({
            appId: this.appId,
            pipelineId: this.pipelineId,
          });
          this.toastsStore.create({ type: "success", body: "Pipeline deleted successfully" });
          this.$router.replace({ path: `/apps/${this.appId}/pipelines` });
        }
        this.closeDeleteDialog();
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    openDeletePipelineDialog() {
      this.deleteDialog = true;
      this.deleteType = "pipeline";
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.deleteType = null;
      this.deleteStageIndex = null;
    },
    openDeleteStageDialog(index) {
      this.deleteDialog = true;
      this.deleteType = "stage";
      this.deleteStageIndex = index;
    },
    promotionThumbsRow(num) {
      return `pipeStage-promo-row p_row_${num}`;
    },
    promotionThumbsColumn(num) {
      return `pipeStage-promo-colm p_col_${num}`;
    },
    pipelineItemClassName(num) {
      return `stage-item-inner div_${num}`;
    },
    segmentUsersCount(stageId, segmentId) {
      if (this.pipelineMetrics.users) {
        const stage = this.pipelineMetrics.users.find(el => el.stage_id === stageId);
        if (stage && stage[this.currChartSrc]) {
          const segment = stage[this.currChartSrc].data.find(
            segmentData => segmentData.id === segmentId,
          );
          if (segment) return segment.users.uniques;
        }
      }
      return 0;
    },
    totalStageMembers(stageId) {
      if (this.pipelineMetrics.users) {
        const stage = this.pipelineMetrics.users.find(el => el.stage_id === stageId);
        if (stage && stage[this.currChartSrc]) {
          const stgdata = stage[this.currChartSrc].data;
          const totalUsers = stgdata.reduce((sum, segmentData) => {
            return sum + segmentData.users.uniques;
          }, 0);
          return totalUsers;
        }
      }
      return 0;
    },
    percentTotal(itemCount, totalCount) {
      if (itemCount && totalCount) {
        return `${((itemCount / totalCount) * 100).toFixed()}%`;
      }
      return null;
    },
    getTransitionPromos(fromStageId, toStageId) {
      if (!this.pipelineMetrics || !this.pipelineMetrics.stage_transitions) return [];

      let promos = [];
      if (this.promoByStageHash[fromStageId] && this.promoByStageHash[fromStageId][toStageId]) {
        promos = this.promoByStageHash[fromStageId][toStageId];
      }
      if (fromStageId !== toStageId) {
        promos.forEach(promo => {
          const data =
            cloneDeep(
              this.pipelineMetrics.stage_transitions.find(transition => {
                const recordData = transition.record_data;
                return (
                  recordData.downstream_stage.id === toStageId &&
                  recordData.upstream_stage.id === fromStageId &&
                  recordData.path.id === promo.id
                );
              }),
            ) || {};
          data.uimpressions = data.stage_path_uimpressions;
          data.impressions = data.stage_path_impressions;
          data.goals = data.goal_stage_transitions;
          if (Object.keys(promo.custom_filter).length > 0) {
            data.totalGoals =
              tally(data, this.currChartSrc, "custom_goals_accepted_stage_transitions") +
              tally(data, this.currChartSrc, "custom_goals_accepted2_stage_transitions") +
              tally(data, this.currChartSrc, "custom_goals_declined_stage_transitions") +
              tally(data, this.currChartSrc, "custom_goals_dismissed_stage_transitions") +
              tally(data, this.currChartSrc, "custom_goals_holdout_stage_transitions");
          } else {
            data.totalGoals = tally(data, this.currChartSrc, "goal_stage_transitions");
          }
          promo.data = data;
        });
      }
      return promos;
    },
    async showPromoDeviceTypeDialog(stage_index) {
      const stage = this.pipeline.pipeline_stages[stage_index];
      const transitionStage = this.pipeline.pipeline_stages[stage_index + 1];
      this.addPromoStageId = transitionStage ? transitionStage.id : stage.id;
      this.addPromoStageName = stage.name;
      this.addPromoSegments = stage.segments;
      this.addPromoSegments.forEach(segment => (segment.pipeline_stage_id = stage.id));

      if (!this.allPromptsStore.promptGroups.length)
        await this.allPromptsStore.getPromptGroups({ appId: this.appId });
      this.promoDeviceTypeDialog = true;
    },
    closePromoDeviceTypeDialog() {
      this.promoDeviceTypeDialog = false;
    },
    tabContentClass(currTab) {
      return `tab-content--${currTab}`;
    },
    enablePipeline(enable) {
      const newPipeline = cloneDeep(this.pipeline);
      newPipeline.is_enabled = enable;
      this.pipelinesStore
        .updatePipeline({ appId: this.appId, pipelineId: this.pipeline.id, model: newPipeline })
        .then(() =>
          this.toastsStore.create({
            type: "success",
            body: "Pipeline stages updated successfully",
          }),
        )
        .catch(() => null);
    },
    submitRestart() {
      this.pipelinesStore
        .restartPipeline({ appId: this.appId, pipelineId: this.pipeline.id })
        .then(() => {
          this.closeRestartDialog();
          this.toastsStore.create({
            type: "success",
            body: "Pipeline restart in progress. Please refresh after several minutes.",
          });
        })
        .catch(() =>
          this.toastsStore.create({ type: "error", body: "Could not restart pipeline." }),
        );
    },
    showRestartDialog() {
      this.restartDialog = true;
    },
    closeRestartDialog() {
      this.restartDialog = false;
    },
    orderSegments(segments) {
      if (!segments) return [];

      return segments.slice().sort((a, b) => {
        if (a.segment_type === "pipeline_usage_increasing") return -1;
        if (b.segment_type === "pipeline_usage_increasing") return 1;
        return 0;
      });
    },
    loadPipeline() {
      if (!this.pipelineId) return;
      const payload = { appId: this.appId, pipelineId: this.pipelineId };
      this.pipelinesStore.getPipeline(payload);
      this.legacyTmpStore.getPipelineStageModel(payload);
      if (this.$refs.date_selector) this.currChartSrc = this.$refs.date_selector.currChartSrc;
    },
    async executeMount() {
      if (this.pipelineId) {
        return this.loadPipeline();
      }
    },
  },
  mounted() {
    this.executeMount();
  },
  watch: {
    async $route() {
      this.loadPipeline();
    },
    async lastError(to) {
      if (!to) return;
      if (to.cause === 404) {
        this.$router.push({ path: `/apps/${this.appId}/pipelines` });
      } else {
        this.toastsStore.create({ type: "error", body: to.message });
      }
    },
  },
};
</script>
