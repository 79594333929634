<template>
  <RfSimpleCard v-if="pipelines.length">
    <RfHiddenPipelines :app="props.app" :pipelines="pipelines" />
  </RfSimpleCard>
</template>

<script setup>
import RfHiddenPipelines from "@/components/RfSettings/RfHiddenPipelines.vue";
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import { usePipelinesStore } from "@/pinia/pipelinesStore";
import { computed, onMounted } from "vue";

const props = defineProps(["app"]);
const pipelinesStore = usePipelinesStore();

const pipelines = computed(() =>
  pipelinesStore.pipelines.filter(p => p.pipeline_type !== "custom"),
);

onMounted(async () => await pipelinesStore.getPipelines(props.app.id));
</script>
