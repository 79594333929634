import dayjs from "dayjs";
import { METRICS_PERIODS } from "./constants/MetricsConstants";
import { dateOrder } from "./DateDisplayUtils";

export default class MetricsUtils {
  static getPeriods() {
    return MetricsUtils.All_Periods.map(item => item); // js implementation of clone, so you don't accidentally mutate
  }

  static getComparablePeriods() {
    return MetricsUtils.All_Periods.filter(
      item => MetricsUtils.Comparisons[item.value] !== undefined,
    );
  }

  static metricDateTimeFormatter(metricsKey, dateString, singleDay) {
    const date = dayjs(`${dateString}`, ["YYYYMMDD", "YYYYMMDDHHmm"]).utc(true);
    if ([METRICS_PERIODS.this_week.value, METRICS_PERIODS.last_week.value].includes(metricsKey)) {
      return date.format("dd");
    }
    if (
      [METRICS_PERIODS.today.value, METRICS_PERIODS.yesterday.value].includes(metricsKey) ||
      singleDay
    ) {
      return date.format("hA");
    }
    return date.format(dateOrder());
  }
}

MetricsUtils.Comparisons = {
  this_month: "last_month",
  this_week: "last_week",
  last_seven_days: "last_seven_to_fourteen_days",
  today: "yesterday",
};

MetricsUtils.All_Periods = Object.values(METRICS_PERIODS);

MetricsUtils.CohortPeriods = [
  { value: 7, name: "Last 7 days" },
  { value: 14, name: "Last 14 days" },
  { value: 30, name: "Last 30 days" },
  { value: 60, name: "Last 60 days" },
  { value: 90, name: "Last 90 days" },
];
