<template>
  <div class="promo-design-group">
    <div class="promo-survey-switch" v-if="surveyEnabled">
      <span>Inputs</span>
      <v-switch v-model="surveySelected" inset></v-switch>
      <span>Survey</span>
    </div>
    <div class="inputs-wrapper" v-if="!surveySelected">
      <v-checkbox
        v-model="inputFormEnabled"
        label="Enable inputs"
        class="background-img-checkbox"
        hide-details
        :disabled="isDisabled"
      />
      <div
        style="padding-top: 18px"
        v-for="inputConfig in inputConfigs"
        :key="inputConfig.labelAction"
      >
        <div class="promo-inputs-fields-div">
          <v-text-field
            outlined
            dense
            hide-details=""
            :label="inputConfig.labelWording"
            v-model.trim="
              currentTranslations.hasOwnProperty(inputConfig.labelAction)
                ? previewActions[inputConfig.labelAction]
                : actions[inputConfig.labelAction]
            "
            :disabled="
              isLocalized
                ? currentTranslations.hasOwnProperty(inputConfig.labelAction)
                : !inputFormEnabled
            "
            placeholder="e.g.: Email address"
          />
          <v-text-field
            outlined
            dense
            hide-details=""
            :label="inputConfig.placeholderWording"
            v-model.trim="
              currentTranslations.hasOwnProperty(inputConfig.placeholderAction)
                ? previewActions[inputConfig.placeholderAction]
                : actions[inputConfig.placeholderAction]
            "
            :disabled="
              isLocalized
                ? currentTranslations.hasOwnProperty(inputConfig.placeholderAction)
                : !inputFormEnabled
            "
          />
        </div>
        <div class="promo-inputs-fields-div" style="padding-top: 15px">
          <v-select
            hide-details
            outlined
            dense
            v-model="actions[inputConfig.typeAction]"
            :label="inputConfig.typeWording"
            :items="typeItems"
            placeholder="Select type"
            :disabled="!inputFormEnabled"
          />
          <v-select
            hide-details
            placeholder="Select width"
            outlined
            dense
            v-model="actions[inputConfig.widthAction]"
            :label="inputConfig.widthWording"
            :items="widthOptions"
            :disabled="!inputFormEnabled"
          />
        </div>
      </div>
    </div>
    <div class="survey-inputs" v-if="surveySelected">
      <div
        class="survey-top-controls mt-5 pb-3 pt-0"
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.38)"
      >
        <v-select
          v-model.number="surveyOptionsTotal"
          class="survey-number-selector"
          hide-details
          outlined
          dense
          label="Number of Options"
          :items="surveyOptionsNumberItems"
        ></v-select>
        <v-text-field
          v-model="actions.rf_retention_survey_options_font_size"
          class="survey-number-selector"
          hide-details
          outlined
          dense
          label="Font size"
          type="text"
        ></v-text-field>
      </div>
      <div class="survey-options-wrapper mt-3">
        <div class="survey-options-row" v-for="n in surveyOptionsTotal" :key="n">
          <v-text-field
            hide-details
            outlined
            dense
            type="text"
            :label="`Option ${n} Label`"
            v-model="
              currentTranslations.hasOwnProperty(`rf_retention_survey_option_${n}_label`)
                ? previewActions[`rf_retention_survey_option_${n}_label`]
                : surveyOptionsPreview[`rf_retention_survey_option_${n}_label`]
            "
            :disabled="currentTranslations.hasOwnProperty(`rf_retention_survey_option_${n}_label`)"
          ></v-text-field>
          <v-text-field
            hide-details
            outlined
            dense
            type="text"
            :label="`Option ${n} Value`"
            v-model="surveyOptionsPreview[`rf_retention_survey_option_${n}_value`]"
          ></v-text-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppsStore } from "@/pinia/appsStore";
import LanguagePreviewMixin from "@/utils/LanguagePreviewMixin";

export default {
  name: "RfRetentionFieldSet",
  props: ["actions", "model", "requireAcceptButton"],
  mixins: [LanguagePreviewMixin],
  setup: () => ({ appsStore: useAppsStore() }),
  data() {
    return {
      widthOptions: [
        { text: "100%", value: "100%" },
        { text: "75%", value: "75%" },
        { text: "50%", value: "50%" },
      ],
      inputFormEnabled: null,
      surveySelected: false,
      surveyOptionsNumberItems: [2, 3, 4, 5],
      surveyOptionsTotal: 0,
      surveyOptionsPreview: {
        rf_retention_survey_option_1_label: null,
        rf_retention_survey_option_1_value: null,
        rf_retention_survey_option_2_label: null,
        rf_retention_survey_option_2_value: null,
        rf_retention_survey_option_3_label: null,
        rf_retention_survey_option_3_value: null,
        rf_retention_survey_option_4_label: null,
        rf_retention_survey_option_4_value: null,
        rf_retention_survey_option_5_label: null,
        rf_retention_survey_option_5_value: null,
      },
    };
  },
  computed: {
    typeItems() {
      const items = [
        { text: "Email", value: "email" },
        { text: "Number", value: "number" },
        { text: "Text", value: "text" },
      ];
      if (this.addressEnabled) {
        items.push({ text: "Address", value: "address" });
      }
      items.push({ text: "None", value: null });
      return items;
    },
    surveyEnabled() {
      const pathType = this.model.path_type;
      return ["retention_modal", "interstitial", "video"].indexOf(pathType) > -1;
    },
    addressEnabled() {
      const key = this.appsStore.app?.providers?.google_play?.maps_api_key;
      const active = this.appsStore.app?.flags?.google_maps_active;
      return key && active;
    },
    isDisabled() {
      if (this.requireAcceptButton) {
        return this.actions.rf_settings_tile_interaction !== "button" || !this.confirmText;
      }
      return false;
    },
    confirmText() {
      return this.isLocalized
        ? this.previewActions.rf_retention_button1_text
        : this.actions.rf_retention_button1_text;
    },
    hasMultipleInputs() {
      const pathType = this.model.path_type;
      return (
        pathType === "retention_modal" ||
        pathType === "interstitial" ||
        pathType === "video" ||
        pathType === "widget"
      );
    },
    inputConfigs() {
      let configs = [
        {
          labelAction: "rf_retention_promo_input_1_label",
          typeAction: "rf_retention_promo_input_1_type",
          widthAction: "rf_retention_promo_input_1_width",
          labelWording: "First Input Label",
          typeWording: "First Input Type",
          widthWording: "First Input Width",
          placeholderAction: "rf_retention_promo_input_1_placeholder",
          placeholderWording: "First Input Placeholder",
        },
      ];
      if (this.hasMultipleInputs) {
        configs = configs.concat([
          {
            labelAction: "rf_retention_promo_input_2_label",
            typeAction: "rf_retention_promo_input_2_type",
            widthAction: "rf_retention_promo_input_2_width",
            labelWording: "Second Input Label",
            typeWording: "Second Input Type",
            widthWording: "Second Input Width",
            placeholderAction: "rf_retention_promo_input_2_placeholder",
            placeholderWording: "Second Input Placeholder",
          },
          {
            labelAction: "rf_retention_promo_input_3_label",
            typeAction: "rf_retention_promo_input_3_type",
            widthAction: "rf_retention_promo_input_3_width",
            labelWording: "Third Input Label",
            typeWording: "Third Input Type",
            widthWording: "Third Input Width",
            placeholderAction: "rf_retention_promo_input_3_placeholder",
            placeholderWording: "Third Input Placeholder",
          },
        ]);
      }
      return configs;
    },
  },
  watch: {
    inputFormEnabled(to) {
      if (!to) {
        if (!this.isLocalized) {
          this.actions.rf_retention_promo_input_1_label = null;
          this.actions.rf_retention_promo_input_2_label = null;
          this.actions.rf_retention_promo_input_3_label = null;
          this.actions.rf_retention_promo_input_1_placeholder = null;
          this.actions.rf_retention_promo_input_2_placeholder = null;
          this.actions.rf_retention_promo_input_3_placeholder = null;
        }
        this.actions.rf_retention_promo_input_1_type = null;
        this.actions.rf_retention_promo_input_1_width = null;
        this.actions.rf_retention_promo_input_2_type = null;
        this.actions.rf_retention_promo_input_2_width = null;
        this.actions.rf_retention_promo_input_3_type = null;
        this.actions.rf_retention_promo_input_3_width = null;
        this.$emit("forceRender");
      }
    },
    surveyOptionsTotal(to, from) {
      this.actions.rf_retention_survey_options_total = to;

      if (to < from) {
        // if the number is decreased, clear excess [`to+1`:5] options from actions
        for (let n = to + 1; n <= 5; n += 1) {
          this.actions[`rf_retention_survey_option_${n}_label`] = null;
          this.actions[`rf_retention_survey_option_${n}_value`] = null;
        }
      } else if (from < to) {
        // if the number is increased, add to the excess [`from+1`:`to`] options from preview
        for (let i = from + 1; i <= to; i += 1) {
          this.actions[`rf_retention_survey_option_${i}_label`] =
            this.surveyOptionsPreview[`rf_retention_survey_option_${i}_label`];
          this.actions[`rf_retention_survey_option_${i}_value`] =
            this.surveyOptionsPreview[`rf_retention_survey_option_${i}_value`];
        }
      }
    },
    surveySelected(to) {
      this.actions.rf_retention_survey_selected = to ? "true" : "false";
    },
    surveyOptionsPreview: {
      handler(to) {
        const optionsLimit = this.actions.rf_retention_survey_options_total;

        for (let n = 1; n <= optionsLimit; n += 1) {
          this.actions[`rf_retention_survey_option_${n}_label`] =
            to[`rf_retention_survey_option_${n}_label`];
          this.actions[`rf_retention_survey_option_${n}_value`] =
            to[`rf_retention_survey_option_${n}_value`];
        }
      },
      deep: true,
    },
  },
  created() {
    this.inputFormEnabled = !!this.readOnlyActions.rf_retention_promo_input_1_label;
    this.surveyOptionsTotal = +this.actions.rf_retention_survey_options_total || 3;
    this.surveySelected =
      this.surveyEnabled && this.actions.rf_retention_survey_selected === "true";
    for (let n = 1; n <= this.surveyOptionsTotal; n += 1) {
      this.surveyOptionsPreview[`rf_retention_survey_option_${n}_label`] =
        this.actions[`rf_retention_survey_option_${n}_label`];
      this.surveyOptionsPreview[`rf_retention_survey_option_${n}_value`] =
        this.actions[`rf_retention_survey_option_${n}_value`];
    }
  },
};
</script>
