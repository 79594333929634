<template>
  <div class="promo-preview-image" :class="previewClass[item.path_type]" :id="elementId">
    <div
      v-if="
        ![PromptTypes.invisible.value, PromptTypes.scheduled_push.value].includes(item.path_type)
      "
    >
      <img :src="imageUrl" loading="lazy" />
    </div>
  </div>
</template>

<script>
import { previewClass } from "@/utils/constants/PreviewClassConstants";
import { PromptTypes } from "@/utils/constants/PromoTypesConstants";
import { isEmail } from "@/utils/prompts/promptHelpers";

export default {
  name: "RfPromotionThumbnail",
  setup: () => ({ previewClass, PromptTypes }),
  props: ["item"],
  data() {
    return {
      elementId: `el-${this.item.id}`,
      DEFAULT_IMAGES: {
        horizontal: "https://assets.redfastlabs.com/images/pulse/prompts/custom/horizontal.png",
        vertical: "https://assets.redfastlabs.com/images/pulse/prompts/custom/vertical.png",
        tile: "https://assets.redfastlabs.com/images/pulse/prompts/custom/tile.png",
        text: "https://assets.redfastlabs.com/images/pulse/prompts/custom/text.png",
        widget: "https://assets.redfastlabs.com/images/pulse/prompts/custom/widget.png",
        interstitial: "https://assets.redfastlabs.com/images/pulse/prompts/custom/interstitial.png",
        retention_modal: "https://assets.redfastlabs.com/images/pulse/prompts/custom/popup-1.png",
        video: "https://assets.redfastlabs.com/images/pulse/prompts/custom/video.png",
        invisible: "https://assets.redfastlabs.com/images/pulse/prompts/custom/invisible.png",
        bottom_banner:
          "https://assets.redfastlabs.com/images/pulse/prompts/custom/bottom_banner.png",
        scheduled_push: require("@/assets/images/push_preview.svg"),
        email: "https://assets.redfastlabs.com/images/pulse/prompts/custom/email.png",
      },
    };
  },
  computed: {
    imageUrl() {
      const { item } = this;
      const deviceType = item.device_type;

      if (isEmail(item.path_type)) {
        return "rf_settings_email_preview_composite";
      }

      const values = {
        ios: "rf_settings_bg_image_ios_ipad_preview_composite",
        tv_os: "rf_settings_bg_image_tv_os_tv_preview_composite",
        android_os: "rf_settings_bg_image_android_os_tv_preview_composite",
        roku_os: "rf_settings_bg_image_roku_os_tv_preview_composite",
        web: "rf_settings_bg_image_web_desktop_preview_composite",
        custom_defined: "rf_settings_bg_image_custom_defined_desktop_preview_composite",
      };
      const previewKey = values[deviceType];
      return item.actions[previewKey]?.length
        ? item.actions[previewKey]
        : this.DEFAULT_IMAGES[item.path_type];
    },
  },
};
</script>

<style lang="scss" scoped>
.promo-preview-image {
  position: relative;
  line-height: normal;
  i {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
</style>
