<template>
  <div>
    <button
      text
      class="rfmodal-button-yes primary-btn-p"
      :style="acceptButtonStyle"
      :disabled="!acceptButtonEnabled"
      v-html="actions.rf_retention_button1_text"
      v-if="!isNewDeviceEditor || actions.rf_retention_button1_text"
    ></button>
    <button
      text
      class="rfmodal-button-yes secondary-btn-p"
      :style="secondaryButtonStyle"
      v-html="actions.rf_retention_button2_text"
      v-if="
        isNewDeviceEditor
          ? actions.rf_settings_confirm_button_2_enabled && actions.rf_retention_button2_text
          : actions.rf_settings_confirm_button_2_enabled
      "
    ></button>
    <button
      text
      class="rfmodal-button-no"
      :style="cancelButtonStyle"
      v-html="actions.rf_retention_button3_text"
      v-if="
        isNewDeviceEditor
          ? actions.rf_settings_cancel_button_enabled && actions.rf_retention_button3_text
          : actions.rf_settings_cancel_button_enabled
      "
    ></button>
  </div>
</template>

<script>
import PromoPreviewMixin from "@/utils/PromoPreviewMixin";

export default {
  name: "RfRetentionButtonsInner",
  props: {
    actions: Object,
    isNewDeviceEditor: { type: Boolean, default: false },
    acceptButtonEnabled: { type: Boolean, default: true },
  },
  mixins: [PromoPreviewMixin],
};
</script>
