import { PromptTypes } from "./constants/PromoTypesConstants";
import { DeviceTypeWording, DefaultDeviceOptions } from "./constants/DevicesConstants";

export const getAllDevices = (customDevices, customDevicesEnabled) => {
  const defaultDevicesArray = Object.values(DefaultDeviceOptions);
  if (!customDevicesEnabled || !customDevices?.length) return defaultDevicesArray;
  return [...defaultDevicesArray, ...customDevices.map(el => ({ value: el.label, text: el.name }))];
};

export const getDevice = (deviceType, customDevices, customDevicesEnabled) => {
  if (customDevicesEnabled && deviceType === DeviceTypeWording.custom_defined.value)
    return `${
      customDevices.length
        ? customDevices.map(el => el.name).join(", ")
        : DeviceTypeWording.custom_defined.text
    }: `;
  if (DeviceTypeWording[deviceType]) return `${DeviceTypeWording[deviceType].text}: `;
  return `${deviceType}`;
};

export const getPromptDeviceType = (
  { path_type, device_type, custom_devices },
  customDevicesEnabled = false,
) =>
  `${getDevice(device_type, custom_devices, customDevicesEnabled)}${PromptTypes[path_type]?.label}`;

export const getAllPrompts = () =>
  Object.values(PromptTypes).map(({ value, label }) => ({ key: value, value: label }));
