<template>
  <div class="input-insert-button">
    <a @click="showDialog = true">
      <v-icon left size="14" color="accent">add</v-icon>
      <span>Insert link</span>
    </a>
    <v-dialog v-model="showDialog" width="550" max-width="550">
      <v-card>
        <v-card-title class="headline">Insert link into {{ title }}</v-card-title>
        <v-card-text>
          <v-text-field
            outlined
            dense
            label="Link text to display"
            v-model.trim="linkText"
            :placeholder="`e.g.: ${textPlaceholder}`"
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-text-field
            outlined
            dense
            label="Link URL"
            v-model.trim="linkURL"
            :placeholder="`e.g.: ${urlPlaceholder}`"
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <RfButton
            button-text="Cancel"
            class="cancel-btn px-5"
            outlined
            @click="closeInsertModal"
          />
          <RfButton color="primary" button-text="Insert link" class="px-7" @click="insertLink" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { ref } from "vue";
import RfButton from "@/components/buttons/RfButton.vue";

const props = defineProps([
  "title",
  "textPlaceholder",
  "urlPlaceholder",
  "deviceType",
  "insertColor",
  "value",
]);
const emit = defineEmits("input");

const showDialog = ref(false);
const linkText = ref("");
const linkURL = ref("");

const insertLink = () => {
  let innerHtml = props.value?.length ? `${props.value} ` : "";
  innerHtml +=
    props.deviceType === "web"
      ? `<a href="${linkURL.value}"` +
        ` style="color:${props.insertColor};text-decoration:underline"` +
        ` target="_blank">${linkText.value}</a>`
      : `[[${linkText.value}|${linkURL.value}]]`;

  emit("input", innerHtml);

  closeInsertModal();
};

const closeInsertModal = () => {
  showDialog.value = false;
  linkURL.value = "";
  linkText.value = "";
};
</script>
