<template>
  <div class="rf-rc-settingss-left rf-rc-col1">
    <div class="promo-design-group flex flex-col gap-4">
      Transparency
      <v-color-picker
        canvas-height="110"
        v-model.trim="actions.rf_settings_overlay_background"
        dot-size="30"
        class="window-transparency-picker"
        outlined
      ></v-color-picker>
      Border
      <div class="promo-fonts-size-input">
        <v-select
          v-model="retentionCorner"
          :items="[all, topRight, topLeft, bottomRight, bottomLeft]"
          class="flex-shrink-1 flex-grow-0"
          label="Corner"
          dense
          hide-details
          outlined
          mandatory
          @input="v => v === 'all' && updateAll()"
        />
        <v-text-field
          outlined
          dense
          hide-details
          type="number"
          label="Radius"
          :value="variable[0]"
          placeholder="e.g.: 400"
          @input="v => (variable = [v, 0])"
        >
          <template #append>
            <v-select
              dense
              :value="variable[1]"
              class="m-0 -mt-2 w-14 p-0"
              hide-details
              :items="WEB_MEASURES"
              @input="v => (variable = [v, 1])"
            >
            </v-select>
          </template>
        </v-text-field>
      </div>
      <div class="promo-fonts-size-input">
        <v-text-field
          outlined
          dense
          hide-details
          type="number"
          label="Width"
          :value="borderWidth[0]"
          placeholder="e.g.: 400"
          @input="v => (borderWidth = [v, 0])"
        >
          <template #append>
            <v-select
              dense
              :value="borderWidth[1]"
              class="m-0 -mt-2 w-14 p-0"
              hide-details
              :items="BORDER_MEASURES"
              @input="v => (borderWidth = [v, 1])"
            >
            </v-select>
          </template>
        </v-text-field>
        <v-select
          v-model="props.actions[`${key}_border-style`]"
          :items="[
            'solid',
            'dashed',
            'dotted',
            'double',
            'groove',
            'ridge',
            'inset',
            'outset',
            'hidden',
          ]"
          class="flex-shrink-1 flex-grow-0"
          label="Corner"
          dense
          hide-details
          outlined
          mandatory
        />
        <RfMenu hideIcon minFit preset="promptStatus">
          <template #activator>
            <v-text-field
              v-model="props.actions[`${key}_border-color`]"
              outlined
              dense
              hide-details
              style="margin-left: 10px"
              label="Color"
            >
            </v-text-field>
          </template>
          <template #menu>
            <v-color-picker
              canvas-height="110"
              v-model.trim="props.actions[`${key}_border-color`]"
              dot-size="30"
              class="window-transparency-picker w-72"
              outlined
            ></v-color-picker>
          </template>
        </RfMenu>
      </div>
      <v-checkbox
        v-model="props.actions['rf_retention_box-sizing']"
        label="Box sizing toggle"
        class="background-img-checkbox"
        true-value="border-box"
        false-value="content-box"
        hide-details
      />

      Shadow
      <div class="promo-fonts-size-input">
        <v-text-field
          outlined
          dense
          hide-details
          type="number"
          label="x"
          :value="boxShadowX[0]"
          @input="v => (boxShadowX = [v, 0])"
        >
          <template #append>
            <v-select
              dense
              :value="boxShadowX[1]"
              class="m-0 -mt-2 w-14 p-0"
              hide-details
              :items="BORDER_MEASURES"
              @input="v => (boxShadowX = [v, 1])"
            >
            </v-select>
          </template>
        </v-text-field>

        <v-text-field
          outlined
          dense
          hide-details
          type="number"
          label="y"
          :value="boxShadowY[0]"
          @input="v => (boxShadowY = [v, 0])"
        >
          <template #append>
            <v-select
              dense
              :value="boxShadowY[1]"
              class="m-0 -mt-2 w-14 p-0"
              hide-details
              :items="BORDER_MEASURES"
              @input="v => (boxShadowY = [v, 1])"
            >
            </v-select>
          </template>
        </v-text-field>
      </div>
      <div class="promo-fonts-size-input">
        <v-text-field
          outlined
          dense
          hide-details
          type="number"
          label="Blur"
          :value="boxShadowBlur[0]"
          placeholder="e.g.: 400"
          @input="v => (boxShadowBlur = [v, 0])"
        >
          <template #append>
            <v-select
              dense
              :value="boxShadowBlur[1]"
              class="m-0 -mt-2 w-14 p-0"
              hide-details
              :items="BORDER_MEASURES"
              @input="v => (boxShadowBlur = [v, 1])"
            >
            </v-select>
          </template>
        </v-text-field>

        <v-text-field
          outlined
          dense
          hide-details
          type="number"
          label="Spread"
          :value="boxShadowSpread[0]"
          placeholder="e.g.: 400"
          @input="v => (boxShadowSpread = [v, 0])"
        >
          <template #append>
            <v-select
              dense
              :value="boxShadowSpread[1]"
              class="m-0 -mt-2 w-14 p-0"
              hide-details
              :items="BORDER_MEASURES"
              @input="v => (boxShadowSpread = [v, 1])"
            >
            </v-select>
          </template>
        </v-text-field>

        <RfMenu hideIcon minFit preset="promptStatus">
          <template #activator>
            <v-text-field
              v-model="boxShadow.color"
              outlined
              dense
              hide-details
              style="margin-left: 10px"
              label="Color"
            >
            </v-text-field>
          </template>
          <template #menu>
            <v-color-picker
              canvas-height="110"
              v-model.trim="boxShadow.color"
              dot-size="30"
              class="window-transparency-picker w-72"
              outlined
            ></v-color-picker>
          </template>
        </RfMenu>
      </div>
    </div>
  </div>
</template>

<script setup>
import { BORDER_MEASURES, useCSSProperty, WEB_MEASURES } from "@/utils/composables/useCSSProperty";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { bottomLeft, bottomRight, topLeft, topRight } from "@/utils/constants/PromptStyleConstants";
import RfMenu from "@/components/menus/RfMenu.vue";

const all = { value: "all", text: "All" };

const props = defineProps({
  actions: { type: Object, default: () => ({}) },
  mobile: { type: Boolean, default: false },
});

const retentionCorner = ref("all");
const key = computed(() => (props.mobile ? "rf_mobile" : "rf_retention"));

const { variable: topRightCorner } = useCSSProperty(
  () => props.actions,
  `${key.value}_border-${topRight.value}-radius`,
);
const { variable: topLeftCorner, init: topLeftCornerInit } = useCSSProperty(
  () => props.actions,
  `${key.value}_border-${topLeft.value}-radius`,
);
const { variable: bottomRightCorner, init: bottomRightCornerInit } = useCSSProperty(
  () => props.actions,
  `${key.value}_border-${bottomRight.value}-radius`,
);
const { variable: bottomLeftCorner, init: bottomLeftCornerInit } = useCSSProperty(
  () => props.actions,
  `${key.value}_border-${bottomLeft.value}-radius`,
);

const cornerMap = computed(() => ({
  [topRight.value]: topRightCorner,
  [topLeft.value]: topLeftCorner,
  [bottomRight.value]: bottomRightCorner,
  [bottomLeft.value]: bottomLeftCorner,
}));

const updateAll = () => {
  topLeftCornerInit(topRightCorner.value);
  bottomRightCornerInit(topRightCorner.value);
  bottomLeftCornerInit(topRightCorner.value);
};

const variable = computed({
  get() {
    if (retentionCorner.value === "all") {
      updateAll();
      return topRightCorner.value;
    }
    return cornerMap.value[retentionCorner.value].value;
  },
  set(v) {
    if (retentionCorner.value === "all") {
      topRightCorner.value = v;
      updateAll();
    } else {
      cornerMap.value[retentionCorner.value].value = v;
    }
  },
});

const { variable: borderWidth } = useCSSProperty(() => props.actions, `${key.value}_border-width`);

const boxShadow = reactive({ x: "0px", y: "0px", blur: "0px", spread: "0px", color: "#ffffff" });
const { variable: boxShadowX } = useCSSProperty(() => boxShadow, "x");
const { variable: boxShadowY } = useCSSProperty(() => boxShadow, "y");
const { variable: boxShadowBlur } = useCSSProperty(() => boxShadow, "blur");
const { variable: boxShadowSpread } = useCSSProperty(() => boxShadow, "spread");

watch(
  boxShadow,
  v =>
    (props.actions[`${key.value}_box-shadow`] =
      `${boxShadow.x} ${boxShadow.y} ${boxShadow.blur} ${boxShadow.spread} ${boxShadow.color}`),
  { deep: true },
);

onMounted(() => {
  if (!props.actions[`${key.value}_border-style`]) {
    props.actions[`${key.value}_border-style`] = "hidden";
  }
  if (!props.actions[`${key.value}_border-color`]) {
    props.actions[`${key.value}_border-color`] = "#000000";
  }
  if (!props.actions.rf_settings_overlay_background) {
    props.actions.rf_settings_overlay_background = "#252323B3";
  }
});
</script>
