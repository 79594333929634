<template>
  <div style="width: 100%">
    <!-- note- ^^this unlabeled div does not exist in brig-->
    <span
      class="rfmodal-close inline-flex flex-row-reverse"
      v-if="!hideCloseButton && closeButtonEnabled"
    >
      <v-icon id="rfmodal-close-icon" :style="{ color: fillColor }">close</v-icon>
      <RfPromptTimeout
        v-if="isNewDeviceEditor"
        :actions="actions"
        class="my-auto mr-2"
        newVariant
      />
    </span>

    <template v-if="isVideo">
      <div class="video-volume-control">
        <span class="video-volume-up">
          <v-icon size="24" :style="{ color: fillColor }" v-if="actions.rf_settings_video_muted"
            >volume_off</v-icon
          >
          <v-icon size="24" class="video-volume-up" :style="{ color: fillColor }" v-else
            >volume_up</v-icon
          >
        </span>
      </div>
      <span class="rfmodal-play">
        <v-icon
          size="24"
          :style="{ color: fillColor }"
          v-if="!actions.rf_settings_video_autoplayed"
        >
          fa-play
        </v-icon>
      </span>
    </template>
    <div class="rfmodal-inner-wrapper">
      <div :class="backgroundContentAlignmentClass">
        <div
          class="rfmodal-text-container"
          :class="messageAlignClass"
          :style="{ width: actions.rf_settings_text_container_max_width }"
        >
          <div>
            <div>
              <!-- extra div layers to match brig ^^ -->
              <div class="rfmodal-header">
                <h1
                  v-html="
                    actions[
                      isNewDeviceEditor && isMobile ? 'rf_mobile_title' : 'rf_retention_title'
                    ]
                  "
                  :style="titleStyle"
                ></h1>
              </div>
              <div class="rfmodal-body">
                <RfBodyCountdown :actions="actions" />
                <div
                  v-html="isNewDeviceEditor && isMobile ? mobileMessage : message"
                  class="rfmodal-message"
                  :style="messageStyle"
                ></div>
              </div>
            </div>
          </div>
          <RfPromoSurveyPreview :actions="actions" v-if="hasSurvey" />
          <RfPromoInputsPreview :actions="actions" v-else />
          <RfPromoConsentPreview
            :actions="actions"
            v-if="consent.needsConsent.value"
            v-model="consent.consentChecked.value"
          />

          <div
            class="rfmodal-footer"
            :class="{
              'disable-bg':
                !actions.rf_retention_button1_text &&
                (!actions.rf_settings_confirm_button_2_enabled ||
                  !actions.rf_retention_button2_text) &&
                (!actions.rf_settings_cancel_button_enabled || !actions.rf_retention_button3_text),
            }"
          >
            <RfRetentionMessageButton
              :actions="actions"
              :isNewDeviceEditor="isNewDeviceEditor"
              :acceptButtonEnabled="consent.consentNotNeededOrGiven.value"
            />
          </div>
          <RfRetentionLinksPreview :actions="actions" />
        </div>
        <div :style="columnspacerWidth" class="rfmodal-colm-spacer" v-if="!isVideo"></div>
      </div>
    </div>
  </div>
</template>

<script>
import PromoPreviewMixin from "@/utils/PromoPreviewMixin";
import RfRetentionLinksPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionLinksPreview.vue";
import RfRetentionMessageButton from "@/components/RfPathCreate/RfRetentionActions/components/RfRetentionMessageButton.vue";
import RfPromoInputsPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfPromoInputsPreview.vue";
import RfPromoSurveyPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfPromoSurveyPreview.vue";
import RfPromoConsentPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfPromoConsentPreview.vue";
import RfPromptTimeout from "./RfPromptTimeout.vue";
import RfBodyCountdown from "../RfBodyCountdown.vue";
import { useConsentPreview } from "@/utils/composables/useConsentPreview";

export default {
  name: "RfRetentionMessage",
  props: {
    actions: { type: Object, default: null },
    isVideo: { type: Boolean, default: false },
    hideCloseButton: { type: Boolean, default: false },
    isNewDeviceEditor: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
  },
  mixins: [PromoPreviewMixin],
  components: {
    RfRetentionLinksPreview,
    RfRetentionMessageButton,
    RfPromoInputsPreview,
    RfPromoSurveyPreview,
    RfPromoConsentPreview,
    RfPromptTimeout,
    RfBodyCountdown,
  },
  setup: props => ({ consent: useConsentPreview(props) }),
  computed: {
    messageStyle() {
      return {
        "font-size":
          this.isNewDeviceEditor && this.isMobile
            ? this.actions.rf_settings_mobile_message_font_size
            : this.actions.rf_settings_message_font_size,
        "color": this.fillColor,
      };
    },
    titleStyle() {
      return {
        "font-size":
          this.isNewDeviceEditor && this.isMobile
            ? this.actions.rf_settings_mobile_title_font_size
            : this.actions.rf_settings_title_font_size,
        "color": this.fillColor,
        "margin-top":
          this.isNewDeviceEditor && this.isMobile
            ? this.actions.rf_settings_mobile_title_padding_top
            : this.actions.rf_settings_title_padding_top,
      };
    },
    messageAlignClass() {
      if (this.actions.rf_retention_align) {
        return `${this.actions.rf_retention_align}-align-mode`;
      }
    },
    backgroundContentAlignmentClass() {
      if (this.actions.rf_retention_img_align === "left") {
        return "rfmodal-content-wrapper b-left-align";
      }
      return "rfmodal-content-wrapper b-right-align";
    },
    hasSurvey() {
      return this.actions.rf_retention_survey_selected === "true";
    },
    columnspacerWidth() {
      return {
        width: `calc(100% - ${this.actions.rf_settings_text_container_max_width})`,
        height: "4px",
      };
    },
  },
};
</script>

<style scoped>
.video-volume-control {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.rfmodal-play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
