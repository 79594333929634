<template>
  <div class="regform-wrapper">
    <div class="regform-content">
      <div class="regform-logo">
        <img src="../assets/images/logo.png" />
      </div>
      <div class="regform-title">
        <h4 slot="title" class="title">Finish Creating Account</h4>
        <span class="login-error-msg" v-if="loginError !== 'Error:  You must login to continue'">{{
          loginError
        }}</span>
      </div>
      <div class="regform-row-ct">
        <v-text-field
          slot="inputs"
          outlined
          dense
          hide-details
          label="First Name"
          placeholder="John"
          v-model="user.first_name"
          type="text"
          v-on:keyup="resetError"
          @keyup.enter="onRegister"
        ></v-text-field>
        <v-text-field
          slot="inputs"
          placeholder="Chapman"
          outlined
          dense
          hide-details
          label="Last Name"
          v-model="user.last_name"
          type="text"
          v-on:keyup="resetError"
          @keyup.enter="onRegister"
        ></v-text-field>
      </div>
      <div class="regform-row-ct">
        <v-text-field
          outlined
          dense
          hide-details
          label="Work Email"
          placeholder="John.chapman@company.com"
          v-model="user.email"
          type="email"
          v-on:keyup="resetError"
          @keyup.enter="onRegister"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          hide-details
          label="Company Name"
          placeholder="Company Inc."
          v-model="user.metadata.company"
          type="text"
          v-on:keyup="resetError"
          @keyup.enter="onRegister"
        ></v-text-field>
      </div>
      <div class="form-short-field">
        <v-text-field
          slot="inputs"
          outlined
          dense
          hide-details
          label="Phone Number"
          placeholder="(310) 123-3456"
          v-model="user.metadata.phone_number"
          type="text"
          v-on:keyup="resetError"
          @keyup.enter="onRegister"
        ></v-text-field>
      </div>
      <div class="regform-divider-line"></div>
      <div class="regform-row-ct">
        <v-text-field
          outlined
          dense
          hide-details
          label="App Name"
          placeholder="Company App"
          v-model="app.name"
          type="text"
          v-on:keyup="resetError"
          @keyup.enter="onRegister"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          hide-details
          label="App Domain Name"
          placeholder="yourappdomain.com"
          v-model="app.domain"
          type="text"
          v-on:keyup="resetError"
          @keyup.enter="onRegister"
        ></v-text-field>
      </div>
      <div class="form-short-field">
        <v-select
          outlined
          dense
          hide-details
          persistent-hint
          hint="Timezone where the app business is based."
          name="timezone_offset"
          id="timezone_offset"
          v-model="app.timezone_offset"
          :items="Object.keys(timezones)"
          label="App Timezone"
        ></v-select>
      </div>
      <div class="regform-divider-line"></div>
      <v-form v-model="isValid" ref="passwordForm" class="form-short-field">
        <v-text-field
          class="regform-field-padding pb-0"
          outlined
          dense
          label="Password"
          prepend-inner-icon="lock_outline"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          v-model="user.password"
          @input="$refs.passwordForm.validate()"
          :rules="passwordRules()"
          v-on:keyup="resetError"
          @keyup.enter="onRegister"
        ></v-text-field>
        <v-text-field
          ref="confirm"
          class="regform-confirm-pswd"
          outlined
          dense
          :type="showConfirmPassword ? 'text' : 'password'"
          @click:append="showConfirmPassword = !showConfirmPassword"
          :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
          :rules="passwordConfirmRules(() => user.password)"
          label="Confirm Password"
          prepend-inner-icon="lock_outline"
          v-model="user.confirmation"
          v-on:keyup="resetError"
          @keyup.enter="onRegister"
        ></v-text-field>
      </v-form>
    </div>
    <div class="regform-action-footer">
      <v-btn depressed x-large color="accent" :disabled="!isValid" @click="onRegister">
        Submit
      </v-btn>
    </div>
  </div>
</template>
<script>
import { useLegacyLastErrorStore } from "@/pinia/legacyLastErrorStore";
import { passwordConfirmRules, passwordRules } from "@/utils/PasswordUtils";

export default {
  name: "RfRegister",
  components: {},
  props: ["onSubmit", "success"],
  setup: () => ({
    passwordRules,
    passwordConfirmRules,
    legacyLastErrorStore: useLegacyLastErrorStore(),
  }),
  data() {
    return {
      user: {
        email: "",
        first_name: "",
        last_name: "",
        password: null,
        confirmation: null,
        metadata: {
          phone_number: "",
          company: "",
        },
      },
      error: null,
      showError: false,
      app: {
        name: "",
        domain: "",
        timezone_offset: "Pacific Time (US & Canada)",
      },
      timezones: {
        "Pacific Time (US & Canada)": "Pacific Time",
        "Mountain Time (US & Canada)": "Mountain Time",
        "Central Time (US & Canada)": "Central Time",
        "Eastern Time (US & Canada)": "Eastern Time",
        "Alaska": "Alaska",
        "Hawaii": "Hawaii",
      },
      urlParams: {},
      showPassword: false,
      showConfirmPassword: false,
      isValid: false,
    };
  },
  methods: {
    onRegister() {
      this.showError = true;
      if (
        !this.user.email.length ||
        !this.user.first_name.length ||
        !this.user.metadata.phone_number.length ||
        !this.user.metadata.company.length ||
        !this.app.name.length ||
        !this.app.domain.length
      ) {
        this.error = "All fields must be filled in";
        return;
      }
      if (
        this.user.password &&
        this.user.confirmation &&
        this.user.confirmation === this.user.password
      ) {
        try {
          this.$emit("submit", {
            user: this.user,
            app: this.app,
          });
        } catch (e) {
          this.error = e;
          this.showError = true;
        }
      } else {
        this.error = "Passwords must match!";
      }
    },
    resetError() {
      this.error = null;
      this.showError = false;
    },
    displayError(error) {
      console.log(error);
      const message = error
        .toString()
        .replace(/[_\[]/g, " ")
        .replace(/^Error:/, "")
        .replace(/[\]"{}:]/g, "");

      return `Error: ${message}`;
    },
  },
  computed: {
    loginError() {
      if (this.showError) {
        return this.error || this.displayError(this.legacyLastErrorStore.lastError);
      }
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams);
    if (params && params.email) {
      this.user.email = params.email;
    }
  },
};
</script>

<style scoped>
.login-error-msg {
  color: #c00;
  display: block;
  text-align: left;
  padding: 0.5rem 0;
  font-size: 1rem;
}
</style>
