<template>
  <div class="rf-all-pipelines-container" style="padding-top: 100px">
    <div class="page-title-wrapper">
      <h1 class="page-title-container" style="border: 0">
        <span class="all-caps">Standard Pipelines</span>
      </h1>
      <div class="retention-blurbs-btn">
        <RfButton
          icon="add"
          button-text="New Pipeline"
          color="success"
          :disabled="userStore.isDisabledWithMessage"
          @click="newPipeline"
        />
      </div>
    </div>
    <div class="default-ppl-wrapper">
      <v-hover
        v-slot:default="{ hover }"
        v-for="(pipeline, index) in defaultPipelines"
        :key="pipeline.id"
      >
        <v-card
          :elevation="hover ? 5 : 0"
          @click="showPipeline(pipeline.id, pipeline.is_hidden)"
          :class="`ppl-hidden-${pipeline.is_hidden}`"
          class="ppl-default-items"
        >
          <div :class="`dpplitem_${index}`">
            <div class="def-ppl-item-icon">
              <img :src="defaultPipelineIcons[pipeline.pipeline_type] || 'trending_up'" alt="" />
            </div>
            <div class="def-ppl-item-inner">
              <div class="def-ppl-item">
                <div class="def-ppl-item-right">
                  {{ pipeline.name }}
                </div>
              </div>
              <div class="default-ppl-item-desc">
                {{ pipeline.description }}
              </div>
            </div>
          </div>
        </v-card>
      </v-hover>
    </div>

    <div style="margin-top: 10px" class="page-title-wrapper">
      <h1 class="page-title-container" style="border: 0">
        <span class="all-caps">Custom Pipelines</span>
      </h1>
    </div>

    <div class="custom-ppl-wrapper">
      <v-hover v-slot:default="{ hover }" v-for="pipeline in customPipelines" :key="pipeline.id">
        <v-card
          :elevation="hover ? 5 : 0"
          @click="showPipeline(pipeline.id, pipeline.is_hidden)"
          :class="`ppl-hidden-${pipeline.is_hidden}`"
          class="ppl-default-items"
        >
          <div class="dpplitem">
            <div class="def-ppl-item-inner">
              <div class="def-ppl-item">
                <div class="def-ppl-item-right">
                  {{ truncate(pipeline.name, 35) }}
                </div>
              </div>
              <div class="default-ppl-item-desc">
                {{ pipeline.description }}
              </div>
            </div>
            <div class="def-ppl-item-stages">
              <div class="stage-item" v-for="stage in pipeline.pipeline_stages" :key="stage.id">
                {{ truncate(stage.name, 20) }}
              </div>
            </div>
          </div>
        </v-card>
      </v-hover>
    </div>

    <CreateEditPipeline
      v-model="pipelineDialog"
      :editModel="editModel"
      isCustom
      @closeEditPipeline="closeEditPipeline"
    />
  </div>
</template>

<script>
import CreateEditPipeline from "@/views/pipelines/CreateEditPipeline.vue";
import GroupIcon from "@/assets/images/group.svg";
import CartIcon from "@/assets/images/cart.svg";
import EngIcon from "@/assets/images/eng.svg";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import StringUtils from "@/utils/StringUtils";
import { useLegacyTmpStore } from "@/pinia/legacyTmpStore";
import { usePipelinesStore } from "@/pinia/pipelinesStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  name: "RfPipelines",
  components: {
    CreateEditPipeline,
    RfButton,
  },
  setup: () => ({
    truncate: StringUtils.truncate,
    pipelinesStore: usePipelinesStore(),
    userStore: useUserStore(),
    legacyTmpStore: useLegacyTmpStore(),
  }),
  data() {
    return {
      defaultPipelineIcons: {
        member: GroupIcon,
        ecommerce: CartIcon,
        engagement: EngIcon,
      },
      pipelineDialog: false,
      editModel: {},
    };
  },
  computed: {
    pipelines() {
      return this.pipelinesStore.pipelines;
    },
    customPipelines() {
      return this.pipelines.filter(p => p.pipeline_type === "custom");
    },
    defaultPipelines() {
      return this.pipelines.filter(pipeline => pipeline.pipeline_type !== "custom");
    },
  },
  methods: {
    newPipeline() {
      this.editModel = cloneDeep(this.legacyTmpStore.pipeline);
      this.pipelineDialog = true;
    },
    closeEditPipeline() {
      this.editModel = {};
      this.pipelineDialog = false;
    },
    showPipeline(pipelineID, pipelineHidden) {
      if (!pipelineHidden) {
        this.$router.push({ path: `/apps/${this.$route.params.aid}/pipelines/${pipelineID}` });
      }
    },
  },
  async mounted() {
    const appId = this.$route.params.aid;
    await this.pipelinesStore.getPipelines(appId);
    this.legacyTmpStore.getPipelineModel({ appId });
  },
};
</script>
