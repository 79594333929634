<template>
  <div>
    <!-- extra div to match brig -->
    <div>
      <RfPromoInputsPreview :actions="actions" :isInline="true" />
      <RfPromoConsentPreview
        :actions="actions"
        v-if="consent.needsConsent.value"
        v-model="consent.consentChecked.value"
      />
      <button
        text
        class="promo-tile-wrapper-btn-accept"
        :style="acceptButtonStyle"
        :disabled="!consent.consentNotNeededOrGiven.value"
        v-html="actions.rf_retention_button1_text"
        v-if="actions.rf_retention_button1_text"
      />
      <button
        text
        class="promo-tile-wrapper-btn-accept btn-ac-2"
        :style="acceptButtonStyle2"
        v-html="actions.rf_retention_button2_text"
        v-if="actions.rf_settings_confirm_button_2_enabled && actions.rf_retention_button2_text"
      />
      <button
        text
        class="promo-tile-wrapper-btn-no"
        :style="cancelButtonStyle"
        v-html="actions.rf_retention_button3_text"
        v-if="
          actions.rf_settings_cancel_button_enabled === 'true' && actions.rf_retention_button3_text
        "
      />
    </div>
  </div>
</template>

<script>
import RfPromoInputsPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfPromoInputsPreview.vue";
import RfPromoConsentPreview from "@/components/RfPathCreate/RfRetentionActions/components/RfPromoConsentPreview.vue";
import { useConsentPreview } from "@/utils/composables/useConsentPreview";

export default {
  name: "RfRetentionTileButtons",
  components: { RfPromoInputsPreview, RfPromoConsentPreview },
  props: ["actions"],
  setup: props => ({ consent: useConsentPreview(props) }),
  computed: {
    buttonWidth() {
      return this.actions.rf_settings_text_container_max_width === "full" ? "100%" : "auto";
    },
    acceptButtonStyle() {
      return {
        "color": this.actions.button1_text_color,
        "background-color": this.actions.button1_bg_color,
        "width": this.buttonWidth,
        "borderRadius": this.actions.rf_email_button_radius,
      };
    },
    acceptButtonStyle2() {
      return {
        "color": this.actions.button2_text_color,
        "background-color": this.actions.button2_bg_color,
        "width": this.buttonWidth,
        "borderRadius": this.actions.rf_email_button_radius,
      };
    },
    cancelButtonStyle() {
      return {
        width: this.buttonWidth,
        color: this.actions.button3_text_color,
        borderRadius: this.actions.rf_email_button_radius,
      };
    },
  },
};
</script>
