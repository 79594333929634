<template>
  <div class="rf-datepicker">
    <RfMenu
      ref="menu"
      preset="datePicker"
      @show="initDate = localDate"
      @hide="resetCalendar()"
      data-cy="datepicker"
    >
      <template #activator>
        <RfDateIcon class="!h-6 !w-6" />
        <span class="text-body mx-2 flex" data-cy="datepicker--preset-value">
          {{ localDate.preset?.name }}
          <span v-if="!hideCustom && localDate.preset?.name">
            {{
              `: ${localDate.startDate?.format(`${dateOrder()}/YYYY`) || "Start date"} – ${
                localDate.endDate?.format(`${dateOrder()}/YYYY`) || "End date"
              }`
            }}
          </span>
        </span>
      </template>
      <template #menu="{ close }">
        <div
          class="text-body flex-shrink-1 flex max-w-max flex-grow-0"
          :style="{ minWidth: tooltipWidth }"
        >
          <div class="flex-column text-action-buttons flex p-4">
            <RfTooltip
              v-if="showSinceStartDateWarning && tooltipAnchorEl"
              ref="tooltipEl"
              :anchor="tooltipAnchorEl[0]"
            >
              <template #tooltip>
                <div>Only last 90 days of data will be shown</div>
              </template>
            </RfTooltip>
            <template v-if="!hideCustom">
              <span>Custom</span>
              <span class="my-4 border-t border-solid border-strokes"></span>
            </template>
            <div class="list flex flex-col gap-4">
              <template
                v-for="period in MetricsUtils.All_Periods.filter(
                  el => !include || include.includes(el.value),
                )"
              >
                <button
                  v-if="
                    (period.value !== 'since_start_date' || sinceStartDayRef) &&
                    period.value !== 'custom'
                  "
                  :ref="
                    period.value === 'since_start_date' && showSinceStartDateWarning
                      ? 'tooltipAnchorEl'
                      : undefined
                  "
                  class="rounded p-2 text-left"
                  :class="{
                    '!bg-blue-5 !text-blue-1': localDate.preset.value === period.value,
                  }"
                  :data-cy="`datepicker--${period.value}`"
                  :key="period.value"
                  @click="setTimeFromPreset(period.value), close()"
                  v-on="{
                    ...(period.value === 'since_start_date' &&
                      showSinceStartDateWarning && {
                        focus: () => tooltipEl?.show?.(),
                        mouseenter: () => tooltipEl?.show?.(),
                        blur: () => tooltipEl?.hide?.(),
                        mouseleave: () => tooltipEl?.hide?.(),
                      }),
                  }"
                >
                  <span class="inline-flex items-center gap-2">
                    {{ period.name }}
                    <RfWarningStatusIconCopy
                      v-if="period.value === 'since_start_date' && showSinceStartDateWarning"
                      class="mb-0.5 !h-5 !w-5"
                    />
                  </span>
                </button>
              </template>
            </div>
          </div>
          <template v-if="!hideCustom">
            <span class="border-l border-solid border-strokes"></span>
            <div class="flex flex-col px-6 py-4">
              <div class="flex flex-grow-0 items-center gap-4">
                <div class="flex flex-1 flex-col gap-1">
                  <span>Start date</span>
                  <span class="rounded-md bg-input-background px-4 py-2 text-[#454950]">
                    {{
                      (selectedDates.startDate ? selectedDates : localDate).startDate?.format(
                        `${dateOrder()}/YYYY`,
                      ) || "Start date"
                    }}
                  </span>
                </div>
                <div class="flex flex-1 flex-col gap-1">
                  <span>End date</span>
                  <span class="rounded-md bg-input-background px-4 py-2 text-[#454950]">
                    {{
                      (selectedDates.startDate ? selectedDates : localDate).endDate?.format(
                        `${dateOrder()}/YYYY`,
                      ) || "End date"
                    }}
                  </span>
                </div>
              </div>
              <div class="flex-grow-1 my-7 flex w-full flex-col">
                <div class="mb-3 flex flex-grow-0 justify-between">
                  <span class="text-heading-2 !text-black-2"
                    >{{ calendarDate.format("MMMM YYYY") }}
                  </span>
                  <span class="flex flex-grow-0 items-center gap-2">
                    <button @click="changeCalendarMonth(-1)">
                      <RfChevronIcon class="!h-6 !w-6 !fill-grey-4" />
                    </button>
                    <button @click="changeCalendarMonth(1)">
                      <RfChevronIcon
                        class="!h-6 !w-6 !fill-grey-4"
                        style="transform: rotate(180deg)"
                      />
                    </button>
                  </span>
                </div>
                <div class="text-body text-center">
                  <ul class="grid list-none grid-cols-7">
                    <li class="p-3 !text-grey-3">Sun</li>
                    <li class="p-3 !text-grey-3">Mon</li>
                    <li class="p-3 !text-grey-3">Tue</li>
                    <li class="p-3 !text-grey-3">Wed</li>
                    <li class="p-3 !text-grey-3">Thu</li>
                    <li class="p-3 !text-grey-3">Fri</li>
                    <li class="p-3 !text-grey-3">Sat</li>
                  </ul>
                  <ul class="grid list-none grid-cols-7">
                    <li v-for="_ in calendarData.lastMonthDates" class="relative flex !text-grey-3">
                      <div
                        class="flex flex-grow items-center justify-center p-3 hover:bg-none disabled:text-background"
                      ></div>
                    </li>
                    <li
                      v-for="date in calendarData.thisMonthDates"
                      class="relative flex"
                      :class="{
                        'rf-datepicker--day-selected !text-blue-1':
                          (selectedDates.startDate || selectedDates.endDate) &&
                          date.isBetween(
                            selectedDates.startDate || selectedDates.endDate,
                            selectedDates.endDate || selectedDates.startDate,
                            'day',
                            '[]',
                          ),
                        'before:rounded-l-full':
                          selectedDates.startDate && date.isSame(selectedDates.startDate, 'day'),
                        'before:rounded-r-full':
                          selectedDates.endDate && date.isSame(selectedDates.endDate, 'day'),
                      }"
                    >
                      <button
                        class="flex flex-grow items-center justify-center p-3 hover:bg-none disabled:text-background"
                        :disabled="isDateDisabled(date)"
                        @click="selectCalendarDate(date)"
                      >
                        {{ date.date() }}
                      </button>
                    </li>
                    <li v-for="_ in calendarData.nextMonthDates" class="relative flex !text-grey-3">
                      <div
                        class="flex flex-grow items-center justify-center p-3 hover:bg-none disabled:text-background"
                      ></div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="mb-0 mt-auto flex w-full flex-grow-0 gap-4">
                <RfButtonNew
                  class="text-buttons ml-auto mr-0 flex-1"
                  type="secondary"
                  text="Cancel"
                  @click="resetCalendar(), close()"
                />
                <RfButtonNew
                  class="text-buttons ml-0 mr-0 flex-1 !bg-blue-1"
                  text="Save"
                  @click="save(), close()"
                />
              </div>
            </div>
          </template>
        </div>
      </template>
    </RfMenu>
  </div>
</template>

<script setup>
import MetricsUtils from "@/utils/MetricsUtils";
import { getTimeFromPreset } from "@/utils/TimeUtils";
import { ref, reactive, computed, onMounted, nextTick } from "vue";
import { dateOrder } from "@/utils/DateDisplayUtils";
import { convertToDays } from "@/utils/DateUtils";
import RfTooltip from "@/components/tooltip/RfTooltip.vue";
import RfMenu from "../menus/RfMenu.vue";
import RfChevronIcon from "../icons/RfChevronIcon.vue";
import RfDateIcon from "../icons/RfDateIcon.vue";
import RfButtonNew from "../buttons/RfButtonNew.vue";
import RfWarningStatusIconCopy from "../icons/RfWarningStatusIcon.vue";

const emits = defineEmits(["input"]);
const props = defineProps({
  sinceStartDayRef: { type: String, default: null },
  hideCustom: { type: Boolean, default: false },
  include: { type: [Array, null], default: null },
  min: { type: Object, default: null },
  max: { type: Object, default: null },
  preset: { type: String, default: null },
  oneYearMetrics: { type: Boolean, default: false },
});

const tooltipEl = ref();
const tooltipAnchorEl = ref();
const tooltipWidth = ref("100%");
const menu = ref({});
const localDate = ref({});
const initDate = ref({});
const calendarDate = ref(null);
const selectedDates = reactive({ startDate: null, endDate: null });
const daysSinceStartDate = props.sinceStartDayRef
  ? convertToDays(+new Date() - +new Date(props.sinceStartDayRef))?.days
  : null;
const showSinceStartDateWarning = computed(
  () => props.sinceStartDayRef && daysSinceStartDate > 90 && !props.oneYearMetrics,
);

const calendarData = computed(() => {
  const firstDay = calendarDate.value.date(1).day();
  const lastDay = 6 - calendarDate.value.endOf("month").day();
  const lastDate = calendarDate.value.endOf("month").date();

  const lastMonthDates = [...Array(firstDay).keys()]
    .map(el => calendarDate.value.startOf("month").subtract(el + 1, "days"))
    .reverse();
  const thisMonthDates = [...Array(lastDate).keys()].map(el =>
    calendarDate.value.startOf("month").add(el, "days"),
  );
  const nextMonthDates = [...Array(lastDay).keys()].map(el =>
    calendarDate.value.endOf("month").add(el + 1, "days"),
  );
  return { lastMonthDates, thisMonthDates, nextMonthDates };
});

const calculateWidth = () => (tooltipWidth.value = `${menu.value.$el.clientWidth}px`);

const save = () => {
  if (selectedDates.startDate) {
    localDate.value = {
      preset: MetricsUtils.All_Periods.find(el => el.value === "custom"),
      startDate: selectedDates.startDate,
      endDate: selectedDates.endDate || selectedDates.startDate,
    };
  } else {
    selectedDates.startDate = selectedDates.endDate = null;
  }
  emits("input", localDate.value);
  initDate.value = localDate.value;
  nextTick(calculateWidth);
};

const setTimeFromPreset = incomingPreset => {
  const { startDate, endDate, preset } = getTimeFromPreset(incomingPreset, props.sinceStartDayRef);
  localDate.value = {
    preset: MetricsUtils.All_Periods.find(el => el.value === preset),
    startDate,
    endDate,
  };
  calendarDate.value = endDate;
  selectedDates.startDate = selectedDates.endDate = null;
  save();
};

const changeCalendarMonth = (amount = 0) =>
  (calendarDate.value = calendarDate.value.add(amount, "month"));

const selectCalendarDate = date => {
  if ((selectedDates.startDate && selectedDates.endDate) || !selectedDates.startDate) {
    selectedDates.startDate = date;
    selectedDates.endDate = null;
  } else {
    selectedDates.endDate = date;
    if (+selectedDates.startDate > +selectedDates.endDate) {
      [selectedDates.startDate, selectedDates.endDate] = [
        selectedDates.endDate,
        selectedDates.startDate,
      ];
    }
  }
};
const resetCalendar = () => {
  if (initDate.value.preset?.value !== "custom") {
    selectedDates.startDate = selectedDates.endDate = null;
  } else {
    selectedDates.startDate = initDate.value.startDate;
    selectedDates.endDate = initDate.value.endDate;
  }

  localDate.value = initDate.value;
};

const isDateDisabled = date => {
  let minValue = false;
  let maxValue = false;
  if (props.min) minValue = date.isBefore(props.min);
  if (props.max) maxValue = date.isAfter(props.max);
  return minValue || maxValue;
};

onMounted(() => {
  calculateWidth();
  if (props.preset) setTimeFromPreset(props.preset);
});

defineExpose({ setTimeFromPreset });
</script>

<style lang="scss" scoped>
::v-deep.rf-datepicker .rf-menu--button {
  @apply relative flex items-center gap-2 rounded bg-white-1 py-2 pl-8 pr-6;
}

.rf-datepicker--day-selected {
  &:before {
    @apply absolute inset-x-0 top-1/2 h-8 bg-blue-4;

    transform: translateY(-50%);
    content: "";
    z-index: -1;
    transition: background-color 0.3s ease-in-out;
  }
}
</style>
