import { usePromptStore } from "@/pinia/promptStore";
import { isLocalized } from "./prompts/promptHelpers";

export default {
  computed: {
    currLanguage() {
      return usePromptStore().currLanguage;
    },
    previewActions() {
      return { ...this.actions, ...this.currentTranslations };
    },
    currentTranslations() {
      if (!this.isLocalized) return {};
      return (
        (this.model.parsed_translations && this.model.parsed_translations[this.currLanguage]) || {}
      );
    },
    isLocalized() {
      return isLocalized(this.model);
    },
    canConfigure() {
      if (!this.isLocalized) return true;

      return !!this.model.translations_csv;
    },
    readOnlyActions() {
      return this.isLocalized ? this.previewActions : this.actions;
    },
  },
};
