<template>
  <div>
    <RfPromoDetailsInfoRow title="Style">
      <template v-slot:promodetail>
        {{ capitalize(displayType) }}
        <div class="pd-info-edit">
          <RfButton
            icon-size="16"
            icon="edit"
            color="accent"
            x-small
            :disabled="editDisabled"
            @click="showPromoDetailDialog"
          />
        </div>
      </template>
    </RfPromoDetailsInfoRow>
    <v-dialog
      v-model="showPromotionInfoDialog"
      width="370"
      content-class="show-promo-details-actions"
      persistent
    >
      <v-card flat>
        <v-card-title class="headline">Appearance</v-card-title>
        <v-card-text style="padding-bottom: 0">
          <v-radio-group style="margin-top: 0" v-model="displayType">
            <v-radio
              v-for="opt in displayItems"
              v-bind:key="opt.value"
              :label="opt.text"
              :value="opt.value"
              :class="opt.class"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large depressed class="cancel-btn" outlined width="100px" @click="closeDialog"
            >Close</v-btn
          >
          <v-btn large @click="submitConfiguration" width="200px" color="primary" depressed>
            <v-icon small left>save</v-icon>Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RfPromoDetailsInfoRow from "@/components/RfPromotions/RfPromoDetailsInfoRow.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { cloneDeep } from "lodash-es";
import StringUtils from "@/utils/StringUtils";
import { useToastsStore } from "@/pinia/toastsStore";
import { usePromptStore } from "@/pinia/promptStore";
import { getAppearanceStyle } from "@/utils/promptGroupUtils";

export default {
  name: "RfPathGroupDisplay",
  props: ["path", "pathGroupDisplay", "editDisabled"],
  components: { RfPromoDetailsInfoRow, RfButton },
  setup: () => ({
    capitalize: StringUtils.capitalize,
    toastsStore: useToastsStore(),
    promptStore: usePromptStore(),
  }),
  data() {
    return {
      showPromotionInfoDialog: false,
      displayType: this.path.display_type,
    };
  },
  computed: {
    displayItems() {
      return getAppearanceStyle(this.path.device_type);
    },
  },
  methods: {
    closeDialog() {
      this.showPromotionInfoDialog = false;
    },
    showPromoDetailDialog() {
      this.showPromotionInfoDialog = true;
    },
    fillModel(model) {
      model.display_type = this.displayType;
    },
    submitConfiguration() {
      try {
        const model = cloneDeep(this.path);
        this.fillModel(model);

        this.promptStore.updatePathGroup({ appId: this.$route.params.aid, model });
        this.showPromotionInfoDialog = false;
        this.toastsStore.create({ type: "success", body: "Zone updated successfully" });
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
  },
};
</script>
