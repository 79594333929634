import { defineStore } from "pinia";
import { ref } from "vue";
import { useCache } from "@/utils/composables/useCache";
import ApiCustomDevices from "@/apis/ApiCustomDevices";
import { catchable } from "./piniaUtils";

export const useCustomDevicesStore = defineStore("custom-devices", () => {
  const customDevices = ref([]);
  const { cachable } = useCache({ storeVariable: customDevices });

  const getCustomDevices = (appId, forceUpdate = false) =>
    catchable({
      t: () =>
        cachable(
          async () =>
            (customDevices.value = (await ApiCustomDevices.getCustomDevices(appId)) || []),
          appId,
          forceUpdate,
        ),
      loadable: true,
    });

  const createCustomDevice = ({ appId, modelDevice }) =>
    catchable({
      t: async () => {
        await ApiCustomDevices.createCustomDevice(appId, modelDevice);
        await getCustomDevices(appId, true);
      },
      loadable: true,
    });

  const updateCustomDevice = ({ appId, deviceId, modelDevice }) =>
    catchable({
      t: async () => {
        await ApiCustomDevices.updateCustomDevice(appId, deviceId, modelDevice);
        await getCustomDevices(appId, true);
      },
      loadable: true,
    });

  const deleteCustomDevice = ({ appId, deviceId }) =>
    catchable({
      t: async () => {
        await ApiCustomDevices.deleteCustomDevice(appId, deviceId);
        await getCustomDevices(appId, true);
      },
      loadable: true,
    });

  return {
    customDevices,
    getCustomDevices,
    createCustomDevice,
    updateCustomDevice,
    deleteCustomDevice,
  };
});
