import { defineStore } from "pinia";
import ApiApps from "@/apis/ApiApps";
import { catchable } from "./piniaUtils";

export const useAdminsStore = defineStore("admin", () => {
  const createDefaultPipelines = appId =>
    catchable({ t: () => ApiApps.createDefaultPipelines(appId), loadable: true });
  const createDefaultSequences = appId =>
    catchable({ t: () => ApiApps.createDefaultSequences(appId), loadable: true });
  const enablePinpointEmails = appId =>
    catchable({ t: () => ApiApps.enablePinpointEmails(appId), loadable: true });
  const syncSfmc = appId => catchable({ t: () => ApiApps.syncSfmc(appId), loadable: true });
  const purgeSfmc = appId => catchable({ t: () => ApiApps.purgeSfmc(appId), loadable: true });
  const publishSdk = ({ appId, type = "javascript" }) =>
    catchable({ t: () => ApiApps.publishSdk(appId, type), loadable: true });
  const imagewizUpdateCfDistro = ({ appId, all = false }) =>
    catchable({ t: () => ApiApps.imagewizUpdateCfDistro(appId, all), loadable: true });
  const invalidateCache = appId =>
    catchable({ t: () => ApiApps.invalidateCache(appId), loadable: true });
  const createPinpointApp = appId =>
    catchable({ t: () => ApiApps.createPinpointApp(appId), loadable: true });
  const createSampleData = appId =>
    catchable({
      t: async () => {
        await ApiApps.createSampleData(appId);
        await new Promise(resolve => setTimeout(resolve, 100000));
      },
      loadable: true,
    });

  return {
    createDefaultPipelines,
    createDefaultSequences,
    enablePinpointEmails,
    imagewizUpdateCfDistro,
    syncSfmc,
    purgeSfmc,
    publishSdk,
    invalidateCache,
    createPinpointApp,
    createSampleData,
  };
});
