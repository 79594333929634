<template>
  <v-dialog width="1024" scrollable v-model="pipelineDialog">
    <v-card>
      <v-card-title>{{ isEdit ? "Edit pipeline" : "Add a pipeline" }}</v-card-title>
      <v-card-text>
        <div v-if="editModel">
          <RfInfo :model="editModel" ref="info" :key="editModel.id" v-if="isCustom" />
          <div class="ppl-slct-tabs-wrapper">
            <button @click.stop="activeTab = 'tab1'" :class="{ active: activeTab === 'tab1' }">
              Device
            </button>
            <button @click.stop="activeTab = 'tab2'" :class="{ active: activeTab === 'tab2' }">
              Location
            </button>
            <button @click.stop="activeTab = 'tab3'" :class="{ active: activeTab === 'tab3' }">
              Custom
            </button>
          </div>
          <div :class="tabContentClass(activeTab)" class="ppl-slct-content-wrapper">
            <div class="pipe-edit-content ct-tab1">
              <RfDevice
                :modelSegment="editModel"
                :app="appsStore.app"
                ref="device"
                :key="editModel.id"
              />
            </div>
            <div class="pipe-edit-content ct-tab2">
              <RfLocation :model="editModel" ref="location" :key="editModel.id" />
            </div>
            <div class="pipe-edit-content ct-tab3">
              <RfCustom :model="editModel" ref="custom" objectType="pipeline" :key="editModel.id" />
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="modal-card-actions-div">
        <v-btn
          x-large
          depressed
          class="cancel-btn"
          outlined
          width="100px"
          @click="$emit('closeEditPipeline')"
          >Cancel</v-btn
        >
        <v-btn x-large color="primary" style="width: 200px" depressed @click="submitConfiguration">
          <v-icon left>save</v-icon>Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import RfInfo from "@/components/RfCommonCards/RfInfo.vue";
import RfLocation from "@/components/RfSegmentCreate/RfLocation.vue";
import RfDevice from "@/components/RfSegmentCreate/RfDevice.vue";
import RfCustom from "@/components/RfCommonCards/RfCustom.vue";
import { cloneDeep } from "lodash-es";
import { useToastsStore } from "@/pinia/toastsStore";
import { usePipelinesStore } from "@/pinia/pipelinesStore";
import { useAppsStore } from "@/pinia/appsStore";

export default {
  name: "CreateEditPipeline",
  setup: () => ({
    toastsStore: useToastsStore(),
    appsStore: useAppsStore(),
    pipelinesStore: usePipelinesStore(),
  }),
  components: {
    RfInfo,
    RfLocation,
    RfDevice,
    RfCustom,
  },
  props: {
    value: { type: Boolean, required: true },
    editModel: { type: Object, default: () => ({}) },
    isEdit: { type: Boolean, default: false },
    isCustom: { type: Boolean, default: false },
  },
  data() {
    return {
      activeTab: "tab1",
    };
  },
  computed: {
    pipelineDialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    async submitConfiguration() {
      try {
        const appId = this.$route.params.aid;
        const newPipeline = cloneDeep(this.editModel);
        if (this.$refs.device) this.$refs.device.fillModelSegment(newPipeline);
        if (this.$refs.location) this.$refs.location.fillModel(newPipeline);
        if (this.$refs.custom) this.$refs.custom.fillModel(newPipeline);
        if (this.$refs.info) this.$refs.info.fillModel(newPipeline);

        if (newPipeline.id) {
          await this.pipelinesStore.updatePipeline({
            appId,
            pipelineId: this.pipelinesStore.currPipeline.id,
            model: newPipeline,
          });
          this.toastsStore.create({ type: "success", body: "Pipeline updated successfully" });
        } else {
          await this.pipelinesStore.createPipeline({ appId, newPipeline });
          this.toastsStore.create({ type: "success", body: "Pipeline created successfully" });
          this.$router.replace({
            path: `/apps/${appId}/pipelines/${this.pipelinesStore.currPipeline.id}`,
          });
        }
        this.$emit("closeEditPipeline");
      } catch (e) {
        this.toastsStore.create({ type: "error", body: e.message });
      }
    },
    tabContentClass(currTab) {
      return `tab-content--${currTab}`;
    },
  },
};
</script>
