<template>
  <div class="page-detail-header">
    <div class="page-detail-header-button">
      <div class="header-button-wrapper">
        <RfStateButtonsInterior v-if="showStatus && !item.sequence_id" :item="item" />
        <RfButton
          v-if="showEnableDisable"
          :icon="item.is_enabled ? 'pause' : 'play_arrow'"
          :button-text="item.is_enabled ? 'Disable' : 'Enable'"
          :data-cy="item.is_enabled ? 'rf-detail-header--disable' : 'rf-detail-header--enable'"
          small
          outlined
          :tooltip="{ bottom: true }"
          :color="item.is_enabled ? 'info' : 'success'"
          :disabled="userStore.isDisabledWithMessage"
          @click="$emit('enableItem', !item.is_enabled)"
        />

        <template>
          <RfButton
            v-if="showPriority"
            icon="format_list_numbered"
            button-text="Priority"
            small
            outlined
            color="info"
            :disabled="userStore.isDisabledWithMessage"
            :tooltip="{ bottom: true }"
            @click="$emit('showPriorityListDialog')"
          />
          <RfButton
            v-if="showClone"
            icon="content_copy"
            button-text="Clone"
            outlined
            small
            color="info"
            :disabled="userStore.isDisabledWithMessage"
            :tooltip="{ bottom: true }"
            @click="$emit('clone')"
          />
        </template>

        <RfButton
          v-if="showReset"
          icon="autorenew"
          button-text="Reset"
          outlined
          small
          :tooltip="{ bottom: true }"
          color="info"
          :disabled="userStore.isDisabledWithMessage"
          @click="showResetConfirm"
        />

        <v-dialog v-model="showDialog" persistent width="500">
          <template v-slot:activator="{ on }">
            <RfButton
              v-if="!deleteOff"
              icon="clear"
              button-text="Delete"
              outlined
              small
              v-on="on"
              :tooltip="{ bottom: true }"
              color="error"
              :disabled="userStore.isDisabledWithMessage || locked"
            />
          </template>
          <v-card>
            <v-card-title class="headline">{{ dialogTitle }}</v-card-title>
            <v-card-text>{{ dialogText }} </v-card-text>
            <v-card-actions class="modal-card-actions-div">
              <v-btn
                large
                depressed
                class="cancel-btn"
                outlined
                @click="closeDialog"
                style="width: 100px"
                >Cancel</v-btn
              >
              <v-btn large depressed color="error" @click="submitDialog" style="width: 150px">{{
                dialogSubmitLabel
              }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import RfStateButtonsInterior from "@/components/RfPromotions/RfStateButtonsInterior.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { useUserStore } from "@/pinia/userStore";

export default {
  name: "RfDetailHeader",
  components: { RfStateButtonsInterior, RfButton },
  props: [
    "locked",
    "objectType",
    "showClone",
    "showPriority",
    "showReset",
    "deleteOff",
    "showStatus",
    "item",
    "showEnableDisable",
  ],
  setup: () => ({ userStore: useUserStore() }),
  data() {
    return {
      showDialog: false,
      resetConfirm: false,
    };
  },
  computed: {
    dialogTitle() {
      return this.resetConfirm
        ? "Are you sure you want to reset?"
        : "Are you sure you want to delete?";
    },
    dialogText() {
      return this.resetConfirm
        ? "Reset will result in all currently targeted users becoming eligible to receive this prompt, regardless of previous interactions. All limits and frequency caps will be reset."
        : this.deleteWarning;
    },
    dialogSubmitLabel() {
      return this.resetConfirm ? "Reset" : "Delete";
    },
    showTitleText() {
      return this.objectType === "Segment";
    },
    deleteWarning() {
      let message = `Deleting this ${this.objectType} removes all information about this ${this.objectType}. This cannot be undone.`;
      if (this.objectType === "Sequence") {
        message += " Deleting a guide will also delete all of its items.";
      }
      return message;
    },
  },
  methods: {
    showResetConfirm() {
      this.resetConfirm = true;
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.resetConfirm = false;
    },
    submitDialog() {
      if (this.resetConfirm) {
        this.$emit("resetGoals");
      } else {
        this.$emit("submitDelete");
      }
      this.closeDialog();
    },
  },
};
</script>
