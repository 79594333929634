<template>
  <div class="place-create-form">
    <div class="zone-name-input-form" v-if="showFullModal">
      <RfTextInput
        dense
        outlined
        persistent-hint
        autofocus
        placeholder="Name"
        title="Name"
        hint="e.g., New users acquisition"
        :rules="[rules.required]"
        :disabled="userStore.isReadOnly"
        v-model.trim="name"
      />
      <RfTextInput
        dense
        outlined
        persistent-hint
        placeholder="Description"
        title="Description"
        :disabled="userStore.isReadOnly"
        hint="e.g., User acquisition designed around generating new registrations"
        v-model.trim="description"
      />
      <RfTextInput
        dense
        outlined
        persistent-hint
        autofocus
        placeholder="Name"
        title="Zone Identifier"
        :disabled="userStore.isReadOnly"
        hint="No spaces. Underscore or dash is allowed."
        v-model.trim="zone"
      />
    </div>
    <div class="crmod-promo-type-row">
      <div class="crmod-promo-type">
        <RfSelect
          title="Zone Type"
          outlined
          dense
          item-text="label"
          hide-details
          :items="InlinePromptsArray"
          v-model="placementType"
          :disabled="!!model.id || userStore.isReadOnly"
        />
      </div>
      <div class="crmod-promo-type">
        <RfSelect
          title="Device Type"
          outlined
          dense
          hide-details
          :items="DeviceTypeWording"
          v-model="deviceType"
          :disabled="!!model.id || userStore.isReadOnly"
        />
      </div>
      <div class="crmod-promo-type">
        <RfSelect
          :items="dimRatioByPathType"
          v-model="selectedDimRatio"
          dense
          outlined
          :disabled="userStore.isReadOnly"
          title="Aspect Ratio"
          hide-details
        />
      </div>
      <div class="crmod-promo-type">
        <RfSelect
          v-model="zoneType"
          title="Placement Type"
          dense
          outlined
          hide-details
          :items="zoneTypeItems"
          :disabled="zoneTypeDisabled || userStore.isReadOnly"
        />
      </div>
    </div>
    <div class="crmod-promo-dims-row">
      <label>Dimensions</label>
      <div>
        <div class="crmod-promo-type">
          <RfTextInput
            outlined
            dense
            title="Width"
            v-model.trim="settings.width"
            :disabled="currDimValue !== 'custom' || userStore.isReadOnly"
            name="width"
            placeholder="e.g., 200px, 50%"
            persistent-hint
            hide-details
            background-color="#fff"
          />
        </div>
        <div class="crmod-promo-type">
          <RfTextInput
            outlined
            dense
            title="Height"
            :disabled="userStore.isReadOnly"
            v-model.trim="settings.height"
            name="height"
            placeholder="e.g., 200px, 50%"
            persistent-hint
            hide-details
            background-color="#fff"
          />
        </div>
      </div>
    </div>
    <div class="crmod-promo-dims-row" v-if="model.device_type === 'web'">
      <label class="text-no-wrap">Mobile Web</label>
      <div>
        <div class="crmod-promo-type">
          <RfTextInput
            outlined
            dense
            :disabled="currDimValue !== 'custom' || userStore.isReadOnly"
            title="Mobile Width"
            v-model.trim="settings.m_width"
            name="width"
            placeholder="e.g., 200px, 50%"
            persistent-hint
            hide-details
            background-color="#fff"
          />
        </div>
        <div class="crmod-promo-type">
          <RfTextInput
            outlined
            dense
            title="Mobile Height"
            :disabled="userStore.isReadOnly"
            v-model.trim="settings.m_height"
            name="height"
            placeholder="e.g., 200px, 50%"
            persistent-hint
            hide-details
            background-color="#fff"
          />
        </div>
      </div>
    </div>
    <div v-if="showFullModal" class="zone-name-input-form">
      <RfSelect
        v-model="displayType"
        title="Appearance"
        dense
        outlined
        hide-details
        :items="appearanceStyles"
        :disabled="userStore.isReadOnly"
      >
        <template #item="{ item }">
          <div :class="item.class" class="flex items-center">{{ item.text }}</div>
        </template>
      </RfSelect>
    </div>
  </div>
</template>

<script>
import {
  DEFAULT_PROMO_DIMENSIONS,
  PROMO_WIDTH_MULTIPLIER,
  getRatioOptionsByPromoType,
  getDefaultPromoRatio,
} from "@/utils/PromoDimUtils";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import { computed, ref } from "vue";
import { DeviceTypeWording } from "@/utils/constants/DevicesConstants";
import { InlinePrompts } from "@/utils/constants/PromoTypesConstants";
import { useUserStore } from "@/pinia/userStore";
import { getAppearanceStyle } from "@/utils/promptGroupUtils";

export default {
  name: "RfZoneId",
  props: {
    model: Object,
    showFullModal: { type: Boolean, default: false },
  },
  components: { RfTextInput, RfSelect },
  setup({ model }, { expose }) {
    const zone = ref(model.zone);
    const name = ref(model.name);

    expose({
      isValid: computed(() => !!zone.value && /^[\w-]+$/.test(zone.value) && !!name.value),
    });
    return {
      zone,
      name,
      DeviceTypeWording: Object.values(DeviceTypeWording),
      InlinePromptsArray: Object.values(InlinePrompts),
      userStore: useUserStore(),
    };
  },
  data() {
    return {
      description: this.model.description,
      placementType: this.model.path_type,
      deviceType: this.model.device_type,
      settings: this.model.settings,
      zoneType: this.model.zone_type,
      displayType: this.model.display_type,
      originalZoneType: this.model.id && this.model.zone_type,
      rules: { required: value => !!value || "Name field is required." },
      currDimValue: null,
      zoneTypeItems: [
        { text: "Live", value: "live" },
        { text: "Code", value: "code" },
      ],
    };
  },
  watch: {
    model(to) {
      this.zone = to.zone;
    },
    settings: {
      handler(to) {
        if (this.currDimValue !== "custom") {
          const number = parseFloat(to.height, 10) || 0;
          const postfix = to.height.replace(`${number}`, "");
          const width = PROMO_WIDTH_MULTIPLIER[this.currDimValue] * number;
          to.width = `${width}${postfix}`;
          const mNumber = parseFloat(to.m_height, 10);
          const mPostfix = to.m_height.replace(`${mNumber}`, "");
          const mWidth = PROMO_WIDTH_MULTIPLIER[this.currDimValue] * mNumber;
          to.m_width = `${mWidth}${mPostfix}`;
        }
      },
      deep: true,
    },
    currDimValue(to) {
      if (to !== this.settings.iab) {
        this.settings = { ...DEFAULT_PROMO_DIMENSIONS[this.placementType][to] };
      }
    },
    placementType(to) {
      if (to && DEFAULT_PROMO_DIMENSIONS[to]) {
        this.settings = { ...DEFAULT_PROMO_DIMENSIONS[to][this.currDimValue] };
      }
      this.setDefaultAppearance();
    },
    deviceType() {
      this.setDefaultAppearance();
    },
  },
  computed: {
    dimRatioByPathType() {
      return getRatioOptionsByPromoType(this.placementType);
    },
    selectedDimRatio: {
      get() {
        return this.settings.iab || getDefaultPromoRatio(this.placementType);
      },
      set(value) {
        this.currDimValue = value;
      },
    },
    zoneTypeDisabled() {
      return this.originalZoneType === "code";
    },
    appearanceStyles() {
      return getAppearanceStyle(this.deviceType);
    },
    supportSlider() {
      return this.deviceType === DeviceTypeWording.web.value;
    },
  },
  methods: {
    setDefaultAppearance() {
      if (!this.supportSlider && this.displayType === "slider")
        return (this.displayType = this.appearanceStyles[0]);
    },
    fillModel(model) {
      if (!this.name) throw new Error("Name field can't be empty");
      model.name = this.name;
      model.description = this.description;
      model.zone = this.zone;
      model.path_type = this.placementType;
      model.device_type = this.deviceType;
      model.settings = this.settings;
      model.zone_type = this.zoneType;
      model.display_type = this.displayType;
    },
  },
  mounted() {
    this.currDimValue = this.selectedDimRatio;

    if (!this.displayType) this.displayType = this.supportSlider ? "slider" : "stack";
  },
};
</script>
