import { ref } from "vue";
import ApiCustomMetrics from "@/apis/ApiCustomMetrics";
import { catchable } from "@/pinia/piniaUtils";

export const useCustomMetrics = () => {
  const customMetrics = ref([]);

  const getCustomMetrics = () =>
    catchable({
      t: async () => (customMetrics.value = await ApiCustomMetrics.getCustomMetrics()),
      loadable: true,
    });

  const createCustomMetric = customMetric =>
    catchable({
      t: async () => {
        await ApiCustomMetrics.createCustomMetric(customMetric);
        getCustomMetrics();
      },
      loadable: true,
    });
  const updateCustomMetric = customMetric =>
    catchable({
      t: async () => {
        await ApiCustomMetrics.updateCustomMetric(customMetric);
        getCustomMetrics();
      },
      loadable: true,
    });
  const deleteCustomMetric = customMetric =>
    catchable({
      t: async () => {
        await ApiCustomMetrics.deleteCustomMetric(customMetric);
        getCustomMetrics();
      },
      loadable: true,
    });

  return {
    customMetrics,
    getCustomMetrics,
    createCustomMetric,
    updateCustomMetric,
    deleteCustomMetric,
  };
};
