import { useAppsStore } from "@/pinia/appsStore";
import { useCustomFieldsStore } from "@/pinia/customFieldsStore";
import { toValue } from "@vueuse/core";
import { computed, onMounted } from "vue";

export const useCustomFields = (
  { app = null, fieldType: fieldTypeIn } = { app: null, fieldType: null },
) => {
  const customFieldsStore = useCustomFieldsStore();

  const appCustomFields = computed(() => customFieldsStore.segmentCustomFields || []);
  const appSystemFields = computed(() => customFieldsStore.segmentSystemFields || []);

  onMounted(() => {
    const fieldType = fieldTypeIn || "custom";
    const fields = fieldType === "custom" ? appCustomFields.value : appSystemFields.value;
    if (!fields.length) {
      customFieldsStore.getAppCustomFields({
        appId: app ? toValue(app).id : useAppsStore().app.id,
        fieldType,
      });
    }
  });

  const ticks = () => {
    customFieldsStore.segmentCustomFields = ["xd"];
  };

  return { appCustomFields, appSystemFields, customFieldsStore, ticks };
};
