<template>
  <RfSimpleCard>
    <div class="system-app-content">
      <RfTextInput
        v-model="model.id"
        outlined
        persistent-hint
        placeholder="ID"
        title="ID:"
        dense
        readonly
        hint="Application unique identification code."
      >
        <template #append>
          <span class="d-inline-flex align-center">
            <RfButton
              icon="mdi-content-copy"
              @click="copyToClipboard(model.id, 'Application ID copied to clipboard.')"
            />
          </span>
        </template>
      </RfTextInput>
      <RfTextInput
        outlined
        persistent-hint
        title="API Key:"
        placeholder="API Key"
        v-model="model.api_key"
        dense
        readonly
        hint="Application api key."
      >
        <template #append>
          <span class="d-inline-flex align-center">
            <RfButton
              icon="mdi-content-copy"
              @click="copyToClipboard(model.api_key, 'Api Key copied to clipboard.')"
            />
            <RfButton
              icon="mdi-refresh"
              :disabled="userStore.isDisabledWithMessage"
              @click="showApiKeyDialog"
            />
          </span>
        </template>
      </RfTextInput>
      <RfTextInput
        outlined
        persistent-hint
        title="Name: *"
        placeholder="Name *"
        dense
        v-model.trim="name"
        name="name"
        v-validate="'required'"
        hint="Specify the name of your app or website."
        :disabled="userStore.isReadOnly"
        :rules="[rules.required]"
      />
      <RfTextInput
        dense
        outlined
        persistent-hint
        title="Domain: *"
        placeholder="Domain *"
        v-model.trim="domain"
        name="domain"
        v-validate="'required'"
        hint="Specify the domain of your customer facing application."
        :disabled="userStore.isReadOnly"
        :rules="[rules.required]"
      />
      <RfTextInput
        dense
        outlined
        persistent-hint
        title="Domain Aliases:"
        placeholder="Domain Aliases"
        v-model.trim="domain_aliases"
        name="domain_aliases"
        :rules="[domainListRule]"
        hint="Specify additional domains. Do not include http(s)://"
        :disabled="userStore.isReadOnly"
      />
      <RfSelect
        dense
        outlined
        placeholder="Timezone"
        title="Timezone:"
        hint="Timezone where the app business is based."
        persistent-hint
        name="timezone_offset"
        id="timezone_offset"
        v-model="timezone_offset"
        :items="timezoneOptions"
        :disabled="userStore.isReadOnly"
      />
      <RfTextInput
        outlined
        dense
        hide-details
        title="User Session Duration in Seconds"
        v-model.trim="session_timeout"
      />

      <template v-if="pinpointEnabled">
        <span></span>
        <p class="d-flex align-center">
          Sender Email: {{ fromEmail }}
          <RfButton
            icon="edit"
            class="ml-1"
            icon-size="16"
            color="accent"
            :disabled="userStore.isDisabledExtendedWithMessage"
            @click="showEmailDialog"
          />
        </p>
      </template>
    </div>
    <v-dialog v-model="showApiDialog" width="640" persistent>
      <v-card class="rf-custom-web-card-wrapper">
        <v-card-title>Regenerate API Key?</v-card-title>
        <v-card-text>
          Are you sure you want to regenerate the API Key? <br />
          Key <b>{{ this.model.api_key }}</b> will be invalidated.
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn large class="cancel-btn" outlined depressed @click="closeApiKeyDialog"
            >Cancel</v-btn
          >
          <v-btn large style="width: 200px" depressed color="success" @click="regenerateApiKey"
            >Regenerate Key</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="userStore.isAdmin" width="500" v-model="emailDialog" persistent>
      <v-card>
        <v-card-title>Verify Email</v-card-title>
        <v-card-text>
          <v-text-field
            v-model.trim="fromEmail"
            dense
            outlined
            persistent-hint
            label="Verify Email"
            hint="For email prompts, specify the sender email address"
          />
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            large
            style="width: 100px"
            depressed
            class="cancel-btn"
            outlined
            @click="closeEmailDialog"
            >Cancel</v-btn
          >
          <v-btn
            large
            style="width: 150px"
            depressed
            color="success"
            @click="submitEmail"
            :disabled="emailSubmitDisabled"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </RfSimpleCard>
</template>

<script>
import RfSimpleCard from "@/components/RfSimpleCard.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfSelect from "@/components/inputs/RfSelect.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import copyToClipboard from "@/utils/CopyToClipboard";
import { useToastsStore } from "@/pinia/toastsStore";
import { useAppsStore } from "@/pinia/appsStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  name: "RfSystem",
  setup: () => ({
    toastsStore: useToastsStore(),
    appsStore: useAppsStore(),
    userStore: useUserStore(),
  }),
  props: ["model"],
  components: { RfSimpleCard, RfTextInput, RfSelect, RfButton },
  data() {
    return {
      showApiDialog: false,
      name: this.model.name,
      domain: this.model.domain,
      domain_aliases: this.arrayToString(this.model.domain_aliases),
      timezone_offset: this.model.timezone_offset,
      session_timeout: this.model.flags.session_timeout,
      rules: {
        required: value => !!value || "This field is required.",
      },
      fromEmail: this.model.pinpoint_sender_email,
      emailDialog: false,
      copyToClipboard,
    };
  },
  computed: {
    pinpointEnabled() {
      if (!this.model.flags.email_path_enabled) return;
      if (this.model && this.model.providers) {
        const { providers } = this.model;
        return !!(providers.redfast && providers.redfast.pinpoint_app_id);
      }
      return false;
    },
    emailSubmitDisabled() {
      return !this.fromEmail;
    },
    currApp() {
      return this.appsStore.app;
    },
    timezoneOptions() {
      return Object.entries(this.model.timezones).map(([key, value]) => {
        return { text: key, value: value };
      });
    },
  },
  methods: {
    showApiKeyDialog() {
      this.showApiDialog = true;
    },
    closeApiKeyDialog() {
      this.showApiDialog = false;
    },
    regenerateApiKey() {
      this.appsStore.generateApiKey(this.model.id);
      this.showApiDialog = false;
    },
    arrayToString(arr) {
      if (!arr) {
        return "";
      }

      return arr.join(", ");
    },
    fillModel(model) {
      for (const prop of ["name", "domain", "timezone_offset", "filter"]) {
        model[prop] = this[prop];
      }
      model.flags.session_timeout = this.session_timeout;
      if (this.domain_aliases) {
        const parsedAliases = this.domain_aliases.split(",").reduce((acc, site) => {
          acc.push(site.trim().replace(/(^\w+:|^)\/\//, ""));
          return acc;
        }, []);
        model.domain_aliases = parsedAliases;
        this.domain_aliases = parsedAliases.join(", ");
      } else {
        model.domain_aliases = [];
      }
    },
    showEmailDialog() {
      this.emailDialog = true;
    },
    closeEmailDialog() {
      this.emailDialog = false;
    },
    async submitEmail() {
      await this.appsStore.verifyPinpointEmail({ appId: this.model.id, email: this.fromEmail });
      this.fromEmail = this.model.pinpoint_sender_email;
      this.closeEmailDialog();
      this.toastsStore.create({
        type: "success",
        body: "Thank you. Expect an email from Amazon SES with further instructions",
      });
    },
    domainListRule(value) {
      if (value) {
        const values = value.split(",");
        const maxDomains = this.currApp.flags.max_domain_aliases || 3;
        if (values.length > maxDomains) {
          return `You have reached the limit of ${maxDomains} per Application. Contact the Customer Success team for details.`;
        }
        const testedValues = values.filter(item =>
          /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}\.?[a-zA-Z0-9][a-zA-Z0-9-]{0,61}\.?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.?[a-zA-Z]*\:?[0-9]*$/.test(
            item.split(" ").join(""),
          ),
        );
        if (values.length !== testedValues.length) {
          return "Your domain list has invalid characters.";
        }
      }
      return true;
    },
  },
  watch: {
    currApp(to) {
      if (to) {
        this.fromEmail = to.pinpoint_sender_email;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.system-app-content {
  padding: 24px 30px 0px;
  gap: 6px 48px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(400px, 45%), 1fr));
  grid-template-rows: min-content;
}
</style>
