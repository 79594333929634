<template>
  <div class="px-7 pt-7">
    <div class="rf-settings-card-wrapper">
      <div class="add-user-header-div">
        <div class="add-user-title">
          <div class="action-provider-settings-title">
            <h4>Credentials</h4>
            <v-checkbox
              v-if="!userStore.isReadOnly"
              v-model="customProvider.active"
              label="Active"
              class="mt-0 pt-0"
              hide-details
            ></v-checkbox>
          </div>
        </div>
        <RfButton
          icon="add"
          button-text="Add new credential"
          class="px-7"
          color="success"
          :disabled="userStore.isDisabledWithMessage"
          @click="showAddProviderDialog"
        />
      </div>
      <v-simple-table class="rf-settings-table-data">
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 25%" class="text-left">Name</th>
              <th style="width: 55%">Value</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="customProviderInputs.length > 0">
            <tr v-for="(input, index) in customProviderInputs" :key="index">
              <td>{{ input }}</td>
              <td>
                <RfTextInput
                  v-if="!userStore.isReadOnly"
                  background-color="#ffffff"
                  outlined
                  dense
                  flat
                  placeholder="value"
                  v-model="customProvider[input]"
                  required
                  solo
                  hide-details
                />
              </td>
              <td align="right">
                <RfButton
                  icon="close"
                  button-text="Delete"
                  outlined
                  class="px-7 py-5"
                  color="error"
                  :disabled="userStore.isDisabledWithMessage"
                  @click="deleteInput(input)"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="3" align="center">
                <div class="settings-no-data-message">Nothing to show</div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <v-dialog v-model="addNewProviderDialog" persistent max-width="700" width="700">
      <v-card>
        <v-card-title class="headline">Add new credential</v-card-title>
        <v-card-text>
          <div class="helper-text">
            Add a new action's provider information. Refer to the
            <a target="_blank" href="https://help.redfast.com/docs/api-actions">documentation</a>
            for additional information.
          </div>
          <div>
            <RfTextInput title="Credential name" outlined dense v-model="newInput" />
          </div>
        </v-card-text>
        <v-card-actions class="modal-card-actions-div">
          <v-btn
            depressed
            class="cancel-btn px-7"
            outlined
            @click.stop="addNewProviderDialog = false"
            >Cancel</v-btn
          >
          <v-btn
            depressed
            color="success"
            class="px-10"
            @click="createNewInput()"
            :disabled="!newInput"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="addNewCustomActionDialog" persistent max-width="700" width="700">
      <RfCustomConnectorActions
        :app="app"
        :isNew="!editingAction"
        :providerInputs="customProviderInputs"
        :editAction="editingAction"
        :disabled="userStore.isDisabledWithMessage"
        @close="closeKeyModal"
        @updateAction="updateCustomAction"
        @addCustomConnectorAction="addCustomConnectorAction"
      />
    </v-dialog>
    <div flat class="rf-settings-card-wrapper mt-10">
      <div>
        <div class="add-user-header-div">
          <div class="add-user-title">
            <h4>Actions</h4>
          </div>
          <div>
            <RfButton
              icon="add"
              button-text="Add New Action"
              class="px-7"
              color="success"
              :disabled="userStore.isDisabledWithMessage"
              @click="openNewActionForm()"
            />
          </div>
        </div>
        <div>
          <v-simple-table class="rf-settings-table-data custom-connectors-all-data">
            <thead>
              <tr>
                <th class="cc-colm cc-col-1">Name</th>
                <th class="cc-colm cc-col-2">Url</th>
                <th class="cc-colm cc-col-3">Payload</th>
                <th class="cc-colm cc-col-4">Headers</th>
                <th class="cc-colm cc-col-5">Query string</th>
                <th class="cc-colm cc-col-6">Method</th>
                <th class="cc-colm cc-col-7"></th>
              </tr>
            </thead>
            <tbody v-if="customConnectorActions.length > 0">
              <tr v-for="(action, index) in customConnectorActions" :key="index">
                <td class="cc-colm cc-col-1">
                  <span>{{ action.args.description }}</span>
                </td>
                <td
                  class="cc-colm"
                  v-for="(input, inputIndex) in action.args.global_inputs"
                  :key="inputIndex"
                >
                  <span>{{ input.value }}</span>
                </td>
                <td class="cc-colm">
                  <div class="edit-buttons-group">
                    <RfButton
                      :icon="!!userStore.isDisabledWithMessage ? 'mdi-information-variant' : 'edit'"
                      color="accent"
                      @click="editCustomAction(action)"
                    />
                    <RfButton
                      icon="clear"
                      color="error"
                      :disabled="userStore.isDisabledWithMessage"
                      @click="deleteCustomAction(action)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td align="center" colspan="7">
                  <div class="settings-no-data-message">Nothing to show</div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfCustomConnectorActions from "@/components/RfSettings/RfCustomConnectorActions.vue";
import RfTextInput from "@/components/inputs/RfTextInput.vue";
import RfButton from "@/components/buttons/RfButton.vue";
import { useActionsStore } from "@/pinia/actionsStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  name: "RfCustomConnectors",
  components: { RfCustomConnectorActions, RfTextInput, RfButton },
  props: ["app"],
  setup: () => ({ actionsStore: useActionsStore(), userStore: useUserStore() }),
  data() {
    return {
      customProvider: this.app.providers.custom_provider || {},
      creatingNewInput: false,
      newInput: null,
      computeKey: 0,
      creatingNewCustom: false,
      editingAction: null,
      addNewProviderDialog: false,
      addNewCustomActionDialog: false,
    };
  },
  methods: {
    showAddProviderDialog() {
      this.addNewProviderDialog = true;
    },
    closeKeyModal() {
      this.editingAction = false;
      this.addNewCustomActionDialog = false;
    },
    fillModel(model) {
      model.providers.custom_provider = this.customProvider;
      let success = true;
      for (const key in this.customProvider) {
        success = !!this.customProvider[key];
      }
      if (!success) {
        throw new Error("All Custom Provider inputs required a value!");
      }
    },
    openNewInputForm() {
      this.newInput = null;
      this.creatingNewInput = true;
    },
    closeNewInput() {
      this.newInput = null;
      this.creatingNewInput = false;
    },
    connectorActionsByProvider(provider) {
      if (!this.connectorActions) return [];
      return this.connectorActions.filter(action => action.provider === provider);
    },
    createNewInput() {
      this.addNewProviderDialog = false;
      this.customProvider[this.newInput] = "";
      this.creatingNewInput = false;
      this.computeKey++;
      this.newInput = null;
    },
    deleteInput(input) {
      delete this.customProvider[input];
      this.computeKey++;
    },
    addCustomConnectorAction(action) {
      this.actionsStore.createConnectorAction({ appId: this.app.id, newAction: action });
      this.creatingNewCustom = false;
      this.addNewCustomActionDialog = false;
    },
    deleteCustomAction(action) {
      this.actionsStore.deleteConnectorAction({ appId: this.app.id, actionId: action.id });
    },
    editCustomAction(action) {
      this.editingAction = action;
      this.addNewCustomActionDialog = true;
    },
    async updateCustomAction(action) {
      this.editingAction = null;
      await this.actionsStore.updateConnectorAction({
        appId: this.app.id,
        actionId: action.id,
        model: action,
      });
      this.computeKey++;
      this.addNewCustomActionDialog = false;
    },
    openNewActionForm() {
      this.editingAction = null;
      this.addNewCustomActionDialog = true;
    },
  },
  computed: {
    customProviderInputs() {
      this.computeKey; // vue can't watch keys that weren't there on mount, so hack the reactivity
      return Object.keys(this.customProvider).filter(key => key !== "active" && key !== "type");
    },
    connectorActions() {
      return this.actionsStore.connectorActions || [];
    },
    customConnectorActions() {
      this.computeKey;
      return this.connectorActionsByProvider("custom_provider");
    },
  },
  async mounted() {
    if (!this.connectorActions.length) await this.actionsStore.getConnectorActions(this.app.id);
  },
};
</script>

<style lang="scss" scoped>
.action-provider-settings-title {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
}

.helper-text {
  padding-left: 0;
  margin-bottom: 12px;
}
</style>
