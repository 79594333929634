import { cloneDeep } from "lodash-es";
import { useAppsStore } from "@/pinia/appsStore";
import { useUserStore } from "@/pinia/userStore";

export default {
  computed: {
    userStore: () => useUserStore(),
    appsStore: () => useAppsStore(),
    user() {
      return this.userStore.currUser;
    },
    app() {
      return this.appsStore.app;
    },
    allItems() {
      return this.appSettings.rf_filter_settings || {};
    },
    appSettings() {
      if (this.user?.settings) {
        return this.user.settings[this.app.id] || {};
      }
      return {};
    },
  },
  methods: {
    getUserSetting(key) {
      return this.allItems[key];
    },
    setUserSetting(key, value) {
      const items = cloneDeep(this.allItems);

      if (this.valuesDifferent(items[key], value)) {
        items[key] = value;
        return this.updateUserSettings(items);
      }
      return Promise.resolve(true);
    },
    setUserSettings(settings) {
      const items = cloneDeep(this.allItems);
      let changed = false;

      Object.keys(settings).forEach(key => {
        const value = settings[key];
        if (this.valuesDifferent(items[key], value)) {
          items[key] = value;
          changed = true;
        }
      });
      if (changed) return this.updateUserSettings(items);
    },
    updateUserSettings(items) {
      if (!this.user) return;

      const appSettings = cloneDeep(this.appSettings);
      appSettings.rf_filter_settings = items;
      const user = cloneDeep(this.user);
      if (!user.settings) user.settings = {};
      user.settings[this.app.id] = appSettings;

      return this.userStore.updateUserSettings(user);
    },
    valuesDifferent(val1, val2) {
      if (Array.isArray(val1) && Array.isArray(val2)) {
        if (val1.length !== val2.length) return true;
        let different = false;
        val1.every((element, index) => {
          if (element !== val2[index]) {
            different = true;
          }
          return !different; // exit loop if different is true
        });
        return different;
      }
      return val1 !== val2;
    },
    pinItem(id) {
      const pinnedItems = { ...this.getUserSetting(this.key) } || {};
      const isPinned = !!pinnedItems?.[id];
      isPinned ? delete pinnedItems[id] : (pinnedItems[id] = true);
      this.setUserSetting(this.key, pinnedItems);
    },
  },
};
