import ApiUsers from "@/apis/ApiUsers";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useCache } from "@/utils/composables/useCache";
import { catchable } from "./piniaUtils";
import { useAppsStore } from "./appsStore";
import { ADMIN, SUPER_ADMIN } from "./userStore";

export const useUsersStore = defineStore("users", () => {
  const appsStore = useAppsStore();
  const users = ref([]);
  const { cachable } = useCache({ storeVariable: users });

  const currUsersEx = computed(() =>
    appsStore.app
      ? users.value.filter(
          user =>
            [ADMIN, SUPER_ADMIN].includes(user.role) ||
            user.apps.find(app => app.id === appsStore.app.id),
        )
      : users.value,
  );

  const currUsers = computed(() =>
    appsStore.app ? currUsersEx.value.filter(user => user.role !== SUPER_ADMIN) : users.value,
  );

  const mutateUpdateUser = user => {
    const index = users.value.findIndex(item => item.id === user.id);
    if (index === -1) return;
    const copyUsers = [...users.value];
    copyUsers[index] = user;
    users.value = copyUsers;
  };

  const mutateDeleteUser = userId => {
    const index = users.value.findIndex(item => item.id === userId);
    if (index === -1) return;
    const copyUsers = [...users.value];
    copyUsers.splice(index, 1);
    users.value = copyUsers;
  };

  const updateUser = ({ user, companyId, updatePermissions }) =>
    catchable({
      t: async () =>
        mutateUpdateUser(await ApiUsers.updateUser({ user, companyId, updatePermissions })),
    });

  const inviteUser = ({ user, companyId }) =>
    catchable({
      t: async () =>
        (users.value = [...users.value, await ApiUsers.inviteUser({ user, companyId })]),
    });

  const getUsers = (companyId, forceUpdate = false) =>
    catchable({
      t: () =>
        cachable(
          async () => (users.value = await ApiUsers.getUsers(companyId)),
          companyId,
          forceUpdate,
        ),
    });

  const deleteUser = ({ userId, isAdminingCompany, appId }) =>
    catchable({
      t: async () =>
        mutateDeleteUser(await ApiUsers.deleteUser({ userId, isAdminingCompany, appId })),
    });

  return { deleteUser, getUsers, inviteUser, updateUser, users, currUsers, currUsersEx };
});
