import { defineStore } from "pinia";
import { ref } from "vue";
import ApiSequences from "@/apis/ApiSequences";
import { exceptionWrapper, useTableData } from "./piniaUtils";

export const useGuidesStore = defineStore("guides", () => {
  const guides = ref([]);
  const loading = ref(true);
  const error = ref(false);
  const { table, resetPage } = useTableData({ perPageInit: 5 });

  const getGuidesPaged = (appId, params, next = false) =>
    exceptionWrapper(
      async () => {
        if (!next) guides.value = [];
        const { sequences, pagination } = await ApiSequences.getSequences(appId, {
          ...params,
          serialize: "short",
          page: table.page + 1,
          per_page: table.perPage,
        });

        if (next) {
          const guidesFiltered = sequences.filter(
            el => !guides.value.find(({ id }) => id === el.id),
          );
          guides.value = [...guides.value, ...guidesFiltered];
        } else {
          guides.value = sequences;
        }

        table.page = pagination.page;
        table.totalCount = pagination.total_count;
        table.totalPages = pagination.total_pages;
      },
      { loading, error, toast: true },
    );

  const updateLocalGuide = guide => {
    if (!guides.value.length) return;
    const index = guides.value.findIndex(({ id }) => id === guide.id);
    const copy = [...guides.value];
    if (index === -1) return;
    copy[index] = { ...guides.value[index], ...guide };
    guides.value = copy;
  };

  const bulkUpdate = (appId, guidesIn = []) =>
    exceptionWrapper(
      async () => {
        await ApiSequences.bulkUpdate(appId, guidesIn);
        guidesIn.forEach(updateLocalGuide);
      },
      { toast: true, throw: true },
    );

  const bulkDelete = (appId, guidesIn = []) =>
    exceptionWrapper(
      () =>
        ApiSequences.bulkDelete(
          appId,
          guidesIn.map(({ id }) => ({ id })),
        ),
      { toast: true, throw: true },
    );

  return {
    guides,
    loading,
    error,
    table,
    resetPage,
    getGuidesPaged,
    bulkUpdate,
    bulkDelete,
  };
});
